import React, { Component } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getReviewBycompanyidAction } from "../../../../store/actions";
// import RatingForm from '../Ratings/RatingForm';
import Helper from "../../../../Configs/Helper";



 class Review extends Component {
     constructor(){
        super()
        this.state={
            addReview:false,
            itemsToShow: 3,
            showMore: false,}
        this.showMore = this.showMore.bind(this);
     }
     showMore() {
         
            console.log('Continue.......show more');
        //  console.log(this.props.reviewerData);
        //  var cmpny=this.props.reviewerData.data.reviews.map((cmpnyid)=>cmpnyid.reviewer_id);
        //  console.log(cmpny);

        //  console.log("conpanies id");
        //  const compObj = Helper.getCompanyDetailsObj(cmpny);
        //  console.log(compObj);


        // this.state.itemsToShow === 3 ? (
        //   this.setState({ itemsToShow: this.props.reviewerData.data.reviews.length, expanded: true })
        // ) : (
        //   this.setState({ itemsToShow: 3, expanded: false })
        // )
      }
     
    componentDidMount=async()=>{
        const isSelfCompany= Helper.isSelfCompanyId(this.props.userData);
        if(!isSelfCompany){
        this.setState({addReview:true})
        }
        await this.props.getReviewBycompanyidAction({company_id:this.props.companyId})
    }
    render() {   
        let isReviewData = true;
        if(this.props.reviewerData===undefined){
            isReviewData = false;
        } else if(this.props.reviewerData===null){
            isReviewData = false;
        } else if(this.props.reviewerData===undefined){
            isReviewData = false;
        } else if(this.props.reviewerData.data===undefined){
            isReviewData = false;
        } else if(this.props.reviewerData.data===null){
            isReviewData = false;
        }  else if(this.props.reviewerData.data.reviews===undefined){
            isReviewData = false;
        } else if(this.props.reviewerData.data.reviews===null){
            isReviewData = false;
        } else if( this.props.reviewerData.error!==undefined){
           if(this.props.reviewerData.error){
                isReviewData = false;
           }
        }
        let one=[];
        let two=[];
        let three=[];
        let four=[];
        let five=[];
        let totalStars=0;
        let evry=0;
        let avg=0;
        let five5=0;
        let four4=0;
        let three3=0;
        let two2=0;
        let one1=0;
        if(isReviewData){
            this.props.reviewerData.data.reviews.map((stars,i)=>{
                if(stars.rating===1){
                    one.push(stars.rating);
                }
                if(stars.rating===2){
                    two.push(stars.rating);
                }
                if(stars.rating===3){
                    three.push(stars.rating);
                }
                if(stars.rating===4){
                    four.push(stars.rating);
                }
                if(stars.rating===5){
                    five.push(stars.rating);
                }
            })
            totalStars=this.props.reviewerData.data.reviews.length;
            evry=(1*one.length+2*two.length+3*three.length+4*four.length+5*five.length);
            avg=evry/totalStars;
            avg=avg.toFixed(2);
            five5=(five.length/totalStars)*100;
            four4=(four.length/totalStars)*100;
            three3=(three.length/totalStars)*100;
            two2=(two.length/totalStars)*100;
            one1=(one.length/totalStars)*100;
     }
     
        return (        
                <>
            <div className="row no-gutters">
                <div className="col-12 middle-container" style={{paddingRight: "0px"}}>

            {this.state.addReview ?<div className="text-right">
                <a href="#" className="btn btn-xs btn-link" id="addReviewBtn" data-toggle="modal" data-target="#myModal">Add Review</a>
            </div>:''}

                <div className="box bg-white border ">
                        <div className="box-header padding-right-0  ">
                            <div className="title ">Reviews</div>
                        </div>
                        <div className="box-body">
                            <div className="starRatingContainer">
                                <div className="one">
                                    <span className="rating">{avg} <i className="fa fa-star" style={{fontSize: "20px"}}></i></span>
                                    <span>{ isReviewData ? this.props.reviewerData.data.reviews.length : ''} User ratings</span>
                                </div>
                                <div className="two">
                                    <ul>
                                        <li>
                                            <span>5 <i className="fa fa-star"></i></span>
                                            <div className="progress">
                                               
                                                <div 
                                                    className='progress-bar bg-success'
                                                    role="progressbar" style={{width:five5+"%"}} aria-valuenow="2" aria-valuemin="50" aria-valuemax="100"></div>
                                            </div>
                                            <span>{five5.toFixed(1)} %</span>
                                        </li>
                                        <li>
                                            <span>4 <i className="fa fa-star"></i></span>
                                            <div className="progress">
                                                <div 
                                                     className='progress-bar bg-success'
                                                     role="progressbar" style={{width: four4+"%"}} aria-valuenow="24" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span>{four4.toFixed(1)} %</span>
                                        </li>
                                        <li>
                                            <span>3 <i className="fa fa-star"></i></span>
                                            <div className="progress">
                                                <div className='progress-bar bg-success' role="progressbar" style={{width: three3+"%"}} aria-valuenow="6" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span>{three3.toFixed(1)} %</span>
                                        </li>
                                        <li>
                                            <span>2 <i className="fa fa-star"></i></span>
                                            <div className="progress">
                                                <div 
                                                         className='progress-bar bg-warning'
                                                            role="progressbar" style={{width:two2+"%"}} aria-valuenow="2" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span>{two2.toFixed(1)} %</span>
                                        </li>
                                        <li>
                                            <span>1 <i className="fa fa-star"></i></span>
                                            <div className="progress">
                                                <div className='progress-bar bg-danger' role="progressbar" style={{width: one1+"%"}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span>{one1.toFixed(1)} %</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className="customerReviewContainer border">
                                {isReviewData ? 
                                    this.props.reviewerData.data.reviews.map((rats,i)=>(
                                    
                                                <div  key={i} className="customerReview">
                                           
                                                    <div className="r-heading">
                                                        <div className="title">{this.props.reviewerData.data.reviewer_names[rats.reviewer_id]}</div>
                                                        <div className="date">{new Date( rats.created_ts*1000).toDateString()}</div>
                                                        <div className="r_star">
                                                        <StarRatingComponent 
                                                              name="rate1" 
                                                              starCount={5}
                                                              value={rats.rating}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="r-body">
                                                        <div className="r_body_div">
                                                            <div className="r_ansDiv r_floatDiv">
                                                                <span className="r_Ques">Question: &nbsp;</span>
                                                                <h5 className="r_pAns">Have you ever been in a demo where the prospect wasn’t talking at all?</h5>
                                                            </div>
                                                            <div className="r_ansDiv r_floatDiv">
                                                                <span>Answer: &nbsp; </span>
                                                                <p className="r_pAns"> {rats.answer === null?'':rats.answer[1]}</p>
                                                            </div>                                                            
                                                        </div>
                                                        <div className="r_body_div">
                                                            <div className="r_ansDiv r_floatDiv">
                                                                <span className="r_Ques">Question: &nbsp;</span>
                                                                <h5 className="r_pAns">Why is this such an important problem for you to fix?</h5>
                                                            </div>
                                                            <div className="r_ansDiv r_floatDiv">
                                                                <span>Answer: &nbsp; </span>
                                                                <p className="r_pAns">{rats.answer=== null?'':rats.answer[2]}</p>
                                                            </div>

                                                            </div>
                                                        <div className="r_floatDiv r_body_div">
                                                            <h5>Pros</h5>
                                                            <p className="r_floatp"> {rats.pros}</p>
                                                        </div>
                                                        <div className="r_floatDiv r_body_div">
                                                            <h5>Cons</h5>
                                                            <p className="r_floatp">{rats.cons}.</p>
                                                        </div>
                                                        <div className="r_floatDiv r_body_div">
                                                            <h5>Description</h5>
                                                            <p className="r_floatp">  {rats.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                )):''}
                                            </div>
                            {/* <div className="r_showmore">
                                <a style={{color:'blue'}} onClick={this.showMore}>
                                {this.state.expanded ? (
                                    <span>Show less</span>
                                ) : (
                                    <span>Show more</span>
                                )}
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
        )
    }
}

const mapStateToProps = state => ({
    reviewerData: state.apiData.reviewerData,
});

function mapDispatchToProps(dispatch) {
return {
    getReviewBycompanyidAction: bindActionCreators(getReviewBycompanyidAction, dispatch),
}
}
export default connect(
mapStateToProps,
mapDispatchToProps
)(Review);