import React, { Component } from 'react'
import InterestInfoContents from './InterestInfoContents';
import PersonalInfoContents from './PersonalInfoContents';

export default class UserAbout extends Component {
    constructor(props){
        super(props);
        // this.validator = new SimpleReactValidator({autoForceUpdate: this});
        this.state={                
            active:"Personal Info"
        }
    }
    onClickTabHandlerf=(e)=>{
        
        this.setState({active:e.target.id})

    }

    render() {
        return (
            <div className="row">
                
                <div className="col-md-3 col-sm-12"> 
                    <div className="inner side_nav">
                        <div className="box border">
                            <div className="box-header "><div className="title">
                                    <span> About </span> </div></div>
                            <div className="box-body padding-0">
                                <ul className="nav withIconList list" role="tablist">
                                    <li className="nav-item" >
                                        <a className={this.state.active==='Personal Info' ? 'active' :''}
                                        onClick={this.onClickTabHandlerf}  id="Personal Info"
                                        data-toggle="tab" href="#" role="tab" aria-controls="nav-personal" aria-selected="false" >
                                            <i className="flaticon-user" ></i>  Personal Info
                                        </a>
                                    </li>
                                    <li  className="nav-item">
                                        <a className={this.active==='Interests' ? 'active' :''}
                                        onClick={this.onClickTabHandlerf} id="Interests"
                                        data-toggle="tab" href="#" role="tab" aria-controls="nav-interests" aria-selected="true">
                                            <i className="flaticon-network "></i>  Interests 
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>  
                </div>

                <div className="col-md-9 col-sm-12 ">   
                    <div className="box bg-white border ">
                        <div className="box-header padding-right-0">
                            <div className="title">{this.state.active}</div>
                            <div></div>
                        </div>
                             {this.state.active==='Personal Info' ? <PersonalInfoContents/> :''}
                                {this.state.active==='Interests' ? <InterestInfoContents/> :''}
                    </div>
                </div>
            </div>
            )
    }
}
