import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from '../../SubComponents/Header/Header';
import Footer from '../../SubComponents/Footer/Footer';
import {Button, Modal} from 'react-bootstrap';
import Constants from '../../../utils/Constants';

const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;
const USER_DEFAULT_IMAGE = Constants.defaultImageUrls.user;

class Notification extends React.Component{
  constructor(props){
    super(props);
   this.state={ }
 }
render (){
    return(
        <> 
        {/* Header Start */}
        <Header {...this.props} headerType="userDashboard" />
        {/* Header End */}
​
​
        <div className=" mainBody container notificationList">
            <div className="row">
               
                <div className="col-sm-12 col-md-9 n_col ">
                <div className=" box border bg-white ">
                
                <div className="box-header ">
                            <div className="title">All Notification List</div>
                        </div>
                    <div className="c-body notification_content">
                      <ul className="n_list">
                      {
            this.props.getNotificationsres!==null && this.props.getNotificationsres.status===200 ?
            this.props.getNotificationsres.length === 0 ?
                <li>
                    <span>No Notifications.</span>
                </li>
                :

                this.props.getNotificationsres.map((notification, index) => (

                        <li key={index}>
                          <a href="#">
                            <div className="img">
                              <img alt="" src={notification.bg_payload.image == null ? USER_DEFAULT_IMAGE : (BUCKET_PATH + notification.bg_payload.image)} />
                              <i className="fa fa-user icon bg-warning" />
                            </div>
                            <div className="n_details">                              
                              <p className="n_detailname"><b>{notification.message_body}</b> following you.</p>                              
                            </div>
                            <div className="n_time">
                            <div className="n_actions">
                            <ul>                            
                              <li className="dropdown show">
                                <a className="btn btn-xs" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                </a>
                                <div className="dropdown-menu " x-placement="bottom-start" style={{position: "absolute", transform: "translate3d(0px, 29px, 0px)", top: "0px", left: "0px", willChange: "transform"}}>
                                  <a className="dropdown-item" style={{cursor:'pointer'}}>Delete</a>
                                  <a className="dropdown-item" style={{cursor:'pointer'}}>Follow</a>
                                </div>
                              </li>
                            </ul>
                            </div>
                            <span className="time"><i class="fa fa-clock-o" aria-hidden="true"></i> &nbsp;{notification.notification_time}ago</span>
                            </div>
                          </a>
                        </li>
                          ))
                          :<div><span>There is no Notification</span></div> }
                      </ul>
                    </div>
                 </div>
                </div>
            </div>
        </div>
        {/* footer Start */}
        <Footer {...this.props} />
        {/* footer End */}
        </>
    )
}
}
const mapStateToProps = state => ({
    userData: state.auth.userData,
    getNotificationsres: state.apiData.getNotificationsres,
    updateReadStatusres: state.apiData.updateReadStatusres,
  });
  export default connect(
    mapStateToProps,
  )(Notification);
  