import React from 'react';
import Helper from "../../../../Configs/Helper";
import ReactBootstrapTable from '../../TableComponent/ReactBootstrapTable';
import { getConnectedCompanies, updateConnectionRequest } from '../../../../api/agent';
import Constants from '../../../../utils/Constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
const COMPANY_DEFAULT_LOGO = Constants.defaultImageUrls.companyLogo;
const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;

class ReceiveRequest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            COMPANY_ID: "",
            ACCOUNT_ID: "",
            requestDetail: [],
            errorMessage: "",
        }
    }
    goToCompanyTimeline=(company_id)=>{
        this.props.goToTimeLine(company_id);
    }

    keyField = "company_id";
    cellEditProperties = {
        mode: "click",
        blurToSave: true
    };

    updateConnectionRequest = (status, companyId, requesterId, connectionRequestId, relationType, rowIndex) => {

        console.log("Updating network request");
        console.log("status : " + status);
        console.log("companyId : " + companyId);
        console.log("requesterId : " + requesterId);
        console.log("networkRequestId : " + connectionRequestId);
        console.log("relationType : " + relationType);
        console.log("rowIndex : " + rowIndex);
        console.log("");

        if (status === 1) {
            this.setState({
                ["isAccepting" + rowIndex]: true
            });
        } else {
            this.setState({
                ["isRejecting" + rowIndex]: true
            });
        }

        let requestParams = {
            request_status: status,
            id: connectionRequestId,
            company_id: companyId,
            user_id: this.state.ACCOUNT_ID,
            requester_id: requesterId,
            relation_type: relationType
        }

        const payload = updateConnectionRequest(requestParams, "updateResponse");

        payload.then(response => {

            console.log("");
            console.log("UPDATE CONNECTION RESPONSE");
            console.log(response);
            console.log("");

            this.setState({
                ["isAccepting" + rowIndex]: false,
                ["isRejecting" + rowIndex]: false
            });

            if (response.updateResponse.status === 200) {

                this.getAllConnectionRequest();

                if (status === 1) {
                    this.props.handleOpen("Network Request accepted");
                } else {
                    this.props.handleOpen("Network Request declined");
                }
            } else {
                this.props.handleOpen("Network Request status is not updated");
            }
        });
    }

    defaultSorted = [
        {
            dataField: "company_name",
            order: "desc"
        }
    ];

    isUnmounted = false;
    getAllConnectionRequest = () => {

        let requestParams = {
            company_id: this.state.COMPANY_ID,
            connection_status: 0,            
            type: 0
        }

        const payload = getConnectedCompanies(requestParams, "companiesDetail");

        payload.then(response => {

            if (this.isUnmounted) {
                return;
            }

            console.log("");
            console.log("GET ALL NETWORK REQUEST RECEVIE LIST");
            console.log(response);
            console.log("");

            if (response.companiesDetail.status === 200) {
                this.setState({
                    requestDetail: response.companiesDetail.data
                });
            } else {
                this.setState({
                    errorMessage: "No pending request.",
                    requestDetail: []
                });
            }
        });
    }

    componentDidMount() {
        this.setState({
            COMPANY_ID: Helper.getCompanyInfo(this.props.companyDetails, 'company_id'),
            ACCOUNT_ID: this.props.userData.data.account_id,
            isLoadingData: true
        }, () => { this.getAllConnectionRequest(); })
    }
    componentWillUnmount() {
        this.isUnmounted = true;
    }

    render() {

        const requestColumns = [
            {
                dataField: "company_name",
                text: "Name",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                editable: false,
                isDummyField: true,
                formatter: (cellContent, row, rowIndex, extraData) => {
                    return (
                        <div className="userNameWithImg">
                            <div className="img">
                                <img alt="imgConnection" style={{ height: "30px", width: "30px" }} src={row.company_logo !== null ? BUCKET_PATH +'/'+ row.company_logo.image_path : COMPANY_DEFAULT_LOGO} />
                            </div>
                            <a style={{ textTransform: "capitalize",cursor:'pointer' }} onClick={()=>this.goToCompanyTimeline(row.company_id)}>
                                {row.company_name}
                            </a>
                        </div>
                    );
                },
            },
            {
                dataField: "location.city",
                text: "Location",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                formatter: (cellContent, row, rowIndex, extraData) => {
                    return (

                        row.location === null || row.location.city === null ?
                            `-`
                            :
                            row.location.city
                    );
                },
                editable: false
            },
            {
                dataField: "industry_type",
                text: "Sector",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                editable: false
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Actions",
                editable: false,
                formatter: (cellContent, row, rowIndex, extraData) => {

                    return (
                        <React.Fragment>
                            <button style={{ width: "47px", height: "23px" }} onClick={() => extraData.updateStatus(1, this.state.COMPANY_ID, row.company_id, row.connection_request_id, row.request_type, rowIndex)} className={`btn btn-sm btn-success ${extraData.isAccepting === true || extraData.isRejecting === true ? 'disabled' : ''}`}>
                                {
                                    extraData.state["isAccepting" + rowIndex] === true ?
                                        <CircularProgress className="loadingSpinnerBtn2" />

                                        : "Accept"
                                }
                            </button>
                            <button onClick={() => extraData.updateStatus(2, this.state.COMPANY_ID, row.company_id, row.connection_request_id, row.request_type, rowIndex)} className={`btn btn-sm btn-danger ${extraData.isRejecting === true || extraData.isAccepting === true ? 'disabled' : ''}`} style={{ marginLeft: "10px", width: "43px", height: "23px" }}>
                                {
                                    extraData.state["isRejecting" + rowIndex] ?
                                        <CircularProgress className="loadingSpinnerBtn2" />

                                        : "Reject"
                                }
                            </button>
                        </React.Fragment>
                    );
                },
                formatExtraData: {
                    updateStatus: this.updateConnectionRequest,
                    state: this.state
                }
            }
        ];

        return (
            <React.Fragment>
                <div className="detailsHeader">
                    Request <span>({this.state.requestDetail.length})</span>
                </div>
                {
                    this.state.errorMessage !== ""
                        ?
                        <div>
                            <h3>
                                {this.state.errorMessage}
                            </h3>
                        </div>
                        :
                        <ReactBootstrapTable
                            data={this.state.requestDetail}
                            keyField={this.keyField}
                            cellEditData={this.cellEditData}
                            defaultSorted={this.defaultSorted}
                            columns={requestColumns}
                        />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    companyDetails: state.appData.companyDetails,
    loading: state.loading,
});

export default connect(
    mapStateToProps,
    null
)(ReceiveRequest);