import React from 'react';
import Helper from '../../../../Configs/Helper'
import ReactBootstrapTable from '../../TableComponent/ReactBootstrapTable';
import { getBlockAccounts } from '../../../../api/agent';
import Constants from '../../../../utils/Constants';
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";

const COMPANY_DEFAULT_LOGO = Constants.defaultImageUrls.companyLogo;
const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;


class BlockUsers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            COMPANY_ID: "",
            ACCOUNT_ID: "",
            blockDetails: [],
            errorMessage: ""
        }
    }
    goToCompanyTimeline=(company_id)=>{
        this.props.goToTimeLine(company_id);
    }
    keyField = "company_id";
    cellEditProperties = {
        mode: "click",
        blurToSave: true
    };

    defaultSorted = [
        {
            dataField: "company_name",
            order: "desc"
        }
    ];

    isUnmounted = false;
    getBlockAccounts = () => {

        let requestParams = {
            blocker_id: this.state.COMPANY_ID
        }

        const payload = getBlockAccounts(requestParams, "blockDetails");

        payload.then(response => {

            if (this.isUnmounted) {
                return;
            }
            
            console.log("");
            console.log("BLOCK DETAIL RESPONSE");
            console.log(response);
            console.log("");

            if (response.blockDetails.status === 200) {
                this.setState({
                    blockDetails: response.blockDetails.data
                });
            } else {
                this.setState({
                    errorMessage: "No details found.",
                    blockDetails: []
                });
            }
        });
    }

    componentDidMount() {
        this.setState({  
            COMPANY_ID: Helper.getCompanyInfo(this.props.companyDetails,'company_id'),
            ACCOUNT_ID: this.props.userData.data.account_id,
        },()=>{this.getBlockAccounts();})
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    render() {

        const columns = [
            {
                dataField: "company_name",
                text: "Name",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                editable: false,
                isDummyField: true,
                formatter: (cellContent, row, rowIndex, extraData) => {
                    return (
                        <div className="userNameWithImg">
                            <div className="img">
                                <img alt="" style={{ height: "30px", width: "30px" }} src={row.company_logo !== null ? BUCKET_PATH +'/'+ row.company_logo.image_path : COMPANY_DEFAULT_LOGO} />
                            </div>
                            <a style={{ textTransform: "capitalize",cursor:'pointer' }} onClick={()=>this.goToCompanyTimeline(row.company_id)}>
                                {row.company_name}
                            </a>
                        </div>
                    );
                },
            },
            {
                dataField: "location.city",
                text: "Location",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                formatter: (cellContent, row, rowIndex, extraData) => {
                    return (

                        row.location === null || row.location.city === null ?
                            `-`
                            :
                            row.location.city
                    );
                },
                editable: false
            },
            {
                dataField: "industry_type",
                text: "Sector",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                editable: false
            },
            {
                dataField: 'status',
                isDummyField: true,
                text: 'Status',
                editable: false,
                // sort: false
                formatter: (cellContent, row) => {
                    return (
                        <h5>
                            <span className="label label-danger"> Blocked</span>
                        </h5>
                    );
                }
            }
        ];

        return (
            <React.Fragment>
                <div className="detailsHeader">
                    Block <span>({this.state.blockDetails.length})</span>
                </div>

                {this.state.errorMessage !== ""
                    ?
                    <div>
                        <h3>
                            {this.state.errorMessage}
                        </h3>
                    </div>
                    :
                    <ReactBootstrapTable
                        data={this.state.blockDetails}
                        keyField={this.keyField}
                        cellEditData={this.cellEditData}
                        defaultSorted={this.defaultSorted}
                        columns={columns}
                    />
                }
                {/* <table className="customTable">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Position</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="userNameWithImg">
                                    <div className="img">
                                        <img src="https://www.menshairstylestoday.com/wp-content/uploads/2018/10/Best-Haircut-For-Oblong-Face-Men.jpg" />
                                    </div>
                                    <div className="name">
                                        John Doe
                                                                        </div>
                                </div>
                            </td>
                            <td>
                                <a href="mailto:john@gmail.com">john@gmail.com</a>
                            </td>
                            <td>
                                xyz
                                                                </td>
                            <td>
                                <select className="form-control">
                                    <option>Active</option>
                                    <option>Remove</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table> */}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    companyDetails: state.appData.companyDetails,
    loading: state.loading,
});

export default connect(
    mapStateToProps,
    null
)(BlockUsers);