import React from 'react'
import { saveCompanyAction, saveCompanyPostAction, deleteFounderTeam } from '../../../../api/agent';
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../../../utils/Constants';
import Helper from "../../../../Configs/Helper";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import MobileCountryCodes from '../../../../utils/MobileCountryCode';
import CircularProgress from '@material-ui/core/CircularProgress';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import { isEmptyObject } from '../../../../utils/HelperFunc';
import { getCompanyDetailsByIdAction } from "../../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;


class CompanyAboutForm extends React.Component {
    constructor(props) {
        super(props);

        const { account_id } = this.props.userData.data;
        this.state = {
            ACCOUNT_ID: account_id,
            COMPANY_ID: "",
            COMPANY_INFO_ID: "",

            founderCount: 1,
            founderArr: [1],
            teamCount: 1,
            teamArr: [1],
            isFounderExists: "",
            isTeamExists: "",
            isLoading: false,
            company: {
                company_name: "",
                company_email: "",
                company_sub_title: "",
                description: "",
                logoUrl: "",
                bgUrl: "",
                founder: {},
                team: {},
                rating: 0,
                company_type: "",
                year_founded: "",
                address: "",
                country: "",
                city: "",
                state: "",
                mobile_number: "",
                website_url: "",
                fax_number: "",
                postal_code: "",
                companyProfileUrl: "",
                founderArr: [],
                industry_type: "",
                company_size: "",
                specialization: [],
                mobile_country_code: "",
                company_bg_image: "",
                company_logo: ""
            },
            company_logo_preview: "",
            company_bg_preview: "",
            coverImgPreviewClass: "",
            companyCopy: {
                company_name: "",
                company_email: "",
                company_sub_title: "",
                description: "",
                logoUrl: "",
                bgUrl: "",
                founder: {},
                team: {},
                rating: 0,
                company_type: "",
                year_founded: "",
                address: "",
                country: "",
                city: "",
                state: "",
                mobile_number: "",
                website_url: "",
                fax_number: "",
                postal_code: "",
                companyProfileUrl: "",
                founderArr: [],
                industry_type: "",
                company_size: "",
                specialization: [],
                mobile_country_code: "",
                company_bg_image: "",
                company_logo: ""
            },
            finalData: {
                company_name: null,
                company_sub_title: null,
                company_type: null,
                description: null,
                website_url: null,
                mobile_number: null,
                company_email: null,
                year_founded: null,
                company_size: null,
                industry_type: null,
                specialization: null,
                fax_number: null,
                mobile_country_code: null,
                address: null,
                city: null,
                country: null,
                state: null,
                postal_code: null,
                company_bg_image: null,
                company_logo: null
            }
        }
        this.validator = new SimpleReactValidator({
            element: (message) => <span className='text-danger'>{message}</span>
        })
    }

    handleOpen = (message) => {
        this.props.handleAlertOpen(message);
    }

    setCompanyCopy() {
        this.setState({ companyCopy: this.state.company });
    }

    async componentDidMount() {
        const companyId = await localStorage.getItem('COMPANY_ID');
        if (companyId !== '' && companyId !== null && companyId !== undefined) {
            this.setState({ companyId }, () => {
                this.getCompanyDetails()
            })
        }
        this.setState({ isLoadingData: true });
    }
    getCompanyDetails = async (event) => {
        const paramsData = {
            account_id: this.props.userData.data.account_id,
            company_id: this.state.companyId,
        }
        await this.props.getCompanyDetailsByIdAction(paramsData);
        if (this.props.companyDetails.status === 200) {
            const companyInfo = this.props.companyDetails.data.companyInfo[0];
            const companyData = this.props.companyDetails.data.company;
            this.setState({
                COMPANY_ID: companyData.id,
                COMPANY_INFO_ID: companyInfo.id,
                company: {
                    ...this.state.company,
                    address: (companyInfo.location !== null && companyInfo.location.address !== null) ? companyInfo.location.address : '',
                    city: (companyInfo.location !== null && companyInfo.location.city !== null) ? companyInfo.location.city : '',
                    country: (companyInfo.location !== null && companyInfo.location.country !== null) ? companyInfo.location.country : '',
                    postal_code: (companyInfo.location !== null && companyInfo.location.postal_code !== null) ? companyInfo.location.postal_code : '',
                    state: (companyInfo.location !== null && companyInfo.location.state !== null) ? companyInfo.location.state : '',
                    company_name: companyData.company_name || '',
                    company_sub_title: companyData.company_sub_title || '',
                    company_type: companyData.company_type || '',
                    description: companyData.description || '',
                    logoUrl: companyData.company_logo !== null ? BUCKET_PATH + "/" + companyData.company_logo.image_path : '',
                    bgUrl: companyInfo.cover_image !== null ? BUCKET_PATH + "/" + companyInfo.cover_image.image_path : '',
                    year_founded: companyInfo.year_founded || '',
                    website_url: companyInfo.website_url || '',
                    mobile_number: companyInfo.mobile_number || '',
                    founder: companyInfo.founder || '',
                    team: companyInfo.team || '',
                    fax_number: companyInfo.fax_number || '',
                    companyProfileUrl: companyInfo.company_profile_url || '',
                    industry_type: companyInfo.industry_type || '',
                    company_size: companyInfo.company_size || '',
                    mobile_country_code: companyInfo.mobile_country_code || '',
                    specialization: companyInfo.specialization || [],
                    company_email: companyInfo.company_email || ''
                },
                coverImgPreviewClass: companyInfo.cover_image !== null ? "js--no-default" : ''
            });

            if (companyInfo.founder !== null) {
                this.setState({ founderArr: [], founderCount: 0, isFounderExists: "" })
                this.showFounderList(companyInfo.founder);
            }

            if (companyInfo.team !== null) {
                this.setState({ teamArr: [], teamCount: 0, isTeamExists: "" });
                this.showTeamList(companyInfo.team);
            }

            this.setCompanyCopy();
        } else {
            //this.loader.showAlert(true,'error',this.props.companyDetails.message)
        }
    }

    convertJSONToArray = (jsonObject) => {
        let arr = [];
        Object.keys(jsonObject).map((type) => {
            arr.push(jsonObject[type]);
        });
        return arr;
    }

    showFounderList = (founderObject) => {
        let count = 0;

        Object.keys(founderObject).map((type) => {

            count++;
            this.addMoreFounderHandler();

            let founderImagePath = BUCKET_PATH + "/" + founderObject[type].image_path;

            this.setState({
                company: {
                    ...this.state.company,
                    [`founder_name_${count}`]: founderObject[type].name,
                    [`founder_detail_${count}`]: founderObject[type].designation,
                    [`founder_image_${count}_preview`]: founderObject[type].image_path !== null ? founderImagePath : '',
                    [`founder_image_id_${count}`]: founderObject[type].image_id
                }
            })
        });
    }

    showTeamList = (teamObject) => {
        let count = 0;
        Object.keys(teamObject).map((type) => {
            count++;
            this.addMoreTeamHandler();
            let teamImagePath = BUCKET_PATH + "/" + teamObject[type].image_path;
            this.setState({
                company: {
                    ...this.state.company,
                    [`team_name_${count}`]: teamObject[type].name,
                    [`team_detail_${count}`]: teamObject[type].designation,
                    [`team_image_${count}_preview`]: teamObject[type].image_path !== null ? teamImagePath : '',
                    [`team_image_id_${count}`]: teamObject[type].image_id
                }
            })
        })
    }

    addMoreFounderHandler = () => {
        let founderCount = this.state.founderCount;
        founderCount += 1;
        let founderArr = this.state.founderArr;
        founderArr.push(founderCount);
        this.setState({ founderCount: founderCount, founderArr: founderArr });
    }

    removeFounderHandler = (value, i) => {

        let founderIndex = i + 1;
        let founderCount = this.state.founderCount;
        founderCount -= 1;
        this.setState({ founderCount: founderCount });

        if (this.state.company["founder_image_id_" + value] === undefined) {
            let founderArr = this.state.founderArr;
            var index = founderArr.indexOf(value);
            if (index > -1) {
                founderArr.splice(index, 1);
            }

            let newFounderArr = [];
            let founderCount = 0;
            founderArr.forEach(function (val) {
                founderCount = founderCount + 1;
                newFounderArr.push(founderCount);
            })

            // this.setState({ founderArr: newFounderArr });
            this.setState({ founderArr: founderArr });
        } else {

            let requestParam = {
                company_id: this.state.companyId,
                category: 0,    // 0 -> founder, 1 -> team
                member_position: founderIndex
            }

            deleteFounderTeam(requestParam, "deleteFounderResponse").then(function (response) {

                if (response.deleteFounderResponse.status === 200) {

                    this.setState({
                        company: {
                            ...this.state.company,
                            [`founder_name_${value}`]: undefined,
                            [`founder_detail_${value}`]: undefined,
                            [`founder_image_${value}_preview`]: undefined,
                            [`founder_image_id_${value}`]: undefined
                        }
                    });

                    let founderArr = this.state.founderArr;
                    var index = founderArr.indexOf(value);
                    if (index > -1) {
                        founderArr.splice(index, 1);
                    }

                    let newFounderArr = [];
                    let founderCount = 0;
                    founderArr.forEach(function (val) {
                        founderCount = founderCount + 1;
                        newFounderArr.push(founderCount);
                    })

                    // this.setState({ founderArr: newFounderArr });
                    this.setState({ founderArr: founderArr });
                }
            }.bind(this));
        }
    }

    addMoreTeamHandler = () => {
        let teamCount = this.state.teamCount;
        teamCount += 1;
        let teamArr = this.state.teamArr;
        teamArr.push(teamCount);
        this.setState({ teamCount: teamCount, teamArr: teamArr });
    }

    removeTeamHandler = (value, i) => {

        let teamIndex = i + 1;

        let teamCount = this.state.teamCount;
        teamCount -= 1;
        this.setState({ teamCount: teamCount });

        if (this.state.company["team_image_id_" + value] === undefined) {
            let teamArr = this.state.teamArr;
            var index = teamArr.indexOf(value);
            if (index > -1) {
                teamArr.splice(index, 1);
            }

            let newTeamArr = [];
            let teamCount = 0;
            teamArr.forEach(function (val) {
                teamCount = teamCount + 1;
                newTeamArr.push(teamCount);
            })

            // this.setState({ teamArr: newTeamArr });
            this.setState({ teamArr: teamArr });
        } else {

            let requestParam = {
                company_id: this.state.companyId,
                category: 1,    // 0 -> founder, 1 -> team
                member_position: teamIndex
            }

            deleteFounderTeam(requestParam, "deleteTeamResponse").then(function (response) {

                if (response.deleteTeamResponse.status === 200) {

                    // if (response.deleteTeamResponse.data !== undefined) {
                    //     let teamObject = response.deleteTeamResponse.data;
                    //     let count = 0;
                    //     Object.keys(teamObject).map((type) => {
                    //         count++;
                    //         // this.addMoreTeamHandler();
                    //         let teamImagePath = BUCKET_PATH + "/" + teamObject[type].image_path;
                    //         this.setState({
                    //             company: {
                    //                 ...this.state.company,
                    //                 [`team_name_${count}`]: teamObject[type].name,
                    //                 [`team_detail_${count}`]: teamObject[type].designation,
                    //                 [`team_image_${count}_preview`]: teamObject[type].image_path !== null ? teamImagePath : '',
                    //                 [`team_image_id_${count}`]: teamObject[type].image_id
                    //             }
                    //         })
                    //     })
                    // }

                    this.setState({
                        company: {
                            ...this.state.company,
                            [`team_name_${value}`]: undefined,
                            [`team_detail_${value}`]: undefined,
                            [`team_image_${value}_preview`]: undefined,
                            [`team_image_id_${value}`]: undefined
                        }
                    });

                    let teamArr = this.state.teamArr;
                    var index = teamArr.indexOf(value);
                    if (index > -1) {
                        teamArr.splice(index, 1);
                    }

                    let newTeamArr = [];
                    let teamCount = 0;
                    teamArr.forEach(function (val) {
                        teamCount = teamCount + 1;
                        newTeamArr.push(teamCount);
                    })

                    // this.setState({ teamArr: newTeamArr });
                    this.setState({ teamArr: teamArr });
                }
            }.bind(this));
        }
    }

    filePreview(file, field) {

        if (file !== undefined) {
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {

                if (field === "bgUrl") {
                    this.setState({
                        company: {
                            ...this.state.company,
                            [field]: [reader.result]
                        },
                        // [field]: [reader.result],
                        coverImgPreviewClass: "js--no-default"
                    })
                } else {
                    this.setState({
                        company: {
                            ...this.state.company,
                            [field]: [reader.result]
                        },
                        // [field]: [reader.result]
                    })
                }
            }.bind(this);
        } else {
            this.setState({
                company: {
                    ...this.state.company,
                    [field]: [null]
                }
                // [field]: [null]
            })
        }
    }

    onChangeHandler = (event) => {
        var name = event.target.name;
        var value = event.target.value;

        var dataName = event.target.getAttribute('data-name');

        if (name === "company_logo") {
            this.setState({ company: { ...this.state.company, company_logo: event.target.files[0] } });
            this.filePreview(event.target.files[0], 'logoUrl');
            this.isCompanyDetailUpdated(1, name, event.target.files[0]);

        } else if (name === "company_bg_image") {
            this.setState({ company: { ...this.state.company, company_bg_image: event.target.files[0] } });
            this.filePreview(event.target.files[0], 'bgUrl');
            this.isCompanyDetailUpdated(1, name, event.target.files[0]);

        } else if (dataName === "founderImg" || dataName === "teamImg") {
            var imgPreview = name + '_preview';
            this.setState({ company: { ...this.state.company, [name]: event.target.files[0] } });
            this.filePreview(event.target.files[0], imgPreview);

        } else {
            this.setState({ company: { ...this.state.company, [name]: value } })
            this.isCompanyDetailUpdated(1, name, value);
        }
    }

    handleChangeSpecialization = tags => {
        this.setState({ company: { ...this.state.company, specialization: tags } })
        this.isCompanyDetailUpdated(1, "specialization", tags);
    }

    updateCompanyDetail = (formType) => {

        if (this.validator.allValid()) {

            let isEmpty = this.isObjectEmpty(this.state.finalData);

            console.log("");
            console.log("FINAL UPDATED COMPANY DETAILS");
            console.log(this.state.finalData);
            console.log("");
            console.log("is object empty");
            console.log(isEmpty);
            console.log("");
            console.log("logo image");
            console.log(this.state.company.company_logo);
            console.log("");
            console.log("bg image");
            console.log(this.state.company.company_bg_image);
            console.log("");

            if (isEmpty === false) {

                this.setState({
                    isLoading: true
                });

                let requestParam = {
                    account_id: this.state.ACCOUNT_ID,
                    company_id: this.state.COMPANY_ID,
                    companyInfo_id: this.state.COMPANY_INFO_ID,
                    form_type: Constants.companyFormType[formType],
                    ...this.state.finalData
                    // company_name: this.state.company.company_name,
                    // company_sub_title: this.state.company.company_sub_title,
                    // company_type: this.state.company.company_type,
                    // description: this.state.company.description,
                    // website_url: this.state.company.website_url,
                    // mobile_number: this.state.company.mobile_number,
                    // company_email: this.state.company.company_email,
                    // year_founded: this.state.company.year_founded,
                    // company_size: this.state.company.company_size,
                    // // about_company: this.state.company.aboutCompany,
                    // industry_type: this.state.company.industry_type,
                    // specialization: this.state.company.specialization.toString(),
                    // fax_number: this.state.company.fax_number,
                    // mobile_country_code: this.state.company.mobile_country_code,
                    // address: this.state.company.address,
                    // city: this.state.company.city,
                    // country: this.state.company.country,
                    // state: this.state.company.state,
                    // postal_code: this.state.company.postal_code,
                }

                let payload = null;
                if (this.state.company.company_logo !== undefined || this.state.company.company_bg_image !== undefined) {

                    const formData = new FormData();

                    if (this.state.company.company_logo !== undefined && this.state.company.company_bg_image !== undefined) {
                        formData.append('company_logo', this.state.company.company_logo);
                        formData.append('cover_image', this.state.company.company_bg_image);

                    } else if (this.state.company.company_logo !== undefined) {
                        formData.append('company_logo', this.state.company.company_logo);

                    } else if (this.state.company.company_bg_image !== undefined) {
                        formData.append('cover_image', this.state.company.company_bg_image);
                    }

                    payload = saveCompanyPostAction(requestParam, formData, 'addCompanyBasicInfo');
                } else {
                    payload = saveCompanyAction(requestParam, "addCompanyBasicInfo");
                }


                payload.then(response => {

                    console.log("");
                    console.log("RESPONSE OF UPDATE COMPANY DETAILS");
                    console.log(response);
                    console.log("");

                    this.setState({
                        isLoading: false
                    })

                    if (response.addCompanyBasicInfo.error !== true && response.addCompanyBasicInfo.status === 200) {
                        // alert("Details are updated");
                        let companyInfo = response.addCompanyBasicInfo.data.Company_info_data;
                        let companyData = response.addCompanyBasicInfo.data.company_data;

                        // this.setState({
                        //     company: {
                        //         ...this.state.company,
                        //         ...companyInfo,
                        //         ...companyData
                        //     },
                        //     companyCopy: {
                        //         ...this.state.companyCopy,
                        //         ...companyInfo,
                        //         ...companyData
                        //     }
                        // })
                       
                        this.setUpdatedCompanyDetail(companyData, companyInfo);
                        this.handleOpen("Company details are updated");
                        const paramsData = {
                            account_id: this.props.userData.data.account_id,
                            company_id: this.state.companyId,
                        }
                        this.props.getCompanyDetailsByIdAction(paramsData)
                        this.props.handleComponentShow(1);
                    } else {
                        // alert(response.addCompanyBasicInfo.message);
                        this.handleOpen(response.addCompanyBasicInfo.message);
                    }
                })
            } else {
                // alert("There is no detail to update");
                this.handleOpen("There is no detail to update");
            }

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    alphaSpaceValidation(value, fieldName, index, msgFieldName) {

        console.log("");
        console.log("value for validation");
        console.log(value);
        console.log("");

        this.setState({
            [`${fieldName}_error_${index}`]: ""
        })

        if (value === "" || value === undefined) {

            this.setState({
                [`${fieldName}_error_${index}`]: `The ${msgFieldName} field is required.`
            })
            return false;

        } else {
            var regex = /^[a-zA-Z ]*$/;
            let isValid = regex.test(value);

            console.log("");
            console.log("isValid 2");
            console.log(isValid);
            console.log("");

            if (!isValid) {
                this.setState({
                    [`${fieldName}_error_${index}`]: `The ${msgFieldName} may only contain letters and spaces.`
                })
            }

            return isValid;
        }
    }

    setUpdatedCompanyDetail(companyData, companyInfo) {

        this.setState({
            company: {
                ...this.state.company,
                address: (companyInfo.location !== null) ? companyInfo.location.address : '',
                city: (companyInfo.location !== null) ? companyInfo.location.city : '',
                country: (companyInfo.location !== null && companyInfo.location.country !== null) ? companyInfo.location.country : '',
                postal_code: (companyInfo.location !== null) ? companyInfo.location.postal_code : '',
                state: (companyInfo.location !== null) ? companyInfo.location.state : '',
                company_name: companyData.company_name || '',
                company_sub_title: companyData.company_sub_title || '',
                company_type: companyData.company_type || '',
                description: companyData.description || '',
                logoUrl: companyData.company_logo !== null ? BUCKET_PATH + "/" + companyData.company_logo.image_path : '',
                bgUrl: companyInfo.cover_image !== null ? BUCKET_PATH + "/" + companyInfo.cover_image.image_path : '',
                year_founded: companyInfo.year_founded || '',
                website_url: companyInfo.website_url || '',
                mobile_number: companyInfo.mobile_number || '',
                founder: companyInfo.founder || '',
                team: companyInfo.team || '',
                fax_number: companyInfo.fax_number || '',
                companyProfileUrl: companyInfo.company_profile_url || '',
                industry_type: companyInfo.industry_type || '',
                company_size: companyInfo.company_size || '',
                mobile_country_code: companyInfo.mobile_country_code || '',
                specialization: companyInfo.specialization || [],
                company_email: companyInfo.company_email || ''
            },
            finalData: {
                company_name: null,
                company_sub_title: null,
                company_type: null,
                description: null,
                website_url: null,
                mobile_number: null,
                company_email: null,
                year_founded: null,
                company_size: null,
                industry_type: null,
                specialization: null,
                fax_number: null,
                mobile_country_code: null,
                address: null,
                city: null,
                country: null,
                state: null,
                postal_code: null,
                company_bg_image: null,
                company_logo: null
            },
            coverImgPreviewClass: companyInfo.cover_image !== null ? "js--no-default" : ''
        });

        this.setCompanyCopy();
    }

    updateFounderTeamDetail = (type, index) => {

        let memberIndex = index + 1;

        console.log("");
        console.log("UPDATE FOUNDER TEAM DETAIL");
        console.log("type : " + type);
        console.log("index : " + memberIndex);
        console.log("");


        if (type === "founder") {

            let founder_name = this.state.company['founder_name_' + memberIndex];
            let founder_designation = this.state.company['founder_detail_' + memberIndex];
            let founder_image = this.state.company['founder_image_' + memberIndex];

            console.log("");
            console.log("founder name : " + founder_name);
            console.log("founder designation : " + founder_designation);
            console.log("founder image file");
            console.log(founder_image);
            console.log("");

            if (founder_name === undefined && founder_designation === undefined && founder_image === undefined) {

                console.log("");
                console.log("founder details are undefined");
                console.log("");

                // alert("There is no detail to update");
                this.handleOpen("There is no detail to update");
            } else {

                console.log("");
                console.log("founder detail are not undefined");
                console.log("");

                if (this.isCompanyDetailUpdated(2, null, null, memberIndex)) {

                    console.log("");
                    console.log("founder details are going to update");
                    console.log("");

                    let isFounderNameValid = this.alphaSpaceValidation(founder_name, "founder_name", memberIndex, "founder name");
                    let isFounderDesignationValid = this.alphaSpaceValidation(founder_designation, "founder_detail", memberIndex, "founder designation");

                    if (isFounderNameValid && isFounderDesignationValid) {

                        let requestParam = {
                            account_id: this.state.ACCOUNT_ID,
                            company_id: this.state.COMPANY_ID,
                            companyInfo_id: this.state.COMPANY_INFO_ID,
                            form_type: Constants.companyFormType[6],
                            founder_member_description: founder_designation,
                            founder_member_name: founder_name,
                            founderUpdatePosition: memberIndex,
                            founder_image: founder_image
                        }

                        let payload = null;
                        let is_founder_image_upload = false;
                        if (requestParam.founder_image !== undefined) {
                            is_founder_image_upload = true;
                            const formData = new FormData();
                            formData.append('founder_image', requestParam.founder_image);
                            payload = saveCompanyPostAction(requestParam, formData, 'updateFounder');
                        } else {
                            payload = saveCompanyAction(requestParam, "updateFounder");
                        }

                        payload.then(response => {

                            console.log("");
                            console.log("RESPONSE OF UPDATE COMPANY FOUNDER DETAILS");
                            console.log(response);
                            console.log("");

                            if (response.updateFounder.error !== true && response.updateFounder.status === 200) {

                                // alert("Details are updated");
                                this.handleOpen("Founder details are updated");

                                let companyInfo = response.updateFounder.data.Company_info_data;
                                let companyData = response.updateFounder.data.company_data;

                                if (is_founder_image_upload === false) {
                                    this.setState({
                                        company: {
                                            ...this.state.company,
                                            [`founder_image_id_${memberIndex}`]: companyInfo.founder[memberIndex].image_id
                                        }
                                    })
                                }

                                // this.setState({
                                //     company: {
                                //         ...this.state.company,
                                //         ...companyInfo,
                                //         ...companyData
                                //     },
                                //     companyCopy: {
                                //         ...this.state.companyCopy,
                                //         ...companyInfo,
                                //         ...companyData
                                //     }
                                // })
                            } else {
                                // alert(response.updateFounder.message);
                                this.handleOpen(response.updateFounder.message);
                            }
                        })

                    }
                } else {

                    console.log("");
                    console.log("founder details are not changed");
                    console.log("");

                    // alert("There is no details to update");
                    this.handleOpen("There is no details to update");
                }
            }
        } else if (type === "team") {

            let team_name = this.state.company['team_name_' + memberIndex];
            let team_designation = this.state.company['team_detail_' + memberIndex];
            let team_image = this.state.company['team_image_' + memberIndex];

            console.log("");
            console.log("team name : " + team_name);
            console.log("team designation : " + team_designation);
            console.log("team image file");
            console.log(team_image);
            console.log("");

            if (team_name === undefined && team_designation === undefined && team_image === undefined) {

                console.log("");
                console.log("team details are undefined");
                console.log("");

                // alert("There is no detail to update");
                this.handleOpen("There is no detail to update");
            } else {

                console.log("");
                console.log("team detail are not undefined");
                console.log("");

                if (this.isCompanyDetailUpdated(3, null, null, memberIndex)) {

                    console.log("");
                    console.log("team details are going to update");
                    console.log("");

                    let isTeamNameValid = this.alphaSpaceValidation(team_name, "team_name", memberIndex, "team name");
                    let isTeamDesignationValid = this.alphaSpaceValidation(team_designation, "team_detail", memberIndex, "team designation");

                    if (isTeamNameValid && isTeamDesignationValid) {

                        let requestParam = {

                            account_id: this.state.ACCOUNT_ID,
                            company_id: this.state.COMPANY_ID,
                            companyInfo_id: this.state.COMPANY_INFO_ID,
                            form_type: Constants.companyFormType[6],
                            team_member_description: team_designation,
                            team_member_name: team_name,
                            teamUpdatePosition: memberIndex,
                            team_image: team_image
                        }

                        let payload = null;
                        let is_team_image_upload = false;

                        if (requestParam.team_image !== undefined) {
                            is_team_image_upload = true;
                            const formData = new FormData();
                            formData.append('team_image', requestParam.team_image);
                            payload = saveCompanyPostAction(requestParam, formData, 'updateTeam');

                        } else {
                            payload = saveCompanyAction(requestParam, "updateTeam");
                        }

                        payload.then(response => {

                            console.log("");
                            console.log("RESPONSE OF UPDATE COMPANY TEAM DETAILS");
                            console.log(response);
                            console.log("");

                            if (response.updateTeam.error !== true && response.updateTeam.status === 200) {

                                // alert("Details are updated");
                                this.handleOpen("Team details are updated");

                                let companyInfo = response.updateTeam.data.Company_info_data;
                                let companyData = response.updateTeam.data.company_data;

                                if (is_team_image_upload === false) {
                                    console.log("im in if of team image not uploading")
                                    this.setState({
                                        company: {
                                            ...this.state.company,
                                            [`team_image_id_${memberIndex}`]: companyInfo.team[memberIndex].image_id
                                        }
                                    })
                                }
                                // this.setState({
                                //     company: {
                                //         ...this.state.company,
                                //         ...companyInfo,
                                //         ...companyData
                                //     },
                                //     companyCopy: {
                                //         ...this.state.companyCopy,
                                //         ...companyInfo,
                                //         ...companyData
                                //     }
                                // })
                            } else {
                                // alert(response.updateTeam.message);
                                this.handleOpen(response.updateTeam.message);
                            }
                        });
                    }
                } else {

                    console.log("");
                    console.log("team details are not changed");
                    console.log("");

                    // alert("There is no details to update");
                    this.handleOpen("There is no details to update");
                }
            }
        }
    }

    isCompanyDetailUpdated(companyInfoType, fieldName, fieldValue, index) {

        if (companyInfoType === 1) {

            if (fieldValue !== "" && fieldValue !== this.state.companyCopy[fieldName]) {

                if (fieldName === "specialization") {
                    this.setState({ finalData: { ...this.state.finalData, [fieldName]: fieldValue.toString() } });
                } else {
                    this.setState({ finalData: { ...this.state.finalData, [fieldName]: fieldValue } });
                }

            } else {
                this.setState({ finalData: { ...this.state.finalData, [fieldName]: null } });
            }
        } else if (companyInfoType === 2) {

            if (this.state.companyCopy.founder[index] === undefined) {
                console.log("new founder going to add");
                return true;

            } else if (this.state.companyCopy.founder[index].name !== this.state.company[`founder_name_${index}`] && this.state.company[`founder_name_${index}`] !== "") {
                console.log("founder name updated");
                return true;

            } else if (this.state.companyCopy.founder[index].designation !== this.state.company[`founder_detail_${index}`] && this.state.company[`founder_detail_${index}`] !== "") {
                console.log("founder designation updated");
                return true;

            } else if (this.state.company[`founder_image_${index}`] !== undefined) {
                console.log("founder image updated");
                return true;

            } else {
                console.log("no founder details updated");
                return false;
            }

        } else if (companyInfoType === 3) {

            if (this.state.companyCopy.team[index] === undefined) {
                console.log("new team going to add");
                return true;

            } else if (this.state.companyCopy.team[index].name !== this.state.company[`team_name_${index}`] && this.state.company[`team_name_${index}`] !== "") {
                console.log("team name updated");
                return true;

            } else if (this.state.companyCopy.team[index].designation !== this.state.company[`team_detail_${index}`] && this.state.company[`team_detail_${index}`] !== "") {
                console.log("team designation updated");
                return true;

            } else if (this.state.company[`team_image_${index}`] !== undefined) {
                console.log("team image updated");
                return true;

            } else {
                console.log("no team details updated");
                return false;
            }
        }
    }

    isObjectEmpty = (object) => {

        let isEmpty = true;
        Object.keys(object).map((key) => {

            if (object[key] !== null) {
                isEmpty = false;
                return isEmpty;
            }
        })

        return isEmpty;
    }

    render() {


        return (
            <div className="row no-gutters">
                <div className="col-12 middle-container edit_aboutform" style={{ paddingRight: "0px" }}>
                    <div className="box bg-white border ">
                        <div className="box-header padding-right-0 headerSticky ">
                            <div className="title">Add Info</div>
                        </div>
                        <div className="box-body">
                            <ul className="deailsList about_detail">
                                <li>
                                    <div>
                                        <div className="label">Company Name</div>
                                        <div className="details">
                                            <input className="form-control" name="company_name" value={this.state.company.company_name} onChange={this.onChangeHandler} />
                                            {this.validator.message('CompanyName', this.state.company.company_name, 'required')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Company Email</div>
                                        <div className="details">
                                            <input className="form-control" name="company_email" value={this.state.company.company_email} onChange={this.onChangeHandler} />
                                            {this.validator.message('CompanyEmail', this.state.company.company_email, 'required|email')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Address</div>
                                        <div className="details">
                                            <textarea className="form-control" name="address" value={this.state.company.address} onChange={this.onChangeHandler}></textarea>
                                            {this.validator.message('address', this.state.company.address, 'required')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Country</div>
                                        <div className="details">
                                            <select className="form-control" name="country" value={this.state.company.country} onChange={this.onChangeHandler}>
                                                <option value="">--select--</option>
                                                {MobileCountryCodes.map((value, key) => <option key={key}>{value.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">State</div>
                                        <div className="details">
                                            <input className="form-control" name="state" value={this.state.company.state} onChange={this.onChangeHandler} />
                                            {this.validator.message('state', this.state.company.state, 'required|alpha_space')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">City</div>
                                        <div className="details">
                                            <input className="form-control" name="city" value={this.state.company.city} onChange={this.onChangeHandler} />
                                            {this.validator.message('city', this.state.company.city, 'required|alpha_space')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Zip Code</div>
                                        <div className="details">
                                            <input className="form-control" name="postal_code" value={this.state.company.postal_code} onChange={this.onChangeHandler} />
                                            {this.validator.message('zipCode', this.state.company.postal_code, 'required|numeric')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Phone No</div>
                                        <div className="details">
                                            <input className="form-control" name="mobile_number" value={this.state.company.mobile_number} onChange={this.onChangeHandler} />
                                            {this.validator.message('mobile_number', this.state.company.mobile_number, 'required|phone|min:10|max:10')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Mobile Country Code</div>
                                        <div className="details">
                                            <select className="form-control" name="mobile_country_code" value={this.state.company.mobile_country_code} onChange={this.onChangeHandler}>
                                                <option value="">--select--</option>
                                                {MobileCountryCodes.map((value, key) => <option key={key}>{value.dial_code}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                {/* <li>
                                    <div>
                                        <div className="label">Fax No</div>
                                        <div className="details">
                                            <input className="form-control" name="fax_number" value={this.state.company.fax_number} onChange={this.onChangeHandler} />
                                            {this.validator.message('fax_number', this.state.company.fax_number, 'required')}
                                        </div>
                                    </div>
                                </li> */}
                                <li>
                                    <div>
                                        <div className="label">Website url</div>
                                        <div className="details">
                                            <input className="form-control" name="website_url" value={this.state.company.website_url} onChange={this.onChangeHandler} />
                                            {this.validator.message('website_url (must use http before url)', this.state.company.website_url, 'required|url')}
                                        </div>
                                    </div>
                                </li>
                                {/* <li>
                                    <div>
                                        <div className="label">Comrate public url</div>
                                        <div className="details">
                                            <input className="form-control" name="companyProfileUrl" value={this.state.company.companyProfileUrl} onChange={this.onChangeHandler} />
                                        </div>
                                    </div>
                                </li> */}
                                <li>
                                    <div>
                                        <div className="label">Company Sub Title</div>
                                        <div className="details">
                                            <input className="form-control" maxLength="60" name="company_sub_title" value={this.state.company.company_sub_title} onChange={this.onChangeHandler} />
                                            {this.validator.message('company_sub_title', this.state.company.company_sub_title, 'required')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Company Type</div>
                                        <div className="details">
                                            <select className="form-control" name="company_type" onChange={this.onChangeHandler}>
                                                <option>Public</option>
                                                <option>Self-Employed</option>
                                                <option>Government</option>
                                                <option>Agency</option>
                                                <option>Nonprofit</option>
                                                <option>Sole Propertiership</option>
                                                <option>Private held</option>
                                                <option>Partnership</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Description</div>
                                        <div className="details">
                                            <textarea className="form-control" name="description" value={this.state.company.description} onChange={this.onChangeHandler}></textarea>
                                            {this.validator.message('description', this.state.company.description, 'required')}
                                        </div>
                                    </div>
                                </li>
                                <li className="imageContainer">
                                    <div>
                                        <div className="label">Cover Image</div>
                                        <div className="details">
                                            <div className="upload-options" >
                                                <input type="file" className="coverImage form-control-file" accept="image/*" name="company_bg_image" onChange={this.onChangeHandler} />
                                                <div className="tooltip_icon"><i className="fa fa-info-circle" aria-hidden="true"></i>
                                                    <span className="tooltip_text">Image should be in .jpg, .jpeg or .png format</span> 
                                                </div>
                                            </div>                                            
                                            <div id="coverImgContainer" className={`image-preview  ${this.state.coverImgPreviewClass}`} style={{ backgroundImage: 'url(' + this.state.company.bgUrl + ')' }}></div>
                                        </div>
                                    </div>
                                </li>
                                <li className="imageContainer">
                                    <div>
                                        <div className="label">Company Logo</div>
                                        <div className="details companyLogo">
                                            <div className="upload-options logo_pre">
                                                <input type="file" className="image-upload form-control-file" accept="image/*" name="company_logo" onChange={this.onChangeHandler} />
                                                <div className="tooltip_icon"><i className="fa fa-info-circle" aria-hidden="true"></i>
                                                    <span className="tooltip_text">Image should be in .jpg, .jpeg or .png format</span> 
                                                </div>
                                            </div>
                                            
                                            <div id="logoImgContainer" className="image-preview img_preview" style={{ backgroundImage: 'url(' + this.state.company.logoUrl + ')' }} />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Year Founded</div>
                                        <div className="details">
                                            <select className="form-control" name="year_founded" value={this.state.company.year_founded} onChange={this.onChangeHandler}>
                                                <option value="">--select--</option>
                                                {Helper.getDropdownsVals('Years').map((value, key) => <option key={value.id}>{value.year}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Industry Type <span className="required_field">*</span></div>
                                        <div className="details">
                                            <select className="form-control" name="industry_type" value={this.state.company.industry_type} onChange={this.onChangeHandler}>
                                                <option value="">--select--</option>
                                                <option>Accounting/Finance</option>
                                                <option>Advertising/PR/MR/Events</option>
                                                <option>Agriculture/Dairy</option>
                                                <option>Animation</option>
                                                <option>Architecture/Interior Designing</option>
                                                <option>Auto/Auto Ancillary</option>
                                                <option>Aviation / Aerospace Firms</option>
                                                <option>Banking/Financial Services/Broking</option>
                                                <option>BPO/ITES</option>
                                                <option>Brewery / Distillery</option>
                                                <option>Broadcasting</option>
                                                <option>Ceramics /Sanitary ware</option>
                                                <option>Chemicals/PetroChemical/Plastic/Rubber</option>
                                                <option>Construction/Engineering/Cement/Metals</option>
                                                <option>Consumer Durables</option>
                                                <option>Courier/Transportation/Freight</option>
                                                <option>Defence/Government</option>
                                                <option>Education/Teaching/Training</option>
                                                <option>Electricals / Switchgears</option>
                                                <option>Export/Import</option>
                                                <option>Facility Management</option>
                                                <option>Fertilizers/Pesticides</option>
                                                <option>FMCG/Foods/Beverage</option>
                                                <option>Food Processing</option>
                                                <option>Fresher/Trainee</option>
                                                <option>Gems &amp; Jewellery</option>
                                                <option>Glass</option>
                                                <option>Heat Ventilation Air Conditioning</option>
                                                <option>Hotels/Restaurants/Airlines/Travel</option>
                                                <option>Industrial Products/Heavy Machinery</option>
                                                <option>Insurance</option>
                                                <option>Internet/Ecommerce</option>
                                                <option>IT-Hardware &amp; Networking</option>
                                                <option>IT-Software/Software Services</option>
                                                <option>KPO / Research /Analytics</option>
                                                <option>Leather</option>
                                                <option>Legal</option>
                                                <option>Media/Dotcom/Entertainment</option>
                                                <option>Medical/Healthcare/Hospital</option>
                                                <option>Medical Devices / Equipments</option>
                                                <option>Mining</option>
                                                <option>NGO/Social Services</option>
                                                <option>Office Equipment/Automation</option>
                                                <option>Oil and Gas/Power/Infrastructure/Energy</option>
                                                <option>Paper</option>
                                                <option>Pharma/Biotech/Clinical Research</option>
                                                <option>Printing/Packaging</option>
                                                <option>Publishing</option>
                                                <option>Real Estate/Property</option>
                                                <option>Recruitment</option>
                                                <option>Retail</option>
                                                <option>Security/Law Enforcement</option>
                                                <option>Semiconductors/Electronics</option>
                                                <option>Shipping/Marine</option>
                                                <option>Steel</option>
                                                <option>Strategy /Management Consulting Firms</option>
                                                <option>Sugar</option>
                                                <option>Telcom/ISP</option>
                                                <option>Textiles/Garments/Accessories</option>
                                                <option>Tyres</option>
                                                <option>Water Treatment / Waste Management</option>
                                                <option>Wellness / Fitness / Sports / Beauty</option>
                                                <option>Other</option>
                                            </select>
                                            {this.validator.message('industry_type', this.state.company.industry_type, 'required')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Company Size <span className="required_field">*</span></div>
                                        <div className="details">
                                            <select className="form-control" name="company_size" value={this.state.company.company_size} onChange={this.onChangeHandler}>
                                                <option value="">--Select--</option>
                                                <option>0-10</option>
                                                <option>11-50</option>
                                                <option>51-100</option>
                                                <option>101-500</option>
                                                <option>500 & Above</option>
                                            </select>
                                            {this.validator.message('company_size', this.state.company.company_size, 'required')}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Specialization</div>
                                        <div className="details">
                                            <TagsInput value={this.state.company.specialization} onChange={this.handleChangeSpecialization} onlyUnique="true" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="box bg-white border ">
                        <div className="box-header padding-right-0 headerSticky">
                            <div className="title">Add Founder</div>
                        </div>
                        <div className="box-body">
                            <ul className="deailsList formAction" id="founderList">
                                <FounderUI state={this.state} onChangeHandler={this.onChangeHandler} removeFounderHandler={this.removeFounderHandler} updateFounderTeamDetail={this.updateFounderTeamDetail} />
                            </ul>
                            <div className="text-right" style={{ paddingRight: "150px" }}>
                                <a onClick={this.addMoreFounderHandler} className="btn btn-xs btn-link" id="addFounderBtn"><i className="fa fa-plus"></i> Add More</a>
                            </div>
                        </div>
                    </div>
                    <div className="box bg-white border ">
                        <div className="box-header padding-right-0 headerSticky">
                            <div className="title">Add Team</div>

                        </div>
                        <div className="box-body">
                            <ul className="deailsList formAction" id="teamList">
                                <TeamUI state={this.state} onChangeHandler={this.onChangeHandler} removeTeamHandler={this.removeTeamHandler} updateFounderTeamDetail={this.updateFounderTeamDetail} />
                            </ul>
                            <div className="text-right" style={{ paddingRight: "150px" }}>
                                <a className="btn btn-xs btn-link" id="addTeamBtn" onClick={this.addMoreTeamHandler}><i className="fa fa-plus"></i> Add More</a>
                            </div>
                        </div>
                    </div>
                    <div className="box bg-white border bottomSticky">
                        <div className="box-body">
                            <div className="text-right" style={{ paddingRight: "150px" }}>
                                <a style={{ width: "46px", height: "29px" }} className="btn btn-xs btn-success" onClick={() => this.updateCompanyDetail(6)} >

                                    {this.state.isLoading ?
                                        <CircularProgress className="loadingSpinnerBtn" />

                                        : "Save"
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function FounderUI(props) {

    let founderUIArr = [];
    props.state.founderArr.forEach(function (val, i) {

        let imgPrevNameVar = 'founder_image_' + val + '_preview';
        let bgImg = {};
        if (props.state.company[imgPrevNameVar] !== undefined) {
            bgImg = { backgroundImage: 'url(' + props.state.company[imgPrevNameVar] + ')' };
        }

        founderUIArr.push(
            <li key={val} className="imageContainer">
                <div>
                    <div className="label">Image</div>
                    <div className="details">
                        <div className="image-preview img_preview" style={bgImg}></div>
                        <div className="upload-options logo_pre">
                            <input type="file" name={`founder_image_${val}`} data-name="founderImg" onChange={props.onChangeHandler} className="image-upload form-control-file" accept="image/*" />
                            <div className="tooltip_icon"><i className="fa fa-info-circle" aria-hidden="true"></i>
                                <span className="tooltip_text">Image should be in .jpg, .jpeg or .png format</span> 
                            </div>

                        </div>                        
                    </div>
                </div>
                <div>
                    <div className="label">Name</div>
                    <div className="details">
                        <input className="form-control" name={`founder_name_${val}`} value={props.state.company[`founder_name_${val}`] || ""} onChange={props.onChangeHandler} type="text" />
                        <span className='text-danger'>{props.state[`founder_name_error_${val}`]}</span>
                    </div>
                </div>
                <div>
                    <div className="label">Designation</div>
                    <div className="details">
                        <input className="form-control" name={`founder_detail_${val}`} value={props.state.company[`founder_detail_${val}`] || ""} onChange={props.onChangeHandler} type="text" />
                        <span className='text-danger'>{props.state[`founder_detail_error_${val}`]}</span>
                    </div>
                </div>
                {/* {
                    (val > 1) && */}
                <div className="actions">
                    <ul>
                        <li>
                            <a className={`btn btn-xs founderRemoveBtn ${props.state.isFounderExists}`} href="#delfounder" onClick={() => props.removeFounderHandler(val, i)}><i className="fa fa-trash"></i></a>
                        </li>
                        <li>
                            <a className={`btn btn-xs founderRemoveBtn`} href="#editfounder" onClick={() => props.updateFounderTeamDetail("founder", i)}><i className="fa fa-save"></i></a>
                        </li>
                    </ul>
                </div>
                {/* } */}
            </li>
        );
    });

    return founderUIArr;
}

function TeamUI(props) {

    let teamUIArr = [];

    props.state.teamArr.forEach(function (val, i) {

        let imgPrevNameVar = 'team_image_' + val + '_preview';
        let bgImg = {};

        if (props.state.company[imgPrevNameVar] !== undefined) {
            bgImg = { backgroundImage: 'url(' + props.state.company[imgPrevNameVar] + ')' };
        }

        teamUIArr.push(
            <li key={val} className="imageContainer">
                <div>
                    <div className="label">Image</div>
                    <div className="details">
                        <div className="image-preview img_preview" style={bgImg}></div>
                        <div className="upload-options logo_pre">
                            <input type="file" name={`team_image_${val}`} data-name="teamImg" onChange={props.onChangeHandler} className="image-upload form-control-file" accept="image/*" />
                            <div className="tooltip_icon"><i className="fa fa-info-circle" aria-hidden="true"></i>
                            <span className="tooltip_text">Image should be in .jpg, .jpeg or .png format</span> 
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div>
                    <div className="label">Name</div>
                    <div className="details">
                        <input className="form-control" name={`team_name_${val}`} value={props.state.company[`team_name_${val}`] || ""} onChange={props.onChangeHandler} type="text" />
                        <span className='text-danger'>{props.state[`team_name_error_${val}`]}</span>
                    </div>
                </div>
                <div>
                    <div className="label">Designation</div>
                    <div className="details">
                        <input className="form-control" name={`team_detail_${val}`} value={props.state.company[`team_detail_${val}`] || ""} onChange={props.onChangeHandler} type="text" />
                        <span className='text-danger'>{props.state[`team_detail_error_${val}`]}</span>
                    </div>
                </div>
                {/* {
                    (val > 1) && */}
                <div className="actions">
                    <ul>
                        <li>
                            <a className={`btn btn-xs founderRemoveBtn ${props.state.isTeamExists}`} href="#delteam" onClick={() => props.removeTeamHandler(val, i)}><i className="fa fa-trash"></i></a>
                        </li>
                        <li>
                            <a className={`btn btn-xs founderRemoveBtn `} href="#editteam" onClick={() => props.updateFounderTeamDetail("team", i)}><i className="fa fa-save"></i></a>
                        </li>
                    </ul>
                </div>
                {/* } */}
            </li>
        );
    });

    return teamUIArr;
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    companyDetails: state.appData.companyDetails,
    loading: state.loading,
});
function mapDispatchToProps(dispatch) {
    return {
        getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyAboutForm);