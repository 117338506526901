import React,{Component} from 'react';

export default class SuggestedPages extends Component{
    render(){
        return(
                <>
                <div className="box border">
                                <div className="box-header padding-right-0">
                                    <div className="title">
                                        <span> Suggested Page </span>
                                    </div>
                                    <div>
                                        <ul className="iconList text-black-50">
                                            <li>
                                                <a href="#">
                                                    <i className="flaticon-repeat"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-body">
                                    <div className="suggestionPage">
                                        <div className="s-header">
                                            <span>885 people like this.</span>
                                            <a href="#"><i className="fa fa-times"></i></a>
                                        </div>
                                        <div className="s-body">
                                            <div>
                                                <ul className="userFollowList">
                                                    <li>
                                                        <img src="images/page_owner.jpg"/>
                                                        <div className="info">
                                                            <h5 className="userName"> WinnerHabits </h5>
                                                            <div className="subTitle text-black-50 text-sm"> Motivational Speaker </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="page-img">
                                                <img src="images/page1.png"/>
                                            </div>
                                        </div>
                                        <div className="s-footer">
                                            <div className="pretty p-icon p-toggle p-plain">
                                                <input type="checkbox"/>
                                                <div className="state p-off">
                                                    <i className="icon fa fa-thumbs-o-up "></i>
                                                    <label>Like Page</label>
                                                </div>
                                                <div className="state p-on p-primary-o ">
                                                    <i className="icon fa fa-thumbs-up"></i>
                                                    <label>Like Page</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </>
            )
    }
}