import React, { Component } from 'react'
import Helper from '../../../../Configs/Helper';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateUserAction,getUserByAccountIdAction } from "../../../../store/actions"
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";        
import Loader from "../../../SubComponents/Loader/Loader";

class PersonalInfoContents extends Component {
    constructor(props){
        super(props);
        this.validator = new SimpleReactValidator({autoForceUpdate: this});
        this.state={
            editshow:false,
            first_name: '',
            last_name: '',
            email:'',
            city: '',
            state: '',
            country: '',
            avatar_url: '',
            profile_url: '',
            mobilenumber: '',
            address:'',
            dob: new Date(),
        }
    }
    componentDidMount(){
        if(this.props.userData!==null && this.props.userData!==undefined){
            if(this.props.userData.data!==null && this.props.userData.data!==undefined){
                const userData = this.props.userData.data
                this.setState({
                    first_name:(userData.first_name!==undefined) ? userData.first_name :'',
                    last_name:(userData.last_name!==undefined) ? userData.last_name:'',
                    email:(userData.email_id!==undefined) ? userData.email_id:'',
                    country:(userData.country!==undefined) ? userData.country:'',
                    // avatar_url:(userData.avatar_url!==undefined) ? userData.avatar_url:'',
                    // profile_url:(userData.profile_url!==undefined) ? userData.profile_url:'',
                    mobilenumber:(userData.mobile_number!==undefined) ? userData.mobile_number:'',
                    dob:(userData.date_of_birth!==undefined) ? userData.date_of_birth:'',
                    city: (userData.address!==undefined) ? userData.address.city:'',
                    state:(userData.address!==undefined) ? userData.address.state:'',
                    address:(userData.address!==undefined) ? userData.address.address_line:'',
                    
                })
            }
        }
    }
    handleDateChange = date => {
        this.setState({ dob: date })
    }
    onClickEditInfoHandler=(e)=>{
        e.preventDefault();
        this.setState({editshow:!this.state.editshow})
    }
    onChangeHandler=e=>{
        if(e.target.name==='mobilenumber'){
            this.setState({mobilenumber:e.target.value.replace(/\D/,'')});
          }else{
        this.setState({
            // startDate: date,
            [e.target.name]:e.target.value,
        })}
      }
    onImgchangeHandler=(e)=>{
        var imgPath=e.target.name.src;      //get image src
            imgPath=URL.createObjectURL(e.target.files[0]);            //   set url of image
            this.setState({                   
                [e.target.name]:imgPath
        })
    }
    onClickSaveHandler=async (e)=>{
       
       
        this.setState({loader:true,saveBtn:false})
        //setTimeout(()=> this.setState({progrss:false,saveBtn:true}),3000)

        if (!this.validator.allValid()) {
            this.validator.showMessages();
             this.forceUpdate();
       
       
        } else {
            this.loader.show(true);

            let dob ='';
                if(this.state.dob!==''){
                    dob = Helper.convertDate(this.state.dob)
                }
                  const  postData = {
                    account_id: this.props.userData.data.account_id,
                    first_name:this.state.first_name,
                    last_name:this.state.last_name,
                    email_id:this.state.email,
                    address:this.state.address,
                    city:this.state.city,
                    state:this.state.state,
                    country:this.state.country,
                    // avatar_url:this.state.avatar_url,
                    // profile_url:this.state.profile_url,
                    mobile_number:this.state.mobilenumber,
                    date_of_birth:this.state.dob,                    
                  }
                    await this.props.updateUser(postData);
                    if(this.props.userData.status===200){
                        this.loader.show(false);
                        this.loader.showAlert(true,'success',this.props.userData.message)
                }else{
                    this.loader.showAlert(true,'error',Helper.flashMsg('apiResErr'))

                }
                    await this.props.getUserByAccountIdAction(this.props.userData.data.account_id);
                   
                    this.setState({editshow:!this.state.editshow})
                    this.loader.show(false);        
                    
                    // this.loader.showAlert(true,'success',this.props.signupData.message)

                   
                   
                }
    }
 
    render() {
     
        return (
            <>
                {this.state.editshow ?
                    <EditPersonalInfo 
                        onClick={this.onClickEditInfoHandler}
                        handleDateChange={this.handleDateChange}
                        onChange={this.onChangeHandler}
                        onClickSave={this.onClickSaveHandler}
                        onImgchange={this.onImgchangeHandler}
                        validator={this.validator}
                            first_name={this.state.first_name}
                            last_name={this.state.last_name}
                            email={this.state.email}
                            city={this.state.city}
                            state={this.state.state}
                            country={this.state.country}
                            avatar_url={this.state.avatar_url}
                            profile_url={this.state.profile_url}
                            mobilenumber={this.state.mobilenumber}
                            dob={this.state.dob}
                            selectedDay={this.state.selectedDay}
                            address={this.state.address}
                            
                    />:      
                            <div className="box-body">
                                <ul className="deailsList">
{/* Edit info */}
                                    <div className="text-right" style={{display: "block"}}>
                                        <a href="#" onClick={this.onClickEditInfoHandler} className="btn btn-sm btn-link"><i className="fa fa-edit"></i> Edit Info</a>
                                    </div>
                                    <li>
                                        <div>
                                            <div className="label">First Name</div>
                                            <div className="details">{this.state.first_name }</div>
                                        </div>

                                    </li>
                                    <li>
                                        <div>
                                            <div className="label">Last Name</div>
                                            <div className="details">{this.state.last_name }</div>
                                        </div>

                                    </li>
                                    <li>
                                        <div>
                                            <div className="label">Email</div>
                                            <div className="details">{this.state.email }</div>
                                        </div>

                                    </li>
                                    <li>
                                        <div>
                                            <div className="label">Mobile</div>
                                            <div className="details">{this.state.mobilenumber }</div>
                                        </div>

                                    </li>
                                    <li>                              
                                        <div>
                                            <div className="label">Date of Birth</div>
                                            <div className="details">{(this.state.dob!=='' && this.state.dob!==null) ? Helper.convertDate(this.state.dob) : ''}</div>
                                        </div>
                                    </li>
                                    <li>                              
                                        <div>
                                        <div className="label">Address</div>
                                                    <div className="details">{this.state.address }</div>
                                        </div>
                                    </li>
                                    <li>                              
                                        <div>
                                        <div className="label">City</div>
                                                    <div className="details">{this.state.city }</div>
                                        </div>
                                    </li>
                                    <li>                              
                                        <div>
                                        <div className="label">State</div>
                                                    <div className="details">{this.state.state }</div>
                                        </div>
                                    </li>
                                   
                                    <li>
                                        <div>
                                            <div className="label">Country</div>
                                            <div className="details">{this.state.country }</div>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div>
                                            <div className="label">Avatar Url</div>
                                            <div className="details">{this.state.avatar_url}</div>
                                            {this.state.avatar_url!=='' ? 
                                            <div className="image-preview">
                                                <img alt="avatarImg" height="50px" width="50px" src={this.state.avatar_url!==null ? this.state.avatar_url:''}/>
                                            </div>
                                            :''}
                                        </div>

                                    </li>
                                    <li>
                                        <div>
                                            <div className="label">Profile url</div>
                                            <div className="details">{this.state.profile_url}</div>
                                            {this.state.profile_url!=='' ?
                                            <div className="image-preview">
                                                <img alt="profileimg" height="50px" width="50px" src={this.state.profile_url!==null ? this.state.profile_url :''}/>
                                            </div>
                                            :''}
                                        </div>

                                    </li> */}
                                </ul>
                                

                            </div>
                            }
                            
                 {/* loader Start */}
          <Loader onRef={ref => {this.loader = ref;}}/>
        {/* loader Start */}    
            </>
        )
    }
}


export  class EditPersonalInfo extends Component{
    render(){
        return(
            <>  
            <div className="box-body">
                            <ul className="deailsList pageList">
                           
                   <li>
                            <div>
                                <div className="label">First Name</div>
                                <div className="details">
                                    <input type="text" className="form-control" onChange={this.props.onChange} name="first_name" value={this.props.first_name}/>
                                <span>{this.props.validator.message('first_name', this.props.first_name, 'required|alpha')}</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="label">Last Name</div>
                                <div className="details">
                                    <input type="text" className="form-control" onChange={this.props.onChange} name="last_name" value={this.props.last_name}/>
                                    <span>{this.props.validator.message('last_name', this.props.last_name, 'required|alpha')}</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="label">Email ID</div>
                                <div className="details">
                                    <input type="text" className="form-control" disabled={true}  name="email" value={this.props.email}/>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="label">Mobile</div>
                                <div className="details">
                                    <input type="text" className="form-control" maxLength="12" onChange={this.props.onChange} name="mobilenumber" value={this.props.mobilenumber}/>
                                    <span>{this.props.validator.message('Mobile number', this.props.mobilenumber, 'required|phone|min:10|max:10')}</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="label">Date of Birth</div>
                                <div className="details">
                                <DatePicker
                                    //dateFormat='MM-DD-YYYY'
                                    selected={this.props.dob ? new Date(moment(this.props.dob).format('YYYY-MM-DD')) : this.props.dob}
                                    onChange={this.props.handleDateChange}
                                    className="form-control"
                                    name="dob"
                                />
                                <span>{this.props.validator.message('dob', this.props.dob, 'required')}</span>
                     
                                
                                    {/* <DatePicker  selected={this.props.startDate} onChange={this.props.onChange} dateFormat="yyyy-dd-mm" /> */}
                                    {/* <input type="text" name="dob" className="form-control" placeholder="yyyy/MM/dd" onChange={this.props.onChange} name="dob" value={this.props.startDate}/> */}
                                    {/* <span>{this.props.validator.message('dob', this.props.dob, 'required')}</span> */}
                                </div>
                                

                            </div>
                        </li>
                        {/* <li style={{position:'absolute',marginLeft:'300px'}}>
                           
                            <div>
                                <CircularProgress />   
                            </div>                        
                        </li> */}
                       <li>
                            <div>
                                <div className="label">Address</div>
                                <div className="details">
                                    <input type="text" className="form-control" onChange={this.props.onChange} name="address" value={this.props.address}/>
                                    <span>{this.props.validator.message('address', this.props.address, 'required')}</span>
                                </div>
                            </div>
                        </li>                       <li>
                            <div>
                                <div className="label">City</div>
                                <div className="details">
                                    <input type="text" className="form-control" onChange={this.props.onChange} name="city" value={this.props.city}/>
                                    <span>{this.props.validator.message('city', this.props.city, 'required')}</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="label">State</div>
                                <div className="details">
                                    <input type="text" className="form-control" onChange={this.props.onChange} name="state" value={this.props.state}/>
                                    <span>{this.props.validator.message('state', this.props.state, 'required')}</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="label">Country</div>
                                <div className="details">
                                    <input type="text" className="form-control" onChange={this.props.onChange} name="country" value={this.props.country}/>
                                    <span>{this.props.validator.message('country', this.props.country, 'required')}</span>
                                </div>
                            </div>
                        </li>
                        {/* <li>
                            <div>
                                <div className="label">Avatar url</div>
                                <div className="details">
                                <input type="file" id="avatarLoader" className="image-upload form-control-file" onChange={this.props.onImgchange} name="avatar_url" 
                                value={this.props.avatar_url} 
                                accept="image/*"/>
                                {this.props.avatar_url!=='' ?
                                    <div className="image-preview">
                                        <img alt="avatarimg" height="50px" width="50px"src={this.props.avatar_url}/>
                                    </div>
                                    :''}
                     
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div className="label">Profile url</div>
                                <div className="details">
                                <input type="file" id="profileLoader" className="image-upload form-control-file" onChange={this.props.onImgchange} name="profile_url" 
                                value={this.props.profile_url} 
                                accept="image/*"/>
                                {this.props.profile_url!=='' ? 
                                    <div className="image-preview">
                                        <img alt="profileImg" height="50px" width="50px"src={this.props.profile_url}/>
                                    </div>
                                    :''}    
                                 
                                </div>

                            </div>
                        </li>   */}
                    </ul>
                        <div className="box-footer text-right" style={{paddingRight: "150px"}}>
                                    <a href="profile-about.html" onClick={this.props.onClick} className="btn btn-light">Cancel</a>
                                    <a href="#" onClick={this.props.onClickSave} className="btn btn-success">Save</a>
                                </div>
                </div>      
                                 
            </>

        )
    }
}
const mapStateToProps = state => ({
    userData: state.auth.userData,
    userPersonalData: state.apiData.userPersonalData,
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      getUserByAccountIdAction: bindActionCreators(getUserByAccountIdAction, dispatch),
      updateUser: bindActionCreators(updateUserAction, dispatch),
    }
  }
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PersonalInfoContents);