import React,{Component} from 'react';
import Helper from "../../../../Configs/Helper";
export default class CompanyProfileCardWithBanner extends Component{
    constructor(props) {
        super(props);
        this.state = {};
      }
    goToCompanyTimeline = async (e) =>{
        Helper.setDefaultCompany(this.props.companyDetails.company_id,'user_company');
            this.props.history.push('/company-timeline');
        }

    render(){
        const company=this.props.companyDetails
        if(company===null){
            return null;
        }
        // const followers = company.totalFollowers;
        // const followings = company.totalFollowing;
        return(
            <div className="box bg-white userContainer shadow-sm">
            <div className="header default-bg" style={{background: 'url('+company.cover_image+')'}} />
            <div className="profileBody">
                <div className="userInfo">
                    <img alt="" src={company.company_logo} className="userImg" />
                    <h4 className="userName">
                        <a href="#" onClick={this.goToCompanyTimeline}>{company.company_name} </a>
                    </h4>
                    <h6 className="text-sm text-black-50"> {company.industry_type} </h6>
                </div>
                <div className="userDetails">
                <h6 className="text-sm text-black-50"> {company.company_sub_title} </h6>

                    {/* <table>
                        <tbody>
                            <tr>
                                { followers !=='' && <td>
                                    <h5><a href="#"> Follower </a></h5>
                                    <span>{followers}</span>
                                </td>
                                }
                                { followings !=='' && <td>
                                    <h5><a href="#"> Following </a></h5>
                                    <span>{followings}</span>
                                </td>
                                }
                               
                            </tr>
                        </tbody> 
                    </table>*/}
                </div>
            </div>
        </div>
        )
    }
}