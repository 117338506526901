import React,{Component} from 'react';
import { connect } from "react-redux";
import Helper from "../../../../Configs/Helper";
export default class CompanyProfileCard extends Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    render(){
        const compObj = this.props.companyDetails
        if(compObj===null){
            return null;
        }
        
        return(
            <div className="box  userContainer  company">
                <div className="header default-bg" />
                <div className="profileBody">
                <div className="userInfo editProfileImage">
                    <figure>
                    <img alt="Company Logo" src={compObj.company_logo} className="userImg" />
                    </figure>
                    <div className="editProfileDetail">
                    <h4 className="userName">
                        <a href="#"> {compObj.company_name} </a>
                    </h4>
                    <h6 className="text-sm text-black-50"> {compObj.industry_type} </h6>
             
                    </div>
                    <div className="userDetails">
                    {/* <h6 className="text-sm text-black-50"> {compObj.company_sub_title} </h6> */}

                        {/* <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h5><a href="#"> Follower </a></h5><span>{compObj.totalFollowers}</span></td><td>
                                        <h5><a href="#"> Following </a></h5><span>{compObj.totalFollowing}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}
                    </div>
                </div>
                </div>
            </div>
        )
    }
}