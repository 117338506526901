import React, { Component } from 'react'

export default class CompanySetting extends Component {
    constructor(porps){
        super(porps);
            this.state={
                tab:[{id:1,tab:'Review'},{id:2,tab:'Address'},{id:3,tab:'Company profile'}],
                show:'Review',
                activeCurrntTab:{id:1}
            }
        }

    onClickcurrentTab=(ind)=>e=>{
        e.preventDefault();
        this.setState({activeCurrntTab:this.state.tab[ind]})
        this.state.tab.map((tab1,idx)=>{
            if(ind===idx){return this.setState({show:tab1.tab})
            }return tab1})
    }
    render() {
        return (<>
            <div className="tab-pane  active show" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div className="detailsHeader">
                    Company Settings
                        </div>
                        <ul className="settingsList">
                            <li className="title">Change The Company  Visibility According To your Preference</li>
                      
                                <li><div>Company Visibility </div>
                                    <div><div className="dropdown" >
                                            <select className="form-control" name="visibility" onChange={this.props.onChange} value={this.props.visibility} data-width="100px" tabIndex="-98">
                                                <option value="1" data-icon="fa fa-globe">Public</option>
                                                <option value="0" data-icon="fa fa-lock">Private</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                            <li className="divider"></li>       {/*divider*/}
                            {/* Tabs */}
                                <div className="box bg-transparent">
                                    <div className="box-body padding-0">
                                        <div className="nav nav-pills" id="v-pills-tab" role="tablist">
                                            {this.state.tab.map((tab,ind)=>(
                                            
                                                <span  key={tab.tab}
                                                    // onClick={this.btnClick(ind)}
                                                    className={tab.id === this.state.activeCurrntTab.id ? 'nav-link active' : 'nav-link' } 
                                                    onClick={this.onClickcurrentTab(ind)}
                                                    id="v-pills-member-tab" 
                                                    data-toggle="pill" 
                                                    role="tab"
                                                    aria-controls="v-pills-member" 
                                                    aria-selected="true"
                                                    style={{cursor:'pointer'}}>
                                                    {tab.tab}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                               
                            <li className="title">Change The Field  Visibility According To your Preference</li>
                                {this.state.show ==="Review"  ?                     //contents
                                                                                                //Review Setting      
                                        Object.keys(this.props.Review).map((key,i)=>(        
                                    <li key={i}><div>{key[0].toUpperCase() + key.slice(1).split('_').join(' ')}</div>
                                        <div><div className="dropdown ">
                                                <select className="form-control" name={key} onChange={this.props.onChange} value={this.props.Review[key]} data-width="100px" tabIndex="-98">
                                                    <option value="1" data-icon="fa fa-globe">Public</option>
                                                    <option value="0" data-icon="fa fa-lock">Private</option>
                                                    <option value="2" data-icon="fa fa-sitemap">Network</option>                                
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    ))    
                                :''}
                                {this.state.show ==="Address"  ?
                                                                                                      //Address Setting
                                        Object.keys(this.props.Address).map((key,i)=>(        
                                   
                                    <li key={i}><div>{key[0].toUpperCase() + key.slice(1).split('_').join(' ')}</div>
                                        <div><div className="dropdown " style={{width: "100px"}}>
                                                <select className="form-control" name={key} onChange={this.props.onChange} value={this.props.Address[key]} data-width="100px" tabIndex="-98">
                                                    <option value="1" data-icon="fa fa-globe">Public</option>
                                                    <option value="0" data-icon="fa fa-lock">Private</option>
                                                    <option value="2" data-icon="fa fa-sitemap">Network</option>                                
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    ))
                                :''}
                                {this.state.show ==="Company profile"  ?  
                                        
                                        Object.keys(this.props.CompanyProfile).map((key,i)=>(        
                                        
                                        <li key={i}><div>{key[0].toUpperCase() + key.slice(1).split('_').join(' ')}</div>
                                            <div><div className="dropdown " style={{width: "100px"}}>
                                                    <select className="form-control" name={key} onChange={this.props.onChange} value={this.props.CompanyProfile[key]} data-width="100px" tabIndex="-98">
                                                        <option value="1" data-icon="fa fa-globe">Public</option>
                                                        <option value="0" data-icon="fa fa-lock">Private</option>
                                                        <option value="2" data-icon="fa fa-sitemap">Network</option>                                
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        ))
                                    :''}   
                                </ul>
                            </div>  
                        </>
                    )
                }
            }