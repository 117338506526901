
import React, { Component } from "react";
class CompanyConnectionModal extends Component {
//   constructor(props) {
//     super(props);
//   }

  render() {
    return (
      <>
      <div className="modal" id="relationModal" tabIndex="-1" role="dialog" aria-labelledby="relationModal" aria-hidden="true">
                <div className="modal-dialog modal-md networkModel" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title text-md text-muted" id="followModalLabel">Network </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label>What's your relation with this company?</label>
                            <select className="conn_dropdown" data-width="100%" onChange={this.props.onChangeHandler} value={this.props.state.isRelation}>
                                <option value="1">Customer</option>
                                <option value="2">Professional</option>
                                <option value="3">Vendor</option>
                                <option value="4">Supplier</option>
                                <option value="5">Other</option>
                            </select>
                            <textarea className="form-control" name="networkMsg" value={this.props.state.networkMsg} placeholder="Write a message for company..." onChange={this.props.onChangeHandler} style={{marginTop:"20px"}}></textarea>
                        </div>
                        <div className="modal-footer">
                            <div> </div>
                            <div>
                                <button type="button" className="btn btn-sm btn-primary" onClick={this.props.connectionCompanyHandler}>Send Request</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </>
    )       
  }
}

export default CompanyConnectionModal;
