import React,{useRef,useEffect} from 'react'
import Footer from '../../../SubComponents/Footer/Footer';
import { userLogoutAction,getReviewBycompanyidAction } from "../../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addRating } from "../../../../store/actions";
import SimpleReactValidator from 'simple-react-validator';      
import Loader from "../../../SubComponents/Loader/Loader";
import Helper from '../../../../Configs/Helper';
import StarRatingComponent from 'react-star-rating-component';
import $ from 'jquery';
class Rating extends React.Component {

    logout = async ()=>{
        await this.props.userLogoutAction();
        this.props.history.push('/');
      }
        constructor(props){
            
            super(props);
            this.validator = new SimpleReactValidator();

            this.state={
                reviewCount:'',
                answer1:'',
                answer2:'',
                answer:[],
                question:[1,2],
                answerScnd:'',
                pros:'',
                cons:'',
                description:'',
                rating: 1,

            }
        }
        componentDidMount=(e)=>{
            if(this.props.reviewerData!==null && this.props.reviewerData!==undefined){
                if(this.props.reviewerData.data!==null && this.props.reviewerData.data!==undefined){
                    if(this.props.reviewerData.data.reviews!==null && this.props.reviewerData.data.reviews!==undefined){
                        this.setState({reviewCount:this.props.reviewerData.data.reviews.length})
                    }
                }
            }
        }

        onStarClick(nextValue, prevValue, name) {
             this.setState({rating: nextValue});
          }
        onChangeHandler=(e)=>{
            this.setState({[e.target.name]:e.target.value,
                answer:[this.state.answer1,this.state.answer2]})
         }
        onClickSaveHandler=async (e)=>{
            if (this.validator.allValid()) {
                this.loader.show(true);
                const  postData = {
                    company_id: this.props.companyId,
                    reviewer_id: this.props.userData.data.account_id,
                    reviewer_type:'USER',
                    answers:this.state.answer.toString(),
                    questions:this.state.question.toString(),
                    pros:this.state.pros,
                    cons:this.state.cons,
                    description:this.state.description,
                    rating: this.state.rating,          
                }
                    await this.props.addRating(postData);
                    if(this.props.userData.status===200){
                        $('.close').trigger('click');
                        this.props.reviewCounter();

                    await this.props.getReviewBycompanyidAction({company_id:this.props.companyId})
                    this.loader.show(false);
                    this.loader.showAlert(true,'success','Successfully add review')
                
                }else{
                this.loader.showAlert(true,'error',Helper.flashMsg('apiResErr'))}
         } else {
                this.validator.showMessages();
                this.forceUpdate()
                } 
    }
        render(){
            const { rating } = this.state;
        return(
            <>
                <div className="box bg-white border ">
                    <div className="ar_heading ">
                        <div className=" detailsHeader">Add Rating</div>
                    </div>
                        <div className="box-body">
                            
                            <ul className="deailsList ar_list">
                                <li>
                                {/* {this.state.answer.map((ans,indx)=>(
                                    <> */}
                                      <div>
                                        <div className="label">Question</div>
                                            <div className="details">
                                                <label>Have you ever been in a demo where the prospect wasn’t talking at all?</label>
                                            </div>
                                    </div>
                                    
                                        <div>
                                            <div className="label">Answer</div>
                                                <div className="details">
                                                    <input type="text" name="answer1" value={this.state.answer1} onChange={this.onChangeHandler} className="form-control"/>
                                                    <span>{this.validator.message('answer 1', this.state.answer1, 'required')}</span>
                     
                                                </div>
                                        </div>
                                       {/* </> ))}         */}
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Question</div>
                                            <div className="details">
                                                <label>Why is this such an important problem for you to fix?</label>
                                            </div>
                                    </div>
                                    <div>
                                        <div className="label">Answer</div>
                                            <div className="details">
                                                <textarea type="text" name="answer2" value={this.state.answer2} onChange={this.onChangeHandler} className="form-control"></textarea>
                                                <span>{this.validator.message('answer 2', this.state.answer2, 'required')}</span>
                                            </div>
                                    </div>
                                                       
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Pros</div>
                                            <div className="details">
                                                <textarea type="text" name="pros" value={this.state.pros} onChange={this.onChangeHandler} className="form-control"></textarea>
                                                <span>{this.validator.message('pros', this.state.pros, 'required')}</span>
                                   
                                            </div>
                                    </div>
                                </li>
                                <li>    
                                    <div>
                                        <div className="label">Cons</div>
                                            <div className="details">
                                                <textarea type="text" name="cons" value={this.state.cons} onChange={this.onChangeHandler} className="form-control"></textarea>
                                                <span>{this.validator.message('cons', this.state.cons, 'required')}</span>
                                      </div>
                                        </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Advice to Management</div>
                                            <div className="details">
                                                <textarea type="text" name="description" onChange={this.onChangeHandler} value={this.state.description} className="form-control"></textarea>
                                                <span>{this.validator.message('description', this.state.description, 'required')}</span>
                                            </div>
                                        </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="label">Overall Rating</div>
                                            <div className="details">
                                                <div className="company-rating">
                                                    <div className="jq-star" style={{width:"100%",  height:"100px"}}>
                                                    <div style={{fontSize:"30px"}}>
                                                            <StarRatingComponent 
                                                              name="rate1" 
                                                              starCount={5}
                                                              value={rating}
                                                              onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                            </ul>
                                            </div>
                                        </div>
                                        <div className="box bg-white border bottomSticky">
                                            <div className="box-body">
                                                <div className="text-right"  style={{ paddingRight: 150 }}>
                                                    <a className="btn btn-xs btn-success" onClick={this.onClickSaveHandler}> Save </a>
                                                </div>
                                            </div>
                                        </div>
        {/* loader Start */}
          <Loader onRef={ref => {this.loader = ref;}}/>
        {/* loader Start */} 
            </>
        )
    }}
    const mapStateToProps = state => ({
        userData: state.auth.userData,
        reviewerData: state.apiData.reviewerData,
        addRatingData:state.apiData.addRatingData
    
  });

function mapDispatchToProps(dispatch) {
    return {
        addRating: bindActionCreators(addRating, dispatch),
        getReviewBycompanyidAction: bindActionCreators(getReviewBycompanyidAction, dispatch),
    }
  }
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Rating);