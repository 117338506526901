import React,{Component} from 'react';

    export default class CmpnyTrackerTrend extends Component{
        render(){
            return(
                <>
                <div className="box border">
                    <div className="box-header with-border ">
                        <div className="title">
                            <span> Company Tracker </span>
                                </div>
                                    <div className="title">
                                        <span>Credit Trends</span>
                                            </div>
                                                </div>
                    <div className="box-body padding-0">
                        <ul className="withIconList list with-border">
                            <li>
                                <a href="#">
                                    <img className="companyImg" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1024px-Apple_logo_black.svg.png"/> 
                                        <span>Apple</span>
                                            <i className="trendsIcon fa fa-arrow-up green"></i>
                                                </a>
                                                    </li>
                            <li>
                                <a href="#">
                                    <img className="companyImg" src="https://www.carlogos.org/logo/General-Motors-logo-2010-3300x3300.png"/> 
                                        <span>General Motors</span>
                                            <i className="trendsIcon fa fa-arrow-down red"></i>
                                                </a>
                                                    </li>
                            <li>
                                <a href="#">
                                    <img className="companyImg" src="https://1000logos.net/wp-content/uploads/2017/05/Pepsi-Logo.png"/> 
                                        <span>Papsi</span>
                                            <i className="trendsIcon fa fa-arrow-right orange"></i>
                                                </a>
                                                     </li>
                            <li>
                                <a href="#">
                                    <img className="companyImg" src="http://logok.org/wp-content/uploads/2014/06/Microsoft-logo-m-box-880x660.png"/> 
                                        <span>Microsoft</span>
                                            <i className="trendsIcon fa fa-arrow-up fa-rotate-45 light-green"></i>
                                                </a>
                                                    </li>
                            <li>
                                <a href="#">
                                    <img className="companyImg" src="https://cdn.imgbin.com/22/18/24/imgbin-fast-food-mcdonald-s-logo-golden-arches-restaurant-mcdonalds-mcdonald-s-logo-m67i4VFZD6Fp5b0h16emYuc12.jpg"/> 
                                        <span>McDonald's</span>
                                            <i className="trendsIcon fa fa-arrow-right fa-rotate-45 dark-orange"></i>
                                                </a>
                                                    </li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}