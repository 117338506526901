import React from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import Helper from "../../../Configs/Helper"
import SimpleLoader from '../../SubComponents/Loader/SimpleLoader';
import Loader from "../../SubComponents/Loader/Loader";
import Footer from "../../SubComponents/Footer/Footer";
import Header from "../../SubComponents/Header/Header";
import AddPostModal from "../../SubComponents/AddPost/AddPostModal";
import NewsFeed from "../../SubComponents/NewsList/NewsList";
import CompanyProfileCard from "./SideComponents/CompanyProfileCard"
import CompanyProfileCardWithBanner from "./SideComponents/CompanyProfileCardWithBanner"
import CompanyPagesLinks from "./SideComponents/CompanyPagesLinks"
import CompanyCommunity from "./SideComponents/CompanyCommunity"
import { getPostListAction, getCompanyDetailsByIdAction, getFollowedCompanyPostAction } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CompanyYouMayKnow from '../GlobalPages/CompanyYouMayKnow';
import FollowersFollowings from "./SideComponents/FollowersFollowings";
import Firebase from "../Firebase/Firebase";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMoreRecords: false,
            nextPage: '',
            items: [],
            likes: [],
            comments: [],
            companies: {},
            levelDetails: {},
            //id: this.props.userData.data.account_id, //account id
            companyId: '',
            paramsData: {
                poster_id: '',
                created_ts: '',
                pageStage: '',
                fetch_size: ''
            },
            companyDetails:{},
            selectedPostData: null
        };
        this.validator = new SimpleReactValidator();
    }
    async componentDidMount(){
        //const companyId =  await localStorage.getItem('COMPANY_ID');
        const compObj = Helper.getCompanyDetailsObj(this.props.ownCompanyDetails);
        Helper.setDefaultCompany(compObj.id,'user_company');
        // if(compObj.id!==''){
        //     this.setState({ companyId: compObj.id, companyDetails: compObj },()=>{
        //         this.getPostList()
        //     })
                
        // }
        this.setState({ companyId: compObj.id, companyDetails: compObj },()=>{
            this.getPostList()
        })
        const paramsData = {
            account_id: this.props.userData.data.account_id,
            company_id: this.state.companyId,
        }

        this.firebase.fcmConfiguration();
        await this.props.getCompanyDetailsByIdAction(paramsData)
       
    }
    getPostList = async () =>{
        await this.props.getFollowedCompanyPostAction({ follower_id: (this.state.companyId!=='')  ? this.state.companyId : this.props.userData.data.account_id });
        if(this.props.followedCompanyPostList.status===200){
            const { posts, like_post, poster_image, level_detail, isNext, nextCreatedTs } = this.props.followedCompanyPostList.data
           
            const prevItems = this.state.items
            const prevLikes = this.state.likes
            const prevCompanies = this.state.companies
            const prevLevelDetails = this.state.levelDetails

            this.setState({ 
                hasMoreRecords: isNext, 
                nextPage: nextCreatedTs, 
                items: [...prevItems, ...posts],
                likes: (like_post!==undefined) ? [...prevLikes, ...like_post] : prevLikes,
                companies: (poster_image!==undefined) ? Object.assign(prevCompanies,poster_image) : prevCompanies,
                levelDetails: (level_detail!==undefined) ? Object.assign(prevLevelDetails,level_detail) : prevLevelDetails,
             })
        } else {
            this.setState({ hasMoreRecords: false })
            //this.loader.showAlert(true,'error',this.props.followedCompanyPostList.message)
        }
    }

    fetchMoreData = () => {
        if(this.state.hasMoreRecords){
            this.setState({ nextPage: this.state.nextPage },()=>this.getPostList());
        }
        return true
    }
    setSelectedPostData = selectedPostData =>{
        this.setState({ selectedPostData });
    }
    newAddedItem =addedItem=> {
        const companyDetails = this.state.companyDetails
        const companyId = companyDetails.company_id
        const prevItems = this.state.items
        
        const prevCompanies = this.state.companies
        let posterImage = {name: null, image: null}
        const { company } = this.props.ownCompanyDetails.data // for get only company logo
        if(company!==undefined && company!==null){            
            if(company.company_name!==undefined && company.company_name!==null) posterImage.name = company.company_name;
            if(company.company_logo!==undefined && company.company_logo!==null) posterImage.image = company.company_logo.image_path;
        }
        addedItem.image = { imagePath: '', imageUrl: companyDetails.company_logo }
        this.setState({ 
            items: [addedItem,...prevItems],
            companies: Object.assign(prevCompanies,{[companyId]: posterImage} ),
         })
    }
    updatedPostItem = (updatedItem, id)=> {
        //const companyDetails = this.state.companyDetails
        let prevItems = this.state.items
        let filteredArray = prevItems.map((item,k)=>{ 
            if(item.id === id){
                item = {...item,...updatedItem};
            }
            return item;            
          })
          this.setState({items:filteredArray})
    }
    // gototimeline=(e)=>{
    //     this.props.history.push('/company-timeline');
    // }
    render() {
        return (
            <div>
                <Header {...this.props} headerType="newsFeed"/>
                <main className="mainBody">
                    <div className="banner">
                    <img alt="" src="images/banner.jpg" />
                    </div>
                    <div className="bodyContent">
                    <div className="container">
                        <div className="row no-gutters">
                        <div className="col-sm-12 col-md-3 leftSide-container">
                            <div className="inner ">
                                { this.state.companyId!=='' && <CompanyProfileCardWithBanner companyDetails={this.state.companyDetails} {...this.props}/> }
                                { this.state.companyId!=='' && <CompanyPagesLinks companyDetails={this.state.companyDetails}/> }
                            
                                <div className="box ">
                                <div className="left_content">
                                <ul className="nav nav-tabs nav-fill side_nav" role="tablist" style={{background: '#f9f9f9'}}>
                                    <li className="nav-item">
                                    <a className="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="true">Comrating</a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">Credit</a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="false">Sentiment</a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" id="four-tab" data-toggle="tab" href="#four" role="tab" aria-controls="four" aria-selected="false">Supply Chain</a>
                                    </li>
                                </ul>
                                <div className="tab-content border" style={{borderTop: 'none !important', minHeight: '250px'}}>
                                    <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                                    <div id="radarChartDiv" className="chart" />
                                    </div>
                                    <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">2</div>
                                    <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">3</div>
                                    <div className="tab-pane fade" id="four" role="tabpanel" aria-labelledby="four-tab">4</div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className=" middle-container">
                                {this.state.companyId!=='' && <div className="box bg-white border creatPostSection">
                                    <div className="box-header padding-right-0">
                                        <div className="title">
                                        <a href="#" className="text-black-50 link" data-toggle="modal" data-target="#newPostModal" onClick={()=>this.setState({selectedPostData: null})}>
                                            <i className="flaticon-edit-1" /> Start a Post
                                        </a>
                                        </div>
                                        <div>
                                        <ul className="iconList text-black-50">
                                            <li>
                                            <a href="#" data-toggle="modal" data-target="#newPostModal" onClick={()=>this.setState({selectedPostData: null})}>
                                                <i className="flaticon-plus" />
                                            </a>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    <div className="box-footer bg-light border-top padding-5 text-sm text-black-50">
                                        <span className="text-bold "> Write an article </span> on COMRATE
                                    </div>
                                </div>
                            }
                                <div className="box bg-white border" >
                                    <div className="box-body">
                                        <Link to="/Search" className="btn-dashed">
                                            Search New Companies
                                        </Link>
                                    </div>
                                </div>
                                <br/>

                                <NewsFeed  page={'news_feed'} state={this.state} isSelfCompany={false} fetchMoreData={this.fetchMoreData} setSelectedPostData={this.setSelectedPostData} {...this.props}/>                                                                   
                            </div>
                        </div>

                            <div className="col-sm-12 col-md-3 rightSide-container ">
                                <div className="inner">
                                { this.state.companyId!=='' && <FollowersFollowings companyDetails={this.state.companyDetails}/>}
                                { this.state.companyId!=='' && <CompanyCommunity companyDetails={this.state.companyDetails}/>}
                                    
                                <div className="box border">
                                    <div className="box-header padding-right-0">
                                    <div className="title">
                                        <span> Advertisment </span>
                                    </div>
                                    <div>
                                        <ul className="iconList text-black-50">
                                        <li>
                                            <a href="#">
                                            <i className="flaticon-repeat" />
                                            </a>
                                        </li>
                                        </ul>
                                    </div>
                                    </div>
                                    <div className="box-body">
                                    <div className="suggestionPage">
                                        <div className="s-body">
                                        <div className="page-img padding-0">
                                            <img alt="" src="https://blog.bannersnack.com/wp-content/uploads/2018/01/Blog-Post-Process-of-creating-ads.jpg" />
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="box border" style={{display: 'none'}}>
                                    <div className="box-header padding-right-0">
                                    <div className="title">
                                        <span> People You May Know </span>
                                    </div>
                                    <div>
                                        <ul className="iconList text-black-50">
                                        <li>
                                            <a href="#">
                                            <i className="flaticon-repeat" />
                                            </a>
                                        </li>
                                        </ul>
                                    </div>
                                    </div>
                                    <div className="box-body">
                                    <ul className="userFollowList">
                                        <li>
                                        <img alt="" src="images/users/1.jpg" />
                                        <div className="info">
                                            <h5 className="userName"> Smith Black </h5>
                                            <div className="subTitle text-black-50 text-sm"> SubTitle </div>
                                        </div>
                                        <div className="actionBtns">
                                            <a href="#" className="btn btn-sm btn-outline-primary followBtn"><i className="fa fa-user-plus" /> Add Friend </a>
                                            <a href="#" className="btn btn-sm btn-outline-primary">Remove</a>
                                        </div>
                                        </li>
                                        <li>
                                        <img alt="" src="images/users/2.png" />
                                        <div className="info">
                                            <h5 className="userName"> Ajay Singh </h5>
                                            <div className="subTitle text-black-50 text-sm"> SubTitle </div>
                                        </div>
                                        <div className="actionBtns">
                                            <a href="#" className="btn btn-sm btn-outline-primary followBtn"><i className="fa fa-user-plus" /> Add Friend </a>
                                            <a href="#" className="btn btn-sm btn-outline-primary">Remove</a>
                                        </div>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <CompanyYouMayKnow />
                                <div className="box border">
                                    <div className="box-header padding-right-0">
                                    <div className="title">
                                        <span> Suggested Page </span>
                                    </div>
                                    <div>
                                        <ul className="iconList text-black-50">
                                        <li>
                                            <a href="#">
                                            <i className="flaticon-repeat" />
                                            </a>
                                        </li>
                                        </ul>
                                    </div>
                                    </div>
                                    <div className="box-body">
                                    <div className="suggestionPage">
                                        <div className="s-header">
                                        <span>885 people like this.</span>
                                        <a href="#"><i className="fa fa-times" /></a>
                                        </div>
                                        <div className="s-body">
                                        <div>
                                            <ul className="userFollowList">
                                            <li>
                                                <img alt="" src="images/page_owner.jpg" />
                                                <div className="info">
                                                <h5 className="userName"> WinnerHabits </h5>
                                                <div className="subTitle text-black-50 text-sm"> Motivational Speaker </div>
                                                </div>
                                            </li>
                                            </ul>
                                        </div>
                                        <div className="page-img">
                                            <img alt="" src="images/page1.png" />
                                        </div>
                                        </div>
                                        <div className="s-footer">
                                        <div className="pretty p-icon p-toggle p-plain">
                                            <input type="checkbox" />
                                            <div className="state p-off">
                                            <i className="icon fa fa-thumbs-o-up " />
                                            <label>Like Page</label>
                                            </div>
                                            <div className="state p-on p-primary-o ">
                                            <i className="icon fa fa-thumbs-up" />
                                            <label>Like Page</label>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </main>
  
                <Footer />
                <Loader onRef={ref => {this.loader = ref;}}/>
                <Firebase onRef={ref => {this.firebase = ref;}}/>
                <AddPostModal state={this.state} newAddedItem={this.newAddedItem} updatedPostItem={this.updatedPostItem}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    postData: state.appData.newAddedPostData,
    postListData: state.appData.postListData,
    //companyDetails: state.appData.companyDetails,
    ownCompanyDetails: state.appData.ownCompanyDetails,
    followedCompanyPostList: state.appData.followedCompanyPostList,
    loading: state.loading,
  });
function mapDispatchToProps(dispatch) {
    return {
        getPostListAction: bindActionCreators(getPostListAction, dispatch),
        getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
        getFollowedCompanyPostAction: bindActionCreators(getFollowedCompanyPostAction, dispatch),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);