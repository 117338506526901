import React,{Component} from 'react';

export default class Advertisment extends Component{
    render(){
        return(
            <>
            <div className="box border">
                                            <div className="box-header padding-right-0">
                                                <div className="title">
                                                    <span> Advertisment </span>
                                                </div>
                                                <div>
                                                    <ul className="iconList text-black-50">
                                                        <li>
                                                            <a href="#">
                                                                <i className="flaticon-repeat"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="box-body">
                                                <div className="suggestionPage">
                                                    <div className="s-body">
                                                        <div className="page-img padding-0">
                                                            <img src="https://blog.bannersnack.com/wp-content/uploads/2018/01/Blog-Post-Process-of-creating-ads.jpg"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
            </>
        )
    }
}