import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Routes from './routes'
import * as serviceWorker from './serviceWorker';

import store from "./store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// import Bootstrap from 'bootstrap/dist/css/bootstrap.min.css';

import {Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
const options = {
	position: 'top center',
	timeout: 10000,
	offset: '30px',
	transition: 'scale',
	zIndex: 9999,
  }

const persistor = persistStore(store);
const history = createBrowserHistory();
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ReactDOM.render((
	<AlertProvider template={AlertTemplate} {...options}>
	<Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
	    <Router history={history}>
	        <Switch>
	          <Route path="/" name="Comrate" component={Routes} />
	        </Switch>
	    </Router>
		</PersistGate>
	 </Provider>
	 </AlertProvider>
), document.getElementById('root'))
serviceWorker.unregister();
