import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const { SearchBar, ClearSearchButton } = Search;


class ReactBootstrapTable extends React.Component {

    render() {
        return (
            <ToolkitProvider
                keyField={this.props.keyField}
                data={this.props.data}
                columns={this.props.columns}
                search>
                {props => (
                    <div>
                        <div style={{ float: "right" }}>
                            <SearchBar {...props.searchProps} />
                            {/* <ClearSearchButton {...props.searchProps} /> */}
                        </div>
                        {/* <hr /> */}
                        <BootstrapTable
                            bootstrap4
                            striped
                            hover
                            {...props.baseProps}
                            // keyField={this.props.keyField}
                            // data={this.props.data}
                            // columns={this.props.columns}
                            pagination={paginationFactory({alwaysShowAllBtns:true, showTotal:true})}
                            defaultSorted={this.props.defaultSorted}
                            cellEdit={cellEditFactory(this.props.cellEditData)}
                        />
                    </div>
                )}
            </ToolkitProvider>
        )
    }
}

export default ReactBootstrapTable;