export const ROOT_URL = 'http://104.197.101.70:8080'

/**
 * AUTH
 */

export const LOGOUT_ACTION_REQUEST = 'LOGOUT_ACTION_REQUEST'
export const LOGOUT_ACTION_SUCCESS = 'LOGOUT_ACTION_SUCCESS'
export const LOGOUT_ACTION_FAILURE = 'LOGOUT_ACTION_FAILURE'

export const GENERATE_TOKEN_ACTION_REQUEST = 'GENERATE_TOKEN_ACTION_REQUEST'
export const GENERATE_TOKEN_ACTION_SUCCESS = 'GENERATE_TOKEN_ACTION_SUCCESS'
export const GENERATE_TOKEN_ACTION_FAILURE = 'GENERATE_TOKEN_ACTION_FAILURE'

export const REGISTER_BY_EMAIL_ACTION_REQUEST = 'REGISTER_BY_EMAIL_ACTION_REQUEST'
export const REGISTER_BY_EMAIL_ACTION_SUCCESS = 'REGISTER_BY_EMAIL_ACTION_SUCCESS'
export const REGISTER_BY_EMAIL_ACTION_FAILURE = 'REGISTER_BY_EMAIL_ACTION_FAILURE'

export const OTP_VERIFY_ACTION_REQUEST = 'OTP_VERIFY_ACTION_REQUEST'
export const OTP_VERIFY_ACTION_SUCCESS = 'OTP_VERIFY_ACTION_SUCCESS'
export const OTP_VERIFY_ACTION_FAILURE = 'OTP_VERIFY_ACTION_FAILURE'

export const RESET_PASS_OTP_VERIFY_ACTION_REQUEST = 'RESET_PASS_OTP_VERIFY_ACTION_REQUEST'
export const RESET_PASS_OTP_VERIFY_ACTION_SUCCESS = 'RESET_PASS_OTP_VERIFY_ACTION_SUCCESS'
export const RESET_PASS_OTP_VERIFY_ACTION_FAILURE = 'RESET_PASS_OTP_VERIFY_ACTION_FAILURE'

export const SEND_OTP_ACTION_REQUEST = 'SEND_OTP_ACTION_REQUEST'
export const SEND_OTP_ACTION_SUCCESS = 'SEND_OTP_ACTION_SUCCESS'
export const SEND_OTP_ACTION_FAILURE = 'SEND_OTP_ACTION_FAILURE'

export const RESET_PASSWORD_ACTION_REQUEST = 'RESET_PASSWORD_ACTION_REQUEST'
export const RESET_PASSWORD_ACTION_SUCCESS = 'RESET_PASSWORD_ACTION_SUCCESS'
export const RESET_PASSWORD_ACTION_FAILURE = 'RESET_PASSWORD_ACTION_FAILURE'

export const LOGIN_BY_EMAIL_ACTION_REQUEST = 'LOGIN_BY_EMAIL_ACTION_REQUEST'
export const LOGIN_BY_EMAIL_ACTION_SUCCESS = 'LOGIN_BY_EMAIL_ACTION_SUCCESS'
export const LOGIN_BY_EMAIL_ACTION_FAILURE = 'LOGIN_BY_EMAIL_ACTION_FAILURE'

export const USER_BY_ACCOUNT_ID_ACTION_REQUEST = 'USER_BY_ACCOUNT_ID_ACTION_REQUEST'
export const USER_BY_ACCOUNT_ID_ACTION_SUCCESS = 'USER_BY_ACCOUNT_ID_ACTION_SUCCESS'
export const USER_BY_ACCOUNT_ID_ACTION_FAILURE = 'USER_BY_ACCOUNT_ID_ACTION_FAILURE'

export const SAVE_COMPANY_ADDRESS_ACTION_REQUEST = 'SAVE_COMPANY_ADDRESS_ACTION_REQUEST'
export const SAVE_COMPANY_ADDRESS_ACTION_SUCCESS = 'SAVE_COMPANY_ADDRESS_ACTION_SUCCESS'
export const SAVE_COMPANY_ADDRESS_ACTION_FAILURE = 'SAVE_COMPANY_ADDRESS_ACTION_FAILURE'

export const SAVE_COMPANY_FOUNDER_ACTION_REQUEST = 'SAVE_COMPANY_FOUNDER_ACTION_REQUEST'
export const SAVE_COMPANY_FOUNDER_ACTION_SUCCESS = 'SAVE_COMPANY_FOUNDER_ACTION_SUCCESS'
export const SAVE_COMPANY_FOUNDER_ACTION_FAILURE = 'SAVE_COMPANY_FOUNDER_ACTION_FAILURE'

export const SAVE_COMPANY_TEAM_ACTION_REQUEST = 'SAVE_COMPANY_TEAM_ACTION_REQUEST'
export const SAVE_COMPANY_TEAM_ACTION_SUCCESS = 'SAVE_COMPANY_TEAM_ACTION_SUCCESS'
export const SAVE_COMPANY_TEAM_ACTION_FAILURE = 'SAVE_COMPANY_TEAM_ACTION_FAILURE'

export const SAVE_COMPANY_INFO_ACTION_REQUEST = 'SAVE_COMPANY_INFO_ACTION_REQUEST'
export const SAVE_COMPANY_INFO_ACTION_SUCCESS = 'SAVE_COMPANY_INFO_ACTION_SUCCESS'
export const SAVE_COMPANY_INFO_ACTION_FAILURE = 'SAVE_COMPANY_INFO_ACTION_FAILURE'

export const SAVE_INTEREST_ACTION_REQUEST = 'SAVE_INTEREST_ACTION_REQUEST'
export const SAVE_INTEREST_ACTION_SUCCESS = 'SAVE_INTEREST_ACTION_SUCCESS'
export const SAVE_INTEREST_ACTION_FAILURE = 'SAVE_INTEREST_ACTION_FAILURE'

export const GET_COMPANY_DETAILS_ACTION_REQUEST = 'GET_COMPANY_DETAILS_ACTION_REQUEST'
export const GET_COMPANY_DETAILS_ACTION_SUCCESS = 'GET_COMPANY_DETAILS_ACTION_SUCCESS'
export const GET_COMPANY_DETAILS_ACTION_FAILURE = 'GET_COMPANY_DETAILS_ACTION_FAILURE'

export const ADD_NEW_POST_ACTION_REQUEST = 'ADD_NEW_POST_ACTION_REQUEST'
export const ADD_NEW_POST_ACTION_SUCCESS = 'ADD_NEW_POST_ACTION_SUCCESS'
export const ADD_NEW_POST_ACTION_FAILURE = 'ADD_NEW_POST_ACTION_FAILURE'

export const EDIT_POST_ACTION_REQUEST = 'EDIT_POST_ACTION_REQUEST'
export const EDIT_POST_ACTION_SUCCESS = 'EDIT_POST_ACTION_SUCCESS'
export const EDIT_POST_ACTION_FAILURE = 'EDIT_POST_ACTION_FAILURE'

export const DELETE_POST_ACTION_REQUEST = 'DELETE_POST_ACTION_REQUEST'
export const DELETE_POST_ACTION_SUCCESS = 'DELETE_POST_ACTION_SUCCESS'
export const DELETE_POST_ACTION_FAILURE = 'DELETE_POST_ACTION_FAILURE'

export const GET_POST_LIST_ACTION_REQUEST = 'GET_POST_LIST_ACTION_REQUEST'
export const GET_POST_LIST_ACTION_SUCCESS = 'GET_POST_LIST_ACTION_SUCCESS'
export const GET_POST_LIST_ACTION_FAILURE = 'GET_POST_LIST_ACTION_FAILURE'

export const POST_LIKE_DISLIKE_ACTION_REQUEST = 'POST_LIKE_DISLIKE_ACTION_REQUEST'
export const POST_LIKE_DISLIKE_ACTION_SUCCESS = 'POST_LIKE_DISLIKE_ACTION_SUCCESS'
export const POST_LIKE_DISLIKE_ACTION_FAILURE = 'POST_LIKE_DISLIKE_ACTION_FAILURE'

export const ADD_POST_COMMENT_ACTION_REQUEST = 'ADD_POST_COMMENT_ACTION_REQUEST'
export const ADD_POST_COMMENT_ACTION_SUCCESS = 'ADD_POST_COMMENT_ACTION_SUCCESS'
export const ADD_POST_COMMENT_ACTION_FAILURE = 'ADD_POST_COMMENT_ACTION_FAILURE'

export const GET_POST_COMMENTS_ACTION_REQUEST = 'GET_POST_COMMENTS_ACTION_REQUEST'
export const GET_POST_COMMENTS_ACTION_SUCCESS = 'GET_POST_COMMENTS_ACTION_SUCCESS'
export const GET_POST_COMMENTS_ACTION_FAILURE = 'GET_POST_COMMENTS_ACTION_FAILURE'

export const GET_COMPANY_LIST_ACTION_REQUEST = 'GET_COMPANY_LIST_ACTION_REQUEST'
export const GET_COMPANY_LIST_ACTION_SUCCESS = 'GET_COMPANY_LIST_ACTION_SUCCESS'
export const GET_COMPANY_LIST_ACTION_FAILURE = 'GET_COMPANY_LIST_ACTION_FAILURE'

export const FOLLOW_COMPANY_ACTION_REQUEST = 'FOLLOW_COMPANY_ACTION_REQUEST'
export const FOLLOW_COMPANY_ACTION_SUCCESS = 'FOLLOW_COMPANY_ACTION_SUCCESS'
export const FOLLOW_COMPANY_ACTION_FAILURE = 'FOLLOW_COMPANY_ACTION_FAILURE'

export const GET_FOLLOWED_COMPANY_POST_ACTION_REQUEST = 'GET_FOLLOWED_COMPANY_POST_ACTION_REQUEST'
export const GET_FOLLOWED_COMPANY_POST_ACTION_SUCCESS = 'GET_FOLLOWED_COMPANY_POST_ACTION_SUCCESS'
export const GET_FOLLOWED_COMPANY_POST_ACTION_FAILURE = 'GET_FOLLOWED_COMPANY_POST_ACTION_FAILURE'

export const GET_COMPANY_SUGGESTION_ACTION_REQUEST = 'GET_COMPANY_SUGGESTION_ACTION_REQUEST'
export const GET_COMPANY_SUGGESTION_ACTION_SUCCESS = 'GET_COMPANY_SUGGESTION_ACTION_SUCCESS'
export const GET_COMPANY_SUGGESTION_ACTION_FAILURE = 'GET_COMPANY_SUGGESTION_ACTION_FAILURE'

export const SET_OWN_COMPANY_ACTION_REQUEST = 'SET_OWN_COMPANY_ACTION_REQUEST'
export const SET_OWN_COMPANY_ACTION_SUCCESS = 'SET_OWN_COMPANY_ACTION_SUCCESS'
export const SET_OWN_COMPANY_ACTION_FAILURE = 'SET_OWN_COMPANY_ACTION_FAILURE'

export const SET_OTHER_COMPANY_ACTION_REQUEST = 'SET_OTHER_COMPANY_ACTION_REQUEST'
export const SET_OTHER_COMPANY_ACTION_SUCCESS = 'SET_OTHER_COMPANY_ACTION_SUCCESS'
export const SET_OTHER_COMPANY_ACTION_FAILURE = 'SET_OTHER_COMPANY_ACTION_FAILURE'

export const SENT_CONNECTION_REQ_ACTION_REQUEST = 'SENT_CONNECTION_REQ_ACTION_REQUEST'
export const SENT_CONNECTION_REQ_ACTION_SUCCESS = 'SENT_CONNECTION_REQ_ACTION_SUCCESS'
export const SENT_CONNECTION_REQ_ACTION_FAILURE = 'SENT_CONNECTION_REQ_ACTION_FAILURE'

export const COMPANY_BLOCK_ACTION_REQUEST = 'COMPANY_BLOCK_ACTION_REQUEST'
export const COMPANY_BLOCK_ACTION_SUCCESS = 'COMPANY_BLOCK_ACTION_SUCCESS'
export const COMPANY_BLOCK_ACTION_FAILURE = 'COMPANY_BLOCK_ACTION_FAILURE'

export const COMPANY_UNBLOCK_ACTION_REQUEST = 'COMPANY_UNBLOCK_ACTION_REQUEST'
export const COMPANY_UNBLOCK_ACTION_SUCCESS = 'COMPANY_UNBLOCK_ACTION_SUCCESS'
export const COMPANY_UNBLOCK_ACTION_FAILURE = 'COMPANY_UNBLOCK_ACTION_FAILURE'


/* ********** Added By Ashis ********** */

export const UPDATE_USER_ACTION_REQUEST = 'UPDATE_USER_ACTION_REQUEST'
export const UPDATE_USER_ACTION_SUCCESS = 'UPDATE_USER_ACTION_SUCCESS'
export const UPDATE_USER_ACTION_FAILURE = 'UPDATE_USER_ACTION_FAILURE'

export const ADD_RATING_ACTION_REQUEST = 'ADD_RATING_ACTION_REQUEST'
export const ADD_RATING_ACTION_SUCCESS = 'ADD_RATING_ACTION_SUCCESS'
export const ADD_RATING_ACTION_FAILURE = 'ADD_RATING_ACTION_FAILURE'

export const GET_REVIEW_BY_COMPANY_ID_ACTION_REQUEST = 'GET_REVIEW_BY_COMPANY_ID_ACTION_REQUEST'
export const GET_REVIEW_BY_COMPANY_ID_ACTION_SUCCESS = 'GET_REVIEW_BY_COMPANY_ID_ACTION_SUCCESS'
export const GET_REVIEW_BY_COMPANY_ID_ACTION_FAILURE = 'GET_REVIEW_BY_COMPANY_ID_ACTION_FAILURE'

export const GET_CONNECTION_STATUS_BY_COMPANY_REQUEST = 'GET_CONNECTION_STATUS_BY_COMPANY_REQUEST'
export const GET_CONNECTION_STATUS_BY_COMPANY_SUCCESS = 'GET_CONNECTION_STATUS_BY_COMPANY_SUCCESS'
export const GET_CONNECTION_STATUS_BY_COMPANY_FAILURE = 'GET_CONNECTION_STATUS_BY_COMPANY_FAILURE'

export const GET_CONNECTION_SUGGESTION_COMPANIES_REQUEST = 'GET_CONNECTION_SUGGESTION_COMPANIES_REQUEST'
export const GET_CONNECTION_SUGGESTION_COMPANIES_SUCCESS = 'GET_CONNECTION_SUGGESTION_COMPANIES_SUCCESS'
export const GET_CONNECTION_SUGGESTION_COMPANIES_FAILURE = 'GET_CONNECTION_SUGGESTION_COMPANIES_FAILURE'

export const UPDATE_POST_COMMENTS_REQUEST = 'UPDATE_POST_COMMENTS_REQUEST'
export const UPDATE_POST_COMMENTS_SUCCESS = 'UPDATE_POST_COMMENTS_SUCCESS'
export const UPDATE_POST_COMMENTS_FAILURE = 'UPDATE_POST_COMMENTS_FAILURE'

export const DELETE_POST_COMMENTS_REQUEST = 'DELETE_POST_COMMENTS_REQUEST'
export const DELETE_POST_COMMENTS_SUCCESS = 'DELETE_POST_COMMENTS_SUCCESS'
export const DELETE_POST_COMMENTS_FAILURE = 'DELETE_POST_COMMENTS_FAILURE'

export const COMPANY_POKE_ACTION_REQUEST = 'COMPANY_POKE_ACTION_REQUEST'
export const COMPANY_POKE_ACTION_SUCCESS = 'COMPANY_POKE_ACTION_SUCCESS'
export const COMPANY_POKE_ACTION_FAILURE = 'COMPANY_POKE_ACTION_FAILURE'

export const GET_COMPANY_POKE_ACTION_REQUEST = 'GET_COMPANY_POKE_ACTION_REQUEST'
export const GET_COMPANY_POKE_ACTION_SUCCESS = 'GET_COMPANY_POKE_ACTION_SUCCESS'
export const GET_COMPANY_POKE_ACTION_FAILURE = 'GET_COMPANY_POKE_ACTION_FAILURE'

export const GET_COMPANY_POKE_BY_STATUS_ACTION_REQUEST = 'GET_COMPANY_POKE_BY_STATUS_ACTION_REQUEST'
export const GET_COMPANY_POKE_BY_STATUS_ACTION_SUCCESS = 'GET_COMPANY_POKE_BY_STATUS_ACTION_SUCCESS'
export const GET_COMPANY_POKE_BY_STATUS_ACTION_FAILURE = 'GET_COMPANY_POKE_BY_STATUS_ACTION_FAILURE'

export const UPDATE_COMPANY_POKE_ACTION_REQUEST = 'UPDATE_COMPANY_POKE_ACTION_REQUEST'
export const UPDATE_COMPANY_POKE_ACTION_SUCCESS = 'UPDATE_COMPANY_POKE_ACTION_SUCCESS'
export const UPDATE_COMPANY_POKE_ACTION_FAILURE = 'UPDATE_COMPANY_POKE_ACTION_FAILURE'

export const COMPANY_UNFOLLOW_ACTION_REQUEST = 'COMPANY_UNFOLLOW_ACTION_REQUEST'
export const COMPANY_UNFOLLOW_ACTION_SUCCESS = 'COMPANY_UNFOLLOW_ACTION_SUCCESS'
export const COMPANY_UNFOLLOW_ACTION_FAILURE = 'COMPANY_UNFOLLOW_ACTION_FAILURE'

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE'

export const UPDATE_READ_STATUS_REQUEST = 'UPDATE_READ_STATUS_REQUEST'
export const UPDATE_READ_STATUS_SUCCESS = 'UPDATE_READ_STATUS_SUCCESS'
export const UPDATE_READ_STATUS_FAILURE = 'UPDATE_READ_STATUS_FAILURE'

export const GET_TYPE_AHEAD_SEARCH_RESULT_REQUEST = 'GET_TYPE_AHEAD_SEARCH_RESULT_REQUEST'
export const GET_TYPE_AHEAD_SEARCH_RESULT_SUCCESS = 'GET_TYPE_AHEAD_SEARCH_RESULT_SUCCESS'
export const GET_TYPE_AHEAD_SEARCH_RESULT_FAILURE = 'GET_TYPE_AHEAD_SEARCH_RESULT_FAILURE'

/* ********** Added By Ashis ********** */