import React, { Component } from "react";
// import { Link } from "react-router-dom";
import Helper from "../../../Configs/Helper";
import Header from "../../SubComponents/Header/Header";
import Footer from "../../SubComponents/Footer/Footer";
import FormStep1 from "./FormStep1/FormStep1";
import FormStep2 from "./FormStep2/FormStep2";
import FormStep3 from "./FormStep3/FormStep3";
import FormStep4 from "./FormStep4/FormStep4";
import Validation from "../../../Configs/Validation";
import Loader from "../../SubComponents/Loader/Loader";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {saveCompanyAction,saveCompanyPostAction,getCompanyDetailsByIdAction, setSelectedOwnCompanyInfoAction} from "../../../store/actions";
import {  geocodeByAddress, getLatLng } from 'react-places-autocomplete';

class CreateCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep:1,
      step1: true,
      s1_i_agree:false,
      s1_company_name: '',
      s1_specialization: [],
      s2_location: '',
      s2_address: '',
      s2_country: '',
      s2_state: '',
      s2_city: '',
      s2_zipcode: '',
      s2_fax_num: '',
      fouderStepFieldsArr: [1],
      teamStepFieldsArr: [1],
      companyInfoFormErrors: {company_name: '', company_size: '', company_sub_title: '', company_type: '', about_company: '', industry_type: '', email_id: '', i_agree:'' },
      companyInfoFormValid: false,
      companyAddressFormErrors: {address: '', country: '', state: '', city: '', zipcode: ''},
      companyAddressFormValid: false,
      accountId : this.props.userData.data.account_id,
      addedCompanyId: '',
      addedCompanyInfoId: ''
    };
    this.onChnageHandler = this.onChnageHandler.bind(this);
    this._nextClick = this._nextClick.bind(this)
    this._prevClick = this._prevClick.bind(this)
    this._tabClick = this._tabClick.bind(this)
    this._skipClick = this._skipClick.bind(this);
    this.fieldsArrHandler = this.fieldsArrHandler.bind(this);
    this.setFormValidateOrNot = this.setFormValidateOrNot.bind(this);
    this.onClickHandler=this.onClickHandler.bind(this);
  }
  onChnageHandler(event) {
    var name = event.target.name;  
    var value = event.target.value; 
    var dataName = event.target.getAttribute('data-name'); 
    var imgPrviewVar = name+'_preview';
   if(name==="s1_company_logo"){
        this.setState({ s1_company_logo: event.target.files[0] });
        this.filePreview(event.target.files[0],'s1_company_logo_preview');
    } else if(dataName==="founderImg"){
      this.setState({ [name]: event.target.files[0] });
      this.filePreview(event.target.files[0],imgPrviewVar);
    } else if(dataName==="teamImg"){
      this.setState({ [name]: event.target.files[0] });
      this.filePreview(event.target.files[0],imgPrviewVar);
    }else {
      this.setState({ [name]: value },()=>this.setFormValidateOrNot(name,value));
    } 
  }
  onClickHandler(event){
    console.log('event');
    console.log(event.target.checked);
    const name = event.target.name;  
    const value = event.target.checked;
    this.setState({s1_i_agree:value},()=>{this.setFormValidateOrNot(name,this.state.s1_i_agree);});

  }
  setFormValidateOrNot(name,value){

    const fields = name.split('_');
    const formStep = fields[0];
    if(formStep==='s1'){
      console.log(name);
      console.log(value);
      const res = Validation.companyInfoStep(name, value,this.state);
      console.log(res);
      this.setState({ companyInfoFormValid: res.formValid, companyInfoFormErrors: res.formErrors }); 
    } else if(formStep==='s2'){
      const res = Validation.companyAddressStep(name, value,this.state);
      this.setState({ companyAddressFormValid: res.formValid, companyAddressFormErrors: res.formErrors }); 
    }
      
  }
  handleChangeSpecialization =tags=> {
    this.setState({s1_specialization: tags})
  }
  fieldsArrHandler(type,arr,event){
    if(type==='founder') this.setState({ fouderStepFieldsArr: arr });
    if(type==='team') this.setState({ teamStepFieldsArr: arr });
  }
  async _nextClick() {
    console.log('this.state.s1_i_agree');
    console.log(this.state.s1_i_agree);
    let dasboard = false;
    let postData;
    let refCurrentStep = this.state.currentStep;
    let currentStep = this.state.currentStep;
    currentStep = (currentStep === 4) ? 4 : currentStep + 1;     
    if(refCurrentStep===1){
      if(!this.state.companyInfoFormValid){
        const companyInfoFormErrors = this.state.companyInfoFormErrors;
        if(Helper.chkVal(this.state.s1_company_name)==='')  companyInfoFormErrors.company_name=Helper.flashMsg('company_name_req');
        if(Helper.chkVal(this.state.s1_company_sub_title)==='')  companyInfoFormErrors.company_sub_title=Helper.flashMsg('company_sub_title_req');
        if(Helper.chkVal(this.state.s1_company_type)==='')  companyInfoFormErrors.company_type=Helper.flashMsg('company_type_req');
        if(Helper.chkVal(this.state.s1_description)==='')  companyInfoFormErrors.about_company=Helper.flashMsg('company_description_req');
        if(Helper.chkVal(this.state.s1_company_size)==='')  companyInfoFormErrors.company_size=Helper.flashMsg('company_size_req');
        if(Helper.chkVal(this.state.s1_industry_type)==='')  companyInfoFormErrors.industry_type=Helper.flashMsg('company_industry_req');
        if(Helper.chkVal(this.state.s1_email_id)==='')  companyInfoFormErrors.email_id=Helper.flashMsg('company_email_req');
        if(Helper.chkVal(this.state.s1_i_agree)===false)  companyInfoFormErrors.i_agree=Helper.flashMsg('company_iagree_req');
        this.setState({companyInfoFormErrors});
       // this.loader.showAlert(true,'error',Helper.flashMsg('requireFields'))
        return;
      }
      const validImage = Validation.fileValidation(this.state.s1_company_logo,'companyLogo');
      if(validImage!==''){
        this.loader.showAlert(true,'error',validImage)
        return;
      }
      this.loader.show(true);
      postData = {
        account_id: this.state.accountId,
        form_type: "company_details",
        company_name: Helper.chkVal(this.state.s1_company_name),
        company_email: Helper.chkVal(this.state.s1_email_id),
        company_sub_title: Helper.chkVal(this.state.s1_company_sub_title),
        company_type: Helper.chkVal(this.state.s1_company_type),
        description: Helper.chkVal(this.state.s1_description),
        company_size: Helper.chkVal(this.state.s1_company_size),
        i_agree: Helper.chkVal(this.state.s1_i_agree),
      };
      if(this.state.addedCompanyId!==''){
        postData.company_id= this.state.addedCompanyId;
        postData.companyInfo_id= this.state.addedCompanyInfoId;
        postData.form_type= "update_details";
      }
      if(Helper.chkVal(this.state.s1_industry_type)!=='') postData.industry_type = this.state.s1_industry_type;
      if(Helper.chkVal(this.state.s1_phone_num)!=='')  postData.mobile_number = this.state.s1_phone_num;
      if(Helper.chkVal(this.state.s1_website_url)!=='') postData.website_url = this.state.s1_website_url;
      if(Helper.chkVal(this.state.s1_year_founded)!=='') postData.year_founded = this.state.s1_year_founded;
      if(this.state.s1_specialization.length>0) {
        const specializationArr = this.state.s1_specialization;
       // postData.specialization = specializationArr.join(',');
        postData.specialization = specializationArr.join(',');
      }
      
      if(Helper.chkVal(this.state.s1_company_logo)!=='') {
        const formData = new FormData();
        formData.append('company_logo',this.state.s1_company_logo);
        await this.props.saveCompanyPostAction(postData,formData,'savedProfileInfo');
      } else {
        await this.props.saveCompanyAction(postData,'savedProfileInfo');
      }

      
      if(this.props.savedCompanyInfoStep1!==undefined){
        if(this.props.savedCompanyInfoStep1.status===200){
          this.setState({ currentStep, ['step'+currentStep]: true })
          if(this.state.addedCompanyId===''){
            this.setState({ addedCompanyId: this.props.savedCompanyInfoStep1.data.company_info_data.company_id, addedCompanyInfoId: this.props.savedCompanyInfoStep1.data.company_info_data.id})
            this.setDefaultCompanyIdAndData(this.props.savedCompanyInfoStep1.data.company_info_data.company_id);
          }
          //this.loader.showAlert(true,'success',Helper.flashMsg('companyCreateSuccess'))
        } else {
          this.loader.showAlert(true,'error',this.props.savedCompanyInfoStep1.message)
        }
      } else {
        this.loader.showAlert(true,'error',Helper.flashMsg(this.props.savedCompanyInfoStep1.message))
      }

      // start step form 2
  } else if(refCurrentStep===2){
    if(!this.state.companyAddressFormValid){
      const companyAddressFormErrors = this.state.companyAddressFormErrors;
      if(Helper.chkVal(this.state.s2_address)==='')  companyAddressFormErrors.address=Helper.flashMsg('company_address_req');
      if(Helper.chkVal(this.state.s2_country)==='')  companyAddressFormErrors.country=Helper.flashMsg('company_country_req');
      if(Helper.chkVal(this.state.s2_state)==='')  companyAddressFormErrors.state=Helper.flashMsg('company_state_req');
      if(Helper.chkVal(this.state.s2_city)==='')  companyAddressFormErrors.city=Helper.flashMsg('company_city_req');
      if(Helper.chkVal(this.state.s2_zipcode)==='')  companyAddressFormErrors.zipcode=Helper.flashMsg('company_zipcode_req');
      this.setState({companyAddressFormErrors});
     // this.loader.showAlert(true,'error',Helper.flashMsg('requireFields'))
      return;
    }
    this.loader.show(true);
    postData = {
      form_type: "address_details",
      account_id: this.state.accountId,
      company_id: this.state.addedCompanyId,
      companyInfo_id: this.state.addedCompanyInfoId,
      city: Helper.chkVal(this.state.s2_city),
      state: Helper.chkVal(this.state.s2_state),
      country: Helper.chkVal(this.state.s2_country),
      postal_code: Helper.chkVal(this.state.s2_zipcode),
      address: Helper.chkVal(this.state.s2_address),
      fax_number: Helper.chkVal(this.state.s2_fax_num),
      latitude: '',
      longitude: '',
    };
    await this.props.saveCompanyAction(postData,'savedAddressInfo');
    if(this.props.savedAddressInfoStep2!==undefined){
      if(this.props.savedAddressInfoStep2.status===200){
        this.setState({ currentStep, ['step'+currentStep]: true })
        //this.loader.showAlert(true,'success',Helper.flashMsg('addressSaveSuccess'))
      } else {
        this.loader.showAlert(true,'error',this.props.savedAddressInfoStep2.message)
      }
    } else {
      this.loader.showAlert(true,'error',this.props.savedAddressInfoStep2.message)
    }
    // start step form 3
  } else if(refCurrentStep===3){
      
      let flag = 1;
      let arrLength = this.state.fouderStepFieldsArr.length;
      let foundersArr = [];
      let state = this.state;
      if(arrLength>0){

        // check image valid or not (size and extention)
        let validImageFlag = true;
        let validImage = '';
        for(let i=0; i<arrLength; i++){
          let val = state.fouderStepFieldsArr[i];
          let stateVarImage = 's3_founder_image_'+val;
          if(state[stateVarImage]!==undefined){
            if(validImageFlag){
              validImage = Validation.fileValidation(state[stateVarImage],'companyFounder');
              if(validImage !== ''){
                validImageFlag = false;
              }
            }
          } 
        }
        if(!validImageFlag){
          this.loader.showAlert(true,'error',validImage)
          return;
        }
        this.loader.show(true);
        for(let i=0; i<arrLength; i++){
          let val = state.fouderStepFieldsArr[i];
          let stateVarName = 's3_founder_name_'+val;
          let stateVarDetail = 's3_founder_detail_'+val;
          let stateVarImage = 's3_founder_image_'+val;
          
          let name = '';
          let detail = '';
          let image = '';
          if(state[stateVarName]!==undefined){
            name = state[stateVarName];
            if(state[stateVarDetail]!==undefined) detail = state[stateVarDetail];
            if(state[stateVarImage]!==undefined) image = state[stateVarImage];
            //foundersArr.push({ name: name, detail: detail, image: image });
            let postData = {
                form_type: "founder_details",
                account_id: this.state.accountId,
                company_id: this.state.addedCompanyId,
                companyInfo_id: this.state.addedCompanyInfoId,
                founder_member_name: name, 
                founder_member_description: detail, 
                founder_image: image 
            };
            foundersArr.push(postData);
            const formData = new FormData();
            formData.append('founder_image',postData.founder_image);
 
            await this.props.saveCompanyPostAction(postData,formData,'savedFounderInfo');
            if(this.props.savedFounderInfoStep3!==undefined){
              if(this.props.savedFounderInfoStep3.status===200){
                flag++;
              }
            }
          }
        }
      }
     // if(arrLength===flag){
        this.setState({ currentStep, ['step'+currentStep]: true })
      //}
     
      
     
    } else if(refCurrentStep===4){
     
      let flag=1;
      let arrLength = this.state.teamStepFieldsArr.length;
      let teamsArr = [];
      let state = this.state;
      if(arrLength>0){
        // check image valid or not (size and extention)
        let validImageFlag = true;
        let validImage = '';
        for(let i=0; i<arrLength; i++){
          let val = state.teamStepFieldsArr[i];
          let stateVarImage = 's4_team_image_'+val;
          if(state[stateVarImage]!==undefined){
            if(validImageFlag){
              validImage = Validation.fileValidation(state[stateVarImage],'companyTeam');
              if(validImage !== ''){
                validImageFlag = false;
              }
            }
          } 
        }
        if(!validImageFlag){
          this.loader.showAlert(true,'error',validImage)
          return;
        }
        this.loader.show(true);

        for(let i=0; i<arrLength; i++){
          let val = state.teamStepFieldsArr[i];
          let stateVarName = 's4_team_name_'+val;
          let stateVarDetail = 's4_team_detail_'+val;
          let stateVarImage = 's4_team_image_'+val;
          
          let name = '';
          let detail = '';
          let image = '';
          if(state[stateVarName]!==undefined){
            name = state[stateVarName];
            if(state[stateVarDetail]!==undefined) detail = state[stateVarDetail];
            if(state[stateVarImage]!==undefined) image = state[stateVarImage];
            //teamsArr.push({ name: name, detail: detail, image: image });
            let postData = {
                form_type: "team_details",
                account_id: this.state.accountId,
                company_id: this.state.addedCompanyId,
                companyInfo_id: this.state.addedCompanyInfoId,
                team_member_name: name, 
                team_member_description: detail, 
                team_image: image 
            };
            teamsArr.push(postData);
            const formData = new FormData();
            formData.append('team_image',postData.team_image);
            await this.props.saveCompanyPostAction(postData,formData,'savedTeamInfo');
            if(this.props.savedTeamInfoStep4!==undefined){
              if(this.props.savedTeamInfoStep4.status===200){
                flag++;
              }
            }

          }
        }
      }
      if(arrLength===flag){
        dasboard =true;
        //this.setState({ currentStep, ['step'+currentStep]: true })
      }
      this.props.history.push('/news-feed');
    }
    if(dasboard){
      this.props.history.push('/news-feed');
    } else {
      //this.props.history.push('/news-feed');
    }
    this.loader.show(false);
  }
    
  _prevClick() {
    let currentStep = this.state.currentStep
    currentStep = currentStep <= 1 ? 1: currentStep - 1
    this.setState({ currentStep })
  }
  _skipClick(event){
    if(this.state.addedCompanyId===''){
      const { company_profiles } = this.props.userData.data
      if(company_profiles!==null && company_profiles!==undefined ){
          if(company_profiles.length>0){
            this.props.history.push('/news-feed');
          }
      }
      this.props.history.push('/user');
    } else {
      this.props.history.push('/news-feed');
    }
    
  }
  _tabClick(step,event){
    if(this.state['step'+step]){
      this.setState({ currentStep: step })
    }
  }
  filePreview(file,field){   
   
    if(file===undefined || file===''){
        this.setState({ [field]: '' });
        return;
    } 
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
     reader.onloadend = function (e) {
        this.setState({
          [field]: [reader.result]
        })
      }.bind(this);
  }
  setDefaultCompanyIdAndData = async (addedCompanyId) =>{
    //set default company id and data
      const { account_id } = this.props.userData.data
      if(addedCompanyId!==null && addedCompanyId!==undefined ){
          //set default company id in local storage
          Helper.setDefaultCompany(addedCompanyId,'user_company');

          const paramsData = {  account_id: account_id, company_id: addedCompanyId }
          await this.props.getCompanyDetailsByIdAction(paramsData);
          if(this.props.companyDetails.status===200){
              await this.props.setSelectedOwnCompanyInfoAction(this.props.companyDetails);
          }
    }
  }
  handleLocationChange = address => {
    this.setState({ s2_location: address });
  };
 
  handleLocationSelect = address => {
    geocodeByAddress(address)
      .then(results => {
       const locObj = Helper.getLocationObj(results[0]);
      
       
        this.setState({ 
          s2_location: locObj.route,
          s2_address: locObj.route,
          s2_country: locObj.country,
          s2_state: locObj.administrative_area_level_1,
          s2_city: locObj.locality,
          s2_zipcode: locObj.postal_code,
        },()=>{
          if(this.state.s2_address!=='' && this.state.s2_country!=='' && this.state.s2_state!=='' && this.state.s2_city!=='' && this.state.s2_zipcode!==''){
            this.setState({ companyAddressFormValid: true }); 
          }
        });
        return getLatLng(results[0]);
      })
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
  };

  render() {
    
    return (
      <div>
        <Header {...this.props} headerType="createCompany"/>
        <main className="mainBody container-fluid " style={{padding: '0px', background: "rgb(255, 255, 255)", height: "100%",paddingBottom: "30px",minHeight: "calc(100vh - 100px)"}}>
           <div className="pageHeader">
                <div className="">Create a Company Profile</div>
                </div>
                <div id="company-create-form">
                      <div className="steps clearfix">
                        <ul role="tablist" className="">
                          <FormStepTabs state={this.state} _tabClick={this._tabClick}/>
                        </ul>
                      </div>
                  </div>
            
          <div className="container companyCreateFormContainer">
            <div className="companyCreateForm row">
              <div id="company-create-form" className=" col-sm-12 col-md-8">
               {/*<div className="steps clearfix">
                  <ul role="tablist">
                    <FormStepTabs state={this.state} _tabClick={this._tabClick}/>
                  </ul>
                </div>*/}
                <div className="content clearfix ">
                  { (this.state.currentStep===1) && 
                    <section className="box bg-white border body current" id="company-create-form-p-1">
                      <FormStep1 state={this.state} onChnageHandler={this.onChnageHandler} handleChangeSpecialization={this.handleChangeSpecialization} onClickHandler={this.onClickHandler}/>
                    </section>
                  }
                  {(this.state.currentStep===2) && 
                    <section className="box bg-white border body current" id="company-create-form-p-2">
                      <FormStep2 state={this.state} onChnageHandler={this.onChnageHandler} handleLocationChange={this.handleLocationChange} handleLocationSelect={this.handleLocationSelect}/>
                    </section>
                  }
                  {(this.state.currentStep===3) && 
                    <section className="box bg-white border body current" id="company-create-form-p-3">
                      <FormStep3 state={this.state} onChnageHandler={this.onChnageHandler} fieldsArrHandler={this.fieldsArrHandler}/>
                    </section>
                  }
                  {(this.state.currentStep===4) && 
                    <section className="box bg-white border body current" id="company-create-form-p-4">
                      <FormStep4 state={this.state} onChnageHandler={this.onChnageHandler} fieldsArrHandler={this.fieldsArrHandler}/>
                    </section>
                  }
                 

                 <div className="actions clearfix actionSkip">
                  <ul role="menu" aria-label="Pagination">
                    <li><a href="#skip" role="menuitem" onClick={this._skipClick}>Skip to Dashboard</a></li>
                    {/* <li className={(this.state.currentStep>1) ? '' : 'disabled'}><a href="#previous" role="menuitem" onClick={this._prevClick}>Previous</a></li> */}
                  </ul>
                </div>

                <div className="actions clearfix actionNext">
                  <ul role="menu" aria-label="Pagination">
                    <li className={(this.state.currentStep<4) ? '' : 'disabled'}><a href="#next" role="menuitem" onClick={this._nextClick}>Save & Next</a></li>
                    <li className={(this.state.currentStep===4) ? '' : 'disabled'}><a href="#done" role="menuitem" onClick={this._nextClick}>Finish</a></li>
                  </ul>
                </div>
              
                </div>
                </div>
            </div>
          </div>
        </main>


        <Footer {...this.props} />
        {/* loader Start */}
          <Loader onRef={ref => {this.loader = ref;}}/>
        {/* loader Start */}
      </div>
    )
  }
}
function FormStepTabs(props){
  let formStepArr = ['Profile Info','Address','Add Founder','Add Team'];
  //let formStepArr = ['Profile Info','Add Founder','Add Team'];
  let tabs = [];
  for(let i=0;i<formStepArr.length;i++){
    let step = i+1;  
    let liClassName = 'disabled';
    if(props.state.currentStep===step){
      liClassName = 'current';
    } else if(props.state['step'+step]) {
      liClassName = 'done';
    }
    tabs.push(<li key={i} role="tab" className={liClassName}><a id={`company-create-form-t-${step}`} className="a-tab" onClick={props._tabClick.bind(this,step)}> {formStepArr[i]}</a></li>);
  }
  return tabs;
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
  savedCompanyInfoStep1: state.auth.savedCompanyInfoStep1,
  savedAddressInfoStep2: state.auth.savedAddressInfoStep2,
  savedFounderInfoStep3: state.auth.savedFounderInfoStep3,
  savedTeamInfoStep4: state.auth.savedTeamInfoStep4,
  companyDetails: state.appData.companyDetails,
  ownCompanyDetails: state.appData.ownCompanyDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    saveCompanyAction: bindActionCreators(saveCompanyAction, dispatch),
    saveCompanyPostAction: bindActionCreators(saveCompanyPostAction, dispatch),
    getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
    setSelectedOwnCompanyInfoAction: bindActionCreators(setSelectedOwnCompanyInfoAction, dispatch),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAlert(CreateCompany));
