import React from 'react';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1
        }
    }

    handleComponentShow = (value) => {
        this.props.handleComponentShow(value);
        this.setState({
            activeTab: value
        });
    }
    render() {
        return (
            <div className="row " style={{ margin: "0px" }}>
                <div className="col" style={{ paddingRight: "0px" }}>
                    <div className="userMainProfile ">
                        <div className="profileBgImgConatiner" style={{ background: "url('https://cdn.cnn.com/cnnnext/dam/assets/190809150655-hong-kong-airport-protest-01-exlarge-169.jpg')" }}>
                        </div>
                        <div className="ProfilenavigationContainer">
                            <div className="userImage"></div>
                            <div className="navigations">
                                <ul>
                                    <li className={`${this.state.activeTab === 1?'active':''}`}>
                                        <a href="#about" onClick={() => this.handleComponentShow(1)}> About </a>
                                    </li>
                                    <li className={`${this.state.activeTab === 3?'active':''}`}>
                                        <a href="#timeline" onClick={() => this.handleComponentShow(3)}> Timeline </a>
                                    </li>
                                    <li className={`${this.state.activeTab === 4?'active':''}`}>
                                        <a href="#connection" onClick={() => this.handleComponentShow(4)}> Connections </a>
                                    </li>
                                    <li className={`${this.state.activeTab === 5?'active':''}`}>
                                        <a href="#rating" onClick={() => this.handleComponentShow(5)}> Add Rating </a>
                                    </li>
                                    <li className={`${this.state.activeTab === 6?'active':''}`}>
                                        <a href="#review" onClick={() => this.handleComponentShow(6)}>
                                            <div>
                                                <span style={{ display: "block", width: "100%", textAlign: "center" }}>
                                                    <span className="badge badge-secondary">1K</span>
                                                </span>
                                                Review
                                                            </div>
                                        </a>
                                    </li>
                                    <li className={`${this.state.activeTab === 7?'active':''}`}>
                                        <a href="#setting" onClick={() => this.handleComponentShow(7)}>
                                            <i className="fa fa-cogs"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;