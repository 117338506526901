import axios from 'axios'
// import Helper from '../Configs/Helper'
import { url } from './endpoints';

//live urls
    const ROOT_AUTH_URL = process.env.REACT_APP_SERVICE_AUTH;
    const ROOT_ACCOUNT_URL = process.env.REACT_APP_SERVICE_ACCOUNT;
    const ROOT_COMPANY_URL = process.env.REACT_APP_SERVICE_COMPANY;
    const ROOT_GLOBALSEARCH_URL = process.env.REACT_APP_SERVICE_GLOBALSEARCH;
    const ROOT_POST_URL = process.env.REACT_APP_SERVICE_POST;
    const ROOT_REVIEW_URL = process.env.REACT_APP_SERVICE_REVIEW;
    const ROOT_NETWORK_URL = process.env.REACT_APP_SERVICE_NETWORK;
    const ROOT_NOTIFICATION_URL = process.env.REACT_APP_SERVICE_NOTIFICATION;

//local urls
    // const ROOT_AUTH_URL = process.env.REACT_APP_LOCAL_SERVICE_AUTH;
    // const ROOT_ACCOUNT_URL = process.env.REACT_APP_LOCAL_SERVICE_ACCOUNT;
    // const ROOT_COMPANY_URL = process.env.REACT_APP_LOCAL_SERVICE_COMPANY;
    // const ROOT_GLOBALSEARCH_URL = process.env.REACT_APP_LOCAL_SERVICE_GLOBALSEARCH;
    // const ROOT_POST_URL = process.env.REACT_APP_LOCAL_SERVICE_POST;
    // const ROOT_REVIEW_URL = process.env.REACT_APP_LOCAL_SERVICE_REVIEW;
    // const ROOT_NETWORK_URL = process.env.REACT_APP_LOCAL_SERVICE_NETWORK;
    // const ROOT_NOTIFICATION_URL = process.env.REACT_APP_LOCAL_SERVICE_NOTIFICATION;

let headerParams = { AUTH_TOKEN: false, ACCESS_TOKEN: false, PASSWORD: false, OAUTH_TOKEN: false };

const authRequest = (data) => ({ method: 'POST', auth: data })
const postRequest = (data) => ({ method: 'POST', data })
const paramsRequest = (data) => ({ method: 'POST', params: data })
const paramsWithPostRequest = (data, formData, method='POST') => ({ method: method, data: formData, params: data })
const getRequest = () => ({ method: 'GET' })
const putRequest = (data) => ({ method: 'PUT', data })
const deleteRequest = (data) => ({ method: 'DELETE', params: data })

//-----updated---by---chetna--joshi
const getRequestWithParams = (data) => ({ method: 'GET' , params: data})
const putRequestWithParams = (data) => ({ method: 'PUT', params: data })

/**
 * AUTH
 */
export const loginByEmailAction = (data, key) => {
    headerParams.PASSWORD = true;
    headerParams.AUTH_TOKEN = true;
    headerParams.ACCESS_TOKEN = false;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_AUTH_URL}/${url.loginByEmail}`
    return callApi(apiUrl, key, options, headerParams)
}
export const loginBySocialAction = (data, key) => {
    headerParams.PASSWORD = false;
    headerParams.AUTH_TOKEN = true;
    headerParams.ACCESS_TOKEN = false;
    headerParams.OAUTH_TOKEN = true;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_AUTH_URL}/${url.loginBySocial}`
    return callApi(apiUrl, key, options, headerParams)
}
export const generateTokenAction = (data, key) => {
    headerParams.ACCESS_TOKEN = false;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_AUTH_URL}/${url.generateToken}`
    console.log("");
    console.log("apiUrl");
    console.log(apiUrl);
    console.log(process.env.REACT_APP_SERVICE_AUTH);
        return callApi(apiUrl, key, options,headerParams)
}
export const registerByEmailAction = (data, key) => {
    headerParams.PASSWORD = true;
    headerParams.AUTH_TOKEN = true;
    headerParams.ACCESS_TOKEN = false;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_ACCOUNT_URL}/${url.signupWithEmail}`
    return callApi(apiUrl, key, options, headerParams)
}
export const sendOtpAction = (data, key) => {
    headerParams.PASSWORD = false;
    headerParams.AUTH_TOKEN = true;
    headerParams.ACCESS_TOKEN = false;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_ACCOUNT_URL}/${url.sendOtp}`
    return callApi(apiUrl, key, options, headerParams)
}
// when reset password
export const otpVerifyResetPassAction = (data, key) => {
    headerParams.AUTH_TOKEN = true;
    headerParams.ACCESS_TOKEN = false;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_ACCOUNT_URL}/${url.verifyResetOtpPassword}`
    return callApi(apiUrl, key, options, headerParams)
}
export const resetPasswordAction = (data, key) => {
    headerParams.AUTH_TOKEN = true;
    headerParams.PASSWORD = true;
    headerParams.ACCESS_TOKEN = false;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_ACCOUNT_URL}/${url.resetPassword}`
    return callApi(apiUrl, key, options, headerParams)
}
export const getUserByAccountIdAction = (accountId, key) => {
    const options = getRequest()
    const apiUrl = `${ROOT_ACCOUNT_URL}/${url.getUserByAccountId}`.replace(':account_id', accountId)
    return callApi(apiUrl, key, options)
}
export const otpVerifyAction = (data, key) => {
    headerParams.AUTH_TOKEN = true;
    headerParams.PASSWORD = true;
    headerParams.ACCESS_TOKEN = false;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_AUTH_URL}/${url.verifyOtp}`
    return callApi(apiUrl, key, options, headerParams)
}
export const resendOtpAction = (data, key) => {
    headerParams.AUTH_TOKEN = true;
    headerParams.ACCESS_TOKEN = false;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_ACCOUNT_URL}/${url.resendOtp}`
    return callApi(apiUrl, key, options, headerParams)
}
export const saveCompanyAction = (data, key) => {
    headerParams.AUTH_TOKEN = false;
    headerParams.ACCESS_TOKEN = true;
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_COMPANY_URL}/${url.saveCompanyDetails}`
    return callApi(apiUrl, key, options, headerParams)
}
export const saveCompanyPostAction = (data,formData, key) => {
    headerParams.AUTH_TOKEN = false;
    headerParams.ACCESS_TOKEN = true;
    const options = paramsWithPostRequest(data,formData)
    const apiUrl = `${ROOT_COMPANY_URL}/${url.saveCompanyDetails}`
    return callApi(apiUrl, key, options, headerParams)
}
export const saveInterestAction = (data, key) => {
    headerParams.AUTH_TOKEN = false;
    headerParams.ACCESS_TOKEN = true;
    const options = paramsWithPostRequest(data)
    const apiUrl = `${ROOT_ACCOUNT_URL}/${url.saveInterest}`
    return callApi(apiUrl, key, options, headerParams)
}
export const getCompanyDetailsByIdAction = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_COMPANY_URL}/${url.getCompanyDetails}`
    return callApi(apiUrl, key, options, headerParams)
}
export const getCompanyListAction = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_COMPANY_URL}/${url.getCompanyList}`
    return callApi(apiUrl, key, options, headerParams)
}
export const getFollowedCompanyPostAction = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_POST_URL}/${url.getFollowedCompanyPostList}`
    return callApi(apiUrl, key, options, headerParams)
}
export const getPostListAction = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_POST_URL}/${url.getPostList}`
    return callApi(apiUrl, key, options, headerParams)
}
export const getPostCommentsAction = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_POST_URL}/${url.getPostComments}`
    return callApi(apiUrl, key, options, headerParams)
}
export const addNewPostAction = (data,formData, key) => {
    const options = paramsWithPostRequest(data,formData)
    const apiUrl = `${ROOT_POST_URL}/${url.addNewPost}`
    return callApi(apiUrl, key, options, headerParams)
}
export const editPostAction = (data,formData, key) => {
    const options = paramsWithPostRequest(data,formData,'PUT')
    const apiUrl = `${ROOT_POST_URL}/${url.editPost}`
    return callApi(apiUrl, key, options, headerParams)
}
export const deletePostAction = (data, key) => {
    const options = deleteRequest(data)
    const apiUrl = `${ROOT_POST_URL}/${url.deletePost}`
    return callApi(apiUrl, key, options, headerParams)
}
export const likeDislikePostAction = (data, key) => {
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_POST_URL}/${url.likeDislikePost}`
    return callApi(apiUrl, key, options, headerParams)
}
export const addPostCommentAction = (data, key) => {
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_POST_URL}/${url.addNewPostComment}`
    return callApi(apiUrl, key, options, headerParams)
}
export const companyFollowAction = (data, key) => {
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_NETWORK_URL}/${url.followCompany}`
    return callApi(apiUrl, key, options, headerParams)
}
export const getCompanySuggestionAction = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_GLOBALSEARCH_URL}/${url.getCompanySuggestions}`
    return callApi(apiUrl, key, options, headerParams)
}
export const sentConnectionRequestAction = (data, key) => {
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_NETWORK_URL}/${url.sentConnectionRequest}`
    return callApi(apiUrl, key, options, headerParams)
}
export const companyBlockAction = (data, key) => {
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_NETWORK_URL}/${url.blockRequest}`
    return callApi(apiUrl, key, options, headerParams)
}
export const companyUnBlockAction = (data, key) => {
    const options = deleteRequest(data)
    const apiUrl = `${ROOT_NETWORK_URL}/${url.unBlockRequest}`
    return callApi(apiUrl, key, options, headerParams)
}

// ------START-----UPDATED-------BY------CHETNA----JOSHI------------------------

//not in use
export const getCompanyDetailByCompanyId = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_COMPANY_URL}/${url.getCompanyDetails}`
    return callApi(apiUrl, key, options)
}

//not in use
export const updateCompanyDetail = (data, header) => {
    const apiUrl = `${ROOT_COMPANY_URL}/${url.saveCompanyDetails}`
    return axios.post(apiUrl, {}, { params: data, headers: header })
    // return axios.post("http://192.168.1.26:8087/save/companydetails", {}, { params: data, headers: null });
}

export const getConnectedCompanies = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_COMPANY_URL}/${url.getConnectedCompanies}`
    return callApi(apiUrl, key, options)
}

export const updateConnectionRequest = (data, key) => {
    const options = putRequestWithParams(data)
    const apiUrl = `${ROOT_NETWORK_URL}/${url.updateConnectionRequest}`
    return callApi(apiUrl, key, options, headerParams)
}

export const getBlockAccounts = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_NETWORK_URL}/${url.getBlockAccounts}`
    return callApi(apiUrl, key, options)
}

export const deleteFounderTeam = (data, key) => {
    const options = deleteRequest(data)
    const apiUrl = `${ROOT_COMPANY_URL}/${url.deleteFounderTeam}`
    return callApi(apiUrl, key, options, headerParams)
}

export const saveFirebaseToken = (data, key) => {
    const options = paramsRequest(data)
    const apiUrl = `${ROOT_NOTIFICATION_URL}/${url.addFirebaseToken}`
    return callApi(apiUrl, key, options)
}

export const getNotifications = (data, key) => {
    const options = getRequestWithParams(data)
    const apiUrl = `${ROOT_NOTIFICATION_URL}/${url.getNotifications}`
    return callApi(apiUrl, key, options)
}

export const updateReadStatus = (data, key) => {
    const options = putRequestWithParams(data)
    const apiUrl = `${ROOT_NOTIFICATION_URL}/${url.updateReadStatus}`
    return callApi(apiUrl, key, options)
}
// -----END--------UPDATED-------BY------CHETNA----JOSHI------------------------


// ---Start---ADDED BY Ashis------------------------

export const updateUser = (data,formData, key) => {
    const options = paramsWithPostRequest(data,formData);
    const apiUrl = `${ROOT_ACCOUNT_URL}/${url.saveInterest}`
    return callApi(apiUrl, key, options, headerParams)
}

export const ratingData = (data, key) => {
    const options = paramsRequest(data);
    const apiUrl = `${ROOT_REVIEW_URL}/${url.saveRating}`
    return callApi(apiUrl, key, options, headerParams)
}

export const getReviewBycompanyidAction = (company_id, key) => {
    const options = getRequestWithParams(company_id);
    const apiUrl = `${ROOT_REVIEW_URL}/${url.getReviewByCompanyId}`
    return callApi(apiUrl, key, options)
}


export const getUserFollowingCompanies = (data, key) => {
        const options = getRequestWithParams(data)
        const apiUrl = `${ROOT_COMPANY_URL}/${url.getCompanyList}`
        return callApi(apiUrl, key, options, headerParams)
    }
    
export const getConnectionStatusByCompany = (data, key) => {
        const options = getRequestWithParams(data);
        const apiUrl = `${ROOT_NETWORK_URL}/${url.getConnectionStatus}`
        return callApi(apiUrl, key, options)
    }

export const getConnectionSuggestionCompanies = (data, key) => {
        const options = getRequestWithParams(data)
        const apiUrl = `${ROOT_COMPANY_URL}/${url.getConnectedCompanies}`
        return callApi(apiUrl, key, options)
    }
export const updatePostComments = (data, key) => {
        const options = putRequestWithParams(data)
        const apiUrl = `${ROOT_POST_URL}/${url.editPostComments}`
        return callApi(apiUrl, key, options, headerParams)
    }
export const deletePostComments = (data, key) => {
        const options = deleteRequest(data)
        const apiUrl = `${ROOT_POST_URL}/${url.deletePostComments}`
        return callApi(apiUrl, key, options, headerParams)
    }
export const companyPokeAction = (data, key) => {
        const options = paramsRequest(data)
        const apiUrl = `${ROOT_NETWORK_URL}/${url.pokeRequest}`
        return callApi(apiUrl, key, options, headerParams)
    }
export const getcompanyPokeAction = (data, key) => {
        const options = getRequestWithParams(data)
        const apiUrl = `${ROOT_NETWORK_URL}/${url.pokeRequest}`
        return callApi(apiUrl, key, options, headerParams)
    }
export const getcompanyPokeByStatusAction = (data, key) => {
        const options = getRequestWithParams(data)
        const apiUrl = `${ROOT_NETWORK_URL}/${url.pokeDetails}`
        return callApi(apiUrl, key, options, headerParams)
    }
    
export const updatecompanyPokeAction = (data, key) => {
        const options = putRequestWithParams(data)
        const apiUrl = `${ROOT_NETWORK_URL}/${url.pokeRequest}`
        return callApi(apiUrl, key, options, headerParams)
    }
export const companyUnFollowAction = (data, key) => {
        const options = putRequestWithParams(data)
        const apiUrl = `${ROOT_NETWORK_URL}/${url.followCompany}`
        console.log('options,apiUrl');
        console.log(options,apiUrl);
        return callApi(apiUrl, key, options, headerParams)
    }
export const getTypeaheadSearchResult = (data, key) => {
        const options = getRequestWithParams(data)
        const apiUrl = `${ROOT_GLOBALSEARCH_URL}/${url.typeaheadSearch}`
        return callApi(apiUrl, key, options)
    }
    
    
// --End ----ADDED BY Ashis------------------------

/**
 * Axios request
 * @param {url} endpoint 
 * @param {redux state key} key 
 * @param {nature of request} options 
 */
const callApi = async (url, key, options, headerParams = { AUTH_TOKEN: false, ACCESS_TOKEN: true, PASSWORD: false, OAUTH_TOKEN: false } ) => {
    let headers = { 'Content-Type': 'application/json' }
    if(headerParams.AUTH_TOKEN){
        let AuthToken =  await localStorage.getItem('AUTH_TOKEN');
        headers.Authorization = AuthToken;
    }
    if(headerParams.ACCESS_TOKEN){
        let AccessToken =  await localStorage.getItem('ACCESS_TOKEN');
        headers.Authorization = AccessToken;
    }
    if(headerParams.PASSWORD){
        headers.password = options.params.password;
    }
    if(headerParams.OAUTH_TOKEN){
        headers.social_oauth_token = options.params.token;
    }
    //console.log(url, options, headers);
    try{
    return axios({ url, ...options, headers })
        .then(res => {        
            let obj = {};
            let resData = res.data;
            if (!resData) {
                obj = { [key]: {}, error: true, errors: resData.error || 'Something went wrong'}
            } else {                
                if(key==='tokenData'){
                    //resData = (res.data.data===undefined) ? res.data :  res.data.data;
                }
                obj = { [key]: resData, error: false, errors: '' }
            }
            console.log(resData, 'API_RES');
            return obj;
        })
        .catch(error => {
            const obj = { 
                [key]: { error: true, errors: error.message || 'Something went wrong', message: error.message || 'Something went wrong' } 
            }
            console.log(error,'API_ERROR')
            return obj;
            
        })
    } catch(error){
        const obj = { 
            [key]: { error: true, errors: error || 'Something went wrong', message: error || 'Something went wrong' } 
        }
        console.log(error,'ERROR_CATCH')
        return obj;
    }
}