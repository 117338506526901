import React from 'react';
import Helper from "../../../../Configs/Helper";
import ReactBootstrapTable from '../../TableComponent/ReactBootstrapTable';
import { getConnectedCompanies, updateConnectionRequest } from '../../../../api/agent';
import Constants from '../../../../utils/Constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";

const COMPANY_DEFAULT_LOGO = Constants.defaultImageUrls.companyLogo;
const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;

class SendRequest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            COMPANY_ID: "",
            ACCOUNT_ID: "",
            sendRequestDetail: [],
            errorMessage: "",
            isCancelLoading: false
        }
    }
    goToCompanyTimeline=(company_id)=>{
        this.props.goToTimeLine(company_id);
    }
    keyField = "company_id";
    cellEditProperties = {
        mode: "click",
        blurToSave: true
    };

    updateConnectionRequest = (status, companyId, requesterId, connectionRequestId, rowIndex) => {

        console.log("Updating network request");
        console.log("status : " + status);
        console.log("companyId : " + companyId);
        console.log("requesterId : " + requesterId);
        console.log("networkRequestId : " + connectionRequestId);
        console.log("rowIndex : " + rowIndex);
        console.log("");

        this.setState({
            ["isCancelLoading" + rowIndex]: true
        });

        let requestParams = {
            request_status: status,
            id: connectionRequestId,
            company_id: companyId,
            user_id: this.state.ACCOUNT_ID,
            requester_id: requesterId,

        }

        const payload = updateConnectionRequest(requestParams, "updateResponse");

        payload.then(response => {

            console.log("");
            console.log("UPDATE NETWORK RESPONSE");
            console.log(response);
            console.log("");

            this.setState({
                ["isCancelLoading" + rowIndex]: false
            });

            if (response.updateResponse.status === 200) {

                this.getAllSentConnectionRequest();
                this.props.handleOpen("Network Request canceled");

            } else {
                this.props.handleOpen("Network Request status is not updated");
            }
        });
    }

    defaultSorted = [
        {
            dataField: "company_name",
            order: "desc"
        }
    ];

    isUnmounted = false;
    getAllSentConnectionRequest = () => {

        let requestParams = {
            company_id: this.state.COMPANY_ID,
            connection_status: 0,
            type: 1,
        }

        const payload = getConnectedCompanies(requestParams, "companiesDetail");

        payload.then(response => {

            if (this.isUnmounted) {
                return;
            }

            console.log("");
            console.log("GET ALL NETWORK REQUEST SENT LIST");
            console.log(response);
            console.log("");

            if (response.companiesDetail.status === 200) {
                this.setState({
                    sendRequestDetail: response.companiesDetail.data
                });
            } else {
                this.setState({
                    errorMessage: "No pending request.",
                    sendRequestDetail: []
                });
            }
        });
    }

    componentDidMount() {
        this.setState({
            COMPANY_ID: Helper.getCompanyInfo(this.props.companyDetails, 'company_id'),
            ACCOUNT_ID: this.props.userData.data.account_id,
            isLoadingData: true
        }, () => { this.getAllSentConnectionRequest(); })
    }
    componentWillUnmount() {
        this.isUnmounted = true;
    }

    render() {

        const sendRequestColumns = [
            {
                dataField: "company_name",
                text: "Name",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                editable: false,
                isDummyField: true,
                formatter: (cellContent, row, rowIndex, extraData) => {
                    return (
                        <div className="userNameWithImg">
                            <div className="img">
                                <img style={{ height: "30px", width: "30px" }} src={row.company_logo !== null ? BUCKET_PATH + '/' + row.company_logo.image_path : COMPANY_DEFAULT_LOGO} />
                            </div>
                            <a style={{ textTransform: "capitalize",cursor:'pointer' }} onClick={()=>this.goToCompanyTimeline(row.company_id)}>
                                {row.company_name}
                            </a>
                        </div>
                    );
                },
            },
            {
                dataField: "location.city",
                text: "Location",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                formatter: (cellContent, row, rowIndex, extraData) => {
                    return (

                        row.location === null || row.location.city === null ?
                            `-`
                            :
                            row.location.city
                    );
                },
                editable: false
            },
            {
                dataField: "industry_type",
                text: "Sector",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                editable: false
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Actions",
                editable: false,
                formatter: (cellContent, row, rowIndex, extraData) => {
                    return (
                        <a href="#c" style={{ width: "46px", height: "23px" }} onClick={() => extraData.updateStatus(3, row.company_id, this.state.COMPANY_ID, row.connection_request_id, rowIndex)} className="btn btn-sm btn-danger">
                            {
                                extraData.state["isCancelLoading" + rowIndex] ?
                                    <CircularProgress className="loadingSpinnerBtn2" />

                                    : "Cancel"
                            }
                        </a>
                    );
                },
                formatExtraData: {
                    updateStatus: this.updateConnectionRequest,
                    state: this.state
                }
            }
        ];

        return (

            <React.Fragment>
                <div className="detailsHeader">
                    Request <span>({this.state.sendRequestDetail.length})</span>
                </div>
                {this.state.errorMessage !== ""
                    ?
                    <div>
                        <h3>
                            {this.state.errorMessage}
                        </h3>
                    </div>
                    :
                    <ReactBootstrapTable
                        data={this.state.sendRequestDetail}
                        keyField={this.keyField}
                        cellEditData={this.cellEditData}
                        defaultSorted={this.defaultSorted}
                        columns={sendRequestColumns}
                    />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    companyDetails: state.appData.companyDetails,
    loading: state.loading,
});

export default connect(
    mapStateToProps,
    null
)(SendRequest);