import React from "react";
//import { Link } from "react-router-dom";
import {getCompanySuggestionAction, setSelectedOtherCompanyInfoAction, getCompanyDetailsByIdAction, companyFollowAction, getReviewBycompanyidAction } from "../../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Helper from '../../../../Configs/Helper';
import Constants from '../../../../utils/Constants';
import SimpleLoader from '../../../SubComponents/Loader/SimpleLoader';
//import LeftSection from './LeftSection';

class UserSuggestedCompanies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           companies: [],
           companyId: '',
           items: [],
        };
      }
      async componentDidMount(){
        const companyId =  await localStorage.getItem('COMPANY_ID');
        if(companyId!=='' && companyId!==undefined && companyId!==null){
            this.setState({ companyId })
        }
        this.getCompanyList()
      }
 
      getCompanyList = async () =>{
          let paramsData = {};
          paramsData.user_id =this.props.userData.data.account_id;
          
        //const ownCompObj = Helper.getCompanyDetailsObj(this.props.ownCompanyDetails);
        //if(ownCompObj.company_id==='')  paramsData.user_id = this.props.userData.data.account_id;
        //if(ownCompObj.company_id!=='')  paramsData.company_id = this.props.userData.data.account_id;

        await this.props.getCompanySuggestionAction(paramsData);    
        if(this.props.suggestedCompanies.status===200){
            const { data } = this.props.suggestedCompanies
            this.setState({ companies: (data.length>0) ? data : [] })
        } else {
            // this.loader.showAlert(true,'error',this.props.companyList.message)
        } 
    }
    goToCompanyTimeline = company_id => async (e) =>{
        console.log('go to timeline');
 
        this.props.loader.show(true);

        //set default company Id
        Helper.setDefaultCompany(company_id);

        // get selected company details
        const paramsData = {
            account_id:this.props.userData.data.account_id,
            requester_id: Helper.getDefaultCompanyId(),
            company_id:company_id,
        }
        await this.props.getCompanyDetailsByIdAction(paramsData);
        if(this.props.companyDetails.status===200){
            await this.props.setSelectedOtherCompanyInfoAction(this.props.companyDetails);
          //get reviews   
              await this.props.getReviewBycompanyidAction({company_id:company_id})
                 Helper.setReviewCounter(this.props.reviewerData);
                this.props.history.push('/company-timeline')
        } else {
            this.props.loader.showAlert(true,'error',this.props.companyDetails.message)
        }
        this.props.loader.show(false);
    }

    followCompanyHandler = company_id => async (e) =>{
        this.props.loader.show(true);
        const ownCompObj = Helper.getCompanyDetailsObj(this.props.ownCompanyDetails);
        const paramsData = {
            follower_id: (ownCompObj.company_id!=='') ? ownCompObj.company_id : this.props.userData.data.account_id, //company or user id
            following_id: company_id, //always company id
            follower_type: (ownCompObj.company_id!=='') ? 2 : 1,
            status: 1,
            is_connected: 0,
            user_id: this.props.userData.data.account_id,
        };
        
        await this.props.companyFollowAction(paramsData);
        if(this.props.companyFollowRes.status===200){
            this.props.loader.showAlert(true,'success',this.props.companyFollowRes.message)
        } else {
            if(this.props.companyFollowRes.message_code===24) this.props.loader.showAlert(true,'warning',this.props.companyFollowRes.message)
            else this.props.loader.showAlert(true,'error',this.props.companyFollowRes.message)
        }
        this.props.loader.show(false);
    } 

    render() {
        return (
            <div className="row">
                {/* <div className="col-3"> 
                    <LeftSection />
                </div> */}
               <div className="col-12" >
                    { this.props.loading.GET_COMPANY_SUGGESTION_ACTION ? <div className="box bg-white border "><SimpleLoader/></div> : 
                        <div className="box bg-white border ">
                            <div className="box-header padding-right-0" style={{paddingTop:"10px"}}>
                                {/* <div className="title">Your suggested Companies</div> */}
                                <div></div>
                            </div>
                            <div className="upperContainer" style={{position:"absolute",top: '10px',right: '26px',width: '45%',}}>
                                <ul>
                                <li>
                                <form>
                                    <fieldset className="form-group ">
                                    <div className="searchInput" style={{width:'auto'}}>
                                        <span>Total Companies <b>{this.state.companies.length} &nbsp;</b><i className="fa fa-briefcase"></i> </span>
                                    {/* <input type="text" placeholder="Search" className="form-control" onChange={this.filterList}/>
                                    <a href="#" className="icon"> <i className="flaticon-search"></i></a> */}
                                    </div>
                                    </fieldset>
                                </form>
                                </li>
                                </ul>
                            </div>
                        <div className="box-body">
                            <CompaniesList state={this.state} goToCompanyTimeline={this.goToCompanyTimeline} followCompanyHandler={this.followCompanyHandler} {...this.props}/>                            </div>
                        </div>                       
                    } 
                </div> 
            </div>
        )
    }
}

function CompaniesList(props){
    let list = [];
     if(props.state.companies.length>0){
        list = props.state.companies.map((val,idx)=>{
            let companyLogo = Constants.defaultImageUrls.companyLogo;
            let compId = val.id;
            if(val.company_logo!==null){
                companyLogo = process.env.REACT_APP_BUCKET_PATH+'/'+val.company_logo.image_path;
            }
            return (<li key={idx}>
                <div>
                    <div className="img">
                        <a href="#"><img src={companyLogo}/></a>
                    </div>
                    <div className="details">
                        <h4> <a href="#" onClick={props.goToCompanyTimeline(compId)}>{val.company_name}</a></h4>
                        <h6 className="subTitle">{val.company_sub_title}</h6>
                        <h6 className="subTitle"><b>Email:</b> {val.company_email}</h6>
                        <h6 className="subTitle"><b>Type:</b> {val.company_type} | <b>Industry:</b> {val.industry_type}</h6>
                        <p>{val.description}</p>
                    </div>
                    <div className="actions">
                        <ul>
                            {/* <li className="dropdown">
                                <a className="btn btn-xs" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-globe"></i>
                                </a>
                            </li> */}
                            <li className="dropdown show">
                                <a className="btn btn-xs" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Option
                                </a>
                                <div className="dropdown-menu " x-placement="bottom-start" style={{position: "absolute", transform: "translate3d(0px, 29px, 0px)", top: "0px", left: "0px", willChange: "transform"}}>
                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={props.followCompanyHandler(compId)}>Follow</a>
                                    {/* {(props.state.companyId!=='') && <a className="dropdown-item" href="#" data-toggle="modal" data-target="#relationModal" onClick={props.setSelectedCompId.bind(this,compId)}> Connection </a> } */}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>)
    })
}
    return <ul className="deailsList pageList">{list}</ul>
}
const mapStateToProps = state => ({
    userData: state.auth.userData,
    suggestedCompanies: state.appData.suggestedCompanies,
    companyDetails: state.appData.companyDetails,
    ownCompanyDetails: state.appData.ownCompanyDetails,
    otherCompanyDetails: state.appData.otherCompanyDetails,
    companyFollowRes: state.appData.companyFollowRes,
    reviewerData: state.apiData.reviewerData,
    loading: state.loading,
  });
function mapDispatchToProps(dispatch) {
    return {
        getCompanySuggestionAction: bindActionCreators(getCompanySuggestionAction, dispatch),
        getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
        setSelectedOtherCompanyInfoAction: bindActionCreators(setSelectedOtherCompanyInfoAction, dispatch),
        companyFollowAction: bindActionCreators(companyFollowAction, dispatch),
        getReviewBycompanyidAction: bindActionCreators(getReviewBycompanyidAction, dispatch),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSuggestedCompanies);