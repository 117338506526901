import React from "react";
import { Link } from "react-router-dom";
import Constants from '../../../../utils/Constants';
import {getCompanyListAction, setSelectedOwnCompanyInfoAction, getCompanyDetailsByIdAction,getReviewBycompanyidAction } from "../../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Helper from '../../../../Configs/Helper';
import SimpleLoader from '../../../SubComponents/Loader/SimpleLoader';
import LeftSection from './LeftSection';
class UserCompanies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           companies: []
        };
      }
      componentDidMount(){
        this.getCompanyList()
      }

    getCompanyList = async () =>{
        const paramsData = {
            account_id: this.props.userData.data.account_id,
            fetchdata: 'companyList',
            fetch_size: 10,
        }
        await this.props.getCompanyListAction(paramsData);
        
        if(this.props.companyList.status===200){
            const { data } = this.props.companyList
            this.setState({ companies: (data.length>0) ? data : [] })
        } else {
            // this.loader.showAlert(true,'error',this.props.companyList.message)
        }
    }

    fetchMoreData = () => {
        if(this.state.hasMoreRecords){
            this.setState({ nextPage: this.state.nextPage },()=>this.getCompanyListAction());
        }
        return true
    }
    goToCompanyTimeline = (company_id, path='') => async (e) =>{
        this.props.loader.show(true);
        //set default company Id
        Helper.setDefaultCompany(company_id,'user_company');

        // get selected company details
        const paramsData = {
            account_id: this.props.userData.data.account_id,
            company_id: company_id,
        }
        await this.props.getCompanyDetailsByIdAction(paramsData);
        if(this.props.companyDetails.status===200){
            await this.props.setSelectedOwnCompanyInfoAction(this.props.companyDetails);
             //get reviews   
            await this.props.getReviewBycompanyidAction({company_id:company_id})
            Helper.setReviewCounter(this.props.reviewerData);
            this.props.history.push('/company-timeline'+path)
        }
        this.props.loader.show(false);       
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-3 col-sm-12"> 
                    <LeftSection />
                </div>

                <div className="col-md-9 col-sm-12">   
                    { this.props.loading.GET_COMPANY_LIST_ACTION ? <div className="box bg-white border "><SimpleLoader/></div> : 
                        <div className="box bg-white border ">
                            <div className="box-header padding-right-0">
                                <div className="title">Your Created Companies</div>
                                <div></div>
                            </div>
                            <div className="upperContainer" style={{position:"absolute",top: '10px',right: '26px',width: '45%',}}>
                                <ul>
                                <li>
                                <form>
                                    <fieldset className="form-group ">
                                    <div className="searchInput" style={{width:'auto'}}>
                                        <span>Total Companies <b>{this.state.companies.length} &nbsp;</b><i className="fa fa-briefcase"></i> </span>
                                    {/* <input type="text" placeholder="Search" className="form-control" onChange={this.filterList}/>
                                    <a href="#" className="icon"> <i className="flaticon-search"></i></a> */}
                                    </div>
                                    </fieldset>
                                </form>
                                </li>
                                </ul>
                            </div>

                            <div className="box-body">
                                <CompaniesList state={this.state} goToCompanyTimeline={this.goToCompanyTimeline}/>
                            </div>
                        </div>
                    } 
                </div> 
            </div>
        )
    }
}

function CompaniesList(props){
    let list = [];
     if(props.state.companies.length>0){
        list = props.state.companies.map((val,idx)=>{
            let companyLogo = Constants.defaultImageUrls.companyLogo;
            let compId = '';
            let compName = '';
            let subTitle = '';
            let desc = '';
            let compIndustry = '';
            let compType = '';
            let compEmail = '';

            if(val.company!==undefined && val.company!==null ){
                if(val.company.id!==undefined){
                    compId = val.company.id
                }
                if(val.company.company_name!==undefined){
                    compName = val.company.company_name
                }
                if(val.company.company_sub_title!==undefined){
                    subTitle = val.company.company_sub_title
                }
                if(val.company.description!==undefined){
                    desc = val.company.description
                }
                if(val.company.company_type!==undefined){
                    compType = val.company.company_type
                }
                if(val.company.company_logo!==null){
                    companyLogo = process.env.REACT_APP_BUCKET_PATH+'/'+val.company.company_logo.image_path;
                }

                if(val.company_info!==undefined && val.company_info!==null){
                    if(val.company_info.company_email!==undefined){
                        compEmail = val.company_info.company_email
                    }
                    if(val.company_info.industry_type!==undefined){
                        compIndustry = val.company_info.industry_type
                    }
                }
            }
            return (<li key={idx}>
                <div>
                    <div className="img">
                        <a href="#"><img src={companyLogo}/></a>
                    </div>
                    <div className="details">
                        <h4> <a href="#" onClick={props.goToCompanyTimeline(compId)}>{compName}</a></h4>
                        <h6 className="subTitle">{subTitle}</h6>
                        <h6 className="subTitle"><b>Email:</b> {compEmail}</h6>
                        <h6 className="subTitle"><b>Type:</b> {compType} | <b>Industry:</b> {compIndustry}</h6>
                        <p>{desc}</p>
                    </div>
                    <div className="actions">
                        <ul>
                            <li className="dropdown">
                                <a className="btn btn-xs" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-globe"></i>
                                </a>
                            </li>
                            <li className="dropdown show">
                                <a className="btn btn-xs"  href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Option
                                </a>
                                <div className="dropdown-menu " x-placement="bottom-start" style={{position: "absolute", transform: "translate3d(0px, 29px, 0px)", top: "0px", left: "0px", willChange: "transform"}}>
                                    <a className="dropdown-item" href="#" onClick={props.goToCompanyTimeline(compId,'/settings')}>Settings</a>
                                    {/* <a className="dropdown-item" href="#">Edit</a>
                                    <a className="dropdown-item" href="#">Delete</a> */}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>)
    })
}
    return <ul className="deailsList pageList">{list}</ul>
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    companyList: state.appData.companyList,
    companyDetails: state.appData.companyDetails,
    ownCompanyDetails: state.appData.ownCompanyDetails,
    loading: state.loading,
    reviewerData: state.apiData.reviewerData,
  });
function mapDispatchToProps(dispatch) {
    return {
        getCompanyListAction: bindActionCreators(getCompanyListAction, dispatch),
        getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
        setSelectedOwnCompanyInfoAction: bindActionCreators(setSelectedOwnCompanyInfoAction, dispatch),
        getReviewBycompanyidAction: bindActionCreators(getReviewBycompanyidAction, dispatch),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserCompanies);