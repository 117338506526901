import React,{Component} from 'react';
import Helper from "../../../../Configs/Helper";
import { companyPokeAction,getcompanyPokeAction} from "../../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class PokeActionsButton extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isRelation:1,
            ownCompanyDetails:{},
            otherCompanyDetails:{},
            is_poked:0,
        }
    }
    componentDidMount(){
        this.setCompanyDetails(this.props);
    }
    
    componentWillReceiveProps(nxtProps){
        this.setCompanyDetails(nxtProps);
    }
    setCompanyDetails=(props)=>{
        this.setState({ 
            ownCompanyDetails: Helper.getCompanyDetailsObj(props.ownCompanyDetails),
            otherCompanyDetails: props.companyDetails
         })
    }
    pokeCompanyHandler = async (e) =>{
      //   this.props.loader.show(true);
      const paramsData = {
            poker_id: (this.state.ownCompanyDetails.company_id!=='') ? this.state.ownCompanyDetails.company_id : this.props.userData.data.account_id, //company or user id
            poked_id:this.state.otherCompanyDetails.company_id, //always company id
            user_id:this.props.userData.data.account_id,
         };
        await this.props.companyPokeAction(paramsData);
        if(this.props.pokeData.status===200){
            this.setState({is_poked:1})

            // this.props.loader.showAlert(true,'success',this.props.pokeData.message)
        } else {
            // this.props.loader.showAlert(true,'error',this.props.pokeData.message)
        }
      //   this.props.loader.show(false);
    }
    render(){
        console.log('this.state.ownCompanyDetails');
        console.log(this.state.ownCompanyDetails);
        return(
               <>
                  <div className="box-body padding-0">
                    <Poke state={this.state} pokeCompanyHandler={this.pokeCompanyHandler}/>
                  </div>
               </>
        )
    }
}
function Poke(props){
    const is_poked = props.state.ownCompanyDetails.is_poked;
    if((is_poked===0 && is_poked!=='') || props.state.is_poked===1){
        return <button className="btn btn-info" style={{width:'100%'}}>
        Poked </button>
    }
    return <button className="btn btn-info" style={{width:'100%'}} onClick={props.pokeCompanyHandler}>
        Poke </button>
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    ownCompanyDetails: state.appData.ownCompanyDetails,
    pokeData:state.apiData.pokeData,
    getpokeData:state.apiData.getpokeData,
    loading: state.loading,
  });
function mapDispatchToProps(dispatch) {
    return {
        companyPokeAction:bindActionCreators(companyPokeAction, dispatch),
        getcompanyPokeAction:bindActionCreators(getcompanyPokeAction, dispatch),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PokeActionsButton);