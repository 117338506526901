import React from 'react';
import Helper from "../../../../Configs/Helper";
import ReactBootstrapTable from "../../TableComponent/ReactBootstrapTable";
import ReceiveRequest from "./ReceiveRequest";
import SendRequest from "./SendRequest";
import BlockUsers from "./BlockUsers";
import { getConnectedCompanies } from '../../../../api/agent';
import Constants from '../../../../utils/Constants';
import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PokeUsers from './PokeUsers';
import PokeSentRequest from './PokeSentRequest';

const COMPANY_DEFAULT_LOGO = Constants.defaultImageUrls.companyLogo;
const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;

class Connection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            connectionDetail: [],
            errorMessage: "",
            isLoadingData: false,
            companyId: '',
        };
    }
    goToCompanyTimeline=(company_id)=>{
        this.props.goToTimeLine(company_id);

    }

    keyField = "company_id";
    cellEditProperties = {
        mode: "click",
        blurToSave: true
    };

    ConnectionColumns = [
        {
            dataField: "company_name",
            text: "Name",
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span className="order-4"></span>);
                else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                return null;
            },
            editable: false,
            isDummyField: true,
            formatter: (cellContent, row, rowIndex, extraData) => {
                return (
                    <div className="userNameWithImg">
                        <div className="img">
                            <img style={{ height: "30px", width: "30px" }} src={row.company_logo !== null ? BUCKET_PATH +'/'+ row.company_logo.image_path : COMPANY_DEFAULT_LOGO} />
                        </div>
                        <div className="name">
                        <a style={{ textTransform: "capitalize",cursor:'pointer' }} 
                            onClick={()=>this.goToCompanyTimeline(row.company_id)}
                            >
                                {row.company_name}
                            </a>
                        </div>
                    </div>
                );
            },
        },
        {
            dataField: "location.city",
            text: "Location",
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span className="order-4"></span>);
                else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                return null;
            },
            formatter: (cellContent, row, rowIndex, extraData) => {
                return (

                    row.location === null || row.location.city === null ?
                        `-`
                        :
                        row.location.city
                );
            },
            editable: false
        },
        {
            dataField: "industry_type",
            text: "Sector",
            sort: true,
            sortCaret: (order, column) => {
                if (!order) return (<span className="order-4"></span>);
                else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                return null;
            },
            editable: false
        },
        {
            dataField: 'status',
            isDummyField: true,
            text: 'Status',
            editable: false,
            // sort: false
            formatter: (cellContent, row) => {
                return (
                    <h5>
                        <span className="label label-success"> Connected</span>
                    </h5>
                );
            }
        }
        // {
        //     dataField: "isConnected",
        //     text: "Status",
        //     sort: true,
        //     editor: {
        //         type: Type.SELECT,
        //         options: [
        //             {
        //                 value: 1,
        //                 label: "Connected"
        //             },
        //             {
        //                 value: 0,
        //                 label: "UnConnected"
        //             }
        //         ]
        //     }
        // },
        // {
        //     dataField: "action",
        //     isDummyField: true,
        //     text: "Action",
        //     editable: false,
        //     formatter: (cellContent, row, rowIndex, extraData) => {
        //         return (
        //             <h5>
        //                 <button className="label label-danger" onClick={() => alert(extraData)}>
        //                     Edit
        //                 </button>
        //             </h5>
        //         );
        //     },
        //     formatExtraData: "2"  //value can be a function (update status function)
        // }
    ];

    defaultSorted = [
        {
            dataField: "company_name",
            order: "desc"
        }
    ];

    handleOpen = (message) => {
        this.props.handleAlertOpen(message);
    }

    getAllConnectedCompanies = () => {

        let requestParams = {
            company_id: this.state.companyId,
            connection_status: 1,
        }
        const payload = getConnectedCompanies(requestParams, "companiesDetail");
        payload.then(response => {

            console.log("");
            console.log("GET CONNECTED COMPANY LIST");
            console.log(response);
            console.log("");

            this.setState({
                isLoadingData: false
            });

            if (response.companiesDetail.status === 200) {
                this.setState({
                    connectionDetail: response.companiesDetail.data
                });
            } else {
                this.setState({
                    errorMessage: "No networks."
                });
            }
        });
    }

    componentDidMount() {
        this.setState({  
            companyId: Helper.getCompanyInfo(this.props.companyDetails,'company_id'),
            isLoadingData: true
        },()=>{this.getAllConnectedCompanies();})
    }

    render() {

        return (

            <div className="row no-gutters">
                <div className="col-12 middle-container" style={{ paddingRight: "0px" }}>
                    <div className="box bg-transparent">

                        <div className="box-body padding-0">
                            <div className="nav nav-pills" id="v-pills-tab" role="tablist" >
                                <a className="nav-link active" id="v-pills-member-tab" data-toggle="pill" href="#v-pills-member" role="tab" aria-controls="v-pills-member" aria-selected="true">Networks</a>
                                <a className="nav-link" id="v-pills-request-tab" data-toggle="pill" href="#v-pills-request" role="tab" aria-controls="v-pills-request" aria-selected="false">Request</a>
                                <a className="nav-link" id="v-pills-send-request-tab" data-toggle="pill" href="#v-pills-send-request" role="tab" aria-controls="v-pills-send-request" aria-selected="false">Send Request</a>
                                <a className="nav-link" id="v-pills-block-tab" data-toggle="pill" href="#v-pills-block" role="tab" aria-controls="v-pills-block" aria-selected="false">Block Network</a>
                                <a className="nav-link" id="v-pills-poke-tab" data-toggle="pill" href="#v-pills-poke" role="tab" aria-controls="v-pills-poke" aria-selected="false">Send Pokes</a>
                                <a className="nav-link" id="v-pills-send-poke-tab" data-toggle="pill" href="#v-pills-send-poke" role="tab" aria-controls="v-pills-send-poke" aria-selected="false">Receive Send Poke</a>
                            </div>
                        </div>
                    </div>
                    <div className="box bg-white border blogContainer">
                        {
                            this.state.isLoadingData ?

                                <CircularProgress style={{ marginLeft: "400px", marginTop: "203px" }} />
                                :
                                <div className="box-body">

                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="v-pills-member" role="tabpanel" aria-labelledby="v-pills-member-tab">
                                            <div className="detailsHeader">
                                                Networks <span>({this.state.connectionDetail.length})</span>
                                            </div>
                                            {
                                                this.state.errorMessage !== ""
                                                    ?
                                                    <div>
                                                        <h3>
                                                            {this.state.errorMessage}
                                                        </h3>
                                                    </div>
                                                    :
                                                    <ReactBootstrapTable
                                                        data={this.state.connectionDetail}
                                                        keyField={this.keyField}
                                                        cellEditData={this.cellEditProperties}
                                                        defaultSorted={this.defaultSorted}
                                                        columns={this.ConnectionColumns}
                                                    />
                                            }
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-request" role="tabpanel" aria-labelledby="v-pills-request-tab">
                                            <ReceiveRequest goToTimeLine={this.goToCompanyTimeline} handleOpen={this.handleOpen} />
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-send-request" role="tabpanel" aria-labelledby="v-pills-send-request-tab">
                                            <SendRequest goToTimeLine={this.goToCompanyTimeline} handleOpen={this.handleOpen} />
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-block" role="tabpanel" aria-labelledby="v-pills-block-tab">
                                            <BlockUsers goToTimeLine={this.goToCompanyTimeline} />
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-poke" role="tabpanel" aria-labelledby="v-pills-poke-tab">
                                            <PokeUsers goToTimeLine={this.goToCompanyTimeline} handleOpen={this.handleOpen}/>
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-send-poke" role="tabpanel" aria-labelledby="v-pills-send-poke-tab">
                                            <PokeSentRequest goToTimeLine={this.goToCompanyTimeline} handleOpen={this.handleOpen}/>
                                        </div>
                                        {/* <div className="tab-pane fade" id="v-pills-search" role="tabpanel" aria-labelledby="v-pills-search-tab">
                                            <div className="" style={{ marginBottom: "15px" }}>
                                                <div className="searchInput" style={{ width: "100%" }}>
                                                    <input className="form-control" />
                                                    <a href="#s" className="icon">
                                                        <i className="flaticon-search"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="detailsHeader">
                                                Result <span>(2)</span>
                                            </div>
                                            <div>
                                                <ul className="deailsList pageList">
                                                    <li>
                                                        <div>
                                                            <div className="img">
                                                                <a href="#">
                                                                    <img src="https://scontent-hkg3-2.xx.fbcdn.net/v/t1.0-9/26167690_1601887163231736_8146650786613096715_n.png?_nc_cat=1&_nc_oc=AQmwlN7OhbJsULYovoJonuXsnH56WcJY8NYI2-EGZpPYd1ZUNj3xxsf29gfr2K3_-2s&_nc_ht=scontent-hkg3-2.xx&oh=0ca207aa41e463131fa05831021def4e&oe=5DCA655E" />
                                                                </a>
                                                            </div>
                                                            <div className="details">
                                                                <h4><a href="#">Company Name</a></h4>
                                                                <h6 className="subTitle"> Fusce pellentesque sit amet risus in aliquam.</h6>
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at elit nec nibh vestibulum lobortis. Etiam at massa est. Etiam et rutrum dolor. Mauris aliquam tortor quam, et tempus elit dictum non. Aenean laoreet sodales dolor sed aliquet. Vivamus eu leo enim. Nunc suscipit nunc ut sem iaculis posuere.
                                                                        </p>
                                                            </div>
                                                            <div className="actions">
                                                                <ul>
                                                                    <li className="dropdown">
                                                                        <a className="btn btn-xs" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            Option
                                                                                </a>
                                                                        <div className="dropdown-menu" >
                                                                            <a className="dropdown-item" href="#">Send Request</a>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <div className="img">
                                                                <a href="#">
                                                                    <img src="https://marketplace.canva.com/MADOPuRUFPM/1/0/thumbnail_large-1/canva-yellow-square-real-estate-logo-MADOPuRUFPM.jpg" />
                                                                </a>
                                                            </div>
                                                            <div className="details">
                                                                <h4><a href="#">Company Name</a></h4>
                                                                <h6 className="subTitle"> Fusce pellentesque sit amet risus in aliquam.</h6>
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at elit nec nibh vestibulum lobortis. Etiam at massa est. Etiam et rutrum dolor. Mauris aliquam tortor quam, et tempus elit dictum non. Aenean laoreet sodales dolor sed aliquet. Vivamus eu leo enim. Nunc suscipit nunc ut sem iaculis posuere.
                                                                        </p>
                                                            </div>
                                                            <div className="actions">
                                                                <ul>
                                                                    <li className="dropdown">
                                                                        <a className="btn btn-xs" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            Option
                                                                                </a>
                                                                        <div className="dropdown-menu" >
                                                                            <a className="dropdown-item" href="#">Send Request</a>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                     */}
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    companyDetails: state.appData.companyDetails,
    loading: state.loading,
});

export default connect(
    mapStateToProps,
    null
)(Connection);