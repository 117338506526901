import * as types from '../actions/types'
// import { updateObject } from '../../utils/HelperFunc';


const initialState = {
    pending: false,
    tokenData: null,
    signupData: null,
    loginData: null,
    otpData: null,
    otpVerifyData: null,
    resetPasswordData: null,
    userData: null,
    savedCompanyInfoStep1: null,
    savedAddressInfoStep2: null,
    savedFounderInfoStep3: null,
    savedTeamInfoStep4: null,
    interestData: null,
    error: null
}

export default (state = initialState, action)=> {
    const { type, payload } = action;
    switch(type) {
        case types.LOGOUT_ACTION_REQUEST:
            return   { ...state, pending: false,
                tokenData: null,
                signupData: null,
                loginData: null,
                otpData: null,
                otpVerifyData: null,
                resetPasswordData: null,
                userData: null,
                savedCompanyInfoStep1: null,
                savedAddressInfoStep2: null,
                savedFounderInfoStep3: null,
                savedTeamInfoStep4: null,
                interestData: null,
                error: null };
        case types.GENERATE_TOKEN_ACTION_REQUEST: 
            return { ...state }
        case types.GENERATE_TOKEN_ACTION_SUCCESS:
            return { ...state, tokenData: payload }
        case types.GENERATE_TOKEN_ACTION_FAILURE:
            return { ...state, tokenData: payload }

        case types.REGISTER_BY_EMAIL_ACTION_REQUEST: 
            return { ...state }
        case types.REGISTER_BY_EMAIL_ACTION_SUCCESS:
            return { ...state, signupData: payload }
        case types.REGISTER_BY_EMAIL_ACTION_FAILURE:
            return { ...state, signupData: payload }

        case types.OTP_VERIFY_ACTION_REQUEST: 
            return { ...state }
        case types.OTP_VERIFY_ACTION_SUCCESS:
            return { ...state, otpData: payload }
        case types.OTP_VERIFY_ACTION_FAILURE:
            return { ...state, otpData: payload }

        case types.SEND_OTP_ACTION_REQUEST: 
            return { ...state }
        case types.SEND_OTP_ACTION_SUCCESS:
            return { ...state, otpData: payload }
        case types.SEND_OTP_ACTION_FAILURE:
            return { ...state, otpData: payload }

        case types.RESET_PASS_OTP_VERIFY_ACTION_REQUEST: 
            return { ...state }
        case types.RESET_PASS_OTP_VERIFY_ACTION_SUCCESS:
            return { ...state, otpVerifyData: payload }
        case types.RESET_PASS_OTP_VERIFY_ACTION_FAILURE:
            return { ...state, otpVerifyData: payload }
        
        case types.RESET_PASSWORD_ACTION_REQUEST: 
            return { ...state }
        case types.RESET_PASSWORD_ACTION_SUCCESS:
            return { ...state, resetPasswordData: payload }
        case types.RESET_PASSWORD_ACTION_FAILURE:
            return { ...state, resetPasswordData: payload }

        case types.LOGIN_BY_EMAIL_ACTION_REQUEST: 
            return { ...state }
        case types.LOGIN_BY_EMAIL_ACTION_SUCCESS:
            return { ...state, loginData: payload }
        case types.LOGIN_BY_EMAIL_ACTION_FAILURE:
            return { ...state, loginData: payload }
        
        case types.USER_BY_ACCOUNT_ID_ACTION_REQUEST: 
            return { ...state }
        case types.USER_BY_ACCOUNT_ID_ACTION_SUCCESS:
            return { ...state, userData: payload }
        case types.USER_BY_ACCOUNT_ID_ACTION_FAILURE:
            return { ...state, userData: payload }

        case types.SAVE_COMPANY_INFO_ACTION_REQUEST: 
            return { ...state }
        case types.SAVE_COMPANY_INFO_ACTION_SUCCESS:
            return { ...state, savedCompanyInfoStep1: payload }
        case types.SAVE_COMPANY_INFO_ACTION_FAILURE:
            return { ...state, savedCompanyInfoStep1: payload }
        
        case types.SAVE_COMPANY_ADDRESS_ACTION_REQUEST: 
            return { ...state }
        case types.SAVE_COMPANY_ADDRESS_ACTION_SUCCESS:
            return { ...state, savedAddressInfoStep2: payload }
        case types.SAVE_COMPANY_ADDRESS_ACTION_FAILURE:
            return { ...state, savedAdddressInfoStep2: payload }

        case types.SAVE_COMPANY_FOUNDER_ACTION_REQUEST: 
            return { ...state }
        case types.SAVE_COMPANY_FOUNDER_ACTION_SUCCESS:
            return { ...state, savedFounderInfoStep3: payload }
        case types.SAVE_COMPANY_FOUNDER_ACTION_FAILURE:
            return { ...state, savedFounderInfoStep3: payload }

        case types.SAVE_COMPANY_TEAM_ACTION_REQUEST: 
            return { ...state }
        case types.SAVE_COMPANY_TEAM_ACTION_SUCCESS:
            return { ...state, savedTeamInfoStep4: payload }
        case types.SAVE_COMPANY_TEAM_ACTION_FAILURE:
            return { ...state, savedTeamInfoStep4: payload }

        case types.SAVE_INTEREST_ACTION_REQUEST: 
            return { ...state }
        case types.SAVE_INTEREST_ACTION_SUCCESS:
            return { ...state, interestData: payload }
        case types.SAVE_INTEREST_ACTION_FAILURE:
            return { ...state, interestData: payload }
        default: 
            return state;
    }
}
