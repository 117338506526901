import React, { Component } from 'react'

export default class NotificationSetting extends Component {
    render() {
        return (<>
            <div className="tab-pane active" id="v-pills-notifications" role="tabpanel" aria-labelledby="v-pills-notifications-tab">
                <div className="detailsHeader">Notifications Settings</div>
                    <ul className="settingsList">
                        <li className="title">Change Notification Alert for Email According To your Preference</li>
                            {          Object.keys(this.props.Notifications).map((key,i)=>(        
                                <li key={i}><div>{key[0].toUpperCase() + key.slice(1).split('_').join(' ')}</div>
                                    <div><div className="dropdown " style={{width: "100px"}}>
                                            <select className="form-control" name={key} onChange={this.props.onChange} value={this.props.Notifications[key]} data-width="100px" tabIndex="-98">
                                                <option value="1" data-icon="fa fa-check">Allow</option>
                                                <option value="0" data-icon="fa fa-ban">Not Allow</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </>
        )
    }
}
