import React from 'react'
import Footer from '../../SubComponents/Footer/Footer'
import { userLogoutAction } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../SubComponents/Header/Header";
class Messages extends React.Component {

    logout = async ()=>{
        await this.props.userLogoutAction();
        this.props.history.push('/');
      }
    render() {
        return (
            <div>
                 <Header {...this.props} headerType="afterLogin"/>
                 <main className="mainBody container-fluid" id="chatBoxContainer">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-9">
                                    <div className="chatBoxContainer">
                                        <div className="leftPanel">
                                            <div className="chatHeader">
                                                <div className="title">Messaging</div>
                                                <div className="acions">
                                                    <ul>
                                                        <li>
                                                            <a href="#" title="Compose a new message" id="composeMsgBtn"><i className="flaticon-edit-1"></i></a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="chatSearch">
                                                <i className="flaticon-search"></i>
                                                <input type="text" placeholder="Search Message" />
                                            </div>
                                            <div className="userMessageContainer">
                                                <ul>
                                                    <li className="unread">
                                                        <div className="img">
                                                            <span className="dot" ></span>
                                                            <img alt="" src="https://www.talescart.com/wp-content/uploads/2019/02/6-6.png" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    Arush Jangid
                                                        </div>
                                                                <div className="date">
                                                                    Oct 3
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                Arush Jangid: Congrats on your work anniversary!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li className="active">
                                                        <div className="img">
                                                            <span className="dot online" ></span>
                                                            <img alt="" src="https://hips.hearstapps.com/cosmouk.cdnds.net/15/04/1600x1278/gallery_nrm_1421677625-most-handsome-man-2.jpg?resize=480:*" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    CA. Vivek Agrawal
                                                        </div>
                                                                <div className="date">
                                                                    Oct 2
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                CA Vivek: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="img">
                                                            <span className="dot" ></span>
                                                            <img alt="" src="https://i.dailymail.co.uk/i/pix/2017/04/20/13/3F6B966D00000578-4428630-image-m-80_1492690622006.jpg" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    Anand Upadhyay
                                                        </div>
                                                                <div className="date">
                                                                    Oct 1
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                CA Vivek: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="img">
                                                            <span className="dot" ></span>
                                                            <img alt="" src="https://datingxp.co/wp-content/uploads/2019/03/image-1.jpeg" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    John Doe
                                                        </div>
                                                                <div className="date">
                                                                    Sep 28
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                John Doe: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li className="unread">
                                                        <div className="img">
                                                            <span className="dot" ></span>
                                                            <img alt="" src="https://cdn-ep19.pressidium.com/wp-content/uploads/2018/10/13472781254_3a68c4e704_o.jpg" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    Anamika Sharma
                                                        </div>
                                                                <div className="date">
                                                                    Sep 28
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                Anamika Sharma: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div className="img">
                                                            <span className="dot online" ></span>
                                                            <img alt="" src="https://image1.masterfile.com/getImage/NjE5LTA4NTQxMTk4ZW4uMDAwMDAwMDA=AEBXpM/619-08541198en_Masterfile.jpg" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    Poonam Upadhyay
                                                        </div>
                                                                <div className="date">
                                                                    Sep 26
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                Poonam Upadhyay: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="img">
                                                            <span className="dot" ></span>
                                                            <img alt="" src="http://s8.favim.com/orig/150123/alternative-beautiful-black-black-and-white-Favim.com-2410389.jpg" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    Komal Kocher
                                                        </div>
                                                                <div className="date">
                                                                    Sep 20
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                Komal Kocher: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="img">
                                                            <span className="dot" ></span>
                                                            <img alt="" src="https://ih1.redbubble.net/image.5178500.4109/flat,1000x1000,075,f.jpg" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    Bhawani Gujjar
                                                        </div>
                                                                <div className="date">
                                                                    Sep 18
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                Bhawani Gujjar: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="img">
                                                            <span className="dot" ></span>
                                                            <img alt="" src="https://i.pinimg.com/originals/b2/e0/13/b2e013c4c512e6296ea2f710094cc2d2.jpg" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    Ram Bisnoi
                                                        </div>
                                                                <div className="date">
                                                                    Sep 5
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                Ram Bisnoi: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="img">
                                                            <span className="dot" ></span>
                                                            <img alt="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT99frS-cKGJedEl27dUTUSmz9s8oNmaa4UpLu8I-ZM_wSeCK0g" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    Kavita Rajpoot
                                                        </div>
                                                                <div className="date">
                                                                    Sep 1
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                Kavita Rajpoot: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="img">
                                                            <span className="dot" ></span>
                                                            <img alt="" src="https://i.pinimg.com/originals/2c/ce/0c/2cce0c14664e5b5e92b0aaf6a1fb20f8.jpg" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="details-header">
                                                                <div className="userName">
                                                                    Bhim Singh
                                                        </div>
                                                                <div className="date">
                                                                    Aug 30
                                                        </div>
                                                            </div>
                                                            <div className="msg">
                                                                Bheem Singh: Thank You!
                                                    </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="rightPanel messageContainer">
                                            <div className="chatHeader">
                                                <div className="title">
                                                    <span id="newMessageTitle"></span>
                                                    <div className="userName">CA. Vivek Agrawal</div>
                                                    <div className="onlineDetails">
                                                        <span className="dot online"></span> 1min ago
                                            </div>
                                                </div>
                                                <div className="acions">
                                                    <ul>
                                                        <li className="dropdown">
                                                            <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                                                <a className="dropdown-item" href="#">Clear Chat</a>
                                                                <a className="dropdown-item" href="#">Delete</a>
                                                                <a className="dropdown-item" href="#">Block</a>
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="messageBody">
                                                <div className="userNameTagContainer">
                                                    <input type="text" className="userNameTagInput" placeholder="Type a name or multiple name..." />
                                                </div>
                                                <div className="messages">
                                                    <ul>
                                                        <li className="userDetails">
                                                            <div className="img">
                                                                <span className="dot online"></span>
                                                                <img alt="" src="https://hips.hearstapps.com/cosmouk.cdnds.net/15/04/1600x1278/gallery_nrm_1421677625-most-handsome-man-2.jpg?resize=480:*" />
                                                            </div>
                                                            <div className="details">
                                                                <div className="userName">CA. Vivek Agrawal</div>
                                                                <div className="post">Senior Executive Finance at XYZ</div>
                                                            </div>
                                                        </li>
                                                        <li className="date">
                                                            <span>May 8, 2018</span>
                                                        </li>
                                                        <li className="user">
                                                            <div className="img">
                                                                <img alt="" src="images/user.svg" />
                                                            </div>
                                                            <div className="details">
                                                                <div className="details-header">
                                                                    <span className="userName">John Doe</span>
                                                                    <span className="time">12:50 PM</span>
                                                                </div>
                                                                <div className="message">
                                                                    Congrats on your work anniversary!
                                                        </div>
                                                            </div>
                                                        </li>
                                                        <li className="user">
                                                            <div className="img">
                                                                <img alt="" src="https://hips.hearstapps.com/cosmouk.cdnds.net/15/04/1600x1278/gallery_nrm_1421677625-most-handsome-man-2.jpg?resize=480:*" />
                                                            </div>
                                                            <div className="details">
                                                                <div className="details-header">
                                                                    <span className="userName">CA. Vivek Agrawal</span>
                                                                    <span className="time">2:50 PM</span>
                                                                </div>
                                                                <div className="message">
                                                                    thanks
                                                        </div>
                                                            </div>
                                                        </li>
                                                        <li className="date">
                                                            <span>Oct 1, 2019</span>
                                                        </li>
                                                        <li className="user">
                                                            <div className="img">
                                                                <img alt="" src="images/user.svg" />
                                                            </div>
                                                            <div className="details">
                                                                <div className="details-header">
                                                                    <span className="userName">John Doe</span>
                                                                    <span className="time">2:50 PM</span>
                                                                </div>
                                                                <div className="message">
                                                                    Congrats on the new role!
                                                        </div>
                                                            </div>
                                                        </li>
                                                        <li className="date">
                                                            <span>Oct 2, 2019</span>
                                                        </li>
                                                        <li className="user">
                                                            <div className="img">
                                                                <img alt="" src="https://hips.hearstapps.com/cosmouk.cdnds.net/15/04/1600x1278/gallery_nrm_1421677625-most-handsome-man-2.jpg?resize=480:*" />
                                                            </div>
                                                            <div className="details">
                                                                <div className="details-header">
                                                                    <span className="userName">CA. Vivek Agrawal</span>
                                                                    <span className="time">2:50 PM</span>
                                                                </div>
                                                                <div className="message">
                                                                    thanks
                                                        </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="messageTextBox">
                                                    <div className="messageTextBody">
                                                        <textarea placeholder="Wright a message..."></textarea>
                                                    </div>
                                                    <div className="messageTextBoxFooter">
                                                        <div className="acions">
                                                            <ul>
                                                                <li>
                                                                    <a href="#"><i className="flaticon-picture"></i></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><i className="flaticon-video-player"></i></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <a className="btn btn-success disabled">Send</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 padding-left-0">
                                    <div className="box border">
                                        <div className="box-header padding-right-0">
                                            <div className="title">
                                                <span> Suggested Page </span>
                                            </div>
                                            <div>
                                                <ul className="iconList text-black-50">
                                                    <li>
                                                        <a href="#">
                                                            <i className="flaticon-repeat"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="suggestionPage">
                                                <div className="s-header">
                                                    <span>885 people like this.</span>
                                                    <a href="#"><i className="fa fa-times"></i></a>
                                                </div>
                                                <div className="s-body">
                                                    <div>
                                                        <ul className="userFollowList">
                                                            <li>
                                                                <img alt="" src="images/page_owner.jpg" />
                                                                <div className="info">
                                                                    <h5 className="userName"> WinnerHabits </h5>
                                                                    <div className="subTitle text-black-50 text-sm"> Motivational Speaker </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="page-img">
                                                        <img alt="" src="images/page1.png" />
                                                    </div>
                                                </div>
                                                <div className="s-footer">
                                                    <div className="pretty p-icon p-toggle p-plain">
                                                        <input type="checkbox" />
                                                        <div className="state p-off">
                                                            <i className="icon fa fa-thumbs-o-up "></i>
                                                            <label>Like Page</label>
                                                        </div>
                                                        <div className="state p-on p-primary-o ">
                                                            <i className="icon fa fa-thumbs-up"></i>
                                                            <label>Like Page</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mainFooter">
                                        <ul>
                                            <li>
                                                <a href="#">Privacy</a>
                                            </li>
                                            <li>
                                                <a href="#">Terms</a>
                                            </li>
                                            <li>
                                                <a href="#">Advertising</a>
                                            </li>
                                            <li>
                                                <a href="#">About</a>
                                            </li>
                                            <li>
                                                <a href="#">Help </a>
                                            </li>
                                        </ul>
                                        <div className="copywrite">
                                            comrate © 2019
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        )
    }
}

// export default Messages;
function mapDispatchToProps(dispatch) {
    return {
      userLogoutAction: bindActionCreators(userLogoutAction, dispatch),
    }
  }
  export default connect(
    null,
    mapDispatchToProps
  )(Messages);