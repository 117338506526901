import React from 'react';

class MainHeader extends React.Component {
    render() {
        return (
            <header id="header" className="container-fluid">
                <div className="row h-100">
                    <div className="container d-flex align-items-center">
                        <div className="logo">
                            <a href="userMain.html">
                                <img alt="" src="images/logo/logo.png" />
                            </a>
                        </div>
                        <div className="topMenu">
                            <div className="upperContainer">
                                <ul>
                                    <li>
                                        <div className="searchInput">
                                            <input className="form-control" />
                                            <a href="#" className="icon">
                                                <i className="flaticon-search"></i>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="lowerContainer">
                                <ul>
                                    <li>
                                        <a href="/news-feed" className="btn btn-default" title="Home">
                                            <i className="flaticon-home-2"></i>
                                            <span>Home</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/company-connections" className="btn btn-default" title="Network">
                                            <i className="flaticon-network"></i>
                                            <span>Connections</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/messages" className="btn btn-default" title="Message">
                                            <i className="flaticon-windows-2"></i>
                                            <span>Message</span>
                                        </a>
                                    </li>
                                    <li className="dropdown">
                                        <a href="#" className="btn btn-default" title="Notifications" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="flaticon-alarm"></i>
                                            <span className="badge badge-danger">2</span>
                                            <span>Notifications</span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right customDropdown" aria-labelledby="dropdownMenuLink">
                                            <div className="c-header bg-danger text-white">
                                                <div>
                                                    <span>Notifications</span>
                                                </div>
                                                <div></div>
                                            </div>
                                            <div className="c-body">
                                                <ul>
                                                    <li className="unread">
                                                        <a href="#">
                                                            <div className="img">
                                                                <img alt="" src="http://keenthemes.com/preview/metronic/theme/assets/pages/media/profile/profile_user.jpg" />
                                                                <i className="fa fa-user icon bg-dark"></i>
                                                            </div>
                                                            <div className="details">
                                                                <p><b>John Doe </b> following you.</p>
                                                                <span className="time">4 hour ago</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="unread">
                                                        <a href="#">
                                                            <div className="img">
                                                                <img alt="" src="https://pixinvent.com/materialize-material-design-admin-template/app-assets/images/user/12.jpg" />
                                                                <i className="fa fa-thumbs-up icon bg-primary"></i>
                                                            </div>
                                                            <div className="details">
                                                                <p><b>Brigid Dawson </b> liked <b>your post</b>.</p>
                                                                <span className="time">Tomorrow</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div className="img">
                                                                <img alt="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNkXBRlbMjiVpZv4J2brSHkrn6f2Jg-WKy12YPcjaVjbBu05l7&s" />
                                                                <i className="fa fa-thumbs-up icon bg-primary"></i>
                                                            </div>
                                                            <div className="details">
                                                                <p><b>Tim Hellman</b> liked <b>your post</b>.</p>
                                                                <span className="time">Monday</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <div className="img">
                                                                <img alt="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNkXBRlbMjiVpZv4J2brSHkrn6f2Jg-WKy12YPcjaVjbBu05l7&s" />
                                                                <i className="fa fa-comment icon bg-info"></i>
                                                            </div>
                                                            <div className="details">
                                                                <p><b>Tim Hellman</b> commented on <b>your post</b>.</p>
                                                                <span className="time">4 Day ago</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <a href="#" className="btn btn-default" title="Rating">
                                            <img alt="" src="images/agreement.svg" style={{ width: "26px" }} />
                                            <span>On Board</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="btn btn-default" title="Rating">
                                            <i className="flaticon-star"></i>
                                            <span>Rating</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="btn btn-default" title="Cix" style={{ marginBottom: "-7px" }}>
                                            <img alt="" src="images/icons/cix.png" style={{ width: "18px" }} />
                                            <span>CIX</span>
                                        </a>
                                    </li>
                                    {/* <li className="dropdown">
                                    <button type="button" className="btn userBtn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img alt="" src="https://scontent-hkg3-2.xx.fbcdn.net/v/t1.0-9/26167690_1601887163231736_8146650786613096715_n.png?_nc_cat=1&_nc_oc=AQmwlN7OhbJsULYovoJonuXsnH56WcJY8NYI2-EGZpPYd1ZUNj3xxsf29gfr2K3_-2s&_nc_ht=scontent-hkg3-2.xx&oh=0ca207aa41e463131fa05831021def4e&oe=5DCA655E" className="userImg" />
                                    </button>
                                    <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="company-about.html"> Profile </a>

                                        <a className="dropdown-item" href="company-about-form.html"> Settings </a>
                                        <a className="dropdown-item" href="#"> Logout </a>
                                    </div>
                                </li> */}
                                    <li className="dropdown">
                                        <button type="button" className="btn userBtn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img alt="" src="images/user.svg" />
                                        </button>
                                        <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                                            <a href="/user-dashboard"><span className="dropdown-item act-span"> Profile </span></a>
                                            <span className="dropdown-item act-span"> Company </span>
                                            {/* <span className="dropdown-item act-span"> Blog </span> */}
                                            <span className="dropdown-item act-span" id="logout-btn" onClick={this.logout}> Logout </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default MainHeader;