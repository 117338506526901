import React from 'react';

class LeftPanel extends React.Component {

    handleComponentShow = (value) => {
        this.props.handleComponentShow(value);
    }

    render() {
        return (
            <div className="col-3 leftSide-container">
                <div className="inner">
                    <div className="box  userContainer  company">
                        <div className="header default-bg"></div>
                        <div className="profileBody">
                            <div className="userInfo editProfileImage">
                                <figure>
                                    <img alt="" src="https://scontent-hkg3-2.xx.fbcdn.net/v/t1.0-9/26167690_1601887163231736_8146650786613096715_n.png?_nc_cat=1&_nc_oc=AQmwlN7OhbJsULYovoJonuXsnH56WcJY8NYI2-EGZpPYd1ZUNj3xxsf29gfr2K3_-2s&_nc_ht=scontent-hkg3-2.xx&oh=0ca207aa41e463131fa05831021def4e&oe=5DCA655E" className="userImg" />
                                </figure>
                                <h4 className="userName"><a href="#about" onClick={() => this.handleComponentShow(1)}> Hong Kong Free Press HKFP </a></h4>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-body padding-0">
                            <ul className="nav nav-tabs nav-fill" role="tablist" style={{ background: "#f9f9f9" }}>
                                <li className="nav-item">
                                    <a className="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="true">Comrating</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">Credit</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="false">Sentiment</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="four-tab" data-toggle="tab" href="#four" role="tab" aria-controls="four" aria-selected="false">Supply Chain</a>
                                </li>

                            </ul>
                            <div className="tab-content border" style={{ borderTop: "none", minheight: "250px" }}>
                                <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                                    <div id="radarChartDiv" className="chart"><img alt="" src="images/radar-chart.png" /></div>
                                </div>
                                <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">2</div>
                                <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">3</div>
                                <div className="tab-pane fade" id="four" role="tabpanel" aria-labelledby="four-tab">4</div>
                            </div>
                        </div>
                    </div>
                    <div className="box border">
                        <div className="box-header with-border ">
                            <div className="title">
                                <span> Company Tracker </span>
                            </div>
                            <div className="title">
                                <span>
                                    Credit Trends
                                            </span>
                            </div>
                        </div>
                        <div className="box-body padding-0">
                            <ul className="withIconList list with-border">
                                <li>
                                    <a href="javascripr:;">
                                        <img alt="" className="companyImg" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1024px-Apple_logo_black.svg.png" />
                                        <span>Apple</span>
                                        <i className="trendsIcon fa fa-arrow-up green"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascripr:;">
                                        <img alt="" className="companyImg" src="https://www.carlogos.org/logo/General-Motors-logo-2010-3300x3300.png" />
                                        <span>General Motors</span>
                                        <i className="trendsIcon fa fa-arrow-down red"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascripr:;">
                                        <img alt="" className="companyImg" src="https://1000logos.net/wp-content/uploads/2017/05/Pepsi-Logo.png" />
                                        <span>Papsi</span>
                                        <i className="trendsIcon fa fa-arrow-right orange"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascripr:;">
                                        <img alt="" className="companyImg" src="http://logok.org/wp-content/uploads/2014/06/Microsoft-logo-m-box-880x660.png" />
                                        <span>Microsoft</span>
                                        <i className="trendsIcon fa fa-arrow-up fa-rotate-45 light-green"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascripr:;">
                                        <img alt="" className="companyImg" src="https://cdn.imgbin.com/22/18/24/imgbin-fast-food-mcdonald-s-logo-golden-arches-restaurant-mcdonalds-mcdonald-s-logo-m67i4VFZD6Fp5b0h16emYuc12.jpg" />
                                        <span>McDonald's</span>
                                        <i className="trendsIcon fa fa-arrow-right fa-rotate-45 dark-orange"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftPanel;