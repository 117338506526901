import { combineReducers } from "redux";

import auth from './auth'
import appData from './appData'
import apiData from './apiData'
import loading from './loading'
import error from './error'

const appReducer = combineReducers({ auth, appData, apiData, loading, error })
const rootReducer = (state, action) => {
    //console.log(state, action,'F');
    if (action.type === 'LOGOUT_ACTION_REQUEST') {
      state = {

      };
    }
    //console.log(state, action,'s');
    return appReducer(state, action);
  };
export default rootReducer;
//export default appReducer;
