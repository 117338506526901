import React, { Component } from 'react'
import Helper from '../../../Configs/Helper'
import $ from 'jquery'
import Loader from "../../SubComponents/Loader/Loader";
import Header from "../../SubComponents/Header/Header";
import UserProfileBanner from './UserProfileBanner'
import UserCompanies from './UserCompanies/UserCompanies';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserByAccountIdAction,sentConnectionRequestAction } from '../../../api/agent';
import UserAbout from './UserAbout/UserAbout';
import UserSuggestedCompanies from './UserSuggestedCompanies/UserSuggestedCompanies';
import CompanyConnectionModal from "../../Modals/CompanyConnectionModal/CompanyConnectionModal"
import UserTimeLine from './UserTimeLine/UserTimeLine';
class UserDashboard extends Component {
    constructor(props){
        super(props);
        this.state={
            isRelation: 1,
            selectedCompanyId: '',
            userNavBar:[
                {id:1,tab:'Suggested Companies', url: 'suggested-companies'},
                {id:2,tab:'TimeLine', url: 'timeline'},
                {id:3,tab:'About', url: 'about'},
                {id:4,tab:'Company', url: 'companies'},
            ],
            activeTab: 2
        }
    }
    componentDidMount(){
        //start set active tab
            const paramsTab = this.props.match.params.user_tab
            if(paramsTab!==undefined){
                let filteredArray = this.state.userNavBar.filter((tab)=>{ 
                    if(tab.url === paramsTab) return tab;
                })
                if(filteredArray.length>0){
                    this.setState({ activeTab: filteredArray[0]['id'] })
                }
            }
        //end set active tab
    }
    activeTabHandler = (activeTab) =>{
     this.setState({ activeTab })
    }

/* start company connect functions */
    setSelectedCompId = (comp_id) =>{
        this.setState({ selectedCompanyId: comp_id})
    }
    onChangeHandler = (e) =>{
        this.setState({ isRelation: e.target.value })
    }
    connectionCompanyHandler= async (e)=>{
        const { data } = this.props.userData;
        const ownCompObj = Helper.getCompanyDetailsObj(this.props.ownCompanyDetails);
        const postData={
            requester_id: (ownCompObj.company_id!=='') ? ownCompObj.company_id : data.account_id, //send from
            company_id: this.state.selectedCompanyId, //send to
            request_status: 0,
            request_type: this.state.isRelation,
            user_id: data.account_id,
        }
    
        this.loader.show(true);
        await this.props.sentConnectionRequestAction(postData);
        if(this.props.sentConnReqRes.status===200){
            $( ".close" ).trigger( "click" )
            this.loader.showAlert(true,'success',this.props.sentConnReqRes.message)
        } else {
          this.loader.showAlert(true,'error',this.props.sentConnReqRes.message)
        }
        this.loader.show(false);
      }
/* end company connect functions */
    render() {
        return (
            <>
            <Header {...this.props} headerType="userDashboard"/>
            <main className="mainBody container-fluid">
                <div className="row">
                    <div className="container">
                        <div className="row no-gutters">
                            <UserProfileBanner userNavBar={this.state.userNavBar} activeTab={this.state.activeTab} activeTabHandler={this.activeTabHandler} />
                        </div>
                        {this.state.activeTab === 1 && <UserSuggestedCompanies loader={this.loader} setSelectedCompId={this.setSelectedCompId} {...this.props}/> }
                        {this.state.activeTab === 2 && <UserTimeLine loader={this.loader}  activeTabHandler={this.activeTabHandler} {...this.props}/> }
                        {this.state.activeTab === 3 && <UserAbout /> }
                        {this.state.activeTab === 4 && <UserCompanies loader={this.loader} {...this.props}/> }

                    </div>
                </div>
            </main>
            <CompanyConnectionModal state={this.state} connectionCompanyHandler={this.connectionCompanyHandler} onChangeHandler={this.onChangeHandler} {...this.props}/>
            {/* loader Start */}
            <Loader onRef={ref => {this.loader = ref;}}/>
            {/* loader Start */}
            </>
        )
    }
}
const mapStateToProps = state => ({
    userData: state.auth.userData,
    sentConnReqRes: state.appData.sentConnReqRes,
    ownCompanyDetails: state.appData.ownCompanyDetails,
  });
  
  function mapDispatchToProps(dispatch) {
    return {
     // getUserByAccountIdAction: bindActionCreators(getUserByAccountIdAction, dispatch),
     sentConnectionRequestAction: bindActionCreators(sentConnectionRequestAction, dispatch),
    }
  }
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserDashboard);