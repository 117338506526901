import React from 'react';
import Footer from '../../SubComponents/Footer/Footer';
import { userLogoutAction } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Connections extends React.Component {
    logout = async ()=>{
        await this.props.userLogoutAction();
        this.props.history.push('/');
      }
    render() {
        return (
            <div>
                <header id="header" className="container-fluid">
                    <div className="row h-100">
                        <div className="container d-flex align-items-center">
                            <div className="logo">
                                <a href="userMain.html">
                                    <img alt="" src="images/logo/logo.png" />
                                </a>
                            </div>
                            <div className="topMenu">
                                <div className="upperContainer">
                                    <ul>
                                        <li>
                                            <div className="searchInput">
                                                <input className="form-control" />
                                                <a href="#" className="icon">
                                                    <i className="flaticon-search"></i>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="lowerContainer">
                                    <ul>
                                        <li>
                                            <a href="/news-feed" className="btn btn-default" title="Home">
                                                <i className="flaticon-home-2"></i>
                                                <span>Home</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/company-connections" className="btn btn-default" title="Network">
                                                <i className="flaticon-network"></i>
                                                <span>Connections</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/messages" className="btn btn-default" title="Message">
                                                <i className="flaticon-windows-2"></i>
                                                <span>Message</span>
                                            </a>
                                        </li>
                                        <li className="dropdown">
                                            <a href="#" className="btn btn-default" title="Notifications" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="flaticon-alarm"></i>
                                                <span className="badge badge-danger">2</span>
                                                <span>Notifications</span>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right customDropdown" aria-labelledby="dropdownMenuLink">
                                                <div className="c-header bg-danger text-white">
                                                    <div>
                                                        <span>Notifications</span>
                                                    </div>
                                                    <div></div>
                                                </div>
                                                <div className="c-body">
                                                    <ul>
                                                        <li className="unread">
                                                            <a href="#">
                                                                <div className="img">
                                                                    <img alt="" src="http://keenthemes.com/preview/metronic/theme/assets/pages/media/profile/profile_user.jpg" />
                                                                    <i className="fa fa-user icon bg-dark"></i>
                                                                </div>
                                                                <div className="details">
                                                                    <p><b>John Doe </b> following you.</p>
                                                                    <span className="time">4 hour ago</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li className="unread">
                                                            <a href="#">
                                                                <div className="img">
                                                                    <img alt="" src="https://pixinvent.com/materialize-material-design-admin-template/app-assets/images/user/12.jpg" />
                                                                    <i className="fa fa-thumbs-up icon bg-primary"></i>
                                                                </div>
                                                                <div className="details">
                                                                    <p><b>Brigid Dawson </b> liked <b>your post</b>.</p>
                                                                    <span className="time">Tomorrow</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="img">
                                                                    <img alt="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNkXBRlbMjiVpZv4J2brSHkrn6f2Jg-WKy12YPcjaVjbBu05l7&s" />
                                                                    <i className="fa fa-thumbs-up icon bg-primary"></i>
                                                                </div>
                                                                <div className="details">
                                                                    <p><b>Tim Hellman</b> liked <b>your post</b>.</p>
                                                                    <span className="time">Monday</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="img">
                                                                    <img alt="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNkXBRlbMjiVpZv4J2brSHkrn6f2Jg-WKy12YPcjaVjbBu05l7&s" />
                                                                    <i className="fa fa-comment icon bg-info"></i>
                                                                </div>
                                                                <div className="details">
                                                                    <p><b>Tim Hellman</b> commented on <b>your post</b>.</p>
                                                                    <span className="time">4 Day ago</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </li>
                                        <li>
                                            <a href="#" className="btn btn-default" title="Rating">
                                                <img alt="" src="images/agreement.svg" style={{width:"26px"}} />
                                                <span>On Board</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn btn-default" title="Rating">
                                                <i className="flaticon-star"></i>
                                                <span>Rating</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn btn-default" title="Cix" style={{ marginBottom: "-7px" }}>
                                                <img alt="" src="images/icons/cix.png" style={{ width: "18px" }} />
                                                <span>CIX</span>
                                            </a>
                                        </li>
                                        {/* <li className="dropdown">
                                    <button type="button" className="btn userBtn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img alt="" src="https://scontent-hkg3-2.xx.fbcdn.net/v/t1.0-9/26167690_1601887163231736_8146650786613096715_n.png?_nc_cat=1&_nc_oc=AQmwlN7OhbJsULYovoJonuXsnH56WcJY8NYI2-EGZpPYd1ZUNj3xxsf29gfr2K3_-2s&_nc_ht=scontent-hkg3-2.xx&oh=0ca207aa41e463131fa05831021def4e&oe=5DCA655E" className="userImg" />
                                    </button>
                                    <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="company-about.html"> Profile </a>

                                        <a className="dropdown-item" href="company-about-form.html"> Settings </a>
                                        <a className="dropdown-item" href="#"> Logout </a>
                                    </div>
                                </li> */}
                                        <li className="dropdown">
                                            <button type="button" className="btn userBtn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img alt="" src="images/user.svg" />
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                                            <a href="/user-dashboard"><span className="dropdown-item act-span"> Profile </span></a>
                                                <span className="dropdown-item act-span"> Company </span>
                                                {/* <span className="dropdown-item act-span"> Blog </span> */}
                                                <span className="dropdown-item act-span" id="logout-btn" onClick={this.logout}> Logout </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="mainBody container-fluid">
                    <div className="row">
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-3 leftSide-container">
                                    <div className="inner">
                                        <div className="box  userContainer  company">
                                            <div className="header default-bg"></div>
                                            <div className="profileBody">
                                                <div className="userInfo editProfileImage">
                                                    <figure>
                                                        <img alt="" src="https://scontent-hkg3-2.xx.fbcdn.net/v/t1.0-9/26167690_1601887163231736_8146650786613096715_n.png?_nc_cat=1&_nc_oc=AQmwlN7OhbJsULYovoJonuXsnH56WcJY8NYI2-EGZpPYd1ZUNj3xxsf29gfr2K3_-2s&_nc_ht=scontent-hkg3-2.xx&oh=0ca207aa41e463131fa05831021def4e&oe=5DCA655E" className="userImg" />
                                                    </figure>
                                                    <h4 className="userName"><a href="company-main-page.html"> Hong Kong Free Press HKFP </a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box border" style={{ display: "none" }}>
                                            <div className="box-body padding-0">
                                                <ul className="withIconList list">
                                                    <li>
                                                        <a href="javascripr:;"><i className="flaticon-user"></i> About </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;"><i className="flaticon-windows-2"></i> Discussion </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;"><i className="flaticon-users"></i> Connections </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;"><i className="flaticon-video-player"></i> Video </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;"><i className="flaticon-picture"></i> Photos </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;"><i className="flaticon-file"></i> Files </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="box ">
                                            <div className="box-body padding-0">
                                                <ul className="nav nav-tabs nav-fill" role="tablist" style={{ background: "#f9f9f9" }}>
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="true">Comrating</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">Credit</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="false">Sentiment</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="four-tab" data-toggle="tab" href="#four" role="tab" aria-controls="four" aria-selected="false">Supply Chain</a>
                                                    </li>

                                                </ul>
                                                <div className="tab-content border" style={{ borderTop: "none", minHeight: "250px" }}>
                                                    <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                                                        <div id="radarChartDiv" className="chart"><img alt="" src="images/radar-chart.png" /></div>
                                                    </div>
                                                    <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">2</div>
                                                    <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">3</div>
                                                    <div className="tab-pane fade" id="four" role="tabpanel" aria-labelledby="four-tab">4</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box border">
                                            <div className="box-header with-border ">
                                                <div className="title">
                                                    <span> Company Tracker </span>
                                                </div>
                                                <div className="title">
                                                    <span>
                                                        Credit Trends
                                            </span>
                                                </div>
                                            </div>
                                            <div className="box-body padding-0">
                                                <ul className="withIconList list with-border">
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1024px-Apple_logo_black.svg.png" />
                                                            <span>Apple</span>
                                                            <i className="trendsIcon fa fa-arrow-up green"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="https://www.carlogos.org/logo/General-Motors-logo-2010-3300x3300.png" />
                                                            <span>General Motors</span>
                                                            <i className="trendsIcon fa fa-arrow-down red"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="https://1000logos.net/wp-content/uploads/2017/05/Pepsi-Logo.png" />
                                                            <span>Papsi</span>
                                                            <i className="trendsIcon fa fa-arrow-right orange"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="http://logok.org/wp-content/uploads/2014/06/Microsoft-logo-m-box-880x660.png" />
                                                            <span>Microsoft</span>
                                                            <i className="trendsIcon fa fa-arrow-up fa-rotate-45 light-green"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="https://cdn.imgbin.com/22/18/24/imgbin-fast-food-mcdonald-s-logo-golden-arches-restaurant-mcdonalds-mcdonald-s-logo-m67i4VFZD6Fp5b0h16emYuc12.jpg" />
                                                            <span>McDonald's</span>
                                                            <i className="trendsIcon fa fa-arrow-right fa-rotate-45 dark-orange"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="row " style={{ margin: "0px" }}>
                                        <div className="col" style={{ paddingRight: "0px" }}>
                                            <div className="userMainProfile ">
                                                <div className="profileBgImgConatiner" style={{ background: "url('https://static01.nyt.com/images/2019/06/12/world/cn-12live-hongkong-teargas-copy/cn-12live-hongkong-teargas-copy-videoSixteenByNine3000-v4.jpg')", backgroundPosition: "center" }}></div>
                                                <div className="ProfilenavigationContainer">
                                                    <div className="userImage">
                                                        <ul>
                                                            <li>
                                                                <a href="#" data-toggle="modal" data-target="#followModal">
                                                                    Follow
                                                        </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="navigations">
                                                        <ul>
                                                            <li>
                                                                <a href="/company-about"> About </a>
                                                            </li>
                                                            <li >
                                                                <a href="/company-timeline"> Timeline </a>
                                                            </li>
                                                            <li className="active">
                                                                <a href="/company-connections"> Connections </a>
                                                            </li>
                                                            <li>
                                                                <a href="/company-rating"> Add Rating </a>
                                                            </li>
                                                            <li>
                                                                <a href="/company-review">
                                                                    <div>
                                                                        <span style={{ display: "block", width: "100%", textAlign: "center" }}>
                                                                            <span className="badge badge-secondary">1K</span>
                                                                        </span>
                                                                        Review
                                                            </div>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/company-setting">
                                                                    <i className="fa fa-cogs"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-12 middle-container" style={{ paddingRight: "0px" }}>
                                            <div className="box bg-transparent">

                                                <div className="box-body padding-0">
                                                    <div className="nav nav-pills" id="v-pills-tab" role="tablist" >
                                                        <a className="nav-link active" id="v-pills-member-tab" data-toggle="pill" href="#v-pills-member" role="tab" aria-controls="v-pills-member" aria-selected="true">Connections</a>
                                                        <a className="nav-link" id="v-pills-request-tab" data-toggle="pill" href="#v-pills-request" role="tab" aria-controls="v-pills-request" aria-selected="false">Request</a>
                                                        <a className="nav-link" id="v-pills-send-request-tab" data-toggle="pill" href="#v-pills-send-request" role="tab" aria-controls="v-pills-send-request" aria-selected="false">Send Request</a>
                                                        <a className="nav-link" id="v-pills-block-tab" data-toggle="pill" href="#v-pills-block" role="tab" aria-controls="v-pills-block" aria-selected="false">Block Member</a>
                                                        <a className="nav-link" id="v-pills-search-tab" data-toggle="pill" href="#v-pills-search" role="tab" aria-controls="v-pills-search" aria-selected="false">Search</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box bg-white border blogContainer">
                                                <div className="box-body">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div className="tab-pane fade show active" id="v-pills-member" role="tabpanel" aria-labelledby="v-pills-member-tab">
                                                            <div className="detailsHeader">
                                                                Connections <span>(3)</span>
                                                            </div>
                                                            <table className="customTable">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Sector</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>


                                                                    <tr>
                                                                        <td>
                                                                            <div className="userNameWithImg">
                                                                                <div className="img">
                                                                                    <img alt="" src="https://www.menshairstylesnow.com/wp-content/uploads/2018/03/Hairstyles-for-Oblong-Faces-Fringe-Fade.jpg" />
                                                                                </div>
                                                                                <div className="name">
                                                                                    Comrate
                                                                        </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a href="mailto:ram@gmail.com">info@comrate.com</a>
                                                                        </td>
                                                                        <td>
                                                                            Retail
                                                                </td>
                                                                        <td>
                                                                            <select className="form-control">
                                                                                <option>Connected</option>
                                                                                <option>UnConnected</option>
                                                                                <option>UnFollow</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="userNameWithImg">
                                                                                <div className="img">
                                                                                    <img alt="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbRe9tWxGq53f7ykswRl2bBicNwfhQ-LV96bR-RawPiMlX9mTB" />
                                                                                </div>
                                                                                <div className="name">
                                                                                    Tecore Labs
                                                                        </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a href="mailto:jiya@gmail.com">tecore@gmail.com</a>
                                                                        </td>
                                                                        <td>
                                                                            Software
                                                                </td>
                                                                        <td>
                                                                            <select className="form-control">
                                                                                <option>Connected</option>
                                                                                <option>UnConnected</option>
                                                                                <option>UnFollow</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="userNameWithImg">
                                                                                <div className="img">
                                                                                    <img alt="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsOwXDHPRJpdMZ6-yhf7J20zL78wJww8NmgiVa0zRAIbduzXdY8Q" />
                                                                                </div>
                                                                                <div className="name">
                                                                                    Global Software
                                                                        </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a href="mailto:juliya@gmail.com">gs@gmail.com</a>
                                                                        </td>
                                                                        <td>
                                                                            IT
                                                                </td>
                                                                        <td>
                                                                            <select className="form-control">
                                                                                <option>Connected</option>
                                                                                <option>UnConnected</option>
                                                                                <option>UnFollow</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="tab-pane fade" id="v-pills-request" role="tabpanel" aria-labelledby="v-pills-request-tab">
                                                            <div className="detailsHeader">
                                                                Request <span>(1)</span>
                                                            </div>
                                                            <table className="customTable">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Position</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr>
                                                                        <td>
                                                                            <div className="userNameWithImg">
                                                                                <div className="img">
                                                                                    <img alt="" src="https://i.pinimg.com/originals/32/6a/26/326a26c22b8dd884aee36701e54d1eba.jpg" />
                                                                                </div>
                                                                                <div className="name">
                                                                                    John Black
                                                                        </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a href="mailto:john@gmail.com">john@gmail.com</a>
                                                                        </td>
                                                                        <td>
                                                                            xyz
                                                                </td>
                                                                        <td className="text-right">
                                                                            <a href="#" className="btn btn-sm btn-success"><i className="fa fa-check"></i></a>
                                                                            <a href="#" className="btn btn-sm btn-danger"><i className="fa fa-times"></i></a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="tab-pane fade" id="v-pills-send-request" role="tabpanel" aria-labelledby="v-pills-send-request-tab">
                                                            <div className="detailsHeader">
                                                                Request <span>(1)</span>
                                                            </div>
                                                            <table className="customTable">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Position</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="userNameWithImg">
                                                                                <div className="img">
                                                                                    <img alt="" src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/shutterstock-editorial-9885692z-hugemod-1540225911.jpg?crop=0.502xw:1.00xh;0.236xw,0&resize=480:*" />
                                                                                </div>
                                                                                <div className="name">
                                                                                    Smith
                                                                        </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a href="mailto:smith@gmail.com">smith@gmail.com</a>
                                                                        </td>
                                                                        <td>
                                                                            xyz
                                                                </td>
                                                                        <td className="text-right">
                                                                            <a href="#" className="btn btn-sm btn-danger">Cancel</a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="tab-pane fade" id="v-pills-block" role="tabpanel" aria-labelledby="v-pills-block-tab">
                                                            <div className="detailsHeader">
                                                                Block <span>(1)</span>
                                                            </div>
                                                            <table className="customTable">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Position</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="userNameWithImg">
                                                                                <div className="img">
                                                                                    <img alt="" src="https://www.menshairstylestoday.com/wp-content/uploads/2018/10/Best-Haircut-For-Oblong-Face-Men.jpg" />
                                                                                </div>
                                                                                <div className="name">
                                                                                    John Doe
                                                                        </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a href="mailto:john@gmail.com">john@gmail.com</a>
                                                                        </td>
                                                                        <td>
                                                                            xyz
                                                                </td>
                                                                        <td>
                                                                            <select className="form-control">
                                                                                <option>Active</option>
                                                                                <option>Remove</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="tab-pane fade" id="v-pills-search" role="tabpanel" aria-labelledby="v-pills-search-tab">
                                                            <div className="" style={{ marginBottom: "15px" }}>
                                                                <div className="searchInput" style={{ width: "100%" }}>
                                                                    <input className="form-control" />
                                                                    <a href="#" className="icon">
                                                                        <i className="flaticon-search"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="detailsHeader">
                                                                Result <span>(2)</span>
                                                            </div>
                                                            <div>
                                                                <ul className="deailsList pageList">
                                                                    <li>
                                                                        <div>
                                                                            <div className="img">
                                                                                <a href="#">
                                                                                    <img alt="" src="https://scontent-hkg3-2.xx.fbcdn.net/v/t1.0-9/26167690_1601887163231736_8146650786613096715_n.png?_nc_cat=1&_nc_oc=AQmwlN7OhbJsULYovoJonuXsnH56WcJY8NYI2-EGZpPYd1ZUNj3xxsf29gfr2K3_-2s&_nc_ht=scontent-hkg3-2.xx&oh=0ca207aa41e463131fa05831021def4e&oe=5DCA655E" />
                                                                                </a>
                                                                            </div>
                                                                            <div className="details">
                                                                                <h4><a href="#">Company Name</a></h4>
                                                                                <h6 className="subTitle"> Fusce pellentesque sit amet risus in aliquam.</h6>
                                                                                <p>
                                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at elit nec nibh vestibulum lobortis. Etiam at massa est. Etiam et rutrum dolor. Mauris aliquam tortor quam, et tempus elit dictum non. Aenean laoreet sodales dolor sed aliquet. Vivamus eu leo enim. Nunc suscipit nunc ut sem iaculis posuere.
                                                                        </p>
                                                                            </div>
                                                                            <div className="actions">
                                                                                <ul>
                                                                                    <li className="dropdown">
                                                                                        <a className="btn btn-xs" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            Option
                                                                                </a>
                                                                                        <div className="dropdown-menu" >
                                                                                            <a className="dropdown-item" href="#">Send Request</a>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div>
                                                                            <div className="img">
                                                                                <a href="#">
                                                                                    <img alt="" src="https://marketplace.canva.com/MADOPuRUFPM/1/0/thumbnail_large-1/canva-yellow-square-real-estate-logo-MADOPuRUFPM.jpg" />
                                                                                </a>
                                                                            </div>
                                                                            <div className="details">
                                                                                <h4><a href="#">Company Name</a></h4>
                                                                                <h6 className="subTitle"> Fusce pellentesque sit amet risus in aliquam.</h6>
                                                                                <p>
                                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at elit nec nibh vestibulum lobortis. Etiam at massa est. Etiam et rutrum dolor. Mauris aliquam tortor quam, et tempus elit dictum non. Aenean laoreet sodales dolor sed aliquet. Vivamus eu leo enim. Nunc suscipit nunc ut sem iaculis posuere.
                                                                        </p>
                                                                            </div>
                                                                            <div className="actions">
                                                                                <ul>
                                                                                    <li className="dropdown">
                                                                                        <a className="btn btn-xs" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            Option
                                                                                </a>
                                                                                        <div className="dropdown-menu" >
                                                                                            <a className="dropdown-item" href="#">Send Request</a>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        )
    }
}

// export default Connections;
function mapDispatchToProps(dispatch) {
    return {
      userLogoutAction: bindActionCreators(userLogoutAction, dispatch),
    }
  }
  export default connect(
    null,
    mapDispatchToProps
  )(Connections);