import * as types from './types'
import * as Api from '../../api/agent'
import { dispatchAction } from '../../utils/HelperFunc';

export const setSelectedOwnCompanyInfoAction = (data) => { 
    return async dispatch => {
        function setSelectedOwnCompanyInfoActionSuccess(payload) {
            return dispatch({
                type: types.SET_OWN_COMPANY_ACTION_SUCCESS,
                payload: payload
            })
        }

        function setSelectedOwnCompanyInfoActionFail(error) {
            return dispatch({
                type: types.SET_OWN_COMPANY_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.SET_OWN_COMPANY_ACTION_REQUEST })
            const payload = data
            dispatchAction(
                payload,
                setSelectedOwnCompanyInfoActionSuccess,
                setSelectedOwnCompanyInfoActionFail
            )
        } catch (error) {
            dispatch(setSelectedOwnCompanyInfoActionFail(error))
        }
    }
}
export const setSelectedOtherCompanyInfoAction = (data) => { 
    return async dispatch => {
        function setSelectedOtherCompanyInfoActionSuccess(payload) {
            return dispatch({
                type: types.SET_OTHER_COMPANY_ACTION_SUCCESS,
                payload: payload
            })
        }

        function setSelectedOtherCompanyInfoActionFail(error) {
            return dispatch({
                type: types.SET_OTHER_COMPANY_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.SET_OTHER_COMPANY_ACTION_REQUEST })
            const payload = data
            dispatchAction(
                payload,
                setSelectedOtherCompanyInfoActionSuccess,
                setSelectedOtherCompanyInfoActionFail
            )
        } catch (error) {
            dispatch(setSelectedOtherCompanyInfoActionFail(error))
        }
    }
}

export const addNewPostAction = (data,formData) => { 
   
    return async dispatch => {
        function addNewPostActionSuccess(payload) {
            return dispatch({
                type: types.ADD_NEW_POST_ACTION_SUCCESS,
                payload: payload
            })
        }

        function addNewPostActionFail(error) {
            return dispatch({
                type: types.ADD_NEW_POST_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.ADD_NEW_POST_ACTION_REQUEST })
            const payload = await Api.addNewPostAction(data,formData,'newAddedPostData')
            dispatchAction(
                payload.newAddedPostData,
                addNewPostActionSuccess,
                addNewPostActionFail
            )
        } catch (error) {
            dispatch(addNewPostActionFail(error))
        }
    }
}
export const editPostAction = (data,formData) => { 
   
    return async dispatch => {
        function editPostActionSuccess(payload) {
            return dispatch({
                type: types.EDIT_POST_ACTION_SUCCESS,
                payload: payload
            })
        }

        function editPostActionFail(error) {
            return dispatch({
                type: types.EDIT_POST_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.EDIT_POST_ACTION_REQUEST })
            const payload = await Api.editPostAction(data,formData,'editedPostData')
            dispatchAction(
                payload.editedPostData,
                editPostActionSuccess,
                editPostActionFail
            )
        } catch (error) {
            dispatch(editPostActionFail(error))
        }
    }
}
export const deletePostAction = (data) => { 
    return async dispatch => {
        function deletePostActionSuccess(payload) {
            return dispatch({
                type: types.DELETE_POST_ACTION_SUCCESS,
                payload: payload
            })
        }

        function deletePostActionFail(error) {
            return dispatch({
                type: types.DELETE_POST_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.DELETE_POST_ACTION_REQUEST })
            const payload = await Api.deletePostAction(data,'deletedPostData')
            dispatchAction(
                payload.deletedPostData,
                deletePostActionSuccess,
                deletePostActionFail
            )
        } catch (error) {
            dispatch(deletePostActionFail(error))
        }
    }
}

export const getPostListAction = (data) => { 
    return async dispatch => {
        function getPostListActionSuccess(payload) {
            return dispatch({
                type: types.GET_POST_LIST_ACTION_SUCCESS,
                payload: payload
            })
        }

        function getPostListActionFail(error) {
            return dispatch({
                type: types.GET_POST_LIST_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_POST_LIST_ACTION_REQUEST })
            const payload = await Api.getPostListAction(data,'postListData')
            dispatchAction(
                payload.postListData,
                getPostListActionSuccess,
                getPostListActionFail
            )
        } catch (error) {
            dispatch(getPostListActionFail(error))
        }
    }
}

export const getPostCommentsAction = (data) => { 
    return async dispatch => {
        function getPostCommentsActionSuccess(payload) {
            return dispatch({
                type: types.GET_POST_COMMENTS_ACTION_SUCCESS,
                payload: payload
            })
        }

        function getPostCommentsActionFail(error) {
            return dispatch({
                type: types.GET_POST_COMMENTS_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_POST_COMMENTS_ACTION_REQUEST })
            const payload = await Api.getPostCommentsAction(data,'postComments')
            dispatchAction(
                payload.postComments,
                getPostCommentsActionSuccess,
                getPostCommentsActionFail
            )
        } catch (error) {
            dispatch(getPostCommentsActionFail(error))
        }
    }
}

export const likeDislikePostAction = (data) => { 
   
    return async dispatch => {
        function likeDislikePostActionSuccess(payload) {
            return dispatch({
                type: types.POST_LIKE_DISLIKE_ACTION_SUCCESS,
                payload: payload
            })
        }

        function likeDislikePostActionFail(error) {
            return dispatch({
                type: types.POST_LIKE_DISLIKE_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.POST_LIKE_DISLIKE_ACTION_REQUEST })
            const payload = await Api.likeDislikePostAction(data,'likeDislikePostData')
            dispatchAction(
                payload.likeDislikePostData,
                likeDislikePostActionSuccess,
                likeDislikePostActionFail
            )
        } catch (error) {
            dispatch(likeDislikePostActionFail(error))
        }
    }
}

export const addPostCommentAction = (data) => { 
    return async dispatch => {
        function addPostCommentActionSuccess(payload) {
            return dispatch({
                type: types.ADD_POST_COMMENT_ACTION_SUCCESS,
                payload: payload
            })
        }

        function addPostCommentActionFail(error) {
            return dispatch({
                type: types.ADD_POST_COMMENT_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.ADD_POST_COMMENT_ACTION_REQUEST })
            const payload = await Api.addPostCommentAction(data,'newAddedPostComment')
            dispatchAction(
                payload.newAddedPostComment,
                addPostCommentActionSuccess,
                addPostCommentActionFail
            )
        } catch (error) {
            dispatch(addPostCommentActionFail(error))
        }
    }
}

export const getCompanyDetailsByIdAction = (data) => { 
    return async dispatch => {
        function getCompanyDetailsByIdActionSuccess(payload) {
            return dispatch({
                type: types.GET_COMPANY_DETAILS_ACTION_SUCCESS,
                payload: payload
            })
        }

        function getCompanyDetailsByIdActionFail(error) {
            return dispatch({
                type: types.GET_COMPANY_DETAILS_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_COMPANY_DETAILS_ACTION_REQUEST })
            const payload = await Api.getCompanyDetailsByIdAction(data,'companyDetails')
            dispatchAction(
                payload.companyDetails,
                getCompanyDetailsByIdActionSuccess,
                getCompanyDetailsByIdActionFail
            )
        } catch (error) {
            dispatch(getCompanyDetailsByIdActionFail(error))
        }
    }
}

export const getCompanyListAction = (data) => { 
    return async dispatch => {
        function getCompanyListActionSuccess(payload) {
            return dispatch({
                type: types.GET_COMPANY_LIST_ACTION_SUCCESS,
                payload: payload
            })
        }

        function getCompanyListActionFail(error) {
            return dispatch({
                type: types.GET_COMPANY_LIST_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_COMPANY_LIST_ACTION_REQUEST })
            const payload = await Api.getCompanyListAction(data,'companyList')
            dispatchAction(
                payload.companyList,
                getCompanyListActionSuccess,
                getCompanyListActionFail
            )
        } catch (error) {
            dispatch(getCompanyListActionFail(error))
        }
    }
}

export const companyFollowAction = (data) => { 
    return async dispatch => {
        function companyFollowActionSuccess(payload) {
            return dispatch({
                type: types.FOLLOW_COMPANY_ACTION_SUCCESS,
                payload: payload
            })
        }

        function companyFollowActionFail(error) {
            return dispatch({
                type: types.FOLLOW_COMPANY_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.FOLLOW_COMPANY_ACTION_REQUEST })
            const payload = await Api.companyFollowAction(data,'companyList')
            dispatchAction(
                payload.companyList,
                companyFollowActionSuccess,
                companyFollowActionFail
            )
        } catch (error) {
            dispatch(companyFollowActionFail(error))
        }
    }
}
export const getFollowedCompanyPostAction = (data) => { 
    return async dispatch => {
        function getFollowedCompanyPostActionSuccess(payload) {
            return dispatch({
                type: types.GET_FOLLOWED_COMPANY_POST_ACTION_SUCCESS,
                payload: payload
            })
        }

        function getFollowedCompanyPostActionFail(error) {
            return dispatch({
                type: types.GET_FOLLOWED_COMPANY_POST_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_FOLLOWED_COMPANY_POST_ACTION_REQUEST })
            const payload = await Api.getFollowedCompanyPostAction(data,'followedCompanyPostList')
            dispatchAction(
                payload.followedCompanyPostList,
                getFollowedCompanyPostActionSuccess,
                getFollowedCompanyPostActionFail
            )
        } catch (error) {
            dispatch(getFollowedCompanyPostActionFail(error))
        }
    }
}
export const getCompanySuggestionAction = (data) => { 
    return async dispatch => {
        function getCompanySuggestionActionSuccess(payload) {
            return dispatch({
                type: types.GET_COMPANY_SUGGESTION_ACTION_SUCCESS,
                payload: payload
            })
        }

        function getCompanySuggestionActionFail(error) {
            return dispatch({
                type: types.GET_COMPANY_SUGGESTION_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_COMPANY_SUGGESTION_ACTION_REQUEST })
            const payload = await Api.getCompanySuggestionAction(data,'suggestedCompanies')
            dispatchAction(
                payload.suggestedCompanies,
                getCompanySuggestionActionSuccess,
                getCompanySuggestionActionFail
            )
        } catch (error) {
            dispatch(getCompanySuggestionActionFail(error))
        }
    }
}
export const sentConnectionRequestAction = (data) => { 
    return async dispatch => {
        function sentConnectionRequestActionSuccess(payload) {
            return dispatch({
                type: types.SENT_CONNECTION_REQ_ACTION_SUCCESS,
                payload: payload
            })
        }

        function sentConnectionRequestActionFail(error) {
            return dispatch({
                type: types.SENT_CONNECTION_REQ_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.SENT_CONNECTION_REQ_ACTION_REQUEST })
            const payload = await Api.sentConnectionRequestAction(data,'data')
            dispatchAction(
                payload.data,
                sentConnectionRequestActionSuccess,
                sentConnectionRequestActionFail
            )
        } catch (error) {
            dispatch(sentConnectionRequestActionFail(error))
        }
    }
}
export const companyBlockAction = (data) => { 
    return async dispatch => {
        function companyBlockActionSuccess(payload) {
            return dispatch({
                type: types.COMPANY_BLOCK_ACTION_SUCCESS,
                payload: payload
            })
        }

        function companyBlockActionFail(error) {
            return dispatch({
                type: types.COMPANY_BLOCK_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.COMPANY_BLOCK_ACTION_REQUEST })
            const payload = await Api.companyBlockAction(data,'data')
            dispatchAction(
                payload.data,
                companyBlockActionSuccess,
                companyBlockActionFail
            )
        } catch (error) {
            dispatch(companyBlockActionFail(error))
        }
    }
}
export const companyUnBlockAction = (data) => { 
    return async dispatch => {
        function companyUnBlockActionSuccess(payload) {
            return dispatch({
                type: types.COMPANY_UNBLOCK_ACTION_SUCCESS,
                payload: payload
            })
        }

        function companyUnBlockActionFail(error) {
            return dispatch({
                type: types.COMPANY_UNBLOCK_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.COMPANY_UNBLOCK_ACTION_REQUEST })
            const payload = await Api.companyUnBlockAction(data,'data')
            dispatchAction(
                payload.data,
                companyUnBlockActionSuccess,
                companyUnBlockActionFail
            )
        } catch (error) {
            dispatch(companyUnBlockActionFail(error))
        }
    }
}