import React, { Component } from 'react'
import { Link } from "react-router-dom";
export default class LeftSection extends Component {
    render() {
        return (
            <>
                <div className="inner">
                    <div className="box border">
                        <div className="box-body padding-0">
                            <ul className="withIconList list">
                                <li>
                                    <Link to="/create-company"><i className="flaticon-add-3"></i> Create Company </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
