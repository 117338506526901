import React,{Component} from 'react';
import {getConnectionSuggestionCompanies,setSelectedOtherCompanyInfoAction, getCompanyDetailsByIdAction, companyFollowAction } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Helper from '../../../Configs/Helper';
import Constants from '../../../utils/Constants';
import { Link } from "react-router-dom";



 class CompanyYouMayKnow extends Component{
    constructor(props) {
        super(props);
        this.state = {
           companies: [],
           companyId: ''
        };
      }
      async componentDidMount(){
        const companyId =  await localStorage.getItem('COMPANY_ID');
        if(companyId!=='' && companyId!==undefined && companyId!==null){
            this.setState({ companyId })
        }
        this.getCompanyList()
    
      }

      getCompanyList = async () =>{
        let paramsData = {
          company_id :this.props.userData.data.account_id,
          is_by_suggestion:1
        };
        
      await this.props.getConnectionSuggestionCompanies(paramsData);  
      if(this.props.conSuggestionCompanies!==null){      
      if(this.props.conSuggestionCompanies.status===200){
          const { data } = this.props.conSuggestionCompanies
          this.setState({ companies: (data.length>0) ? data : [] })
      }} else {
          // this.loader.showAlert(true,'error',this.props.companyList.message)
      } 
  }



    goToCompanyTimeline = company_id => async (e) =>{
        this.props.loader.show(true);

        //set default company Id
        Helper.setDefaultCompany(company_id);

        // get selected company details
        const paramsData = {
            account_id:this.props.userData.data.account_id,
            requester_id: Helper.getDefaultCompanyId(),
            company_id:company_id,
        }
        await this.props.getCompanyDetailsByIdAction(paramsData);
        if(this.props.companyDetails.status===200){
            await this.props.setSelectedOtherCompanyInfoAction(this.props.companyDetails);
            this.props.history.push('/company-timeline')
        } else {
            this.props.loader.showAlert(true,'error',this.props.companyDetails.message)
        }
        this.props.loader.show(false);
    }

    followCompanyHandler = company_id => async (e) =>{
        this.props.loader.show(true);
        const ownCompObj = Helper.getCompanyDetailsObj(this.props.ownCompanyDetails);
        const paramsData = {
            follower_id: (ownCompObj.company_id!=='') ? ownCompObj.company_id : this.props.userData.data.account_id, //company or user id
            following_id: company_id, //always company id
            follower_type: (ownCompObj.company_id!=='') ? 2 : 1,
            status: 1,
            is_connected: 0,
            user_id: this.props.userData.data.account_id,
        };
        
        await this.props.companyFollowAction(paramsData);
        if(this.props.companyFollowRes.status===200){
            this.props.loader.showAlert(true,'success',this.props.companyFollowRes.message)
        } else {
            if(this.props.companyFollowRes.message_code===24) this.props.loader.showAlert(true,'warning',this.props.companyFollowRes.message)
            else this.props.loader.showAlert(true,'error',this.props.companyFollowRes.message)
        }
        this.props.loader.show(false);
    }
     
    render(){
        return(
                   
            <div className="box border">
                 {this.state.companies.length>0 ? 
                    <>
                    <div className="box-header padding-right-0">
                        <div className="title">
                            <span> Company You May Know </span>
                        </div>
                        <div>
                            <ul className="iconList text-black-50">
                                <li>
                                    <a href="#">
                                        <i className="flaticon-repeat"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="box-body">
                        <CompaniesList state={this.state} goToCompanyTimeline={this.goToCompanyTimeline} followCompanyHandler={this.followCompanyHandler} {...this.props}/>
                    </div>
                    <div className="box-footer bg-light border-top padding-5 text-sm text-black-50 ">
                    {this.state.companies.length>5 ? 
                    <Link to="/company-timeline/connection-suggested-companies" className="text-bold "> View All </Link> 
                        :''}
                    </div>
                    </>
                     :''}
                </div>
               
        )
    }
}

function CompaniesList(props){
    let list = [];
   
      if(props.state.companies.length>0){
        list = props.state.companies.map((val,idx)=>{
            let companyLogo = Constants.defaultImageUrls.companyLogo;
            let compId = val.id;
            if(val.company_logo!==null){
                companyLogo = process.env.REACT_APP_BUCKET_PATH+'/'+val.company_logo.image_path;
            }
            return (<li key={idx}>
                <img src={companyLogo}/>
                <div className="info">
                    <h5 className="userName"> <a href="#" onClick={props.goToCompanyTimeline(compId)}>{val.company_name}</a> </h5>
                    <div className="subTitle text-black-50 text-sm">{val.company_sub_title} </div>
                    <h6 className="subTitle"><b>Type:</b> {val.company_type} | <b>Industry:</b> {val.industry_type}</h6>
                    <p>{val.description}</p>
                </div>
    
                <div className="actionBtns">
                    <a href="#" className="btn btn-sm btn-outline-primary followBtn" onClick={props.followCompanyHandler(compId)}><i className="fa fa-user-plus"></i> Follow </a>
                    <a href="#" className="btn btn-sm btn-outline-primary">Remove</a>
                </div>
            </li>)
        })
   
}
        return <ul className="userFollowList">{list}</ul>
    }

const mapStateToProps = state => ({
    userData: state.auth.userData,
    conSuggestionCompanies: state.apiData.conSuggestionCompanies,
    companyDetails: state.appData.companyDetails,
  });
  function mapDispatchToProps(dispatch) {
    return {
        getConnectionSuggestionCompanies: bindActionCreators(getConnectionSuggestionCompanies, dispatch),
        getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
        setSelectedOtherCompanyInfoAction: bindActionCreators(setSelectedOtherCompanyInfoAction, dispatch),
        companyFollowAction: bindActionCreators(companyFollowAction, dispatch),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyYouMayKnow);