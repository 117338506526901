import React from 'react';
import Footer from '../../SubComponents/Footer/Footer';
import { userLogoutAction } from "../../../store/actions";
import { connect } from "react-redux";
import StarRatingComponent from 'react-star-rating-component';
import { bindActionCreators } from "redux";

class CompanyReview extends React.Component {

    logout = async ()=>{
        await this.props.userLogoutAction();
        this.props.history.push('/');
      }
      constructor(props){
          super(props);
          this.state={
            rating: 1,
          }
      }
    render() {
        const { rating } = this.state;
   
        return (
            <div>
                <header id="header" className="container-fluid">
                    <div className="row h-100">
                        <div className="container d-flex align-items-center">
                            <div className="logo">
                                <a href="userMain.html">
                                    <img alt="" src="images/logo/logo.png" />
                                </a>
                            </div>
                            <div className="topMenu">
                                <div className="upperContainer">
                                    <ul>
                                        <li>
                                            <div className="searchInput">
                                                <input className="form-control" />
                                                <a href="#" className="icon">
                                                    <i className="flaticon-search"></i>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="lowerContainer">
                                    <ul>
                                        <li>
                                            <a href="/news-feed" className="btn btn-default" title="Home">
                                                <i className="flaticon-home-2"></i>
                                                <span>Home</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/company-connections" className="btn btn-default" title="Network">
                                                <i className="flaticon-network"></i>
                                                <span>Connections</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/messages" className="btn btn-default" title="Message">
                                                <i className="flaticon-windows-2"></i>
                                                <span>Message</span>
                                            </a>
                                        </li>
                                        <li className="dropdown">
                                            <a href="#" className="btn btn-default" title="Notifications" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="flaticon-alarm"></i>
                                                <span className="badge badge-danger">2</span>
                                                <span>Notifications</span>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right customDropdown" aria-labelledby="dropdownMenuLink">
                                                <div className="c-header bg-danger text-white">
                                                    <div>
                                                        <span>Notifications</span>
                                                    </div>
                                                    <div></div>
                                                </div>
                                                <div className="c-body">
                                                    <ul>
                                                        <li className="unread">
                                                            <a href="#">
                                                                <div className="img">
                                                                    <img alt="" src="http://keenthemes.com/preview/metronic/theme/assets/pages/media/profile/profile_user.jpg" />
                                                                    <i className="fa fa-user icon bg-dark"></i>
                                                                </div>
                                                                <div className="details">
                                                                    <p><b>John Doe </b> following you.</p>
                                                                    <span className="time">4 hour ago</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li className="unread">
                                                            <a href="#">
                                                                <div className="img">
                                                                    <img alt="" src="https://pixinvent.com/materialize-material-design-admin-template/app-assets/images/user/12.jpg" />
                                                                    <i className="fa fa-thumbs-up icon bg-primary"></i>
                                                                </div>
                                                                <div className="details">
                                                                    <p><b>Brigid Dawson </b> liked <b>your post</b>.</p>
                                                                    <span className="time">Tomorrow</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="img">
                                                                    <img alt="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNkXBRlbMjiVpZv4J2brSHkrn6f2Jg-WKy12YPcjaVjbBu05l7&s" />
                                                                    <i className="fa fa-thumbs-up icon bg-primary"></i>
                                                                </div>
                                                                <div className="details">
                                                                    <p><b>Tim Hellman</b> liked <b>your post</b>.</p>
                                                                    <span className="time">Monday</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="img">
                                                                    <img alt="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNkXBRlbMjiVpZv4J2brSHkrn6f2Jg-WKy12YPcjaVjbBu05l7&s" />
                                                                    <i className="fa fa-comment icon bg-info"></i>
                                                                </div>
                                                                <div className="details">
                                                                    <p><b>Tim Hellman</b> commented on <b>your post</b>.</p>
                                                                    <span className="time">4 Day ago</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </li>
                                        <li>
                                            <a href="#" className="btn btn-default" title="Rating">
                                                <img alt="" src="images/agreement.svg" style={{width:"26px"}} />
                                                <span>On Board</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn btn-default" title="Rating">
                                                <i className="flaticon-star"></i>
                                                <span>Rating</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn btn-default" title="Cix" style={{ marginBottom: "-7px" }}>
                                                <img alt="" src="images/icons/cix.png" style={{ width: "18px" }} />
                                                <span>CIX</span>
                                            </a>
                                        </li>
                                        {/* <li className="dropdown">
                                    <button type="button" className="btn userBtn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img alt="" src="https://scontent-hkg3-2.xx.fbcdn.net/v/t1.0-9/26167690_1601887163231736_8146650786613096715_n.png?_nc_cat=1&_nc_oc=AQmwlN7OhbJsULYovoJonuXsnH56WcJY8NYI2-EGZpPYd1ZUNj3xxsf29gfr2K3_-2s&_nc_ht=scontent-hkg3-2.xx&oh=0ca207aa41e463131fa05831021def4e&oe=5DCA655E" className="userImg" />
                                    </button>
                                    <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="company-about.html"> Profile </a>

                                        <a className="dropdown-item" href="company-about-form.html"> Settings </a>
                                        <a className="dropdown-item" href="#"> Logout </a>
                                    </div>
                                </li> */}
                                        <li className="dropdown">
                                            <button type="button" className="btn userBtn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img alt="" src="images/user.svg" />
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                                                <a href="/user-dashboard"><span className="dropdown-item act-span"> Profile </span></a>
                                                <span className="dropdown-item act-span"> Company </span>
                                                {/* <span className="dropdown-item act-span"> Blog </span> */}
                                                <span className="dropdown-item act-span" id="logout-btn" onClick={this.logout}> Logout </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="mainBody container-fluid">
                    <div className="row">
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-3 leftSide-container">
                                    <div className="inner ">
                                        <div className="box  userContainer  company">
                                            <div className="header default-bg"></div>
                                            <div className="profileBody">
                                                <div className="userInfo editProfileImage">
                                                    <figure>
                                                        <img alt="" id="profileImage" src="https://scontent-hkg3-2.xx.fbcdn.net/v/t1.0-9/26167690_1601887163231736_8146650786613096715_n.png?_nc_cat=1&_nc_oc=AQmwlN7OhbJsULYovoJonuXsnH56WcJY8NYI2-EGZpPYd1ZUNj3xxsf29gfr2K3_-2s&_nc_ht=scontent-hkg3-2.xx&oh=0ca207aa41e463131fa05831021def4e&oe=5DCA655E" className="userImg" />
                                                    </figure>
                                                    <h4 className="userName"><a href="company-main-page.html"> Hong Kong Free Press HKFP </a></h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="box">
                                            <div className="box-body padding-0">
                                                <ul className="nav nav-tabs nav-fill" role="tablist" style={{ background: "#f9f9f9" }}>
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="true">Comrating</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">Credit</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="false">Sentiment</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="four-tab" data-toggle="tab" href="#four" role="tab" aria-controls="four" aria-selected="false">Supply Chain</a>
                                                    </li>

                                                </ul>
                                                <div className="tab-content border" style={{ borderTop: "none", minHeight: "250px" }}>
                                                    <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                                                        <div id="radarChartDiv" className="chart"><img alt="" src="images/radar-chart.png" /></div>
                                                    </div>
                                                    <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">2</div>
                                                    <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">3</div>
                                                    <div className="tab-pane fade" id="four" role="tabpanel" aria-labelledby="four-tab">4</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box border">
                                            <div className="box-header with-border ">
                                                <div className="title">
                                                    <span> Company Tracker </span>
                                                </div>
                                                <div className="title">
                                                    <span>
                                                        Credit Trends
                                            </span>
                                                </div>
                                            </div>
                                            <div className="box-body padding-0">
                                                <ul className="withIconList list with-border">
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1024px-Apple_logo_black.svg.png" />
                                                            <span>Apple</span>
                                                            <i className="trendsIcon fa fa-arrow-up green"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="https://www.carlogos.org/logo/General-Motors-logo-2010-3300x3300.png" />
                                                            <span>General Motors</span>
                                                            <i className="trendsIcon fa fa-arrow-down red"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="https://1000logos.net/wp-content/uploads/2017/05/Pepsi-Logo.png" />
                                                            <span>Papsi</span>
                                                            <i className="trendsIcon fa fa-arrow-right orange"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="http://logok.org/wp-content/uploads/2014/06/Microsoft-logo-m-box-880x660.png" />
                                                            <span>Microsoft</span>
                                                            <i className="trendsIcon fa fa-arrow-up fa-rotate-45 light-green"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascripr:;">
                                                            <img alt="" className="companyImg" src="https://cdn.imgbin.com/22/18/24/imgbin-fast-food-mcdonald-s-logo-golden-arches-restaurant-mcdonalds-mcdonald-s-logo-m67i4VFZD6Fp5b0h16emYuc12.jpg" />
                                                            <span>McDonald's</span>
                                                            <i className="trendsIcon fa fa-arrow-right fa-rotate-45 dark-orange"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="row " style={{ margin: "0px" }}>
                                        <div className="col" style={{ paddingRight: "0px" }}>
                                            <div className="userMainProfile ">
                                                <div className="profileBgImgConatiner" style={{ background: "url('https://cdn.cnn.com/cnnnext/dam/assets/190809150655-hong-kong-airport-protest-01-exlarge-169.jpg')" }}>
                                                </div>
                                                <div className="ProfilenavigationContainer">
                                                    <div className="userImage"></div>
                                                    <div className="navigations">
                                                        <ul>
                                                            <li>
                                                                <a href="/company-about"> About </a>
                                                            </li>
                                                            <li >
                                                                <a href="/company-timeline"> Timeline </a>
                                                            </li>
                                                            <li>
                                                                <a href="/company-connections"> Connections </a>
                                                            </li>
                                                            <li>
                                                                <a href="/company-rating"> Add Rating </a>
                                                            </li>
                                                            <li className="active">
                                                                <a href="/company-review">
                                                                    <div>
                                                                        <span style={{ display: "block", width: "100%", textAlign: "center" }}>
                                                                            <span className="badge badge-secondary">1K</span>
                                                                        </span>
                                                                        Review
                                                            </div>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/company-setting">
                                                                    <i className="fa fa-cogs"></i>
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-12 middle-container" style={{ paddingRight: "0px" }}>
                                            <div className="text-right">
                                                <a href="#" className="btn btn-xs btn-link" id="wrightReviewBtn">Wright Review</a>
                                            </div>
                                            <div className="box bg-white border" id="wrightReviewContainer" style={{ display: "none" }}>
                                                <div className="box-header padding-right-0  ">
                                                    <div className="title">Wright Your Reviews</div>
                                                </div>
                                                <div className="box-body">
                                                    <ul className="deailsList">
                                                        <li>
                                                            <div>
                                                                <div className="label">Add a Headline</div>
                                                                <div className="details">
                                                                    <input type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <div className="label">Write your review</div>
                                                                <div className="details">
                                                                    <textarea type="text" className="form-control"></textarea>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <div className="label">Overall Rating</div>
                                                                <div className="details">
                                                                    <div className="company-rating"></div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <div className="text-right" style={{ paddingRight: "150px" }}>
                                                        <a href="#" className="btn btn-xs btn-success" > Submit </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box bg-white border ">
                                                <div className="box-header padding-right-0  ">
                                                    <div className="title ">Reviews</div>
                                                </div>
                                                <div className="box-body">
                                                    <div className="starRatingContainer">
                                                        <div className="one">
                                                            <span className="rating">4.2 <i className="fa fa-star" style={{ fontSize: "20px" }}></i></span>
                                                            <span>1,000 customer ratings</span>
                                                        </div>
                                                        <div className="two">
                                                            <ul>
                                                                <li>
                                                                    <span>5 <i className="fa fa-star"></i></span>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "58%" }} aria-valuenow="58" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span>70 %</span>
                                                                </li>
                                                                <li>
                                                                    <span>4 <i className="fa fa-star"></i></span>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: "24%" }} aria-valuenow="24" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span>70 %</span>
                                                                </li>
                                                                <li>
                                                                    <span>3 <i className="fa fa-star"></i></span>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: "6%" }} aria-valuenow="6" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span>70 %</span>
                                                                </li>
                                                                <li>
                                                                    <span>2 <i className="fa fa-star"></i></span>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: "2%" }} aria-valuenow="2" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span>70 %</span>
                                                                </li>
                                                                <li>
                                                                    <span>1 <i className="fa fa-star"></i></span>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: "10%" }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span>70 %</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="customerReviewContainer">
                                                <div class="customerReview">
                                                    <div class="r-heading">
                                                        <span class="title">Company/User Name</span>
                                                        <div class="date">10-Aug-2019</div>
                                                        <div>
                                                        <StarRatingComponent 
                                                              name="rate1" 
                                                              starCount={5}
                                                              value={rating}
                                                            //   onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </div>
                                                        {/* <div class="starRating">
                                                            <i class="fa fa-star fill"></i>
                                                            <i class="fa fa-star fill"></i>
                                                            <i class="fa fa-star fill"></i>
                                                            <i class="fa fa-star-o"></i>
                                                            <i class="fa fa-star-o"></i>
                                                        </div> */}
                                                        {/* <!--<span class="badge badge-success">4.0 <i class="fa fa-star"></i></span>--> */}
                                                    </div>
                                                    <div class="r-body">
                                                        <div>
                                                            <h5>Have you ever been in a demo where the prospect wasn’t talking at all?</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                                        </div>
                                                        <div>
                                                            <h5>Why is this such an important problem for you to fix?</h5>
                                                            <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                        </div>
                                                        <div>
                                                            <h5>Pros</h5>
                                                            <p>Aliquam nulla facilisi cras fermentum odio eu feugiat. Nibh ipsum consequat nisl vel pretium lectus quam id. </p>
                                                        </div>
                                                        <div>
                                                            <h5>Cons</h5>
                                                            <p>Mauris cursus mattis molestie a. Pharetra convallis posuere morbi leo.</p>
                                                        </div>
                                                        <div>
                                                            <h5>Description</h5>
                                                            <p>Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae. Id volutpat lacus laoreet non curabitur gravida arcu ac. Est placerat in egestas erat imperdiet sed euismod nisi.</p>
                                                        </div>
                                                    </div>
                                                    {/* <!--<div class="r-footer">-->
                                                        <!--<div class="userName">John Doe</div>-->
                                                        <!--<div class="post">Marketing</div>-->
                                                        <!--<div class="date">10-Aug-2019</div>-->
                                                    <!--</div>--> */}
                                                </div>
                                            </div>
                                                </div>
                                                <div className="box-footer ">
                                                    <nav aria-label="...">
                                                        <ul className="pagination pagination-md justify-content-center">
                                                            <li className="page-item active" aria-current="page">
                                                                <span className="page-link">
                                                                    1
                                                            <span className="sr-only">(current)</span>
                                                                </span>
                                                            </li>
                                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        )
    }
}

// export default CompanyReview;
function mapDispatchToProps(dispatch) {
    return {
      userLogoutAction: bindActionCreators(userLogoutAction, dispatch),
    }
  }
  export default connect(
    null,
    mapDispatchToProps
  )(CompanyReview);