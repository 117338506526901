import { createStore, applyMiddleware } from "redux";
import { persistReducer } from "redux-persist";
import thunkMiddleware from 'redux-thunk';
import storage from "redux-persist/lib/storage";

import rootReducer from './reducers';
//import { createLogger } from 'redux-logger';
const persistConfig = {
  key: "combrate",
  storage
};
const persistedReducers = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducers,
  applyMiddleware(thunkMiddleware)
);

export default store;