import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateUserAction,
  getUserByAccountIdAction
} from "../../../store/actions";
import Constants from '../../../utils/Constants';
import Helper from '../../../Configs/Helper';
import Loader from '../../SubComponents/Loader/Loader';
import Validation from '../../../Configs/Validation';


const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;

class UserProfilBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar_image:"",
      avatar_image_preview:"",
      cover_image:"",
      cover_image_preview:'',
      userNavBar: this.props.userNavBar,
      activeTabId: this.props.activeTab,
      saveshow: false,
      coverSave:false,
    };
  }
  onClickCancleImgUpload=pas=>e=>{
    this.componentDidMount();
    if(pas==='avatar'){
    this.setState({saveshow: false,});
    }

      if(pas==='cover'){
        this.setState({coverSave:false,});
      }
  }

  componentDidMount(){
    if(this.props.userData!==null && this.props.userData!==undefined){
        if(this.props.userData.data!==null && this.props.userData.data!==undefined){
            const userData = this.props.userData.data
            this.setState({
              avatar_image:(userData.avatar_image!==undefined && userData.avatar_image!==null) ? userData.avatar_image : Constants.defaultImageUrls.user,
              avatar_image_preview:(userData.avatar_image!==undefined && userData.avatar_image!==null) ? BUCKET_PATH + '/' + userData.avatar_image.image_path: Constants.defaultImageUrls.user,
              cover_image:(userData.cover_image!==undefined && userData.cover_image!==null) ? userData.cover_image : Constants.defaultImageUrls.userCover,
              cover_image_preview:(userData.cover_image!==undefined && userData.cover_image!==null) ?   BUCKET_PATH + '/' + userData.cover_image.image_path: Constants.defaultImageUrls.userCover,
            })
        }
    }
}

  componentWillReceiveProps(nxtProps) {
    this.setState({ activeTabId: nxtProps.activeTab });
  }
  onImgchangeHandler = event => {
    var name = event.target.name;
    // var value = event.target.value;
    if (name === "avatar_image") {
      this.setState({ 
        saveshow: true,
        avatar_image:event.target.files[0],
        avatar_image_preview: event.target.files[0]
      });
      this.filePreview(event.target.files[0], 'avatar_image_preview');
   
  } else if (name === "cover_image") {
      this.setState({ 
        coverSave:true,
        cover_image:event.target.files[0],
        cover_image_preview:event.target.files[0],
      });
      
      this.filePreview(event.target.files[0], 'cover_image_preview');
  }
  };

  
  filePreview(file, field) {

    if (file !== undefined) {
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {

            if (field === "avatar_image_preview") {
                this.setState({
                    [field]: [reader.result],
                })
            }else if (field === "cover_image_preview") {
              this.setState({
                [field]: [reader.result],
            })        
            }  else {
                this.setState({
                    [field]: [reader.result]
                })
            }
        }.bind(this);
    } else {
        this.setState({
            [field]: [null]
        })
    }
}

onClickImgUpload = async( e) => {

  if(e.target.id==='avatar'){
     const validImage = Validation.fileValidation(this.state.avatar_image,'userProfile');
    if(validImage!==''){
    this.loader.showAlert(true,'error',validImage)
    return;
  } }
  if(e.target.id==='cover'){
    const validImage = Validation.fileValidation(this.state.cover_image,'userProfile');
   if(validImage!==''){
   this.loader.showAlert(true,'error',validImage)
   return;
 } }

 
    this.setState({ saveshow: false,coverSave:false });

    const formData = new FormData();
    formData.append("avatar_image", this.state.avatar_image);
    formData.append('cover_image',this.state.cover_image);

    this.loader.show(true);
 if(this.props.userData!==null && this.props.userData!==undefined){
        if(this.props.userData.data!==null && this.props.userData.data!==undefined){
            const userData = this.props.userData.data
    const postData = {
      account_id:userData.account_id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      email_id: userData.email_id,
      address: (userData.address!==null && userData.address!==undefined) ? userData.address.address_line:'',
      city: (userData.address!==null && userData.address!==undefined) ? userData.address.city:'',
      state:(userData.address!==null && userData.address!==undefined) ?  userData.address.state:'',
      country: userData.country,
      mobile_number: userData.mobile_number,
      date_of_birth: userData.date_of_birth
    };
    await this.props.updateUser(postData, formData);

    if(this.props.userPersonalData.status===200){
            this.loader.showAlert(true,'success',this.props.userPersonalData.message)
          await this.props.getUserByAccountIdAction(this.props.userData.data.account_id);

          var userImg= this.props.userData.data;
          this.setState({
            avatar_image:(userImg.avatar_image!==undefined && userImg.avatar_image!==null) ? userImg.avatar_image : Constants.defaultImageUrls.user,
            avatar_image_preview:(userImg.avatar_image!==undefined && userImg.avatar_image!==null) ? BUCKET_PATH + '/' + userImg.avatar_image.image_path: Constants.defaultImageUrls.user,
            cover_image:(userImg.cover_image!==undefined && userImg.cover_image!==null) ? userImg.cover_image : Constants.defaultImageUrls.userCover,
            cover_image_preview:(userImg.cover_image!==undefined && userImg.cover_image!==null) ?   BUCKET_PATH + '/' + userImg.cover_image.image_path: Constants.defaultImageUrls.userCover,
          })
      }else{
          this.loader.showAlert(true,'error',Helper.flashMsg('apiResErr'))
      }
  }
}
    this.loader.show(false);

                  

  };

  onClickTabsHandler = (tabId, e) => {
    this.setState({ activeTabId: tabId });
    this.props.activeTabHandler(tabId);
  };
  render() {
    const { userData } = this.props;
    let fName = "";
    let lname = "";
    if (userData !== null && userData !== undefined) {
      if (userData.data !== null && userData.data !== undefined) {
        if (userData.data.first_name !== undefined) {
          fName = userData.data.first_name;
          lname = userData.data.last_name;
        }
      }
    }

    return (
      <div className="userMainProfile col-sm-12">
        
        <div  className="profileBgImgConatiner"  style={{ backgroundImage: "url(" + this.state.cover_image_preview + ")" }}>
          {this.state.coverSave ?
          <>
          <a className="btn btn-xs btn-warning" onClick={this.onClickCancleImgUpload('cover')} style={{position: "absolute",marginTop: "10px",marginRight:"50px"}}>
            <i className="fa fa-close"></i>
          </a>
          
          <a className="btn btn-xs btn-info" id="cover" onClick={this.onClickImgUpload} style={{position:"absolute",marginTop:"90px",marginLeft: "75px"}}>
            <i className="fa fa-check"></i>
          </a>
          </>
          :
          <a href="#" className="btn btn-xs btn-default">
            <label htmlFor="file-inputcoverimage" style={{ cursor: "pointer" }}>
              <i className="fa fa-camera"></i>&nbsp;Upload Image
            </label>
            <input
              id="file-inputcoverimage"
              name="cover_image"
              type="file"
              accept="image/*"
              onChange={this.onImgchangeHandler}
              style={{ display: "none" }}
            />
          </a>
          }
          <div className="userImage">
            <figure>
              <img alt=""
                src={this.state.avatar_image_preview}
                style={{ position: "absolute" }}
              />
              {this.state.saveshow ? (
                <>
              {/* close Button */}
                  <a className="btn btn-xs btn-warning" onClick={this.onClickCancleImgUpload('avatar')} style={{   position: "absolute",   marginTop: "90px",   marginLeft: "35px" }}>
                    <i className="fa fa-close"></i>{" "}
                  </a>
              {/* Check Button */}
                <a id="avatar" className="btn btn-xs btn-info" onClick={this.onClickImgUpload} style={{   position: "absolute",   marginTop: "90px",   marginLeft: "75px" }}>
                    <i className="fa fa-check"></i>{" "}
                  </a>
                </>
              ) : (
                <div className="changeprofile">
                  <label htmlFor="file-inputavatar_image">
                    <i className="fa fa-camera"></i>
                  </label>
                  <input
                    id="file-inputavatar_image"
                    name="avatar_image"
                    type="file"
                    accept="image/*"
                    onChange={this.onImgchangeHandler}
                    style={{ display: "none" }}
                  />
                </div>
              )}
            </figure>
            <div className="userName">
              <span>
                {fName} {lname}
              </span>
            </div>
          </div>
         
            

        </div>
        
        <div className="profileBlankDiv"></div>
        <div className="ProfilenavigationContainer">
          <div></div>
          <div className="navigations userProfilenav">
            <ul>
              {this.state.userNavBar.map((tabs, idx) => (
                <li
                  key={idx}
                  className={tabs.id === this.state.activeTabId ? "active" : ""}
                >
                  <Link
                    to={`/user/${tabs.url}`}
                    onClick={this.onClickTabsHandler.bind(this, tabs.id)}
                  >
                    {tabs.tab}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* loader Start */}
 <Loader onRef={ref => {this.loader = ref;}}/>
        {/* loader Start */} 
 
      </div>
    );
  }
}
const mapStateToProps = state => ({
  userData: state.auth.userData,
  userPersonalData: state.apiData.userPersonalData
});

function mapDispatchToProps(dispatch) {
  return {
    getUserByAccountIdAction: bindActionCreators(getUserByAccountIdAction,dispatch),
    updateUser: bindActionCreators(updateUserAction, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfilBanner);
