import * as types from '../actions/types'
//import { updateObject } from '../../utils/HelperFunc';
const initialState = {
    pending: false,
    ownCompanyDetails: null,
    otherCompanyDetails: null,
    newAddedPostData: null,
    followedCompanyPostList: null,
    postListData: null,
    postComments: null,
    newLikeDislikePostData: null,
    newAddedPostComment: null,
    editedPostData: null,
    deletedPostData: null,
    companyDetails: null,
    companyList: null,
    companyFollowRes: null,
    sentConnReqRes: null,
    companyBlockRes: null,
    companyUnBlockRes: null,
    suggestedCompanies: null,
    error: null
}

export default (state = initialState, action)=> {
    const { type, payload, error } = action;
    switch(type) {
        case types.LOGOUT_ACTION_REQUEST:
            return   { ...state, pending: false,
                ownCompanyDetails: null,
                otherCompanyDetails: null,
                newAddedPostData: null,
                followedCompanyPostList: null,
                postListData: null,
                postComments: null,
                newLikeDislikePostData: null,
                newAddedPostComment: null,
                editedPostData: null,
                deletedPostData: null,
                companyDetails: null,
                companyList: null,
                companyFollowRes: null,
                sentConnReqRes: null,
                companyBlockRes: null,
                companyUnBlockRes: null,
                suggestedCompanies: null,
                error: null
            };
        case types.SET_OWN_COMPANY_ACTION_REQUEST: 
            return { ...state }
        case types.SET_OWN_COMPANY_ACTION_SUCCESS:
            return { ...state, ownCompanyDetails: payload }
        case types.SET_OWN_COMPANY_ACTION_FAILURE:
            return { ...state, ownCompanyDetails: payload }

        case types.COMPANY_BLOCK_ACTION_REQUEST: 
            return { ...state }
        case types.COMPANY_BLOCK_ACTION_SUCCESS:
            return { ...state, companyBlockRes: payload }
        case types.COMPANY_BLOCK_ACTION_FAILURE:
            return { ...state, companyBlockRes: payload }

        case types.COMPANY_UNBLOCK_ACTION_REQUEST: 
            return { ...state }
        case types.COMPANY_UNBLOCK_ACTION_SUCCESS:
            return { ...state, companyUnBlockRes: payload }
        case types.COMPANY_UNBLOCK_ACTION_FAILURE:
            return { ...state, companyUnBlockRes: payload }

        case types.SET_OTHER_COMPANY_ACTION_REQUEST: 
            return { ...state }
        case types.SET_OTHER_COMPANY_ACTION_SUCCESS:
            return { ...state, otherCompanyDetails: payload }
        case types.SET_OTHER_COMPANY_ACTION_FAILURE:
            return { ...state, otherCompanyDetails: payload }

        case types.SENT_CONNECTION_REQ_ACTION_REQUEST: 
            return { ...state }
        case types.SENT_CONNECTION_REQ_ACTION_SUCCESS:
            return { ...state, sentConnReqRes: payload }
        case types.SENT_CONNECTION_REQ_ACTION_FAILURE:
            return { ...state, sentConnReqRes: payload }

        case types.ADD_NEW_POST_ACTION_REQUEST: 
            return { ...state }
        case types.ADD_NEW_POST_ACTION_SUCCESS:
            return { ...state, newAddedPostData: payload }
        case types.ADD_NEW_POST_ACTION_FAILURE:
            return { ...state, newAddedPostData: payload }

        case types.EDIT_POST_ACTION_REQUEST: 
            return { ...state }
        case types.EDIT_POST_ACTION_SUCCESS:
            return { ...state, editedPostData: payload }
        case types.EDIT_POST_ACTION_FAILURE:
            return { ...state, editedPostData: payload }

        case types.DELETE_POST_ACTION_REQUEST: 
            return { ...state }
        case types.DELETE_POST_ACTION_SUCCESS:
            return { ...state, deletedPostData: payload }
        case types.DELETE_POST_ACTION_FAILURE:
            return { ...state, deletedPostData: payload }

        case types.GET_POST_LIST_ACTION_REQUEST: 
            return { ...state }
        case types.GET_POST_LIST_ACTION_SUCCESS:
            return { ...state, postListData: payload }
        case types.GET_POST_LIST_ACTION_FAILURE:
            return { ...state, postListData: payload }

        case types.POST_LIKE_DISLIKE_ACTION_REQUEST: 
            return { ...state }
        case types.POST_LIKE_DISLIKE_ACTION_SUCCESS:
            return { ...state, newLikeDislikePostData: payload }
        case types.POST_LIKE_DISLIKE_ACTION_FAILURE:
            return { ...state, newLikeDislikePostData: payload }

        case types.ADD_POST_COMMENT_ACTION_REQUEST: 
            return { ...state }
        case types.ADD_POST_COMMENT_ACTION_SUCCESS:
            return { ...state, newAddedPostComment: payload }
        case types.ADD_POST_COMMENT_ACTION_FAILURE:
            return { ...state, newAddedPostComment: payload }
        
        case types.GET_COMPANY_DETAILS_ACTION_REQUEST: 
            return { ...state }
        case types.GET_COMPANY_DETAILS_ACTION_SUCCESS:
            return { ...state, companyDetails: payload }
        case types.GET_COMPANY_DETAILS_ACTION_FAILURE:
            return { ...state, companyDetails: payload }

        case types.GET_POST_COMMENTS_ACTION_REQUEST: 
            return { ...state }
        case types.GET_POST_COMMENTS_ACTION_SUCCESS:
            return { ...state, postComments: payload }
        case types.GET_POST_COMMENTS_ACTION_FAILURE:
            return { ...state, postComments: payload }

        case types.GET_COMPANY_LIST_ACTION_REQUEST: 
            return { ...state }
        case types.GET_COMPANY_LIST_ACTION_SUCCESS:
            return { ...state, companyList: payload }
        case types.GET_COMPANY_LIST_ACTION_FAILURE:
            return { ...state, companyList: payload }

        case types.FOLLOW_COMPANY_ACTION_REQUEST: 
            return { ...state }
        case types.FOLLOW_COMPANY_ACTION_SUCCESS:
            return { ...state, companyFollowRes: payload }
        case types.FOLLOW_COMPANY_ACTION_FAILURE:
            return { ...state, companyFollowRes: payload }

        case types.GET_FOLLOWED_COMPANY_POST_ACTION_REQUEST: 
            return { ...state }
        case types.GET_FOLLOWED_COMPANY_POST_ACTION_SUCCESS:
            return { ...state, followedCompanyPostList: payload }
        case types.GET_FOLLOWED_COMPANY_POST_ACTION_FAILURE:
            return { ...state, followedCompanyPostList: payload }

        case types.GET_COMPANY_SUGGESTION_ACTION_REQUEST: 
            return { ...state }
        case types.GET_COMPANY_SUGGESTION_ACTION_SUCCESS:
            return { ...state, suggestedCompanies: payload }
        case types.GET_COMPANY_SUGGESTION_ACTION_FAILURE:
            return { ...state, suggestedCompanies: payload }

        default: 
            return state;
    }
}
