import React, { Component } from "react";
import Helper from "../../../../Configs/Helper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  saveInterestAction,
  getUserByAccountIdAction
} from "../../../../store/actions";
import Loader from "../../../SubComponents/Loader/Loader";

class InterestInfoContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInterest:
        this.props.userData !== null &&
        this.props.userData.data.interests !== null
          ? this.props.userData.data.interests
          : []
    };
  }
  handleInterestSelectn = event => {
    let interestArr = this.state.selectedInterest;
    if (interestArr === undefined) {
      interestArr = [];
    } else if (interestArr === null) {
      interestArr = [];
    }
    if (event.target.checked) {
      // var name={name:event.target.value}
      if (event.target.value !== "" && event.target.value !== null)
        interestArr.push(event.target.value);
    } else {
      const index = interestArr.indexOf(event.target.value);
      if (index > -1) {
        interestArr.splice(index, 1);
      }
    }
    this.setState({ selectedInterest: interestArr }, () =>
      console.log(this.state.selectedInterest.map(value => value))
    );
  };
  onClickInterestsSave = async e => {
    const postData = {
      account_id: this.props.userData.data.account_id,
      interests: ""
    };
    if (this.state.selectedInterest.length > 0) {
      this.loader.show(true);

      //this.loader.show(true);
      const selectedInterest = this.state.selectedInterest;
      postData.interests = selectedInterest.join(",");
      await this.props.saveInterestAction(postData);
      if (this.props.interestData.status === 200) {
        await this.props.getUserByAccountIdAction(
          this.props.userData.data.account_id
        );

        // this.setState({selectedInterest:this.props.userData.data.interests})
        this.loader.showAlert(true, "success", 'Interest is updated');
      } else {
      }
    }
    //this.props.history.push('/company-options');
    // this.loader.show(false);
    else {
      this.loader.showAlert(
        true,
        "error",
        "please select atleast one interest"
      );
    }

    this.loader.show(false);
  };

  render() {
    return (
      <>
        <div className="box-body">
          <ul className="deailsList pageList">
            <li>
              {/* <div className="text-right" style={{display: "block"}}>
                      <a href="#" onClick={this.onClickEditInterestHandler} className="btn btn-sm btn-link"><i className="fa fa-edit"></i> Edit Interests</a>
                  </div> */}
              <div>
                <div className=" interestListContainer">
                  <ul>
                    {Helper.getDropdownsVals("CompanyIndustries").map(
                      (value, key) => (
                        <li key={key} style={{ width: "calc(100%/2)" }}>
                          <div className="pretty p-default">
                            <input
                              type="checkbox"
                              onClick={this.handleInterestSelectn}
                              value={value.name}
                              defaultChecked={
                                this.state.selectedInterest !== null &&
                                this.state.selectedInterest !== undefined
                                  ? this.state.selectedInterest.includes(
                                      value.name
                                    )
                                  : ""
                              }
                            />
                            <div className="state p-primary">
                              <label>{value.name}</label>
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <div
            className="box-footer text-right"
            style={{ paddingRight: "150px" }}
          >
            <a
              href="#"
              onClick={this.onClickInterestsSave}
              className="btn btn-success"
            >
              Save
            </a>
          </div>
        </div>

        {/* loader Start */}
        <Loader
          onRef={ref => {
            this.loader = ref;
          }}
        />
        {/* loader Start */}
      </>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
  interestData: state.auth.interestData
});

function mapDispatchToProps(dispatch) {
  return {
    getUserByAccountIdAction: bindActionCreators(
      getUserByAccountIdAction,
      dispatch
    ),
    saveInterestAction: bindActionCreators(saveInterestAction, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterestInfoContents);
