import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import { bindActionCreators } from "redux";

import Home from "./Screens/Components/Home/Home";
import CompanyOptions from "./Screens/Components/CompanyOptions/CompanyOptions";
import CreateComapny from "./Screens/Components/CreateCompany/CreateCompany";
import CompanyTimeline from "./Screens/Components/CompanyDashboard/CompanyTimeline";
import UserDashboard from "./Screens/Components/UserDashboard/UserDashboard";
import TermConditions from "./Screens/Components/TermConditions/TermConditions";
// import CompanyAbout from "./Screens/Components/CompanyDashboard/About/CompanyAbout";
// import CompanyAboutForm from "./Screens/Components/CompanyDashboard/About/CompanyAboutForm";
import Dashboard from "./Screens/Components/CompanyDashboard/Dashboard";
import Connections from "./Screens/Components/CompanyDashboard/Connections";
import Messages from "./Screens/Components/CompanyDashboard/Messages";
// import Rating from "./Screens/Components/CompanyDashboard/Ratings/RatingForm";
// import AboutForm from "./Screens/Components/CompanyDashboard/About/AboutFormDemo";
// import AboutDemo from "./Screens/Components/CompanyDashboard/About/AboutDemo";
import CompanyReview from "./Screens/Components/CompanyDashboard/CompanyReview";
import CompanySetting from "./Screens/Components/CompanyDashboard/CompanySetting";
import CompanyDashboardMain from "./Screens/Components/CompanyDashboard/CompanyDashboardMain";
import Interest from "./Screens/Components/Interest/Interest";
import Page404 from "./Screens/Components/ErrorPage/Page404";
import SearchCompanies from "./Screens/SubComponents/SearchCompanies/SearchCompanies";
import Notification from "./Screens/SubComponents/AllNotification/Notification";


import { ProtectedRoute } from "./protected_route";
//React Routing & Navigation
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div className="app">
        <div className="app-body">
          <main className="main">
            <Switch>
            <Route path="/dashboard" name="Dashboard" component={CompanyDashboardMain}/>
              {/* <Route path="/company-about" name="CompanyAbout" component={AboutDemo}/> */}
              <Route path="/company-review" name="CompanyReview" component={CompanyReview}/>
              <Route path="/company-setting" name="CompanySetting" component={CompanySetting}/>
              {/* <Route path="/company-about-form" name="CompanyAboutForm" component={AboutForm}/> */}
              {/* <Route path="/company-rating" name="CompanyRating" component={Rating}/> */}
              <Route path="/company-connections" name="CompanyConnections" component={Connections} />
              <Route path="/messages" name="CompanyAbout" component={Messages} />
              {/* <Route path="/company-about-form2" name="CompanyAboutForm2" component={CompanyAboutForm}/> */}
              {/* <Route path="/company-about2" name="CompanyAbout2" component={CompanyAbout}/> */}
              <Route path="/term-conditions" name="TermConditions" component={TermConditions} />
              <Route path="/home" name="Home" component={Home} /> 

              <Route path="/Search" name="Home" component={SearchCompanies} /> 
              <Route path="/Notification" name="Home" component={Notification} />             
              
              <ProtectedRoute exact path="/company-options" name="CompanyOptions" component={CompanyOptions} />
              <ProtectedRoute exact path="/create-company" name="CreateCompany" component={CreateComapny} />
              <ProtectedRoute exact path="/news-feed" name="CompanyDashboard" component={Dashboard} />
              <ProtectedRoute exact path="/company-timeline/:company_tab?/:company_id?" name="CompanyDashboard" component={CompanyTimeline} />
              {/* <Route exact path="/news-feed" name="CompanyDashboard" component={Dashboard} /> */}
              <ProtectedRoute exact path="/user/:user_tab?" name="UserDashboard" component={UserDashboard} />
              <ProtectedRoute exact path="/interest" name="Interest" component={Interest}/>

              <Route path="/not-found" name="NotFound" component={Page404} /> 
              <Redirect from="/" to="/home" />
            </Switch>
          </main>
        </div>
      </div>
    );
  }
}
//export default Routes;
const mapStateToProps = state => ({
  loginData: state.auth.loginData,
});


export default connect(
  mapStateToProps,
  null
)(Routes);
