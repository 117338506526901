import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import Helper from "../../../Configs/Helper";
import Header from "../../SubComponents/Header/Header";
import Footer from "../../SubComponents/Footer/Footer";
import { connect } from "react-redux";

class Page404 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  
  render() {
    return (
      <div>
        <Header {...this.props} headerType="errorPage" />
        <main className="mainBody container-fluid">
          <div className="row">
            <div className="container">
              <div className="row no-gutters">
               
                <div className="message-box">
                  <h1>404</h1>
                  <p>Page not found</p>
                  <div className="buttons-con">
                    <div className="action-link-wrap">
                      <a href="#" className="link-button link-back-button btn btn-md btn-outline-primary">Go Back</a>
                      <a href="#" className="link-button btn btn-md btn-outline-primary">Go to Home Page</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer {...this.props} />
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
});

export default connect(
  mapStateToProps,
  null
)(Page404);
