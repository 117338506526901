
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addNewPostAction, editPostAction } from "../../../store/actions";

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import SimpleReactValidator from 'simple-react-validator';
import Helper from "../../../Configs/Helper"
import Loader from "../../SubComponents/Loader/Loader";
import $ from "jquery";
class AddPostModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      tagsBox: false,
      post_title: "",
      post_desc: "",
      post_heading: "",
      post_tag: [],
      post_privacy: "PUBLIC",
      post_image: "",
      post_image_preview: "",
      post_video: "",
      post_video_preview: "",
      hasMoreRecords: true,
      nextPage: '',
      items: [],
      likes: [],
      comments: [],
      companyId: '',
      paramsData: {
          poster_id: '',
          created_ts: '',
          pageStage: '',
          fetch_size: ''
      },
      selectedPostData: null,
  };
  this.validator = new SimpleReactValidator();
}
componentDidMount(){
  this.setPropsData(this.props)
}
componentWillReceiveProps(nxtProps){
  this.setPropsData(nxtProps)
}
setPropsData=(props)=>{
    //this.setStateBlank();
    const compData = Helper.getCompanyDetailsObj(props.ownCompanyDetails);
    this.setState({ companyId: compData.company_id, selectedPostData: props.state.selectedPostData},()=>{
      if(this.state.selectedPostData!==null){
        
        const pData = this.state.selectedPostData.data;

        //set privacy in state
        const postPrivacyList = Helper.getDropdownsVals('PostPrivacy');
        let privacy = this.state.post_privacy;
        if(parseInt(pData.post_privacy)>0){
          privacy = postPrivacyList[pData.post_privacy-1].value;
        }
        
        this.setState({
          post_title: pData.heading,
          post_desc: pData.post_text,
          post_tag: (pData.tag!==null) ? pData.tag : [],
          post_privacy: privacy,
          post_image: "",
          post_image_preview: (pData.media_image!==null) ? process.env.REACT_APP_BUCKET_PATH+'/'+pData.media_image.imagePath : "",
          post_video: "",
          post_video_preview: (pData.media_video!==null) ? process.env.REACT_APP_BUCKET_PATH+'/'+pData.media_video.videoPath : "",
        });
      }
    })
}

setStateBlank = () =>{
  this.setState({ 
    tagsBox: false, 
    post_title: "", 
    post_desc: "", 
    post_heading: "", 
    post_tag: [], 
    post_privacy: "PUBLIC", 
    post_image: "", 
    post_image_preview: "", 
    post_video: "", 
    post_video_preview: "", 
  });
}
onTagsAdd =post_tag=> {
  this.setState({ post_tag })
}
onChangeHandler = (event) => {
  if(event.target.name==="post_image"){
      this.setState({ post_image: event.target.files[0] });
      this.filePreview(event.target.files[0],'post_image_preview');
  } else if(event.target.name==="post_video"){
      this.setState({ post_video: event.target.files[0] });
      this.filePreview(event.target.files[0],'post_video_preview');
  } else {
      this.setState({ [event.target.name]: event.target.value });
  }
}
filePreview(file,field){  
  if(file===undefined || file===''){
      this.setState({ [field]: '' });
      return;
  }
  if(field==='post_video_preview'){
    this.setState({ [field]: URL.createObjectURL(file) });
    return;
  }
  const reader = new FileReader();
  const url = reader.readAsDataURL(file);
   reader.onloadend = function (e) {
      this.setState({
        [field]: e.target.result
      })
    }.bind(this);
}

onRemoveImgHandler = (event) => {
  this.setState({ post_image: '', post_image_preview:'' });
}
savePostHandler= async ()=>{
  /*if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
  }*/
 
  //const { data } = this.props.userData;
  const tags = this.state.post_tag;
  const postData={
    poster_id: this.state.companyId,
    poster_type: 'COMPANY',
    post_privacy: this.state.post_privacy,
    post_text: this.state.post_desc,
    heading: this.state.post_title,
    tag: tags.length > 0 ? tags.join(','):null
  }
  const formData = new FormData();        
  //post image 
      if(this.state.post_image!==''){
          formData.append('media_file',this.state.post_image);
      }            
  //post video
      if(this.state.post_video!==''){
          formData.append('media_file',this.state.post_video);
      }

  this.loader.show(true);
  await this.props.addNewPostAction(postData,formData);
  if(this.props.postData.status===200){
      $( ".close" ).trigger( "click" )
      this.setStateBlank();
      this.props.newAddedItem(this.props.postData.data);
      this.loader.showAlert(true,'success',this.props.postData.message)
  } else {
    this.loader.showAlert(true,'error',this.props.postData.message)
  }
  this.loader.show(false);
}

updatePostHandler= async ()=>{
  const pData = this.state.selectedPostData.data;
  const tags = this.state.post_tag;
  let postData={
    id: pData.id,
    poster_id: pData.poster_id,
    created_ts: pData.created_ts,
    poster_type: 'COMPANY',
    post_privacy: this.state.post_privacy,
  }
  if(this.state.post_desc!==''){
    postData.post_text = this.state.post_desc;
  }
  if(this.state.post_title!==''){
    postData.heading = this.state.post_title;
  }
  if(tags.length>0){
    postData.tag = tags.join(',');
  }

  const formData = new FormData();        
  //post image 
      if(this.state.post_image!==''){
          formData.append('media_file',this.state.post_image);
      }
      if((pData.media_image!==null) && this.state.post_image_preview===''){
        postData.ismediaFileDeleletd = 2;
        postData.fileType = 1;
        postData.fileId = pData.media_image.imageId;
      }            
  //post video
      if(this.state.post_video!==''){
          formData.append('media_file',this.state.post_video);
      }
      if((pData.media_video!==null) && this.state.post_video_preview===''){
        postData.ismediaFileDeleletd = 2;
        postData.fileType = 2;
        postData.fileId = pData.media_video.videoId;
      } 

  this.loader.show(true);
  await this.props.editPostAction(postData,formData);
  if(this.props.postDataEdited.status===200){
      $( ".close" ).trigger( "click" )
      this.setStateBlank();
      this.props.updatedPostItem(this.props.postDataEdited.data,pData.id);
      this.loader.showAlert(true,'success',this.props.postDataEdited.message)
  } else {
    this.loader.showAlert(true,'error',this.props.postDataEdited.message)
  }
  this.loader.show(false);
}
  render() {
    return (
      <>
      <div className="modal" id="newPostModal" tabIndex={-1} role="dialog" aria-labelledby="newPostModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-md" role="document">
              <div className="modal-content">
              <div className="modal-header bg-light">
                  <h5 className="modal-title text-md text-muted" id="newPostModalLabel">Create Post</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                  </button>
              </div>
              <div className="modal-body">
                  {/* <textarea className="form-control without-border" rows="10" placeholder="What do you want talk about?"></textarea> */}
                  <input type="text" name="post_title" onChange={this.onChangeHandler} className="form-control" placeholder="Heading" value={this.state.post_title}/>
                  {this.validator.message('postTitle', this.state.post_title, 'required')}
                  <div className="textarea">
                      <textarea name="post_desc" onChange={this.onChangeHandler} className="form-control without-border helpText" rows="3" placeholder="What do you want talk about?" style={{borderBottom: "0px"}} value={this.state.post_desc}></textarea>
                      {this.validator.message('post_desc', this.state.post_desc, 'required')}
                  </div>
                  <span id="tags-input" style={{display: 'none'}} />
                  { (this.state.tagsBox) && <TagsInput value={this.state.post_tag} onChange={this.onTagsAdd} /> }

                  <div className="icon-cross-parent" style={{display: this.state.post_image_preview==='' ? 'none' : ''}}>
                      <i className="fa fa-times" onClick={ ()=>{ this.setState({ post_image:'', post_image_preview: '' }) } }/>
                      <img alt="" src={this.state.post_image_preview} style={{width:'100px',height:'100px'}}/>
                  </div>
                  
                  <div className="icon-cross-parent" style={{display: this.state.post_video_preview==='' ? 'none' : ''}}>
                      <i className="fa fa-times" onClick={ ()=>{ this.setState({ post_video: '', post_video_preview: '' }) } }/>
                      <video src={this.state.post_video_preview} height="100" width="100" className="video-preview"/>
                  </div>
              </div>
              <div className="modal-footer">
                  <div>
                  <ul className="icon-inline-list" style={{marginLeft: '-1rem'}}>
                      <li>
                          <a href="#" title="Add Tags" id="tagBtn"><i className="flaticon-price-tag" onClick={ ()=>{ this.setState({ tagsBox: !this.state.tagsBox }) }}/></a>
                      </li>
                      <li>
                          <a href="#" title="Add Video"><label htmlFor={this.state.post_image_preview==='' ? 'post-video' : ''}><i className="flaticon-video-camera" /></label></a>
                          <input type="file" className=" " id="post-video"  name="post_video"  onChange={this.onChangeHandler} accept="video/*" style={{display: "none"}}/>
                      </li>
                      <li>
                          <a href="#" title="Add Image"><label htmlFor={this.state.post_video_preview==='' ? 'post-img' : ''}><i className="flaticon-picture" /></label></a>
                          <input type="file" className=" " id="post-img"  name="post_image"  onChange={this.onChangeHandler} accept="image/*" style={{display: "none"}}/>
                      </li>
                      <li><a href="#"><i className="flaticon-document" /></a></li>
                      <li>
                      <select className="" data-width="100px" name="post_privacy" onChange={this.onChangeHandler} value={this.state.post_privacy}>
                          {Helper.getDropdownsVals('PostPrivacy').map((value, key) => <option key={key} value={value.value}>{value.name}</option>)}
                      </select>
                      </li>
                  </ul>
                  </div>
                  
                  <div>
                  { (this.state.selectedPostData===null) 
                    ? <button type="button" className="btn btn-sm btn-primary" onClick={this.savePostHandler}>Post</button>
                    : <button type="button" className="btn btn-sm btn-primary" onClick={this.updatePostHandler}>Update</button> 
                  }
                  </div>
              </div>
              </div>
          </div>
      </div>
       {/* loader Start */}
       <Loader onRef={ref => {this.loader = ref;}}/>
                {/* loader Start */}
      </>
    )       
  }
}
const mapStateToProps = state => ({
  userData: state.auth.userData,
  postData: state.appData.newAddedPostData,
  postDataEdited: state.appData.editedPostData,
  //companyDetails: state.appData.companyDetails,
  ownCompanyDetails: state.appData.ownCompanyDetails,
  loading: state.loading,
});
function mapDispatchToProps(dispatch) {
  return {
      addNewPostAction: bindActionCreators(addNewPostAction, dispatch),
      editPostAction: bindActionCreators(editPostAction, dispatch),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPostModal);
