import React, { Component } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
const loaderSize = { size: 'small', value: 10 }
const SimpleLoader = (props) => {
  if(props.type==='dotted'){
      return <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  } else {
    let loaderSize = 40;
    if(props.size!==undefined){
      if(props.size==='s') loaderSize=20;
      else if(props.size==='m') loaderSize=30;
      else if(props.size==='l') loaderSize=40;
    }
    return <div style={{textAlign: "center"}}><CircularProgress size={loaderSize} /></div>
  }
  
};
export default SimpleLoader;