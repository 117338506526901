import React, { Component } from 'react'
import RatingForm from '../../Components/CompanyDashboard/Ratings/RatingForm'

export default class RatingModal extends Component {
    render() {
        return (
                 <div className="modal" id="myModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header bg-light">
                            <h5 className="modal-title text-md text-muted" id="followModalLabel">Write Your Review </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    <div className="modal-body">
                        <RatingForm reviewCounter={this.props.reviewCounter} companyId={this.props.companyId}/>
                  </div>
                  
                  </div>

                </div>
            </div>
        )
    }
}
