import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
// import { Modal, ModalBody } from "reactstrap";
import Helper from "../../../Configs/Helper";
import Constants from "../../../utils/Constants";
import Loader from "../../SubComponents/Loader/Loader";
import SimpleLoader from "../../SubComponents/Loader/SimpleLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import ShowMoreText from "react-show-more-text";
import {
  deletePostAction,
  addPostCommentAction,
  likeDislikePostAction,
  getPostCommentsAction,
  updatePostComments,
  deletePostComments,
  getCompanyDetailsByIdAction,
  setSelectedOtherCompanyInfoAction,
  getReviewBycompanyidAction
} from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const tempPostIds = [];
class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      comments: [],
      companies: {},
      levelDetails: {},
      commenterDetails: [],
      userAccId: this.props.userData.data.account_id,
      paramsData: {
        poster_id: this.props.userData.data.account_id,
        created_ts: "",
        pageStage: "",
        fetch_size: ""
      },
      commentText: "",
      commentEditText:"",
      hasMoreRecords: true,
      nextPage: "",
      selectedIndex: "",

      hasMoreComments: false,
      nextPageCommentKey: "",
      editBox: { show: false, ind: "" },
      isRelatedBox:'',
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    this.updateNewItems(this.props);
  }
  componentWillReceiveProps(nxtProps) {
    this.updateNewItems(nxtProps);
  }

  updateNewItems = props => {
    this.setState({
      items: props.state.items,
      companies: props.state.companies,
      levelDetails: props.state.levelDetails,
      //comments: nxtProps.state.comments,
    });
  };

  executeOnClick(isExpanded) {
    console.log(isExpanded);
  }
  onChangeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  savePostComment = async (obj, event) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ selectedIndex: obj.index });
    const { data } = this.props.userData;
    const compId = Helper.getDefaultCompanyId();
    const params = {
      id: obj.data.id,
      poster_id: obj.data.poster_id,
      created_ts: obj.data.created_ts,
      text: this.state.commentText,
      commenter_id: compId !== "" ? compId : data.account_id,
      commenter_type: compId !== "" ? "COMPANY" : "USER"
    };

    //api call
    //this.loader.show(true);
    await this.props.addPostCommentAction(params);
    if (this.props.commentData.status === 200) {
      this.filterDataItems("commentItem", obj);

      //company details
      const ownCompObj = Helper.getCompanyDetailsObj(
        this.props.ownCompanyDetails
      );
      const avatarImage =
        (data.avatar_image !== undefined && data.avatar_image !== null)
          ? process.env.REACT_APP_BUCKET_PATH + "/" + data.avatar_image.image_path
          : Constants.defaultImageUrls.user;

      // add new comment in commentStateArray
      let savedComment = this.props.commentData;
      savedComment.text = this.state.commentText;
      savedComment.post_id = savedComment.data.post_id;
      savedComment.created_ts = savedComment.data.created_ts;
      savedComment.id = savedComment.data.id;
      savedComment.name = data.first_name + " " + data.last_name;
      savedComment.image = avatarImage;
      if(params.commenter_type === "COMPANY"){
        savedComment.name = ownCompObj.company_name;
        savedComment.image = ownCompObj.company_logo;
      }

      let filteredArray = this.state.items.filter(item => {
        if (item.post_comments === undefined) {
          item.post_comments = [];
        }
        if (item.id === obj.data.id) {
          const prevComments = item.post_comments;
          item.post_comments[prevComments.length] = savedComment;
        }
        return item;
      });

      this.setState({ items: filteredArray, commentText: "" });
      //this.loader.showAlert(true,'success',this.props.commentData.message)
    } else {
      this.loader.showAlert(true, "error", this.props.commentData.message);
    }
    //this.loader.show(false);

  };

  postLikeDislike = async (obj, event) => {
    const { data } = this.props.userData;

    const key = "postLikeDislike" + obj.index;
    this.setState({ [key]: !this.state[key], selectedIndex: obj.index });

    const compId = Helper.getDefaultCompanyId();
    const params = {
      id: obj.data.id,
      poster_id: obj.data.poster_id,
      created_ts: obj.data.created_ts,
      liker_id: compId !== "" ? compId : data.account_id,
      liker_type: compId !== "" ? "COMPANY" : "USER"
    };

    //api call
    //this.loader.show(true);
    await this.props.likeDislikePostAction(params);
    if (this.props.likeDislikeData.status === 200) {
      if (this.props.likeDislikeData.data !== undefined) {
        this.filterDataItems("likeItem", obj);
      } else {
        this.filterDataItems("unLikeItem", obj);
      }
      //this.loader.showAlert(true,'success',this.props.likeDislikeData.message)
    } else {
      this.loader.showAlert(true, "error", this.props.likeDislikeData.message);
    }
    //this.loader.show(false);
  };

  onPostActionHandler = async (obj,event) =>{

    const action = event.target.getAttribute('data-action');
    if(action==='showHideCommentBox'){
      const key = 'commentBox'+obj.index;
      this.setState({ 
        [key]: !this.state[key],
        hasMoreComments: false,
        nextPageCommentKey: '',
       },()=>{
        if(!Helper.existInArray(obj.data.id,tempPostIds) && obj.data.total_comments>0){
          tempPostIds.push(obj.data.id);
          this.getPostComments(obj,event);
        }
      }
      );
    } else if (action === "deletePost") {
      this.loader.show(true);
      await this.props.deletePostAction({
        id: obj.data.id,
        poster_id: obj.data.poster_id,
        created_ts: obj.data.created_ts
      });
      if (this.props.deletedPostData.status === 200) {
        this.filterDataItems("deleteItem", obj);
        this.loader.showAlert(
          true,
          "success",
          this.props.deletedPostData.message
        );
      } else {
        this.loader.showAlert(
          true,
          "error",
          this.props.deletedPostData.message
        );
      }
      this.loader.show(false);
    } else if(action==='editPost'){
      this.loader.show(true);
        await localStorage.setItem('POST_DATA',obj);
      this.props.setSelectedPostData(obj);
      this.loader.show(false);
    }
  };

  getPostComments = async (obj, event) => {
    this.setState({ currCommentsPostId: obj.data.id });
    const params = {
      post_id: obj.data.id,
      pageStage: this.state.nextPageCommentKey,
      fetch_size: Helper.fetchSize.comments
    };

    //api call
    await this.props.getPostCommentsAction(params);
    if (this.props.postComments.status === 200) {
      const {
        hasMoreRecords,
        nextPage,
        comments,
        commenter_details
      } = this.props.postComments.data;

      let commenterDetails = this.state.commenterDetails;
      if (commenter_details !== undefined && commenter_details !== null) {
        const keys = Object.keys(commenter_details);
        if (keys.length > 0) {
          for (let i = 0; i < keys.length; i++) {
            let image = Constants.defaultImageUrls.user;
            if (commenter_details[keys[i]]["image"] !== null)
              image =
                process.env.REACT_APP_BUCKET_PATH +
                "/" +
                commenter_details[keys[i]]["image"]["image_path"];
            commenterDetails[keys[i]] = {
              name: commenter_details[keys[i]]["name"],
              image: image
            };
          }
        }
      }

      let filteredArray = this.state.items.filter(item => {
        if (item.post_comments === undefined) {
          item.post_comments = [];
        }
        if (item.id === obj.data.id) {
          const prevComments = item.post_comments;
          item.post_comments = [...comments, ...prevComments];
        }
        return item;
      });
      this.setState({
        hasMoreComments: hasMoreRecords,
        nextPageCommentKey: nextPage,
        commenterDetails: commenterDetails,
        items: filteredArray
      });
    }
  };

  filterDataItems = (opt, obj) => {
    let filteredArray = this.state.items.filter(item => {
      if (opt === "deleteItem") {
        if (item.id !== obj.data.id) {
          return item;
        }
      } else if (opt === "likeItem" || opt === "unLikeItem") {
        if (item.id === obj.data.id) {
          item.total_likes =
            opt === "likeItem" ? item.total_likes + 1 : item.total_likes - 1;
        }
        return item;
      } else if (opt === "commentItem") {
        if (item.id === obj.data.id) {
          item.total_comments = item.total_comments + 1;
        }
        return item;
      }
      else if (opt === "commentDelete") {
        if (item.id === obj.post_id) {
          item.total_comments = item.total_comments - 1;
        }
        return item;
      }
    });
    this.setState({ items: filteredArray });
  };
  editPostComment = async (editVal, k) => {

    this.state.items.map(val =>
      val.post_comments.map((cmnts, cmntind) => {
        if (cmntind === k) {
          this.setState({
            editBox: { show:!this.state.editBox.show, ind: k },
            commentEditText: editVal
          });
        }
      })
    );
  };
  updatePostComment = async (obj, ind,k, event) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ selectedIndex: obj.index });
    const params = {
      id: obj.id,
      post_id: obj.post_id,
      created_ts: obj.created_ts,
      text: this.state.commentEditText
    };
    await this.props.updatePostComments(params);
    if (this.props.editPostComments.status === 200) {
      
      let posts = this.state.items;
      let post_comments = posts[ind].post_comments;

      posts[ind].post_comments[k].text = this.props.editPostComments.data.updated_text
      
    this.setState({ items: posts,
         editBox:{show: false, ind: ''},  });
    }
  };
  deletePostComment = async (obj, ind,createdTS,k, event) => {
    this.setState({ selectedIndex: obj.index,isRelatedBox:k });
    const params = {
      poster_id: obj.poster_id,
      post_id: obj.post_id,
      id: obj.id,
      created_ts:obj.created_ts,
      post_created_ts: createdTS
    };
    await this.props.deletePostComments(params);

    if (this.props.deleteComments.status === 200) {
      this.filterDataItems("commentDelete", obj);

      let posts = this.state.items;
      let post_comments = posts[ind].post_comments;

      let filteredArray = post_comments.filter(item => {
        if (item.id !== obj.id) {
          return item;
        }
      });

      posts[ind].post_comments = filteredArray;
      
      this.setState({ items: posts,
              editBox:{show: false, ind:''},  });
      }
  };
  goToCompanyTimeline = (company_id,commenter_type) =>async(e) =>{
    this.loader.show(true);
    if(commenter_type===1){
      return null
    }
    else if(this.props.page==='news_feed'){
        //set default company Id
        Helper.setDefaultCompany(company_id);

        // get selected company details
        const paramsData = {
            account_id:this.props.userData.data.account_id,
            requester_id: Helper.getDefaultCompanyId(),
            company_id:company_id,
        }
        await this.props.getCompanyDetailsByIdAction(paramsData);
        if(this.props.companyDetails.status===200){
            await this.props.setSelectedOtherCompanyInfoAction(this.props.companyDetails);
          //get reviews   
              await this.props.getReviewBycompanyidAction({company_id:company_id})
                 Helper.setReviewCounter(this.props.reviewerData);
                this.props.history.push('/company-timeline')
        } else {
            this.loader.showAlert(true,'error',this.props.companyDetails.message)
        }
    }
            else{
      this.props.goToTimeLine(company_id);
    }
    this.loader.show(false);
   
}


  render() {
    const postCardPros = {
      executeOnClick: this.executeOnClick,
      onChangeHandler: this.onChangeHandler,
      savePostComment: this.savePostComment,
      postLikeDislike: this.postLikeDislike,
      onPostActionHandler: this.onPostActionHandler,
      getPostComments: this.getPostComments,
      editPostComment: this.editPostComment,
      updatePostComment: this.updatePostComment,
      deletePostComment: this.deletePostComment,
      goToCompanyTimeline:this.goToCompanyTimeline
    };
    return (
      <>
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.props.fetchMoreData}
          hasMore={this.props.state.hasMoreRecords}
          scrollThreshold={0.4}
          loader={
            this.props.state.hasMoreRecords && (
              <div style={{ textAlign: "center", marginTop: "15px" }}>
                <SimpleLoader />
              </div>
            )
          }
        >
         <PostCard page={this.props.page} state={this.state} isSelfCompany={this.props.isSelfCompany} datalist={this.state.items} likes={this.props.state.likes} postCardProps={postCardPros} loadingProps={this.props.loading} companyDetails={this.props.ownCompanyDetails} />
      </InfiniteScroll>
      
      {/* loader Start */}
        <Loader onRef={ref => {this.loader = ref;}}/>
      {/* loader Start */}
      </>
    );
  }
}

function PostCard(props) {
  let state = props.state;
  let items = props.datalist.map((val, index) => {
    let postData = { index, data: val };
    let liked = false;
    if (
      Helper.existInArray(val.id, props.likes) ||
      state["postLikeDislike" + index]
    ) {
      liked = true;
    }

    let moreComments = false;
    if (val.total_comments > 2) {
      moreComments = true;

      if (
        val.post_comments !== undefined &&
        val.post_comments.length !== undefined
      ) {
        const totalComments = parseInt(val.total_comments);
        const postComments = parseInt(val.post_comments.length);

        if (totalComments === postComments) {
          moreComments = false;
        }
      }
    }

    let tags = "";
    const postTags = val.tag;
    if (postTags !== undefined && postTags !== null) {
      if (postTags.length > 0) {
        tags = postTags.join("BS,");
        let newArr = [];
        for (var i = 0; i < postTags.length; i++) {
          newArr[i] = '<a class="tags-color">#' + postTags[i] + "</a>";
        }
        tags = newArr.join(" ");
      }
    }

    let companyLogo = (val.poster_image !== null && val.poster_image !== undefined) ? process.env.REACT_APP_BUCKET_PATH+'/'+val.poster_image : Constants.defaultImageUrls.companyLogo;
    let companyName = val.poster_name;
    let level = '';
    if(props.page==='news_feed'){
      if(props.state.companies[val.poster_id]!==undefined){
        companyLogo = (props.state.companies[val.poster_id].image!==null) ? process.env.REACT_APP_BUCKET_PATH+'/'+props.state.companies[val.poster_id].image : Constants.defaultImageUrls.companyLogo;
        companyName = props.state.companies[val.poster_id].name;
      }

      if(props.state.levelDetails[val.poster_id]!==undefined){
        if(props.state.levelDetails[val.poster_id]>3){
          level = '3+';
        } else {
          level = Helper.ordinalSuffix(props.state.levelDetails[val.poster_id]);
        }
      }
    }
    return (
      <div key={index} className="box bg-white border blogContainer">
        <div className="box-body">
          <div className="userInfo d-flex justify-content-between">
            <div>
              <img alt="logo" src={companyLogo} />
              <div className="info">
                <h5 className="userName">
                  <a href="#" onClick={props.postCardProps.goToCompanyTimeline(val.poster_id,2)}>
                    {companyName} 
                    {(level!=='') && <span class="level-user">• {level}</span>}
                    <br />
                  </a>
                </h5>
                <div className="subTitle text-black-50 text-sm">
                  {" "}
                  {Helper.timeSince(val.created_ts)}
                </div>                
              </div>
            </div>
            <div className="">
              <ul>
                <li className="dropdown">
                  <button
                    type="button"
                    className="btn userBtn"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v"></i>
                    </button>
                    <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                      { (props.isSelfCompany) && <span className="dropdown-item act-span" data-action="editPost" onClick={props.postCardProps.onPostActionHandler.bind(this,postData)} data-toggle="modal" data-target="#newPostModal"> Edit </span> }
                      { (props.isSelfCompany) && <span className="dropdown-item act-span" data-action="deletePost" onClick={props.postCardProps.onPostActionHandler.bind(this,postData)}> Delete </span> }
                    </div>
                  </li>
                </ul>
              </div>
          </div>
          <h5 style={{marginTop: "6px"}}>
            {val.heading}
            <br />
          </h5>
          <div className="blogSection">
                <PostDesc post_text={val.post_text}  executeOnClick={props.postCardProps.executeOnClick}/>    

                { (tags!=='') && <><div dangerouslySetInnerHTML={{__html: tags}} /><br/></>}
                { val.media_image!==null && <figure><img alt="Post Image" src={process.env.REACT_APP_BUCKET_PATH+'/'+val.media_image.imagePath} /></figure> }
                { val.media_video!==null && <figure>
                  <video width="500" controls controlsList="nodownload noremoteplayback">
                    <source src={process.env.REACT_APP_BUCKET_PATH+'/'+val.media_video.videoPath} />
                    Your browser does not support this video.
                  </video>
                </figure> }
                
              <div className="blogFooter">
                  <ul className="inlineList">
                      <li><span> {val.total_likes} Like </span></li>
                      <li><span> {val.total_comments} Comments </span></li>
                  </ul>
              </div>
          </div>
        </div>
        <div className="box-footer bg-light border-top  text-sm text-black-50">
          <ul className="inlineList">
            <li>
              <div className="pretty p-icon p-toggle p-plain">
                <input
                  type="checkbox"
                  name=""
                  onClick={props.postCardProps.postLikeDislike.bind(
                    this,
                    postData
                  )}
                />
                {props.loadingProps.POST_LIKE_DISLIKE_ACTION &&
                state.selectedIndex === index ? (
                  <SimpleLoader size="s" type="dotted" />
                ) : (
                  <div className={`state ${liked ? "p-primary-o" : ""}`}>
                    {liked ? (
                      <i className="icon fa fa-thumbs-up" />
                    ) : (
                      <i className="icon fa fa-thumbs-o-up " />
                    )}
                    <label>Like</label>
                  </div>
                )}
              </div>
            </li>
            <li>
              <span
                className="commentBtn cursor-pointer"
                data-action="showHideCommentBox"
                onClick={props.postCardProps.onPostActionHandler.bind(
                  this,
                  postData
                )}
              >
                <i className="fa fa-comment-o" /> Comment{" "}
              </span>
            </li>
          </ul>
        </div>
        <div
          className="commentContainer"
          style={{ display: !state["commentBox" + index] ? "none" : "" }}
        >
          {props.loadingProps.GET_POST_COMMENTS_ACTION &&
            props.state.currCommentsPostId === val.id && (
              <SimpleLoader size="s" type="dotted" />
            )}
          {moreComments && (
            <a
              className="load-comment-a cursor-pointer"
              onClick={props.postCardProps.getPostComments.bind(this, postData)}
            >
              {" "}
              Load Previous Comments
            </a>
          )}
          <CommentsPopulate val={val} index={index} {...props} />
            <div className="addComment">
              <div className="img">
                <img src={Helper.getCompanyInfo(props.companyDetails,"company_logo")}/>
              </div>
              <div className="comment">
                <div className="input-group">
                  <div className="inputDiv form-control">
                    <input
                      type="text"
                      className="form-control"
                      name="commentText"
                      value={props.state.commentText}
                      placeholder="Write a comment..."
                      onChange={props.postCardProps.onChangeHandler}
                    />
                  </div>
                  <div className="input-group-append">
                    <div>
                      {/* <button className="btn btn-outline-secondary" type="button"><i className="fa fa-smile-o"></i></button>
                              <button className="btn btn-outline-secondary" type="button"><i className="flaticon-picture"></i></button> */}

                      {props.loadingProps.ADD_POST_COMMENT_ACTION &&
                      state.selectedIndex === index ? (
                        <div className="loader">
                          <SimpleLoader size="s" type="dotted" />
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={props.postCardProps.savePostComment.bind(
                            this,
                            postData
                          )}
                        >
                          <i className="fa fa-paper-plane-o"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  });
  return <>{items}</>;
}

function PostDesc(props) {
  //return <div dangerouslySetInnerHTML={{__html: props.post_text}} />;
  return (
    <ShowMoreText
      lines={Helper.expandedLine}
      more="Show more"
      less="Show less"
      anchorClass="show-more-less-btn"
      onClick={props.executeOnClick}
      expanded={false}
    >
      {props.post_text}
    </ShowMoreText>
  );
}
function CommentsPopulate(props) {
  let commentList = [];
  var createdTS=props.val.created_ts;
  if (props.val.post_comments !== undefined) {
    if (props.val.post_comments.length > 0) {
      commentList = props.val.post_comments.map(function(v, k) {
        const date = Helper.getDateObj(v.created_ts);
        // v.name = '';
        // v.image = Constants.defaultImageUrls.user;
        const companyDetails = props.state.commenterDetails;
        if (companyDetails[v.commenter_id] !== undefined) {
          v.name = companyDetails[v.commenter_id]["name"];
          v.image = companyDetails[v.commenter_id]["image"];
        } 
        
        //set conditon comment action btns show or not
        const companyId = localStorage.getItem('SELECTED_COMPANY_ID');
        let showBtns = false;
        if (v.commenter_id === companyId) {
          showBtns = true;
        } else if (v.commenter_id === props.state.userAccId) {
          showBtns = true;
        }        
        return (
          <div key={k} className="addComment addCommentText ">
            <div className="img">  <img src={v.image} /></div>
            <div className="comment">
              <div className="userName"><a style={{ textTransform: "capitalize",cursor:'pointer' }} onClick={props.postCardProps.goToCompanyTimeline(v.commenter_id,v.commenter_type)}>{v.name}</a></div>
                  <p className="userComment"><span>{v.text}</span>
                  { showBtns && <>
              {/* Delete Comment */}
                {props.loadingProps.DELETE_POST_COMMENTS && props.state.isRelatedBox===k ? ( <SimpleLoader size="s" type="dotted" />):(
                  <button  className="btn editPost deletePost"  title="Delete Comment"  onClick={() => props.postCardProps.deletePostComment(v,props.index,createdTS,k) }>
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                )}
              {/* Edit Comment */}
                <button  className="btn editPost"  title="Edit Comment"  onClick={() =>props.postCardProps.editPostComment(v.text, k)}>
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </button>
                 </> }
                  </p>
                  <div className="timeSection">
                {date.dayNum}-{date.monthShort}-{date.fullYear} {date.hours12}:
                {date.minutes}
                {date.ampm}
              </div>
                  { props.state.editBox.ind === k ?(
                  // {/* // Edit Input Box */}
                  <div className=" edit_commentBox">
                    <div className="input-group">
                      <div className="inputDiv form-control">
                        <input type="text" className="form-control" name="commentEditText" value={props.state.commentEditText} placeholder="Write a comment..." onChange={props.postCardProps.onChangeHandler}/>
                          </div>
                            <div className="input-group-append">
                              <div>
                                {props.loadingProps.UPDATE_POST_COMMENTS ? (
                                  <SimpleLoader size="s" type="dotted" />
                            ) : (
                              <button type="button" className="btn btn-outline-secondary" onClick={() =>props.postCardProps.updatePostComment(v,props.index,k)}> <i className="fa fa-paper-plane-o"></i></button>
                            )}
                              </div>
                                </div>
                              </div>
                            </div>
                  ):''}
                  {/* <p className="userComment"><span>{v.text}</span>
                    <button className="btn editPost" title="EditPost" onClick={() =>   props.postCardProps.editPostComment(v.text, k) } >
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                  </p>
                )} */}

              
            </div>
            {/* <div className="tools">
                <ul>
                    <li>
                        <a href="javascript:;"><i className="fa fa-reply"></i> Reply</a>
                    </li>
                </ul>
            </div> */}
          </div>
        );
      });
    }
  }

  return (
    <>
      <div className="showcomments">{commentList}</div>
    </>
  );
}
const mapStateToProps = state => ({
  userData: state.auth.userData,
  deletedPostData: state.appData.deletedPostData,
  commentData: state.appData.newAddedPostComment,
  postComments: state.appData.postComments,
  likeDislikeData: state.appData.newLikeDislikePostData,
  otherCompanyDetails: state.appData.otherCompanyDetails,
  //companyDetails: state.appData.companyDetails,
  companyDetails: state.appData.ownCompanyDetails,
  ownCompanyDetails: state.appData.ownCompanyDetails,
  loading: state.loading,
  editPostComments: state.apiData.editPostComments,
  deleteComments: state.apiData.deleteComments,
  reviewerData: state.apiData.reviewerData,

});
function mapDispatchToProps(dispatch) {
  return {
    deletePostAction: bindActionCreators(deletePostAction, dispatch),
    likeDislikePostAction: bindActionCreators(likeDislikePostAction, dispatch),
    addPostCommentAction: bindActionCreators(addPostCommentAction, dispatch),
    getPostCommentsAction: bindActionCreators(getPostCommentsAction, dispatch),
    updatePostComments: bindActionCreators(updatePostComments, dispatch),
    deletePostComments: bindActionCreators(deletePostComments, dispatch),
    getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
    setSelectedOtherCompanyInfoAction: bindActionCreators(setSelectedOtherCompanyInfoAction, dispatch),  
    getReviewBycompanyidAction: bindActionCreators(getReviewBycompanyidAction, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewsList);
