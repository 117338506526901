import React from 'react';
import LeftPanel from './LeftPanel';
import Header from './Header';
import MainHeader from './MainHeader';
import Footer from '../../SubComponents/Footer/Footer';
import CompanyAbout from './About/CompanyAbout';
import CompanyAboutForm from './About/CompanyAboutForm';
import AlertBox from '../../DialogBox/AlertBox';
import Connection from './CompanyConnection/Connection';

/*
about -> 1,
about-form -> 2,
timeline -> 3,
connections -> 4,
rating -> 5,
review -> 6,
setting -> 7
*/
class CompanyDashboardMain extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            componentToShow: 1,
            isShowAlert: false,
            alertMessage: null
        }

        // this.handleComponentShow = this.handleComponentShow.bind(this);
    }

    handleComponentShow = (value) => {
        this.setState({ componentToShow: value });
    }

    handleClose = () => {
        this.setState({ isShowAlert: false });
    }

    handleOpen = (message) => {
        this.setState({ isShowAlert: true, alertMessage: message });
    }

    btnText = "OK";
    dialogTitle = "";

    render() {
        return (
            <React.Fragment>
                <MainHeader />
                <main className="mainBody container-fluid">
                    <div className="row">
                        <div className="container">
                            <div className="row no-gutters">
                                <LeftPanel handleComponentShow={this.handleComponentShow} />
                                <div className="col-9">
                                    <Header handleComponentShow={this.handleComponentShow} />
                                    {(this.state.componentToShow === 1) &&
                                        <CompanyAbout handleAlertOpen={this.handleOpen} handleComponentShow={this.handleComponentShow} />
                                    }
                                    {(this.state.componentToShow === 2) &&
                                        <CompanyAboutForm handleAlertOpen={this.handleOpen} />
                                    }
                                    {(this.state.componentToShow === 4) &&
                                        <Connection handleAlertOpen={this.handleOpen} />
                                    }
                                </div>
                            </div>
                            <AlertBox title={this.dialogTitle} content={this.state.alertMessage}
                                btnText={this.btnText} open={!!this.state.isShowAlert}
                                onClose={() => this.handleClose()} />
                        </div>
                    </div>
                </main>
                <Footer />
            </React.Fragment>
        )
    }
}

export default CompanyDashboardMain;