import React from 'react';
import Helper from '../../../../Configs/Helper'
import ReactBootstrapTable from '../../TableComponent/ReactBootstrapTable';
// import { getBlockAccounts } from '../../../../api/agent';
import Constants from '../../../../utils/Constants';
import { connect } from "react-redux";
import { getcompanyPokeByStatusAction,updatecompanyPokeAction } from "../../../../store/actions";
import { bindActionCreators } from "redux";
import CircularProgress from '@material-ui/core/CircularProgress';

const COMPANY_DEFAULT_LOGO = Constants.defaultImageUrls.companyLogo;
const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;


class PokeUsers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            COMPANY_ID: "",
            ACCOUNT_ID: "",
            pokeDetails: [],
            errorMessage: "",
            isCancelLoading:false
        }
    }
    goToCompanyTimeline=(company_id)=>{
        this.props.goToTimeLine(company_id);

    }

    keyField = "company_id";
    cellEditProperties = {
        mode: "click",
        blurToSave: true
    };
    defaultSorted = [
        {
            dataField: "poked_id",
            order: "desc"
        }
    ];

    isUnmounted = false;

    getAllPokeConnectionRequest = async() => {
        
        let requestParams = {
            poker_id:this.state.COMPANY_ID,
            // '5e1852492e86b56e6412b0a1',
            user_id:this.state.ACCOUNT_ID,
            // '5da89588d22f560001354c71',
            // status: 0,
        }

        await this.props.getcompanyPokeByStatusAction(requestParams);

            console.log("");
            console.log("GET ALL POKE REQUEST SENT LIST");
            console.log(this.props.pokeDetails);
            console.log("");

            if (this.props.pokeDetails.status === 200) {
                this.setState({
                    pokeDetails: this.props.pokeDetails.data.poke_details
                });
            } else {
                this.setState({
                    errorMessage: "No pending request.",
                    pokeDetails: []
                });
            }
    }

    componentDidMount() {
        this.setState({
            COMPANY_ID: localStorage.getItem('SELECTED_COMPANY_ID'),
            ACCOUNT_ID: this.props.userData.data.account_id,
            isLoadingData: true
        }, () => { this.getAllPokeConnectionRequest(); })
    }
    componentWillUnmount() {
        this.isUnmounted = true;
    }

    render() {
            const pokeColumns = [
            {
                dataField: "poked_id",
                text: "Name",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                     return null;
                },
                editable: false,
                isDummyField: true,
                formatter: (cellContent, row, rowIndex, extraData) => {
                    if(this.props.pokeDetails!==undefined && this.props.poke_details!==null){
                        var company=this.props.pokeDetails.data.company_details[row.poked_id]
                    }
                    return (
                        <div className="userNameWithImg">
                            <div className="img">
                                <img style={{ height: "30px", width: "30px" }} src={company.company_logo!== null && company.company_logo!== undefined ? BUCKET_PATH +'/'+  company.company_logo.image_path : COMPANY_DEFAULT_LOGO} />
                            </div>
                            <a style={{ textTransform: "capitalize",cursor:'pointer' }} onClick={()=>this.goToCompanyTimeline(company.company_id)}>
                                {company.company_name}
                            </a>
                        </div>
                    );
                },
            },
            {
                dataField: "location.city",
                text: "Location",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                formatter: (cellContent, row, rowIndex, extraData) => {
                    if(this.props.pokeDetails!==undefined && this.props.poke_details!==null){
                        var company=this.props.pokeDetails.data.company_details[row.poked_id]
                    }
                    return (
                        company.location === null || company.location.city === null ?
                            `-`
                            :
                            company.location.city
                    );
                },
                editable: false
            },
            {
                dataField: "industry_type",
                text: "Sector",
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<span className="order-4"></span>);
                    else if (order === 'asc') return (<span className="caret-4-asc"></span>);
                    else if (order === 'desc') return (<span className="caret-4-desc"></span>);
                    return null;
                },
                formatter: (cellContent, row, rowIndex, extraData) => {
                    if(this.props.pokeDetails!==undefined && this.props.poke_details!==null){
                        var company=this.props.pokeDetails.data.company_details[row.poked_id]
                    }
                    return (
                        company.industry_type === null ?
                            `-`
                            :
                            company.industry_type
                    );
                },
                editable: false
            },
            // {
            //     dataField: "action",
            //     isDummyField: true,
            //     text: "Actions",
            //     editable: false,
            //     formatter: (cellContent, row, rowIndex, extraData) => {
            //         return (
            //             <React.Fragment>
            //                 {/* {row.status!==1 ? <> */}
            //                 <button style={{ width: "47px", height: "23px" }} onClick={() => extraData.updateStatus(1, this.state.COMPANY_ID, row.poker_id, row.poked_id, rowIndex)} className={`btn btn-sm btn-success ${extraData.isAccepting === true || extraData.isRejecting === true ? 'disabled' : ''}`}>
            //                     {
            //                         extraData.state["isAccepting" + rowIndex] === true ?
            //                             <CircularProgress className="loadingSpinnerBtn2" />

            //                             : "Accept"
            //                     }
            //                 </button>
            //                 <button onClick={() => extraData.updateStatus(2, this.state.COMPANY_ID, row.poker_id, row.poked_id, rowIndex)} className={`btn btn-sm btn-danger ${extraData.isRejecting === true || extraData.isAccepting === true ? 'disabled' : ''}`} style={{ marginLeft: "10px", width: "43px", height: "23px" }}>
            //                     {
            //                         extraData.state["isRejecting" + rowIndex] ?
            //                             <CircularProgress className="loadingSpinnerBtn2" />

            //                             : "Reject"
            //                     }
            //                 </button>
            //                {/* </> : */}
            //                 {/* <button onClick={() => extraData.updateStatus(2, this.state.COMPANY_ID, row.poker_id, row.poked_id, row.request_type, rowIndex)} className={`btn btn-sm btn-info ${extraData.isRejecting === true || extraData.isAccepting === true ? 'disabled' : ''}`} style={{ marginLeft: "10px", width: "75px", height: "23px" }}>
            //                 {
            //                     extraData.state["isRejecting" + rowIndex] ?
            //                         <CircularProgress className="loadingSpinnerBtn2" />

            //                         : "Unpoke"
            //                 }
            //             </button> */}
            //     {/* } */}
            //             </React.Fragment>
            //         );
            //     },
            //     formatExtraData: {
            //         updateStatus: this.updatecompanyPokeAction,
            //         state: this.state
            //     }
            // }
        ];
        return (
            <React.Fragment>
                <div className="detailsHeader">
                    Request <span>({this.state.pokeDetails!==null && this.state.pokeDetails!==undefined ?
                                    this.state.pokeDetails.length :''} )</span>
                </div>
                {this.state.errorMessage !== ""
                    ?
                    <div>
                        <h3>
                            {this.state.errorMessage}
                        </h3>
                    </div>
                    :
                    <ReactBootstrapTable
                        data={this.state.pokeDetails}
                        keyField={this.keyField}
                        cellEditData={this.cellEditData}
                        defaultSorted={this.defaultSorted}
                        columns={pokeColumns}
                    />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    companyDetails: state.appData.companyDetails,
    pokeDetails:state.apiData.pokeDetails,
    updatepokeData:state.apiData.updatepokeData,
    loading: state.loading,
});
function mapDispatchToProps(dispatch) {
   return {
      getcompanyPokeByStatusAction:bindActionCreators(getcompanyPokeByStatusAction, dispatch),
      updatecompanyPokeAction:bindActionCreators(updatecompanyPokeAction, dispatch),
   }
}
export default connect(
   mapStateToProps,
   mapDispatchToProps
)(PokeUsers);