import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import  '../../../style/css/style.css';
import Header from '../../SubComponents/Header/Header';
import Footer from '../../SubComponents/Footer/Footer';
// import {Button, Modal} from 'react-bootstrap';
import Helper from "../../../Configs/Helper";
import UserSuggestedCompanies from '../../Components/UserDashboard/UserSuggestedCompanies/UserSuggestedCompanies';
import Constants from '../../../utils/Constants';
import {getCompanyDetailsByIdAction,setSelectedOtherCompanyInfoAction,getReviewBycompanyidAction} from "../../../store/actions";
import Loader from "../../SubComponents/Loader/Loader";

const USER_DEFAULT_AVATAR = Constants.defaultImageUrls.user;
const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;


class SearchCompanies extends React.Component{
    constructor(props){
        super(props);
        this.state={
            search:0,
            text:''
        }
    }
    componentDidMount=()=>{
        this.setState({search:this.props.location.state})
            // [0],text:this.props.location.state[1]
    }
    goToCompanyTimeline = company_id => async (e) =>{
        console.log('go to timeline');
        this.loader.show(true);

        //set default company Id
        Helper.setDefaultCompany(company_id);

        // get selected company details
        const paramsData = {
            account_id:this.props.userData.data.account_id,
            requester_id: Helper.getDefaultCompanyId(),
            company_id:company_id,
        }
        await this.props.getCompanyDetailsByIdAction(paramsData);
        if(this.props.companyDetails.status===200){
            await this.props.setSelectedOtherCompanyInfoAction(this.props.companyDetails);
          //get reviews   
              await this.props.getReviewBycompanyidAction({company_id:company_id})
                 Helper.setReviewCounter(this.props.reviewerData);
                this.props.history.push('/company-timeline')
        } else {
            this.loader.showAlert(true,'error',this.props.companyDetails.message)
        }
        this.loader.show(false);
    }

render (){
    // console.log('this.props.typeaheadSearchresult')
    // console.log(this.props.typeaheadSearchresult)

    
    var searchResult =this.props.typeaheadSearchresult;
             

     return(
        <> 
        {/* Header Start */}
        <Header {...this.props} text={this.state.text} headerType="userDashboard" />
        {/* Header End */}
        
            <div className=" mainBody container searchList">
                <div className="row">
                    <div className="col-sm-12 col-md-3 padding-right-0">
                    {this.state.search===2 ?<>
                    <div className="box bg-white border searchInterest ">
                        <div className="box-header with-border  ">
                            <div className="title">search by interest</div>
                        </div>
                            <div className="s_interestlist">
                                <PopulateInterest
                                handleInterestSelect={this.handleInterestSelect}
                                />
                            </div>
                            <div className="s_buttons">
                                <div className="s_action">
                                    <button className="btn">Apply</button>
                                    <button className="btn">Clear all</button>
                                </div>
                            </div>
                        </div>
                    <div className="box bg-white border searchInterest ">
                        <div className="box-header with-border  ">
                            <div className="title">search by location</div>
                        </div>
                            <div className="s_interestlist">
                                <ul>
                                {Helper.getDropdownsVals('DialCodes').map((value, key) =>
                                 <li key={key}>{
                                     <div className="pretty p-default ">
                                        <input type="checkbox" value="Auto/Auto Ancillary"></input>
                                        <div className="state p-primary">
                                            <label>{value.name}</label>
                                        </div>
                                    </div>
                                    }
                                </li>)}
                                </ul>
                            </div>
                            <div className="s_buttons">
                                <div className="s_action">
                                    <button className="btn">Apply</button>
                                    <button className="btn">Clear all</button>
                                </div>
                            </div>
                        
                    </div>
                    </>:''}

                    </div>
                
                    <div className="col-sm-12 col-md-9">
                        <div className="box bg-white border ">
                        <div className="box-header ">
                            <div className="title">Your Searched Companies List</div>
                        </div>
                        {this.state.search!==2 ?
                          <UserSuggestedCompanies loader={this.loader} {...this.props}/>
                          :
                         <div className="box-body"> 
                            <ul className="deailsList pageList comp_searchlist">
                            {this.props.typeaheadSearchresult!==null && this.props.typeaheadSearchresult!==undefined &&
                            this.props.typeaheadSearchresult.data!==null && this.props.typeaheadSearchresult.data!==undefined ?
                                    this.props.typeaheadSearchresult.data.search_result.length === 0 ? 
                                  <li>
                                     <span>No details.</span>
                                 </li>
                                 :
                                 <PopulateSearch typeaheadSearchresult={this.props.typeaheadSearchresult} goToCompanyTimeline={this.goToCompanyTimeline}/>
                                 : <li><span>No details.</span></li>
                              } 
                                    </ul>
                                </div> }


                        </div>
                    </div>
                </div>
            </div>

        {/* footer Start */}
        <Footer {...this.props} />
        {/* footer End */}

         {/* loader Start */}
         <Loader onRef={ref => {this.loader = ref;}}/>
            {/* loader Start */}
        </>
    )
}
}

function PopulateInterest(props) {
    let list = Helper.getDropdownsVals("CompanyIndustries").map((value, key) => {
      return (
        <li key={key}>
          <div className="pretty p-default ">
            <input
              type="checkbox"
              onClick={props.handleInterestSelect}
              value={value.name}
            />
            <div className="state p-primary">
              <label>{value.name}</label>
            </div>
          </div>
        </li>
      );
    });
    return <ul>{list}</ul>;
  }
  function PopulateSearch(props) {

    var searchResultres = props.typeaheadSearchresult
    let list=searchResultres.data.search_result.map((result)=>{
      var data=searchResultres.data;
      var image='';
      if(result.poster_id!==null){
        if(data.poster_detail[result.poster_id].image!==null){
            image=data.poster_detail[result.poster_id].image
          }else{ image=USER_DEFAULT_AVATAR}
      }
      else if(result.company_profile_url!==null){
         image=result.company_profile_url ;
      }else{image=USER_DEFAULT_AVATAR}
    
      return (
                <li>
                     <div>
                        <div className="img">
                            <img src={image!==USER_DEFAULT_AVATAR ? BUCKET_PATH + "/" + image:image}/>
                        </div>

                        <div className="details">
                        {result.company_name!==null ?
                            <h4><a href="#" onClick={props.goToCompanyTimeline(result.id)}>{result.company_name}</a></h4>  
                                                   :
                            <h4><a href="#" onClick={props.goToCompanyTimeline(result.poster_id)}>{searchResultres.data.poster_detail[result.poster_id].name}</a></h4>
                        }
                    {result.company_sub_title!==null &&<h6 className="subTitle">{result.company_sub_title}</h6>}
                    {result.company_email!==null && <h6 className="subTitle"><b>Email:</b>{result.company_email} </h6>}
                    {result.company_type!==null && <h6 className="subTitle"><b>Type:</b>{result.company_type} &nbsp; &nbsp; | &nbsp; &nbsp; <b>Industry:</b> {result.industry_type}</h6>}
                    <p className="subTitle">{result.description!==null ?
                                                <><b>Description:</b>&nbsp;{result.description}</>
                                            :
                                                <><b>Post text:</b>&nbsp;{result.post_text}</>}</p>
                </div>
                    </div>
                    <div className="actions">
                    <ul>
                        <li className="dropdown">
                            <a className="btn btn-xs" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-globe"></i>
                            </a>
                        </li>
                        <li className="dropdown show">
                            <a className="btn btn-xs" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                Option
                            </a>
                            <div className="dropdown-menu " x-placement="bottom-start" style={{position: "absolute", transform: "translate3d(0px, 29px, 0px)", top: "0px", left: "0px", willChange: "transform"}}>
                                <a className="dropdown-item" style={{cursor:'pointer'}} 
                                // onClick={props.followCompanyHandler(compId)}
                                > Follow</a>
                                {/* {(props.state.companyId!=='') && <a className="dropdown-item" href="#" data-toggle="modal" data-target="#relationModal" onClick={props.setSelectedCompId.bind(this,compId)}> Connection </a> } */}
                            </div>
                        </li>
                    </ul>
                </div>    
                  </li>
              );
            });
    return list
    }
  
  const mapStateToProps = state => ({
    userData: state.auth.userData,
    companyDetails: state.appData.companyDetails,
    typeaheadSearchresult:state.apiData.typeaheadSearchresult,
    reviewerData: state.apiData.reviewerData,
    loading: state.loading,
    
  });
  function mapDispatchToProps(dispatch) {
    return {
        getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
        setSelectedOtherCompanyInfoAction: bindActionCreators(setSelectedOtherCompanyInfoAction, dispatch),
        getReviewBycompanyidAction: bindActionCreators(getReviewBycompanyidAction, dispatch),
    
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchCompanies);
