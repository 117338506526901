import React,{Component} from 'react';
import Helper from "../../../../Configs/Helper";
import { companyFollowAction, companyBlockAction, companyUnBlockAction,getConnectionStatusByCompany,companyUnFollowAction,getCompanyDetailsByIdAction} from "../../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserCompanies from '../../UserDashboard/UserCompanies/UserCompanies';


class CompanyActionsBtns extends Component{
    constructor(props) {
        super(props);
        this.state = {
            // is_blocked:'',
            // isRelation:'',
            ownCompanyDetails:{},
            otherCompanyDetails:{}, 
        }
    }
    componentDidMount(){
        const ConParamData={
            to_company_id: localStorage.getItem('OTHER_COMPANY_ID'),
            from_company_id: localStorage.getItem('SELECTED_COMPANY_ID'),
            }
        this.props.getConnectionStatusByCompany(ConParamData);
        this.setCompanyDetails(this.props);
    }
    componentWillReceiveProps(nxtProps){
        this.setCompanyDetails(nxtProps);
    }
    setCompanyDetails=(props)=>{
        this.setState({ 
            ownCompanyDetails: Helper.getCompanyDetailsObj(props.ownCompanyDetails),
            otherCompanyDetails: props.companyDetails,
            }) 
        }
    followCompanyHandler =async (e) =>{
        this.props.loader.show(true);
        const paramsData = {
            follower_id: (this.state.ownCompanyDetails.company_id!=='') ? this.state.ownCompanyDetails.company_id : this.props.userData.data.account_id, //company or user id
            following_id: this.state.otherCompanyDetails.company_id, //always company id
            follower_type: (this.state.ownCompanyDetails.company_id!=='') ? 2 : 1,
            status: 1,
            is_connected: 0,
            user_id: this.props.userData.data.account_id,
        };
        await this.props.companyFollowAction(paramsData);
        if(this.props.companyFollowRes.status===200){
            const paramsData = {
                account_id: this.props.userData.data.account_id,
                requester_id: Helper.getDefaultCompanyId(),
                company_id: localStorage.getItem('OTHER_COMPANY_ID'),
            }
            await this.props.getCompanyDetailsByIdAction(paramsData);
            if(this.props.detailscompany.status===200){    
            this.setState({otherCompanyDetails:Helper.getCompanyDetailsObj(this.props.detailscompany)})
            }
            this.props.loader.showAlert(true,'success',this.props.companyFollowRes.message)
        } else {
            this.props.loader.showAlert(true,'error',this.props.companyFollowRes.message)
        }
        this.props.loader.show(false);     
    }
    unfollowCompanyHandler = async (e) =>{
        this.props.loader.show(true);
        const paramsData = {
            id:this.state.otherCompanyDetails.follow_id,
            follower_id: (this.state.ownCompanyDetails.company_id!=='') ? this.state.ownCompanyDetails.company_id : this.props.userData.data.account_id, //company or user id
            following_id: this.state.otherCompanyDetails.company_id, //always company id
            follower_type: (this.state.ownCompanyDetails.company_id!=='') ? 2 : 1,
            status: 0,
            is_connected: 1,
            user_id: this.props.userData.data.account_id,
        };
        await this.props.companyUnFollowAction(paramsData);
        if(this.props.unfollow.status===200){
                const paramsData = {
                    account_id: this.props.userData.data.account_id,
                    requester_id: Helper.getDefaultCompanyId(),
                    company_id: localStorage.getItem('OTHER_COMPANY_ID'),
                }
                await this.props.getCompanyDetailsByIdAction(paramsData);
                if(this.props.detailscompany.status===200){    
                this.setState({otherCompanyDetails:Helper.getCompanyDetailsObj(this.props.detailscompany)})
                }
               this.props.loader.showAlert(true,'success',this.props.unfollow.message)
        } else {
            this.props.loader.showAlert(true,'error',this.props.unfollow.message)
        }
        this.props.loader.show(false);
    } 
    blockCompanyHandler = async (e) =>{
        this.props.loader.show(true);
        const paramsData = {
            blocker_id: this.state.ownCompanyDetails.company_id,
            blocked_id: this.state.otherCompanyDetails.company_id,
        };
        await this.props.companyBlockAction(paramsData);
        if(this.props.companyBlockRes.status===200){
            const paramsData = {
                account_id: this.props.userData.data.account_id,
                requester_id: Helper.getDefaultCompanyId(),
                company_id: localStorage.getItem('OTHER_COMPANY_ID'),
            }
            await this.props.getCompanyDetailsByIdAction(paramsData);
            if(this.props.detailscompany.status===200){    
            this.setState({otherCompanyDetails:Helper.getCompanyDetailsObj(this.props.detailscompany)})
            }
        this.props.loader.showAlert(true,'success',this.props.companyBlockRes.message)
        } else {
            this.props.loader.showAlert(true,'error',this.props.companyBlockRes.message)
        }
        this.props.loader.show(false);
    } 
    unBlockCompanyHandler = async (e) =>{
        this.props.loader.show(true);
        const paramsData = {
            blocker_id: this.state.ownCompanyDetails.company_id,
            blocked_id: this.state.otherCompanyDetails.company_id,
        };
        await this.props.companyUnBlockAction(paramsData);
        if(this.props.companyUnBlockRes.status===200){
            const paramsData = {
                account_id: this.props.userData.data.account_id,
                requester_id: Helper.getDefaultCompanyId(),
                company_id: localStorage.getItem('OTHER_COMPANY_ID'),
            }
            await this.props.getCompanyDetailsByIdAction(paramsData);
            if(this.props.detailscompany.status===200){    
            this.setState({otherCompanyDetails:Helper.getCompanyDetailsObj(this.props.detailscompany)})
            }
        this.props.loader.showAlert(true,'success',this.props.companyUnBlockRes.message)
        } else {
            this.props.loader.showAlert(true,'error',this.props.companyUnBlockRes.message)
        }
        this.props.loader.show(false);
    } 
    render(){
        return(
            <>
                <div className="box-body padding-0">
                <ul className="withIconList list timeline_icon">
                    <FollowLi state={this.state} followCompanyHandler={this.followCompanyHandler} unfollowCompanyHandler={this.unfollowCompanyHandler}/>
                    {/* {userCompanies > 0 ?<> */}
                      {this.props.userData.data!==null && this.props.userData.data!==undefined ?
                    this.props.userData.data.company_profiles!==null && this.props.userData.data.company_profiles!==undefined ?
                    this.props.userData.data.company_profiles.length > 0 ?
                 <>
                    <ConnectionLi state={this.state} props={this.props.state}/>
                    <BlockUnblockLi state={this.state} blockCompanyHandler={this.blockCompanyHandler} unBlockCompanyHandler={this.unBlockCompanyHandler}/>
                   </> :'':'':''}
                </ul>
            </div>
            {/* Start Connection Modal */}
                {/* <CompanyConnectionModal state={this.state} connectionCompanyHandler={this.connectionCompanyHandler} onChangeHandler={this.onChangeHandler}/> */}
             {/* End Connection Modal */}
            </>
        )
    }
}
function FollowLi(props){
 const compData = props.state.otherCompanyDetails;
 console.log("Current follow_status");
 console.log(compData.follow_status);

    if(compData.follow_status!==undefined && compData.follow_status!==""){
        if(compData.follow_status===1){
            return<li><button className="anchor-btn" type="button" onClick={props.unfollowCompanyHandler}>Unfollow </button></li>
        }else{
       return<li><button className="anchor-btn" type="button" onClick={props.followCompanyHandler}>Follow </button></li>
        }
    }else{
    return<li><button className="anchor-btn" type="button" onClick={props.followCompanyHandler}>Follow </button></li>
    }
}

function ConnectionLi(props){
    const compData = props.state.otherCompanyDetails;
    if((compData.is_connected!==undefined && compData.is_connected!=='') || props.props.is_sent===1 ){
        if(compData.is_connected===0 || props.props.is_sent===1) return <li><button className="anchor-btn" type="button" >Networks[Sent] </button></li>
        else if(compData.is_connected===1) return <li><button className="anchor-btn" type="button"> Connected </button></li>
        //else return null;
    }
    return <li><button className="anchor-btn" type="button" data-toggle="modal" data-target="#relationModal" >
        {/* <i className="flaticon-alarm-clock-1" /> */}
         Network </button></li>
}
function BlockUnblockLi(props){

    const compData = props.state.otherCompanyDetails;
 console.log("Current Block_status");
 console.log(compData.is_blocked);

    if(compData.is_blocked!==undefined && compData.is_blocked!==''){
        if(compData.is_blocked===1){
            return <li><button className="anchor-btn" type="button" onClick={props.unBlockCompanyHandler}>
            Un-Block </button></li>
        }else{
            return <li><button className="anchor-btn" type="button" onClick={props.blockCompanyHandler}>
            Block </button></li>
        }
    }else{
        return <li><button className="anchor-btn" type="button" onClick={props.blockCompanyHandler}>
        Block </button></li>
    }
}
const mapStateToProps = state => ({
    userData: state.auth.userData,
    ownCompanyDetails: state.appData.ownCompanyDetails,
    companyFollowRes: state.appData.companyFollowRes,
    companyBlockRes: state.appData.companyBlockRes,
    companyUnBlockRes: state.appData.companyUnBlockRes,
    connectionStatus:state.apiData.connectionStatus,
    sentConnReqRes: state.appData.sentConnReqRes,
    unfollow: state.apiData.unfollow,
    // otherCompanyDetails: state.appData.otherCompanyDetails,
    detailscompany: state.appData.companyDetails,
    loading: state.loading,
  });
function mapDispatchToProps(dispatch) {
    return {
        companyFollowAction: bindActionCreators(companyFollowAction, dispatch),
        companyBlockAction: bindActionCreators(companyBlockAction, dispatch),
        companyUnBlockAction: bindActionCreators(companyUnBlockAction, dispatch),
        getConnectionStatusByCompany:bindActionCreators(getConnectionStatusByCompany, dispatch),
        companyUnFollowAction: bindActionCreators(companyUnFollowAction, dispatch),
        getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyActionsBtns);