import * as firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAy9SlrGk9wH-xIA1KmVVDBBwP1ByyLRkA",
    authDomain: "comrate-1b464.firebaseapp.com",
    databaseURL: "https://comrate-1b464.firebaseio.com",
    projectId: "comrate-1b464",
    storageBucket: "comrate-1b464.appspot.com",
    messagingSenderId: "431700259073",
    appId: "1:431700259073:web:8727874582f46911292b00",
    measurementId: "G-NY59KCR3B5"
};


const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);

let messaging = '';
let isSupported = true;
if (firebase.messaging.isSupported()) {
    // messaging is supported
     //messaging = firebase.messaging();
    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(
         "BCHpbt0EXuHnUb2dzd23F8KECVzMuwLj9wOfVxtuqClYwDCu1XN6z_xbmAKq0SrgkynUdY6sPME95x78C9OR_Rg"
     );
  } else {
    isSupported = false;
    // messaging is not supported
    console.log('firebase messaing not supported this browser');
  }
export { isSupported, messaging };