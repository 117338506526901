import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from "../../../Configs/Helper";
import Header from "../../SubComponents/Header/Header";
import Footer from "../../SubComponents/Footer/Footer";
import Loader from "../../SubComponents/Loader/Loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveInterestAction,getUserByAccountIdAction } from "../../../store/actions";
class Interest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInterest: [],
    };
  }
  componentDidMount(){
    
  }
  handleInterestSelect = event => {
    let interestArr = this.state.selectedInterest;
    if (event.target.checked) {
      interestArr.push(event.target.value);
    } else {
      const index = interestArr.indexOf(event.target.value);
      if (index > -1) {
        interestArr.splice(index, 1);
      }
    }
    this.setState({ selectedInterest: interestArr });
  };
  handleSubmit = async (e) =>{
    const  postData = {
      account_id: this.props.userData.data.account_id,
      interests: '',
    }
    if(this.state.selectedInterest.length>0) {
      this.loader.show(true);
      const selectedInterest = this.state.selectedInterest;
      postData.interests = selectedInterest.join(',');
      await this.props.saveInterestAction(postData);
      if(this.props.interestData.status===200){
        await this.props.getUserByAccountIdAction(this.props.userData.data.account_id);
        //this.loader.showAlert(true,'success','');
        this.props.history.push('/company-options');
      } else {
        this.loader.showAlert(true,'error',this.props.interestData.message);
      }
      this.props.history.push('/company-options');
      this.loader.show(false);
    }
    
  }
  
  render() {
    return (
      <div>
        <Header {...this.props} headerType="beforeLogin" />
        <main
          className="mainBody container-fluid "
          style={{
            padding: "0px",
            background: "#fff",
            height: "calc(100% - 40px)"
          }}
        >
          <div className="pageHeader">What are you interested in?</div>
          <div className="container interestListContainer">
            <PopulateInterest
              handleInterestSelect={this.handleInterestSelect}
            />
            <br></br>
            <br></br>
            <div className="text-center">
              <button
                className="btn  btn-outline-primary "
                onClick={this.handleSubmit}
                disabled={this.state.selectedInterest.length===0 ? true : false}
              >
                Save and Continue
              </button>
              &nbsp;&nbsp;&nbsp;
              <Link to="/company-options" className="btn  btn-outline-primary" id="forOtp">Skip</Link>
            </div>
          </div>
        </main>
        <br></br>
        <br></br>
        <br></br>

        <Footer {...this.props} />
         {/* loader Start */}
         <Loader onRef={ref => {this.loader = ref;}}/>
        {/* loader Start */}
      </div>
    );
  }
}
function PopulateInterest(props) {
  let list = Helper.getDropdownsVals("CompanyIndustries").map((value, key) => {
    return (
      <li key={key}>
        <div className="pretty p-default ">
          <input
            type="checkbox"
            onClick={props.handleInterestSelect}
            value={value.name}
          />
          <div className="state p-primary">
            <label>{value.name}</label>
          </div>
        </div>
      </li>
    );
  });
  return <ul>{list}</ul>;
}
const mapStateToProps = state => ({
  userData: state.auth.userData,
  interestData: state.auth.interestData
});

function mapDispatchToProps(dispatch) {
  return {
    saveInterestAction: bindActionCreators(saveInterestAction, dispatch),
    getUserByAccountIdAction: bindActionCreators(getUserByAccountIdAction,dispatch),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Interest);
