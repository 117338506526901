import React from "react";
// import { Link } from "react-router-dom";
import Header from "../../SubComponents/Header/Header";
import Footer from "../../SubComponents/Footer/Footer";
import{Button, Form, FormControl, Nav, NavDropdown, Navbar} from 'react-bootstrap';
const TermConditions = props => (
  <div>
    <Header {...props} headerType="termsConditions"/>
    <main className="mainBody container-fluid">
  <div className="row">
    <div className="container">
     <h5>Terms & Conditions</h5>

    






     </div>
  </div>
</main>

    <Footer {...props} />
  </div>
);

export default TermConditions;
