import React, { Component } from "react";
import NotificationSetting from "./NotificationSetting";
import CompanySetting from "./CompanySetting";
import Advertisment from "../SideComponents/Advertisment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {saveCompanyAction, getCompanyDetailsByIdAction} from "../../../../store/actions";
// import Helper from "../../../../Configs/Helper"
import Loader from "../../../SubComponents/Loader/Loader";
// import { string } from "prop-types";


 class CompanyPageSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility:'',
      Notifications :'',
      Review:'',
      Address:'',
      CompanyProfile:'',
      setting: {
        form_type: "field_visibility",
        account_id:(this.props.userData!==null && this.props.userData!==undefined) ? this.props.userData.data.account_id:'',
        
      },
      show1: true,
    };
  }
  componentDidMount=()=>{


    this.setCompanyDetails();

  }

  setCompanyDetails=()=>{

    const companyDetails= this.props.companyDetails;
    if(companyDetails!==null && companyDetails!==undefined){
      if(companyDetails.data!==null && companyDetails.data!==undefined)
      if(companyDetails.data.companyInfo!==null && companyDetails.data.companyInfo!==undefined){
      if(companyDetails.data.companyInfo[0].privacy!==null && companyDetails.data.companyInfo[0].privacy!==undefined){
        var field=this.props.companyDetails.data.companyInfo[0].privacy.fieldVisibility;
        var privacy=this.props.companyDetails.data.companyInfo[0].privacy;
          this.setState({
                        visibility:privacy.visibility,
                        Notifications :{
                          post_visibility:privacy.post_visibility,
                          get_company_request:privacy.get_company_request,
                          accept_company_request:privacy.accept_company_request,
                          blog_post:privacy.blog_post,
                          poke:privacy.poke,
                        },
                        Review:{
                          rating_visibility:field.rating_visibility,
                          review_visibility:field.review_visibility,
                        }, 
                        Address:{
                          address_visibility:field.address_visibility,
                          city_visibility:field.city_visibility,
                          state_visibility: field.state_visibility,
                          country_visibility: field.country_visibility,
                          postal_code_visibility: field.postal_code_visibility,
                          longitude_visibility: field.longitude_visibility,
                          latitude_visibility: field.latitude_visibility,
                          mobile_number_visibility: field.mobile_number_visibility,
                          mobile_country_code_visibility: field.mobile_country_code_visibility,
                          fax_number_visibility: field.fax_number_visibility,
                        },
                        CompanyProfile:{
                          description_visibility:field.description_visibility,
                          website_url_visibility:field.website_url_visibility,
                          company_email_visibility:field.company_email_visibility,
                          year_founded_visibility:field.year_founded_visibility,
                          company_size_visibility:field.company_size_visibility,
                          about_company_visibility:field.about_company_visibility,
                          specialization_visibility:field.specialization_visibility,
                          team_visibility:field.team_visibility,
                          founder_visibility:field.founder_visibility,
                        },
                        
                      });
      }
    }
    }

  }

  onSelectHandler =(e)=> {  

var rState = this.state.Notifications;

Object.keys(rState).map((val)=>{
if(val===e.target.name){
  rState[val] = e.target.value
}
})

var review = this.state.Review;

Object.keys(review).map((val)=>{
if(val===e.target.name){
  review[val] = e.target.value
}
})
var address = this.state.Address;

Object.keys(address).map((val)=>{
if(val===e.target.name){
  address[val] = e.target.value
}
})
var CompanyProfile = this.state.CompanyProfile;

Object.keys(CompanyProfile).map((val)=>{
if(val===e.target.name){
  CompanyProfile[val] = e.target.value
}
})
     this.setState({
       //visibility:e.target.value,
       [e.target.name]:e.target.value,
      Notifications:rState,
      Review:review,
      Address:address,
      CompanyProfile:CompanyProfile
     },()=>{
      if(this.props.companyDetails!==null && this.props.companyDetails!==undefined){
        if(this.props.companyDetails.data!==null && this.props.companyDetails.data!==undefined){
      
            this.setState({
                setting: {
                  companyInfo_id: this.props.companyDetails.data.companyInfo[0].id,
                  company_id:this.props.companyId,
                  visibility:this.state.visibility,
                  ...this.state.setting,
                  ...this.state.Notifications,
                  ...this.state.Address,
                  ...this.state.CompanyProfile,
                  ...this.state.Review,
                  },
              });
  
  
          }
        }
     });

}

    onClickSaveChanges=async(e)=>{
    this.loader.show(true);
      await this.props.saveCompanyAction(this.state.setting);
      if(this.props.companyDetails.status===200){
        this.loader.showAlert(true,'success',this.props.companyDetails.message)
        const paramsData = {
        account_id: this.props.userData.data.account_id,
        company_id: this.props.companyId,
    }
    await this.props.getCompanyDetailsByIdAction(paramsData);
    console.log("set leatest details with with onclik save");
    console.log(this.props.companyDetails.data.companyInfo[0].privacy);
    var field=this.props.companyDetails.data.companyInfo[0].privacy.fieldVisibility;
    var privacy=this.props.companyDetails.data.companyInfo[0].privacy;
      this.setState({
        Notifications:{
        post_visibility:privacy.post_visibility,
        get_company_request:privacy.get_company_request,
        accept_company_request:privacy.accept_company_request,
        blog_post:privacy.blog_post,
        poke:privacy.poke,
      },
      Review:{
        rating_visibility:field.rating_visibility,
        review_visibility:field.review_visibility,
      },
      Address:{
        address_visibility:field.address_visibility,
        city_visibility:field.city_visibility,
        state_visibility: field.state_visibility,
        country_visibility: field.country_visibility,
        postal_code_visibility: field.postal_code_visibility,
        longitude_visibility: field.longitude_visibility,
        latitude_visibility: field.latitude_visibility,
        mobile_number_visibility: field.mobile_number_visibility,
        mobile_country_code_visibility: field.mobile_country_code_visibility,
        fax_number_visibility: field.fax_number_visibility,
      },
      CompanyProfile:{
        description_visibility:field.description_visibility,
        website_url_visibility:field.website_url_visibility,
        company_email_visibility:field.company_email_visibility,
        year_founded_visibility:field.year_founded_visibility,
        company_size_visibility:field.company_size_visibility,
        about_company_visibility:field.about_company_visibility,
        specialization_visibility:field.specialization_visibility,
        team_visibility:field.team_visibility,
        founder_visibility:field.founder_visibility,
      },
    })
  }else{
    this.loader.showAlert(true,'error',this.props.companyDetails.message)
     
  }
  this.loader.show(false);

    }
    

    onClickSettingHandlere = e => {
        e.preventDefault();
            if (e.target.id === "v-pills-profile-tab") {this.setState({ show1: true });}
                if (e.target.id === "v-pills-notifications-tab") {this.setState({ show1: false });}
    };

    render() {
        return (<>
            <div className="row no-gutters">
              <div className="col-8 middle-container">
                <div className="box bg-white border blogContainer">
                  <div className="box-body">
                  <ul style={{float:"right"}}><li>
                        <button className="btn btn-md btn-info" onClick={this.onClickSaveChanges}><b>Save</b> 
                        {/* &nbsp;
                            <i className="fa fa-check"></i> */}
                            </button>
                                </li></ul>
                    <div className="tab-content">
                        {this.state.show1 ? 
                        <CompanySetting
                          
                          visibility={this.state.visibility}
                          Review={this.state.Review}
                          Address={this.state.Address}
                          CompanyProfile={this.state.CompanyProfile}
                          onChange={this.onSelectHandler}
                           />
                       : 
                        <NotificationSetting
                          id="NotifiationSetting"
                          Notifications={this.state.Notifications}
                          onChange={this.onSelectHandler}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* Left Side container */}
              <div className="col-4 rightSide-container ">
                <div className="inner sticky">
                  <div className="box border">
                    <div className="box-header with-border ">
                      <div className="title">
                        <span> Settings </span>
                      </div>
                      <div></div>
                    </div>
                    <div className="box-body padding-0">
                      <ul
                        className="nav nav-pills  withIconList list "
                        role="tablist"
                      >
                        <li style={{ }}>
                          <a
                            className={
                              this.state.show1 ? "nav-link active" : "nav-link "
                            }
                            id="v-pills-profile-tab"
                            data-toggle="pill"
                            href="#v-pills-profile"
                            role="tab"
                            aria-controls="v-pills-profile"
                            aria-selected="true"
                            onClick={this.onClickSettingHandlere}
                          >
                            <i className="flaticon-briefcase"></i>
                            Company
                          </a>
                        </li>

                        <li style={{}}>
                          <a
                            className={
                              this.state.show1 ? "nav-link " : "nav-link active"
                            }
                            id="v-pills-notifications-tab"
                            data-toggle="pill"
                            href="#v-pills-notifications"
                            role="tab"
                            aria-controls="v-pills-notifications"
                            aria-selected="true"
                            onClick={this.onClickSettingHandlere}
                          >
                            <i className="flaticon-megaphone"></i>
                            Notifications
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Advertisment/>
                </div>
              </div>
            </div>
                                                       {/* loader Start */}
          <Loader onRef={ref => {this.loader = ref;}}/>
        {/* loader Start */} 

      </>
    );
  }
}


const mapStateToProps = state => ({
  userData: state.auth.userData,
  companyDetails: state.appData.companyDetails,

});

function mapDispatchToProps(dispatch) {
  return {
    saveCompanyAction: bindActionCreators(saveCompanyAction, dispatch),
    getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),

  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyPageSettings);
