import React from 'react'
// import { getCompanyDetailByCompanyId } from '../../../../api/agent';
import Constants from '../../../../utils/Constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCompanyDetailsByIdAction,setSelectedOtherCompanyInfoAction, setSelectedOwnCompanyInfoAction } from "../../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Helper from "../../../../Configs/Helper";


const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;
const USER_DEFAULT_IMAGE = Constants.defaultImageUrls.user;
const COMPANY_DEFAULT_LOGO = Constants.defaultImageUrls.companyLogo;
// const COMAPNY_DEFAULT_BG = Constants.defaultImageUrls.companyBg;


class CompanyAbout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSelfCompany: Helper.isSelfCompanyId(this.props.userData),

            account_id: this.props.userData.data.account_id,
            companyId: "",

            isLoadingData: false,
            isCompanyDataFound: true,
            errorMessage: "",
            company: {
                name: "",
                companySubTitle: "",
                description: "",
                logoUrl: "",
                bgUrl: "",
                founder: {},
                team: {},
                rating: 0,
                companyType: "",
                yearFounded: null,
                address: "",
                country: "",
                city: "",
                state: "",
                phoneNumber: "",
                websiteUrl: "",
            }
        }
    }

    handleComponentShow = (value) => {
        if (value === 2) value = 'edit';
        this.props.handleComponentShow(value);
    }
    async componentDidMount() {
        // const companyId =  await localStorage.getItem('OTHER_COMPANY_ID');
        // let compObjProps = this.props.ownCompanyDetails;
        
        // if(companyId!=='' && companyId!==null && companyId!==undefined){
        //     compObjProps = this.props.otherCompanyDetails;
           
        // }
        //***************** */
            let compObjProps = {};
            let isMyCompany = true;
            let companyId =  await localStorage.getItem('OTHER_COMPANY_ID');
            if(companyId!=='' && companyId!==null && companyId!==undefined){
                isMyCompany = false;
            } else {
                companyId =  await localStorage.getItem('COMPANY_ID');
            }
            const paramsData = {
                account_id:this.props.userData.data.account_id,
                requester_id: Helper.getDefaultCompanyId(),
                company_id:companyId,
            }
            await this.props.getCompanyDetailsByIdAction(paramsData);
            if(this.props.companyDetails.status===200){
               if(isMyCompany){
                    await this.props.setSelectedOwnCompanyInfoAction(this.props.companyDetails);
                    compObjProps = this.props.ownCompanyDetails;
               } else {
                    await this.props.setSelectedOtherCompanyInfoAction(this.props.companyDetails);
                    compObjProps = this.props.otherCompanyDetails;
               }
            }
        //******************** */
        this.setState({ companyId }, () => {
            this.getCompanyDetails(isMyCompany,compObjProps)
        })
        this.setState({ isLoadingData: true });
    }
    getCompanyDetails =  (isMyCompany,compObjProps) => {
        //const companyInfo = compObjProps;
        //const compObj = (isMyCompany) ? companyInfo.data.companyInfo[0]:companyInfo.data.companyInfo;
        //const companyData = compObjProps.data.company;
        const compObj = Helper.getCompanyDetailsObj(compObjProps);
        const company= {
            address: (compObj.location !== undefined && compObj.location !== null) ? compObj.location.address : '',
            city: (compObj.location !== undefined && compObj.location !== null) ? compObj.location.city : '',
            country: (compObj.location !== undefined && compObj.location !== null) ? compObj.location.country : '',
            postalCode: (compObj.location !== undefined && compObj.location !== null) ? compObj.location.postal_code : '',
            state: (compObj.location !== undefined && compObj.location !== null) ? compObj.location.state : '',
            stateMap: (compObj.location !== undefined && compObj.location !== null) ? compObj.location.state : "India",
            name: compObj.company_name,
            companySubTitle: compObj.company_sub_title,
            companyType: compObj.company_type,
            description: compObj.description,
            logoUrl: compObj.company_logo ,
            yearFounded: compObj.year_founded,
            websiteUrl: compObj.website_url,
            phoneNumber: compObj.mobile_number,
            founder: compObj.founder,
            team: compObj.team,
        }            
        this.setState({
            isCompanyDataFound: true,
            company:company
        });
    }


    render() {

        return (
            <div className="row no-gutters">
                {this.props.loading.GET_COMPANY_DETAILS_ACTION ?
                    <CircularProgress style={{ marginLeft: "400px", marginTop: "203px" }} />
                    :
                    <div className=" middle-container only_left" style={{ }}>
                        {(this.state.isCompanyDataFound === false) ?
                            <h3>{this.state.errorMessage}</h3>
                            :
                            <React.Fragment>
                                <div className="box bg-white border ">
                                    <div className="box-header padding-right-0">
                                        <div className="detailsHeader">About Us</div>
                                        {this.state.isSelfCompany ? <div>
                                            <a href="#about-form" onClick={() => this.handleComponentShow(2)} className="btn btn-sm btn-link"><i className="fa fa-edit"></i> Edit Info</a>
                                        </div> : ''}
                                    </div>
                                    <div className="box-body">
                                        <img alp="" className="responsive-img margin-bottom-15" src="https://www.incimages.com/uploaded_files/image/1940x900/shutterstock_721918399_357252.jpg" />
                                        <h4 className="margin-bottom-15">{this.state.company.name || "-"}</h4>
                                        <h6 className="subTitle margin-bottom-15">{this.state.company.companySubTitle || "-"}</h6>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at elit nec nibh vestibulum lobortis. Etiam at massa est. Etiam et rutrum dolor. Mauris aliquam tortor quam, et tempus elit dictum non. Aenean laoreet sodales dolor sed aliquet. Vivamus eu leo enim. Nunc suscipit nunc ut sem iaculis posuere. In eget sapien id justo tempor accumsan vehicula at nisl. Vivamus laoreet ultricies urna, blandit aliquet purus mattis quis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce eget nulla non turpis sodales auctor mollis eu tellus. Ut at cursus lorem, ac aliquet urna. Aliquam viverra erat vitae nisi elementum, ac pulvinar velit posuere.</p> */}
                                        <p>{this.state.company.description || "-"}</p>

                                        <ul className="deailsList">
                                            {/* <li>
                                                <div>
                                                    <div className="label">Rating</div>
                                                    <div className="details">
                                                        <span className="starRating">
                                                            <i className="fa fa-star fill"></i>
                                                            <i className="fa fa-star fill"></i>
                                                            <i className="fa fa-star fill"></i>
                                                            <i className="fa fa-star fill"></i>
                                                            <i className="fa fa-star-o"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </li> */}
                                            <li>
                                                <div>
                                                    <div className="label">Company Type</div>
                                                    <div className="details">
                                                        <span>{this.state.company.companyType || "-"}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="label">Year Founded</div>
                                                    <div className="details">
                                                        <span>{this.state.company.yearFounded || '-'}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box bg-white border ">
                                    <div className="box-header padding-right-0">
                                        <div className="title">Founder</div>
                                        {this.state.isSelfCompany ? <div>
                                            <a href="#about-form" onClick={() => this.handleComponentShow(2)} className="btn btn-sm btn-link"><i className="fa fa-edit"></i> Edit Info</a>
                                        </div> : ''}
                                    </div>
                                    <div className="box-body">
                                        <ul className="big-img-details-list">
                                            {
                                                (this.state.company.founder !== undefined && this.state.company.founder !== null) ?
                                                    Object.keys(this.state.company.founder).map((type) => {
                                                        return (
                                                            <li key={type}>
                                                                <div className="img">
                                                                    <img alt="" src={this.state.company.founder[type].image_path != null ? `${BUCKET_PATH + "/"}${this.state.company.founder[type].image_path}` : USER_DEFAULT_IMAGE} />
                                                                </div>
                                                                <div className="details">
                                                                    <h5 className="bold">
                                                                        <a href="#">{this.state.company.founder[type].name}</a>
                                                                    </h5>
                                                                    <p className="subTitle">{this.state.company.founder[type].designation}</p>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    :
                                                    <li>No founder.</li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="box bg-white border ">
                                    <div className="box-header padding-right-0">
                                        <div className="title">Team</div>
                                        {this.state.isSelfCompany ? <div>
                                            <a href="#about-form" onClick={() => this.handleComponentShow(2)} className="btn btn-sm btn-link"><i className="fa fa-edit"></i> Edit Info </a>
                                        </div> : ''}
                                    </div>
                                    <div className="box-body">
                                        <ul className="small-img-details-list">

                                            {
                                                (this.state.company.team !== undefined && this.state.company.team !== null) ?
                                                    Object.keys(this.state.company.team).map((type) => {
                                                        return (
                                                            <li key={type}>
                                                                <div className="img">
                                                                    <img src={this.state.company.team[type].image_path != null ? `${BUCKET_PATH + "/"}${this.state.company.team[type].image_path}` : USER_DEFAULT_IMAGE} />
                                                                </div>
                                                                <div className="details">
                                                                    <h5 className="bold">
                                                                        <a href="#">{this.state.company.team[type].name}</a>
                                                                    </h5>
                                                                    <p className="subTitle">{this.state.company.team[type].designation}</p>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    :
                                                    <li>No team.</li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="box bg-white border ">
                                    <div className="box-header padding-right-0">
                                        <div className="title">Address</div>
                                        {this.state.isSelfCompany ? <div>
                                            <a href="#about-form" onClick={() => this.handleComponentShow(2)} className="btn btn-sm btn-link"><i className="fa fa-edit"></i> Edit Info</a>
                                        </div> : ''}
                                    </div>
                                    <div className="box-body">
                                        <div className="mapouter">
                                            <div className="gmap_canvas">
                                                <iframe style={{ width: "100%", height: "250px" }} id="gmap_canvas" src={`https://maps.google.com/maps?q=${this.state.company.stateMap}&t=&z=13&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                                            </div>
                                        </div>
                                        <ul className="deailsList about_detail">
                                            <li>
                                                <div>
                                                    <div className="label">Address</div>
                                                    <div className="details">
                                                        <span>{this.state.company.address || "-"}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="label">City</div>
                                                    <div className="details">
                                                        <span>{this.state.company.city || "-"}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="label">State</div>
                                                    <div className="details">
                                                        <span>{this.state.company.state || "-"}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="label">Phone No</div>
                                                    <div className="details">
                                                        <span>{this.state.company.phoneNumber || "-"}</span>
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    <div className="label">Fax</div>
                                                    <div className="details">
                                                        <span>{this.state.company.faxNumber || "-"}</span>
                                                    </div>
                                                </div> */}
                                                <div>
                                                    <div className="label">Website url</div>
                                                    <div className="details">
                                                        <span><a href={this.state.company.websiteUrl || "#"}>{this.state.company.websiteUrl || "-"}</a></span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                }

            </div>
        )
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    companyDetails: state.appData.companyDetails,
    loading: state.loading,
    ownCompanyDetails: state.appData.ownCompanyDetails,
    otherCompanyDetails: state.appData.otherCompanyDetails,
});
function mapDispatchToProps(dispatch) {
    return {
        getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
        setSelectedOtherCompanyInfoAction: bindActionCreators(setSelectedOtherCompanyInfoAction, dispatch), 
        setSelectedOwnCompanyInfoAction: bindActionCreators(setSelectedOwnCompanyInfoAction, dispatch), 
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyAbout);
