import React,{Component} from 'react';
// import Helper from "../../../../Configs/Helper";
export default class CompanyCommunity extends Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    render(){
        if(this.props.companyDetails===null || this.props.companyDetails===undefined){
            return null;
        }
        const location = this.props.companyDetails.location;
        const email = this.props.companyDetails.company_email;
        const contact_num = this.props.companyDetails.mobile_number;
        const web_url = this.props.companyDetails.website_url;
        return(
            <div className="box border">
                <div className="box-body padding-0">
                    <ul className="withIconList list withoutHoverEffect">
                        { (location!==null && location.address!==undefined && location.address!=="") && <li><a><i className="fa fa-map-marker" /> <span> {location.address}</span> </a></li> }
                        { email!=='' && <li><a href={`mailto:${email}?Subject=Comrate`}><i className="fa fa-envelope" /> <span> {email}</span> </a></li> }
                        { contact_num!=='' && <li><a href={`tel:${contact_num}`}><i className="fa fa-phone" /> <span> {contact_num}</span> </a></li>}
                        { web_url!=='' && <li><a href={web_url} target="_blank"><i className="fa fa-globe" /> <span> {web_url}</span> </a></li> }
                    </ul>
                </div>
            </div>
        )
    }
}