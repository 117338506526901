import React,{Component} from 'react';
import $ from "jquery";
import { Link } from "react-router-dom";
import { getPostListAction,
         sentConnectionRequestAction,
         getReviewBycompanyidAction,
         getCompanyDetailsByIdAction,
         setSelectedOtherCompanyInfoAction,
         companyPokeAction,
         } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Helper from "../../../Configs/Helper"
import SimpleLoader from '../../SubComponents/Loader/SimpleLoader';
import Loader from "../../SubComponents/Loader/Loader";
import Footer from "../../SubComponents/Footer/Footer";
import Header from "../../SubComponents/Header/Header";
import AddPostModal from "../../SubComponents/AddPost/AddPostModal";
import CompanyProfileCard from './SideComponents/CompanyProfileCard';
import CompanyCommunity from './SideComponents/CompanyCommunity';
import CompanyActionBtns from './SideComponents/CompanyActionBtns';
import CompanyTrackerTrend from './SideComponents/CompanyTrackerTrend';
import RadarChart from './SideComponents/RadarChart';
import NewsFeed from "../../SubComponents/NewsList/NewsList";
import Advertisment from './SideComponents/Advertisment';
import SuggestedPages from '../GlobalPages/SuggestedPages';
import CompanyAbout from './About/CompanyAbout';
// import Members from './ComrateCompanyMembers/Members';
import Review from './Review/Review';
import CompanyPageSettings from './ComrateCompanyPageSetting/CompanyPageSettings';
import RatingForm from './Ratings/RatingForm';
import CompanyAboutForm from './About/CompanyAboutForm';
import AlertBox from '../../DialogBox/AlertBox';
import Connection from './CompanyConnection/Connection';
import CompanyConnectionModal from "../../Modals/CompanyConnectionModal/CompanyConnectionModal";
import BlankPageMessage from "../../Components/GlobalPages/BlankPageMessage";
import SuggestedCompanies from './SuggestedCompanies/SuggestedCompanies';
import constants from '../../../utils/Constants';
import RatingModal from '../../Modals/RatingModal/RatingModal';
import FollowersFollowings from './SideComponents/FollowersFollowings';
import { toNumber } from '@amcharts/amcharts4/.internal/core/utils/Type';
import PokeActionsButton from './SideComponents/PokeActionButton';
const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;

class CompanyDashboard extends Component{
    constructor(props){
        super(props);
        this.state={
            reviewCount:'',
            isRelation: 1,
            networkMsg:'',
            bannerTabs:Helper.getDropdownsVals('CompanyBannerTabs'),
            isSelfCompany: Helper.isSelfCompanyId(this.props.userData),
            activeBannerTab:2,
            leftBoxTabs:[
                {id:1, tab:'Comrating'},
                {id:2, tab:'Credit'},
                {id:3, tab:'Sentiment'},
                {id:4, tab:'Supply Chain'},
            ],
            activeLeftBoxTab:1,
            hasMoreRecords: false,
            nextPage: '',
            items: [],
            likes: [],
            //id:'5e020bd647b2e80001da2cf8',
            companyId: '',
            paramsData: {
                poster_id: '',
                is_poster: 0, //1=poster_id self company id
                created_ts: '',
                pageStage: '',
                fetch_size: ''
            },
            companyDetails:null,
            errorMessage :'data not found',
            companyCover:'',
            selectedPostData: null,
            is_sent:'',
            is_poked:'',

        }
    }
    async componentDidMount(){
        if(!this.state.isSelfCompany){
            const bannerTabs = this.state.bannerTabs
            delete bannerTabs[0]
            delete bannerTabs[3]
            delete bannerTabs[5]
            this.setState({ bannerTabs })
        }        
        //start set active tab
            const paramsTab = this.props.match.params.company_tab
            let filteredArray = this.state.bannerTabs.filter((tab)=>{ 
                if(tab.url === paramsTab)return tab;
            })
            if(filteredArray.length>0){
                this.setState({ activeBannerTab: filteredArray[0]['id'] })
            }
        //end set active tab
        
        const paramsData = this.state.paramsData;

        const companyId =  await localStorage.getItem('OTHER_COMPANY_ID');
        let compObjProps = this.props.ownCompanyDetails;
        paramsData.is_poster = 1;
        if(companyId!=='' && companyId!==null && companyId!==undefined){
            compObjProps = this.props.otherCompanyDetails;
            paramsData.is_poster = 0;
        }
        const compObj = Helper.getCompanyDetailsObj(compObjProps);
        this.setState({ companyId: compObj.id, companyDetails: compObj, paramsData },()=>{
            this.getPostList()
        })
        Helper.setReviewCounter(this.props.reviewerData);
        
        let count=localStorage.getItem('REVIEW_COUNT');
        this.setState({reviewCount:toNumber(count)});
    }

    getPostList = async () =>{
        const paramsData = this.state.paramsData;
        paramsData.poster_id = this.state.companyId;
        paramsData.pageStage = this.state.nextPage;
        await this.props.getPostListAction(paramsData);
        if(this.props.postListData.status===200){
            const { posts, like_post} = this.props.postListData.data
            const prevItems = this.state.items
            const prevLikes = this.state.likes
            this.setState({ 
                hasMoreRecords: posts.hasMoreRecords, 
                nextPage: posts.nextPage, 
                items: [...prevItems, ...posts.data],
                likes: (like_post!==undefined) ? [...prevLikes, ...like_post] : prevLikes,
                postlist:this.props.postListData.status,
             })
        } else {
            this.setState({ hasMoreRecords: false, postlist:this.props.postListData.message })
            //this.loader.showAlert(true,'error',this.props.postListData.message)
        }
    }

    fetchMoreData = () => {
        if(this.state.hasMoreRecords){
            this.setState({ nextPage: this.state.nextPage },()=>this.getPostList());
        }
        return true
    }

    bannerTabHandler=(id)=>e=>{    
        this.setState({ activeBannerTab: id })
        if(id===2){
            //this.getPostList()
        } 
    }
    leftBoxTabHandler=(id)=>e=>{    
        this.setState({ activeLeftBoxTab: id }) 
    }
    newAddedItem =addedItem=> {
        const companyDetails = this.state.companyDetails
        const { company } = this.props.ownCompanyDetails.data // for get only company logo

        let prevItems = this.state.items
        addedItem.image = { imagePath: '', imageUrl:companyDetails.company_logo }
        addedItem.poster_name = companyDetails.company_name
        addedItem.poster_image = null
        if(company!==undefined && company!==null){            
            if(company.company_logo!==undefined && company.company_logo!==null) addedItem.poster_image = company.company_logo.image_path;
        }
        
        this.setState({ items: [addedItem,...prevItems] })
    }
    updatedPostItem = (updatedItem, id)=> {
        //const companyDetails = this.state.companyDetails
        let prevItems = this.state.items
        let filteredArray = prevItems.map((item,k)=>{ 
            if(item.id === id){
                item = {...item,...updatedItem};
            }
            return item;            
          })
          this.setState({items:filteredArray})
    }

    onChangeHandler = (e) =>{
        this.setState({ isRelation: e.target.value})
    }
    connectionCompanyHandler= async ()=>{
        const { data } = this.props.userData;
        const ownCompObj = Helper.getCompanyDetailsObj(this.props.ownCompanyDetails);
        const otherCompObj = Helper.getCompanyDetailsObj(this.props.otherCompanyDetails);
        const postData={
            requester_id: (ownCompObj.company_id!=='') ? ownCompObj.company_id : data.account_id, //send from
            company_id: otherCompObj.company_id, //send to
            request_status: 0,
            request_type: this.state.isRelation,
            user_id: data.account_id,
        }
    
        this.loader.show(true);
        await this.props.sentConnectionRequestAction(postData);
        if(this.props.sentConnReqRes.status===200){
            this.setState({is_sent:1})
            $( ".close" ).trigger( "click" )
            this.loader.showAlert(true,'success',this.props.sentConnReqRes.message)
        } else {
          this.loader.showAlert(true,'error',this.props.sentConnReqRes.message)
        }
        this.loader.show(false);
      }

    handleComponentShow = (value) => {
        this.setState({ activeBannerTab: value });
    }

    handleClose = () => {
        this.setState({ isShowAlert: false });
    }

    handleOpen = (message) => {
        this.setState({ isShowAlert: true, alertMessage: message });
    }

    setSelectedCompId = (comp_id) =>{
        this.setState({ selectedCompanyId: comp_id})
    }
    setSelectedPostData = selectedPostData =>{
        this.setState({ selectedPostData });
    }
    reviewCounter=()=>{
        this.setState({reviewCount:this.state.reviewCount + 1})
    }


    welcomeToTimeLine=async (company_id)=>{

        //set default company Id
        Helper.setDefaultCompany(company_id);

        // get selected company details
        const paramsData = {
            account_id:this.props.userData.data.account_id,
            requester_id: Helper.getDefaultCompanyId(),
            company_id:company_id,
        }
        await this.props.getCompanyDetailsByIdAction(paramsData);
        if(this.props.companyDetails.status===200){
            await this.props.setSelectedOtherCompanyInfoAction(this.props.companyDetails);
              //get reviews   
            await this.props.getReviewBycompanyidAction({company_id:company_id})
            Helper.setReviewCounter(this.props.reviewerData);
            let count=localStorage.getItem('REVIEW_COUNT');
            this.setState({
                nextPage:'',
                items:[],   
                likes:[],
                reviewCount:toNumber(count),
                activeBannerTab:2,
                isSelfCompany:Helper.isSelfCompanyId(this.props.userData)
             })
            if(!this.state.isSelfCompany){
                const bannerTabs = this.state.bannerTabs
                delete bannerTabs[0]
                delete bannerTabs[3]
                delete bannerTabs[5]
                this.setState({ bannerTabs })
            }
    
        const companyId =  company_id;
        let compObjProps = this.props.ownCompanyDetails;
        if(companyId!=='' && companyId!==null && companyId!==undefined){
            compObjProps = this.props.otherCompanyDetails; 
        }
        const compObj = Helper.getCompanyDetailsObj(compObjProps);
        this.setState({ companyId: compObj.id, companyDetails: compObj,},()=>{
            this.getPostList()
        })
        } else {
            // this.loader.showAlert(true,'error',this.props.companyDetails.message)
        }
    }
    pokeCompanyHandler = async (e) =>{
          this.loader.show(true);
        const paramsData = {
              poker_id: (this.props.ownCompanyDetails.data.company.id!=='') ? this.props.ownCompanyDetails.data.company.id : '', //company or user id
              poked_id:this.state.companyDetails.company_id, //always company id
              user_id:this.props.userData.data.account_id,
           };
          await this.props.companyPokeAction(paramsData);
          if(this.props.pokeData.status===200){
              this.setState({is_poked:0})
  
              this.loader.showAlert(true,'success',this.props.pokeData.message)
          } else {
              this.loader.showAlert(true,'error',this.props.pokeData.message)
          }
          this.loader.show(false);
      }
  
    btnText = "OK";
    dialogTitle = "";
    
    render(){
        console.log('this.props.companyDetails');
        console.log(this.props.companyDetails);
        const comdet =this.state.companyDetails; 
        if(this.state.companyDetails===null || this.state.companyDetails===undefined){
            return null;
        } 
            var is_poked = comdet.is_poked;
        return(
            <div>
             <Header {...this.props} goToTimeLine={this.welcomeToTimeLine} headerType="newsFeed"/>
            <main className="mainBody container-fluid">
                <div className="row">
                    <div className="container">
                        <div className="row no-gutters">
                            {/* leftSide-container  */}
                                <div className="col-sm-12 col-md-3 leftSide-container">
                                    <div className="inner">
                                        {/* Start Profile Card */}
                                            <CompanyProfileCard companyDetails={comdet}/>
                                        {/* End Profile Card */}
                                        
                                        {/* Start Chart Card */}
                                        {this.props.ownCompanyDetails===null ?'':
                                        !this.state.isSelfCompany &&
                                            comdet.is_poked!==1 ?
                                            <div className="box bg-white border shadow-sm" style={{padding:'10px'}}>
                                            {is_poked===0 || this.state.is_poked===0 ?
                                                <button className="btn btn-info" style={{width:'100%'}}>Poked </button>
                                                :
                                                <button className="btn btn-info" style={{width:'100%'}} onClick={this.pokeCompanyHandler}>Poke</button>
                                            }
                                            </div>: 
                                            <div className="box ">
                                                <div className="left_content">
                                                    <ul className="nav nav-tabs nav-fill " role="tablist" style={{background:"#f9f9f9"}}>
                                                        {this.state.leftBoxTabs.map((tab,idx)=>(
                                                            <li key={tab.id} className="nav-item">
                                                                <span onClick={this.leftBoxTabHandler(tab.id)} className={tab.id=== this.state.activeLeftBoxTab ? 'nav-link active' : 'nav-link' } data-toggle="tab"  role="tab" style={{padding: "10px 0px",cursor:'pointer'}} aria-controls="one" aria-selected="true"> {tab.tab}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <div className="tab-content border" >
                                                        {this.state.activeLeftBoxTab ===1 && <div className="tab-pane active" role="tabpanel" aria-labelledby="one-tab" > <RadarChart/> </div>}
                                                        {this.state.activeLeftBoxTab ===2 && <div className="tab-pane active" id="two" role="tabpanel" aria-labelledby="two-tab">2 </div>     }
                                                        {this.state.activeLeftBoxTab ===3 && <div className="tab-pane  active" id="three" role="tabpanel" aria-labelledby="three-tab">3</div> } 
                                                        {this.state.activeLeftBoxTab ===4 && <div className="tab-pane active" id="four" role="tabpanel" aria-labelledby="four-tab">4</div>    }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* End Chart Card */}

                                        {/* Start TrackeTrend Chart */}
                                        <CompanyTrackerTrend />
                                        {/* End TrackeTrend Chart */}
                                    </div>
                                </div>
                            {/* leftSide-container end */}

                            {/* Middle Container*/}
                                <div className="col-sm-12 col-md-9">
                                
                                    <div className="row ">
                                        <div className="col">
                                            <div className="userMainProfile ">
                                                <div className="timeLine profileBgImgConatiner" style={{backgroundImage:"url("+ comdet.cover_image +")"}} ></div>
                                                <div className="ProfilenavigationContainer">
                                                    <div className="time_action col-sm-12 col-md-4" style={{paddingLeft:"0px"}}>
                                                            {/* Start Action Btn Card */}
                                                                    { (!this.state.isSelfCompany) && <CompanyActionBtns isSelfCompany={this.state.isSelfCompany} companyDetails={this.state.companyDetails} loader={this.loader} state={this.state}/> }
                                                            {/* End Action Btn Card */}
                                                        </div>
                                                    <div className="navigations col-sm-12 col-md-8" style={{paddingRight:"0px"}}>
                                                        <ul>        
                                                            {this.state.bannerTabs.map((tab,idx)=>(
                                                                <li key={idx}  className={tab.id === this.state.activeBannerTab ? 'active' :''}>
                                                                    <Link to={`/company-timeline/${tab.url}`} onClick={this.bannerTabHandler(tab.id)}>
                                                                    {tab.id && tab.id===6 ? 
                                                                        <i className="fa fa-cogs"></i>
                                                                    : 
                                                                    tab.id===5 ?
                                                                        <div>
                                                                            <span style={{display: "block", width: "100%", textAlign: "center"}}>
                                                                                <span className="badge badge-secondary">
                                                                                    {this.state.reviewCount!==null && this.state.reviewCount!=='' ? this.state.reviewCount : '0' }
                                                                                    {this.state.reviewCount>=1000 ? 'K':''}
                                                                                </span>
                                                                            </span> 
                                                                            {tab.tab}
                                                                        </div> 
                                                                    :
                                                                    tab.tab} 
                                                                    </Link>
                                                                </li>
                                                            ))} 
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div className="row no-gutters">
                                        {this.state.activeBannerTab === 2  ? <> 
                                            <div className="col-md-8 col-sm-12 middle-container timeline-middle">
                                                { (this.state.isSelfCompany) && <div className="box bg-white border creatPostSection">
                                                        <div className="box-header">
                                                            <div className="title">
                                                                <span className="text-black-50 link" data-toggle="modal" data-target="#newPostModal" style={{cursor:'pointer'}} onClick={()=>this.setState({selectedPostData: null})}><i className="flaticon-edit-1" /> Start a Post</span>
                                                            </div>
                                                            <div>
                                                                <ul className="iconList text-black-50">
                                                                    <li><span data-toggle="modal" data-target="#newPostModal" style={{cursor:'pointer'}} onClick={()=>this.setState({selectedPostData: null})}><i className="flaticon-plus" /></span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="box-footer bg-light border-top padding-5 text-sm text-black-50">
                                                            <span className="text-bold "> Write an article </span> on COMRATE
                                                        </div>
                                                    </div>
                                                }

                                                { (this.props.loading.GET_POST_LIST_ACTION) 
                                                    ? <div className="box bg-white border "><SimpleLoader/></div> 
                                                    : (comdet.company_id && this.state.items.length > 0) 
                                                        ? <NewsFeed goToTimeLine={this.welcomeToTimeLine} isSelfCompany={this.state.isSelfCompany} page={'company_timeline'} state={this.state} fetchMoreData={this.fetchMoreData} setSelectedPostData={this.setSelectedPostData} {...this.props}/>
                                                        : <BlankPageMessage message = {this.state.errorMessage} />
                                                }
                                            </div>
                                               
                                            <div className="col-md-4 col-sm-12 rightSide-container ">    
                                                <div className="inner sticky">
                                                { this.state.companyId!=='' && <FollowersFollowings companyDetails={this.state.companyDetails}/>}
                                                    <CompanyCommunity companyDetails={this.state.companyDetails}/>
                                                    <Advertisment/>
                                                    <SuggestedPages/>
                                                </div>                   
                                            </div>
                                        </> : null }
                                        
                                        <div className="col-12 middle-container padding-right-0">
                                        
                                            {this.state.activeBannerTab ==='edit' &&  <CompanyAboutForm handleAlertOpen={this.handleOpen} handleComponentShow={this.handleComponentShow}/> }
                                            {this.state.activeBannerTab ===1 &&  <CompanyAbout  isSelfCompany={this.state.isSelfCompany} handleAlertOpen={this.handleOpen} handleComponentShow={this.handleComponentShow} /> }
                                            {this.state.activeBannerTab ===3 && <Connection goToTimeLine={this.welcomeToTimeLine} handleAlertOpen={this.handleOpen} /> }
                                            {/* {this.state.activeBannerTab ===4 && <RatingForm companyId={this.state.companyId} reviewCounter={this.reviewCounter}/> }  */}
                                            {this.state.companyId && this.state.activeBannerTab ===5 && <Review {...this.props} companyId={this.state.companyId}/> } 
                                            {this.state.activeBannerTab ===6 && <CompanyPageSettings companyId={this.state.companyId}/> }
                                            {this.state.activeBannerTab ===7 && <SuggestedCompanies loader={this.loader} setSelectedCompId={this.setSelectedCompId} {...this.props}/> }
                                        </div>
                                    </div>
            
                                </div>
                            {/* Middle Container end */}
                        </div>
                        <AlertBox title={this.dialogTitle} content={this.state.alertMessage}
                                btnText={this.btnText} open={!!this.state.isShowAlert}
                                onClose={() => this.handleClose()} />
                    </div>
                </div>
            </main>
            <Footer />
            {/* loader Start */}
                <Loader onRef={ref => {this.loader = ref;}}/>
            {/* loader Start */}
            <AddPostModal state={this.state}  newAddedItem={this.newAddedItem} updatedPostItem={this.updatedPostItem}/>
            <CompanyConnectionModal state={this.state} connectionCompanyHandler={this.connectionCompanyHandler} onChangeHandler={this.onChangeHandler}/>
            <RatingModal reviewCounter={this.reviewCounter} companyId={this.state.companyId}/>
    </div>

        )
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    reviewerData: state.apiData.reviewerData,
    postData: state.appData.newAddedPostData,
    postListData: state.appData.postListData,
    companyDetails: state.appData.companyDetails,
    ownCompanyDetails: state.appData.ownCompanyDetails,
    otherCompanyDetails: state.appData.otherCompanyDetails,
    companyFollowRes: state.appData.companyFollowRes,
    sentConnReqRes: state.appData.sentConnReqRes,
    pokeData:state.apiData.pokeData,
    loading: state.loading,  
  });
function mapDispatchToProps(dispatch) {
    return {
        //addNewPostAction: bindActionCreators(addNewPostAction, dispatch),
        getPostListAction: bindActionCreators(getPostListAction, dispatch),
        sentConnectionRequestAction: bindActionCreators(sentConnectionRequestAction, dispatch),
        getReviewBycompanyidAction: bindActionCreators(getReviewBycompanyidAction, dispatch),
        getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
        setSelectedOtherCompanyInfoAction: bindActionCreators(setSelectedOtherCompanyInfoAction, dispatch), 
        companyPokeAction:bindActionCreators(companyPokeAction, dispatch),

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyDashboard);