import React from "react";
// import { Dialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from 'material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

class AlertBox extends React.Component {

    handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
          }
    }

    dialogStyle = {
        color: "#070709"
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MuiTypography: {
                body1: {
                    fontSize: "14px"
                }
            },

            MuiButton: {
                root: {
                    fontSize: "14px"
                }
            },

            // MuiTypography:{
            //     colorTextSecondary:{
            //         color: "#070709"
            //     }
            // }

            // MuiDialog: {
            //     paperWidthSm: {
            //         maxWidth: "400px",
            //         maxHeight: "173px"
            //     }
            // }
        }
    });

    
    render() {
        const {title, content, btnText, open} = this.props;
        return (
            <MuiThemeProvider theme={this.getMuiTheme()}>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" style={this.dialogStyle}>
                           {content}
                    </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {btnText}
                    </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        );
    }
}

export default AlertBox;