import React, { Component } from 'react'

export default class FollowersFollowings extends Component {
    render() {
        const company=this.props.companyDetails
        if(company===null){
            return null;
        }
        const followers = company.totalFollowers;
        const followings = company.totalFollowing;
      
        return (
            <div className="box bg-white border shadow-sm">
            <div className="follow_count">
            
                    
                            <div>
                                { followers !=='' && 
                                    <h5><a href="#"> Follower &nbsp; {followers} </a></h5>
                                    
                                }
                            </div>
                            <div>
                                { followings !=='' && 
                                    <h5><a href="#"> Following &nbsp; {followings}</a></h5>
                                
                                }
                               
                            </div>
                        
                </div>
            </div>
            )
        }
    }
