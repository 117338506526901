//Application Header
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { userLogoutAction } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import '../../../style/css/styleWeb.css';
class HomeHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  openModal(type, e) {
    this.props.openModal(type);
  }
  logout = async ()=>{
    await this.props.userLogoutAction();
    //this.props.history.push('/');
    window.location.assign('/');
  }
  render() {
    const logo = 'images/logo/logo.png';
    const { userData, headerType } = this.props;
    let fName = '';
    let isUserData = false;
    if(userData!==null && userData!==undefined){
      if(userData.data!==null && userData.data!==undefined){
        isUserData = true;
        if(userData.data.first_name!==undefined){
          fName = userData.data.first_name;
        }
      }
    }
    if(headerType==="home"){
      return (
        <header id="header" className=" offscroll">
          <div className="container h-100">
            <div className="row d-flex align-items-center">
              <div className="logo col-sm-6 padding-0">
                <Link to="/home"><img alt="" src={logo} /> </Link>
              </div>
              <div className="topMenu col-sm-6 padding-0">
                <ul>
                { !isUserData ? <>
                  <li> 
                    
                    <Link to="#" id="signupbtn" className="btn btn-default" data-toggle="modal" data-target="#signUpModal" onClick={this.openModal.bind(this, "signUpModal")}>
                      <i className="flaticon-user" />
                      <span>Sign Up</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="btn btn-default" id="loginbtn" data-toggle="modal" data-target="#loginModal" onClick={this.openModal.bind(this, "loginModal")}>
                      <i className="flaticon-login" />
                      <span>Login</span>
                    </Link>
                  </li></>
                  : 
                  <li className="dropdown">
                  <button type="button" className="btn userBtn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img alt="" src="images/user.svg" />                    
                  </button>
                  <p className="userName">{ fName }</p>
                  <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                  <a href="/user"><span className="dropdown-item act-span">User Dashboard </span></a>
                    <span className="dropdown-item act-span" id="logout-btn" onClick={this.logout}> Logout </span>
                  </div>
                </li>
              }
                </ul>
              </div>
            </div>
          </div>
        </header>
      );
    } 
    return (<div>&nbsp;</div> );
  }
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
});
function mapDispatchToProps(dispatch) {
  return {
    userLogoutAction: bindActionCreators(userLogoutAction, dispatch),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeHeader);
