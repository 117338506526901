import React,{Component} from 'react';
import { Link } from "react-router-dom";
// import Helper from "../../../../Configs/Helper";
export default class CompanyPagesLinks extends Component{
    constructor(props) {
        super(props);
        this.state = { }
    }
    render(){
        return(
            <div className="box border">
                <div className="box-body padding-0">
                <ul className="withIconList list">               
                    <li><Link to={`/company-timeline/about`}><i className="flaticon-user" /> About </Link></li>
                    <li><Link to={`/company-timeline/timeline`}><i className="flaticon-alarm-clock-1" /> Timeline </Link></li>
                    <li><Link to={`/company-timeline/members`}><i className="flaticon-users" /> Networks </Link></li>
                </ul>
                </div>
            </div>
        )
    }
}