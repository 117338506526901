import * as types from '../actions/types'
//import { updateObject } from '../../utils/HelperFunc';


const initialState = {
    pending: false,
    error: null,
    userPersonalData: null,
    addRatingData: null,
    reviewerData:null,
    connectionStatus:null,
    conSuggestionCompanies:null,
    editPostComments:null,
    deleteComments:null,
    pokeData:null,
    getpokeData:null,
    pokeDetails:null,
    updatepokeData:null,
    unfollow:null,
    getNotificationsres:null,
    updateReadStatusres:null,
    typeaheadSearchresult:null,
    
}

export default (state = initialState, action)=> {
    const { type, payload, error } = action;
    switch(type) {
        case types.UPDATE_USER_ACTION_REQUEST: 
            return { ...state }
        case types.UPDATE_USER_ACTION_SUCCESS:
            return { ...state, userPersonalData: payload }
        case types.UPDATE_USER_ACTION_FAILURE:
            return { ...state, userPersonalData: payload }

        case types.ADD_RATING_ACTION_REQUEST: 
            return { ...state }
        case types.ADD_RATING_ACTION_SUCCESS:
            return { ...state, addRatingData: payload }
        case types.ADD_RATING_ACTION_FAILURE:
            return { ...state, addRatingData: payload }

            case types.GET_REVIEW_BY_COMPANY_ID_ACTION_REQUEST: 
            return { ...state }
        case types.GET_REVIEW_BY_COMPANY_ID_ACTION_SUCCESS:
            return { ...state, reviewerData: payload }
        case types.GET_REVIEW_BY_COMPANY_ID_ACTION_FAILURE:
            return { ...state, reviewerData: payload }
        
            case types.GET_CONNECTION_STATUS_BY_COMPANY_REQUEST: 
            return { ...state }
        case types.GET_CONNECTION_STATUS_BY_COMPANY_SUCCESS:
            return { ...state, connectionStatus: payload }
        case types.GET_CONNECTION_STATUS_BY_COMPANY_FAILURE:
            return { ...state, connectionStatus: payload }

            case types.GET_CONNECTION_SUGGESTION_COMPANIES_REQUEST: 
            return { ...state }
        case types.GET_CONNECTION_SUGGESTION_COMPANIES_SUCCESS:
            return { ...state, conSuggestionCompanies: payload }
        case types.GET_CONNECTION_SUGGESTION_COMPANIES_FAILURE:
            return { ...state, conSuggestionCompanies: payload }
            
            case types.UPDATE_POST_COMMENTS_REQUEST: 
            return { ...state }
        case types.UPDATE_POST_COMMENTS_SUCCESS:
            return { ...state, editPostComments: payload }
        case types.UPDATE_POST_COMMENTS_FAILURE:
            return { ...state, editPostComments: payload }
    
            case types.DELETE_POST_COMMENTS_REQUEST: 
            return { ...state }
        case types.DELETE_POST_COMMENTS_SUCCESS:
            return { ...state, deleteComments: payload }
        case types.DELETE_POST_COMMENTS_FAILURE:
            return { ...state, deleteComments: payload }

            case types.COMPANY_POKE_ACTION_REQUEST: 
            return { ...state }
        case types.COMPANY_POKE_ACTION_SUCCESS:
            return { ...state, pokeData: payload }
        case types.COMPANY_POKE_ACTION_FAILURE:
            return { ...state, pokeData: payload }

            case types.GET_COMPANY_POKE_ACTION_REQUEST: 
            return { ...state }
        case types.GET_COMPANY_POKE_ACTION_SUCCESS:
            return { ...state, getpokeData: payload }
        case types.GET_COMPANY_POKE_ACTION_FAILURE:
            return { ...state, getpokeData: payload }

            case types.GET_COMPANY_POKE_BY_STATUS_ACTION_REQUEST: 
            return { ...state }
        case types.GET_COMPANY_POKE_BY_STATUS_ACTION_SUCCESS:
            return { ...state, pokeDetails: payload }
        case types.GET_COMPANY_POKE_BY_STATUS_ACTION_FAILURE:
            return { ...state, pokeDetails: payload }

            case types.UPDATE_COMPANY_POKE_ACTION_REQUEST: 
            return { ...state }
        case types.UPDATE_COMPANY_POKE_ACTION_SUCCESS:
            return { ...state, updatepokeData: payload }
        case types.UPDATE_COMPANY_POKE_ACTION_FAILURE:
            return { ...state, updatepokeData: payload }

            case types.COMPANY_UNFOLLOW_ACTION_REQUEST: 
            return { ...state }
        case types.COMPANY_UNFOLLOW_ACTION_SUCCESS:
            return { ...state, unfollow: payload }
        case types.COMPANY_UNFOLLOW_ACTION_FAILURE:
            return { ...state, unfollow: payload }

            case types.GET_NOTIFICATIONS_REQUEST: 
            return { ...state }
        case types.GET_NOTIFICATIONS_SUCCESS:
            return { ...state, getNotificationsres: payload }
        case types.GET_NOTIFICATIONS_FAILURE:
            return { ...state, getNotificationsres: payload }

            case types.UPDATE_READ_STATUS_REQUEST: 
            return { ...state }
        case types.UPDATE_READ_STATUS_SUCCESS:
            return { ...state, updateReadStatusres: payload }
        case types.UPDATE_READ_STATUS_FAILURE:
            return { ...state, updateReadStatusres: payload }

            case types.GET_TYPE_AHEAD_SEARCH_RESULT_REQUEST: 
            return { ...state }
        case types.GET_TYPE_AHEAD_SEARCH_RESULT_SUCCESS:
            return { ...state, typeaheadSearchresult: payload }
        case types.GET_TYPE_AHEAD_SEARCH_RESULT_FAILURE:
            return { ...state, typeaheadSearchresult: payload }

            default: 
            return state;
    }
}