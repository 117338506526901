import React from "react";
import { isSupported, messaging } from "../../../init-fcm";
import { saveFirebaseToken } from "../../../api/agent"
//import { saveFirebaseToken } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const FIREBASE_APP = process.env.REACT_APP_FIREBASE_WEB_APP;

class Firebase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account_id: ''
        }
    }
    componentDidMount() {
        this.props.onRef(this);
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("./firebase-messaging-sw.js")
                .then(function (registration) {
                    console.log("Registration successful, scope is:", registration.scope);
                })
                .catch(function (err) {
                    console.log("Service worker registration failed, error:", err);
                });
        }
    }
    isTokenSentToServer = () => {
        return localStorage.getItem('comrate_firebase_token') === '1';
    }
    setTokenSentToServer = (sent) => {
        localStorage.setItem('comrate_firebase_token', sent ? '1' : '0');
    }
    getRegistrationToken = () => {
        if(isSupported){
            const _this = this
            const accountId = this.props.userData.data.account_id
            messaging.getToken().then(function (currentToken) {
                if (currentToken) {
                    console.log("");
                    console.log("CURRENT TOKEN FOR: "+accountId);
                    console.log(currentToken);
                    console.log("");
                    let request_params = {
                        firebase_token: currentToken,
                        account_id: accountId,
                        platform: "web",
                        app_name: FIREBASE_APP
                    };

                    saveFirebaseToken(request_params, "firebaseTokenData").then(function (response) {
                        console.log("");
                        console.log("RESPONSE OF SAVE FIREBASE TOKEN");
                        console.log(response);
                        console.log("");
                        if (response.firebaseTokenData.status === 200) {
                            _this.sendTokenToServer(currentToken);
                        }
                    });
                } else {
                    console.log('No Instance ID token available. Request permission to generate one.');
                    _this.setTokenSentToServer(false);
                }
            }).catch(function (err) {
                console.log('An error occurred while retrieving token. ', err);
                _this.setTokenSentToServer(false);
            });
        }
    }
    sendTokenToServer = (currentToken) => {
        if (!this.isTokenSentToServer()) {
            console.log('Sending token to server...');
            this.setTokenSentToServer(true);
        } else {
            console.log('Token already sent to server so won\'t send it again ' + 'unless it changes');
        }
    }

    fcmConfiguration = () => {
        if(isSupported){
            const _this = this
            messaging.requestPermission().then(function () {
                if (_this.isTokenSentToServer()) {
                    console.log("Token already sent");
                } else {
                    console.log("getting new registration token");
                    _this.getRegistrationToken();
                }
            }).catch(function (err) {
                console.log("Unable to get permission to notify.", err);
            });

            navigator.serviceWorker.addEventListener("message", function (message) {
                console.log(message);
                let title = message.data["firebase-messaging-msg-data"].notification.title;
                let body = message.data["firebase-messaging-msg-data"].notification.body;
                let icon = message.data["firebase-messaging-msg-data"].notification.icon;
                let click_action = message.data["firebase-messaging-msg-data"].notification.click_action;
                const options = {
                    body: body,
                    icon: icon,
                    data: {
                        click_action: click_action
                    }
                };
                let myNotification = new Notification(title, options);
            });

            messaging.onTokenRefresh(function () {
                console.log("token refresh called");
                messaging.getToken().then(function (refreshedToken) {
                    console.log('Token refreshed: '+refreshedToken);
                    alert(refreshedToken)
                    _this.setTokenSentToServer(false);
                    _this.sendTokenToServer(refreshedToken);
                }).catch(function (err) {
                    console.log('Unable to retrieve refreshed token ', err);
                });
            });
        }
    }
    render() {
        return null;
    }
}
    const mapStateToProps = state => ({
        userData: state.auth.userData,
        loading: state.loading,
      });
    export default connect(
        mapStateToProps,
        null
    )(Firebase);