import * as types from './types'
import * as Api from '../../api/agent'
// import Helper from '../../Configs/Helper'
import { dispatchAction } from '../../utils/HelperFunc';

export const generateTokenAction = (data) => { 
    return async dispatch => {
        function generateTokenActionSuccess(payload) {
            return dispatch({
                type: types.GENERATE_TOKEN_ACTION_SUCCESS,
                payload: payload
            })
        }

        function generateTokenActionFail(error) {
            return dispatch({
                type: types.GENERATE_TOKEN_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GENERATE_TOKEN_ACTION_REQUEST })
            const payload = await Api.generateTokenAction(data, 'tokenData')
            if (payload.tokenData.data!==undefined) {
                const { token } = payload.tokenData.data
                if(token!==undefined) await localStorage.setItem('AUTH_TOKEN', token);
            }
            dispatchAction(
                payload.tokenData,
                generateTokenActionSuccess,
                generateTokenActionFail
            )
        } catch (error) {
            dispatch(generateTokenActionFail(error))
        }
    }
}

export const registerByEmailAction = (data) => { 
    return async dispatch => {
        function registerByEmailActionSuccess(payload) {
            return dispatch({
                type: types.REGISTER_BY_EMAIL_ACTION_SUCCESS,
                payload: payload
            })
        }

        function registerByEmailActionFail(error) {
            return dispatch({
                type: types.REGISTER_BY_EMAIL_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.REGISTER_BY_EMAIL_ACTION_REQUEST })
            let payload = await Api.registerByEmailAction(data, 'signupData')
            dispatchAction(
                payload.signupData,
                registerByEmailActionSuccess,
                registerByEmailActionFail
            )
        } catch (error) {
            dispatch(registerByEmailActionFail(error))
        }
    }
}

export const loginByEmailAction = (data) => { 
    return async dispatch => {
        function loginByEmailActionSuccess(payload) {
            return dispatch({
                type: types.LOGIN_BY_EMAIL_ACTION_SUCCESS,
                payload: payload
            })
        }

        function loginByEmailActionFail(error) {
            return dispatch({
                type: types.LOGIN_BY_EMAIL_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.LOGIN_BY_EMAIL_ACTION_REQUEST })
            let payload = await Api.loginByEmailAction(data, 'loginData')
            if (!payload.error) {
                if(payload.loginData.status===200){
                    const { access_token } = payload.loginData.data
                    await localStorage.setItem('ACCESS_TOKEN', access_token);
                }
            }
            dispatchAction(
                payload.loginData,
                loginByEmailActionSuccess,
                loginByEmailActionFail
            )
        } catch (error) {
            dispatch(loginByEmailActionFail(error))
        }
    }
}

export const loginBySocialAction = (data) => { 
    return async dispatch => {
        function loginBySocialActionSuccess(payload) {
            return dispatch({
                type: types.LOGIN_BY_EMAIL_ACTION_SUCCESS,
                payload: payload
            })
        }

        function loginBySocialActionFail(error) {
            return dispatch({
                type: types.LOGIN_BY_EMAIL_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.LOGIN_BY_EMAIL_ACTION_REQUEST })
            let payload = await Api.loginBySocialAction(data, 'loginData')
            if (!payload.error) {
                const { access_token } = payload.loginData.data
                await localStorage.setItem('ACCESS_TOKEN', access_token);
            }
            dispatchAction(
                payload.loginData,
                loginBySocialActionSuccess,
                loginBySocialActionFail
            )
        } catch (error) {
            dispatch(loginBySocialActionFail(error))
        }
    }
}

// send otp : reset password time
export const sendOtpAction = (data) => { 
    return async dispatch => {
        function sendOtpActionSuccess(payload) {
            return dispatch({
                type: types.SEND_OTP_ACTION_SUCCESS,
                payload: payload
            })
        }

        function sendOtpActionFail(error) {
            return dispatch({
                type: types.SEND_OTP_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.SEND_OTP_ACTION_REQUEST })
            let payload = await Api.sendOtpAction(data, 'otpData')
            dispatchAction(
                payload.otpData,
                sendOtpActionSuccess,
                sendOtpActionFail
            )
        } catch (error) {
            dispatch(sendOtpActionFail(error))
        }
    }
}
export const otpVerifyResetPassAction = (data) => { 
    return async dispatch => {
        function otpVerifyResetPassActionSuccess(payload) {
            return dispatch({
                type: types.RESET_PASS_OTP_VERIFY_ACTION_SUCCESS,
                payload: payload
            })
        }

        function otpVerifyResetPassActionFail(error) {
            return dispatch({
                type: types.RESET_PASS_OTP_VERIFY_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.RESET_PASS_OTP_VERIFY_ACTION_REQUEST })
            let payload = await Api.otpVerifyResetPassAction(data, 'otpVerifyData')
            if (!payload.error) {
                if(payload.otpVerifyData.status===200){
                    const { token } = payload.otpVerifyData.data.data
                    await localStorage.setItem('AUTH_TOKEN', token);
                }
            }
            
            dispatchAction(
                payload.otpVerifyData,
                otpVerifyResetPassActionSuccess,
                otpVerifyResetPassActionFail
            )
        } catch (error) {
            dispatch(otpVerifyResetPassActionFail(error))
        }
    }
}

export const resetPasswordAction = (data) => { 
    return async dispatch => {
        function resetPasswordActionSuccess(payload) {
            return dispatch({
                type: types.RESET_PASSWORD_ACTION_SUCCESS,
                payload: payload
            })
        }

        function resetPasswordActionFail(error) {
            return dispatch({
                type: types.RESET_PASSWORD_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.RESET_PASSWORD_ACTION_REQUEST })
            let payload = await Api.resetPasswordAction(data, 'resetPasswordData')
            dispatchAction(
                payload.resetPasswordData,
                resetPasswordActionSuccess,
                resetPasswordActionFail
            )
        } catch (error) {
            dispatch(resetPasswordActionFail(error))
        }
    }
}

export const getUserByAccountIdAction = (accountId) => { 
    return async dispatch => {
        function getUserByAccountIdActionSuccess(payload) {
            return dispatch({
                type: types.USER_BY_ACCOUNT_ID_ACTION_SUCCESS,
                payload: payload
            })
        }

        function getUserByAccountIdActionFail(error) {
            return dispatch({
                type: types.USER_BY_ACCOUNT_ID_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.USER_BY_ACCOUNT_ID_ACTION_REQUEST })
            let payload = await Api.getUserByAccountIdAction(accountId, 'userData')
            if (!payload.error) {
                // const { access_token,company_profiles } = payload.userData.data
                // if(company_profiles!==null && company_profiles!==undefined ){
                //     if(company_profiles.length>0){
                //         Helper.setDefaultCompany(company_profiles[0])
                //     }
                // }
            }
            
            dispatchAction(
                payload.userData,
                getUserByAccountIdActionSuccess,
                getUserByAccountIdActionFail
            )
        } catch (error) {
            dispatch(getUserByAccountIdActionFail(error))
        }
    }
}

export const otpVerifyAction = (data) => { 
    return async dispatch => {
        function otpVerifyActionSuccess(payload) {
            return dispatch({
                type: types.OTP_VERIFY_ACTION_SUCCESS,
                payload: payload
            })
        }

        function otpVerifyActionFail(error) {
            return dispatch({
                type: types.OTP_VERIFY_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.OTP_VERIFY_ACTION_REQUEST })
            const payload = await Api.otpVerifyAction(data, 'otpData');
            if (!payload.error) {
                if(payload.otpData.status===200){
                    const { access_token } = payload.otpData.data
                    await localStorage.setItem('ACCESS_TOKEN', access_token);
                }
            }
            dispatchAction(
                payload.otpData,
                otpVerifyActionSuccess,
                otpVerifyActionFail
            )
        } catch (error) {
            dispatch(otpVerifyActionFail(error))
        }
    }
}

export const resendOtpAction = async (data) => {
    const res = await Api.resendOtpAction(data,'otpResendData');
    return (res);
}

export const saveCompanyAction = (data, key) => { 
    let typeRequest='';
    let typeFailur='';
    let typeSuccess='';
    if(key==='savedProfileInfo'){
        typeRequest=types.SAVE_COMPANY_INFO_ACTION_SUCCESS;
        typeFailur=types.SAVE_COMPANY_INFO_ACTION_SUCCESS;
        typeSuccess=types.SAVE_COMPANY_INFO_ACTION_SUCCESS;
    } else if(key==='savedAddressInfo'){
        typeRequest=types.SAVE_COMPANY_ADDRESS_ACTION_SUCCESS;
        typeFailur=types.SAVE_COMPANY_ADDRESS_ACTION_SUCCESS;
        typeSuccess=types.SAVE_COMPANY_ADDRESS_ACTION_SUCCESS;
    } else if(key==='savedFounderInfo'){
        typeRequest=types.SAVE_COMPANY_FOUNDER_ACTION_SUCCESS;
        typeFailur=types.SAVE_COMPANY_FOUNDER_ACTION_SUCCESS;
        typeSuccess=types.SAVE_COMPANY_FOUNDER_ACTION_SUCCESS;
    } else if(key==='savedTeamInfo'){
        typeRequest=types.SAVE_COMPANY_TEAM_ACTION_SUCCESS;
        typeFailur=types.SAVE_COMPANY_TEAM_ACTION_SUCCESS;
        typeSuccess=types.SAVE_COMPANY_TEAM_ACTION_SUCCESS;
    }
    return async dispatch => {
        function saveCompanyActionSuccess(payload) {
            return dispatch({
                type: typeSuccess,
                payload: payload
            })
        }

        function saveCompanyActionFail(error) {
            return dispatch({
                type: typeFailur,
                payload: error
            })
        }
        try {
            dispatch({ type: typeRequest })
            const payload = await Api.saveCompanyAction(data, key)
            dispatchAction(
                payload[key],
                saveCompanyActionSuccess,
                saveCompanyActionFail
            )
        } catch (error) {
            dispatch(saveCompanyActionFail(error))
        }
    }
}

export const saveCompanyPostAction = (data,formData, key) => { 
    let typeRequest='';
    let typeFailur='';
    let typeSuccess='';
    if(key==='savedProfileInfo'){
        typeRequest=types.SAVE_COMPANY_INFO_ACTION_SUCCESS;
        typeFailur=types.SAVE_COMPANY_INFO_ACTION_SUCCESS;
        typeSuccess=types.SAVE_COMPANY_INFO_ACTION_SUCCESS;
    } else if(key==='savedAddressInfo'){
        typeRequest=types.SAVE_COMPANY_ADDRESS_ACTION_SUCCESS;
        typeFailur=types.SAVE_COMPANY_ADDRESS_ACTION_SUCCESS;
        typeSuccess=types.SAVE_COMPANY_ADDRESS_ACTION_SUCCESS;
    } else if(key==='savedFounderInfo'){
        typeRequest=types.SAVE_COMPANY_FOUNDER_ACTION_SUCCESS;
        typeFailur=types.SAVE_COMPANY_FOUNDER_ACTION_SUCCESS;
        typeSuccess=types.SAVE_COMPANY_FOUNDER_ACTION_SUCCESS;
    } else if(key==='savedTeamInfo'){
        typeRequest=types.SAVE_COMPANY_TEAM_ACTION_SUCCESS;
        typeFailur=types.SAVE_COMPANY_TEAM_ACTION_SUCCESS;
        typeSuccess=types.SAVE_COMPANY_TEAM_ACTION_SUCCESS;
    }
    return async dispatch => {
        function saveCompanyActionSuccess(payload) {
            return dispatch({
                type: typeSuccess,
                payload: payload
            })
        }

        function saveCompanyActionFail(error) {
            return dispatch({
                type: typeFailur,
                payload: error
            })
        }
        try {
            dispatch({ type: typeRequest })
            const payload = await Api.saveCompanyPostAction(data,formData, key)
            dispatchAction(
                payload[key],
                saveCompanyActionSuccess,
                saveCompanyActionFail
            )
        } catch (error) {
            dispatch(saveCompanyActionFail(error))
        }
    }
}
export const saveInterestAction = (data) => { 
   
    return async dispatch => {
        function saveCompanyActionSuccess(payload) {
            return dispatch({
                type: types.SAVE_INTEREST_ACTION_SUCCESS,
                payload: payload
            })
        }

        function saveCompanyActionFail(error) {
            return dispatch({
                type: types.SAVE_INTEREST_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.SAVE_INTEREST_ACTION_REQUEST })
            const payload = await Api.saveInterestAction(data,'interestData')
            dispatchAction(
                payload.interestData,
                saveCompanyActionSuccess,
                saveCompanyActionFail
            )
        } catch (error) {
            dispatch(saveCompanyActionFail(error))
        }
    }
}
export const userLogoutAction = () => { 
    return async (dispatch) => {
        await localStorage.setItem('ACCESS_TOKEN', '');
        await localStorage.setItem('AUTH_TOKEN', '');
        await localStorage.setItem('COMPANY_ID', '');
        await localStorage.setItem('OTHER_COMPANY_ID', '');
        return dispatch({
            type: types.LOGOUT_ACTION_REQUEST,
            payload: null
        })
    }
    // return true;
}